<template>
   <div class="container-fluid py-4">
      <div class="card-body p-4">
         <div class="row">
            <div class="col-md-3 mb-md-0 mb-4">
               <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                  <div class="input-group input-group-dynamic">
                     <label class="form-label"></label>
                     <input class="form-control" aria-label="" placeholder="Targa o codice" type="text" v-model="plate">
                  </div>
               </div>
            </div>
            <div class="col-md-3">
            </div>
            <div class="col-md-3">
            </div>
            <div class="col-md-3">
               <div class="card card-body card-plain d-flex align-items-center flex-row">
                  <div class="input-group input-group-dynamic ">
                     <a class="float-right align-bottom btn bg-gradient-dark mb-0" @click="deleteItemModal(plate)">
                        <i v-if="isInLoading" class="material-icons text-sm text-warning rotating">autorenew</i>
                        <i v-else class="material-icons text-sm">delete</i>
                        &nbsp;&nbsp;Vai!
                     </a>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div v-if="list.length" class="row">
         <div class="col-12 mt-4">
            <div class="card mb-4">
               <div class="card-header pb-0 p-3">
                  <h6>Log Processo</h6>
               </div>
               <div class="card-body px-0 pt-0 pb-2">
                  <div class="table-responsive p-0">
                     <table class="table align-items-center mb-0">
                        <thead>
                           <tr>
                              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">#</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr v-for="(item, index) in list" :key="index">
                              <td>
                                 <div class="d-flex px-3 py-1">
                                    <div class="d-flex flex-column justify-content-center">
                                       <p class="text-sm font-weight-normal text-secondary mb-0"><span
                                             class="text-info font-weight-bold">{{ item }}</span></p>
                                    </div>
                                 </div>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div v-else>
         <div class="col-12 mt-4">
            <div class="card mb-4">
               <div class="card-header pb-0 p-3">
                  <h6>
                     <span class="align-middle fs-4">Istruzioni </span>
                     <span class="material-icons material-symbols material-symbols-outlined color-trivellato align-middle">
                        info
                     </span>
                  </h6>
               </div>
               <div class="card-body px-0 pt-0 pb-2">
                  <div class="table-responsive p-0">
                     <p class="p-3 pt-0 pb-0">Scrivi nell'input qui sopra la targa del veicolo di cui vuoi eliminare le
                        immagini. In alternativa puoi anche specificare il codice completo del veicolo (Esempio:
                        <b>007N123456</b>)</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import fetchFromAPI from "../../script/api.js"
export default {
   data() {
      return {
         plate: '',
         list: [],
         isInLoading: false
      };
   },
   methods: {
      Delete() {
         this.isInLoading = true;
         fetchFromAPI("InfinityImageDelete/DeleteInfinityImageByPlate", { Plate: this.plate }, (res) => {
            this.list = res;
            this.isInLoading = false;
         }, (err) => {
            this.isInLoading = false;
            this.$toast.warning(err.response.data.Message);
         });
      },
      deleteItemModal(plate) {
         this.$swal.fire({
            title: 'Sicuro?',
            text: 'Vuoi cancellare tutte le foto relative alla targa ' + plate + '?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, vai!'
         }).then((result) => {
            if (result.isConfirmed) {
               this.Delete()
            }
         })
      }
   },

}
</script>