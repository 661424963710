<template>
    <link rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0" />
    <div class="container py-4 all-small">
        <div class="row justify-content-between">
            <div class="col-md-3">
                <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                    <div class="input-group input-group-dynamic trivellato">
                        <label class="form-label"></label>
                        <input type="date" class="form-control" :class="{ 'text-warning': invalidFilterDates }"
                            :disabled="!filters.Active" placeholder="Da" aria-label="" min="2010-01-01"
                            v-model="filters.DateFrom" v-on:keyup.enter="LoadData()">
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                    <div class="input-group input-group-dynamic trivellato">
                        <label class="form-label"></label>
                        <input type="date" class="form-control" :class="{ 'text-warning': invalidFilterDates }"
                            :disabled="!filters.Active" placeholder="a" aria-label="" min="2010-01-01"
                            v-model="filters.DateTo" v-on:keyup.enter="LoadData()">
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row  my-2">
                    <div class="form-check form-switch float-right">
                        <input class="form-check-input my-2" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                            v-model="filters.Active">
                        <label class="form-check-label my-0" for="flexSwitchCheckDefault">Includi auto in arrivo</label>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card card-body card-plain d-flex align-items-center flex-row">
                    <div class="input-group input-group-dynamic justify-content-end">
                        <a class="float-right align-bottom btn bg-gradient-dark mb-0" @click="searchVehicle()"
                            :class="{ 'disabled': isInLoading }">
                            <i v-if="isInLoading" class="material-icons text-sm text-warning rotating">autorenew</i>
                            <i v-else class="material-icons text-sm">search</i>
                            &nbsp;&nbsp;Cerca
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mb-3">
            <div class="card-body">
                <div class="card-body-container">
                    <h3>Informazioni
                        <a href="javascript:" @click="showHelp = !showHelp"
                            class="material-symbols-outlined float-end align-middle" v-if="showHelp">
                            visibility
                        </a>
                        <a href="javascript:" @click="showHelp = !showHelp"
                            class="material-symbols-outlined float-end align-middle" v-else>
                            visibility_off
                        </a>
                    </h3>
                    <div v-if="showHelp">
                        <p>Tramite questa interfaccia puoi vedere le vetture che non hanno foto che sono:</p>
                        <ul>
                            <li>Appartenenti a Trivellato Spa o PadovaStar</li>
                            <li>In stock</li>
                            <li>Vendibili</li>
                            <li>Senza contratto</li>
                        </ul>
                        <p>Nel caso in cui abilitassi il flag per la ricerca dei veicoli in arrivo puoi visualizzare anche i
                            veicoli che:</p>
                        <ul>
                            <li>Hanno data previsto arrivo compresa tra <i><b>data_inizio</b></i> e <i><b>data_fine</b></i>
                                impostabili nel
                                filtro sopra</li>
                            <li>Sono vendibili</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="card" v-if="isInLoading">
            <div class="card-body">
                <div class="card-body-container">
                    <div class="row text-center">
                        <h5 class="material-icons material-symbols-outlined rotating color-trivellato fs-1">
                            autorenew
                        </h5>
                        <h5>Ricerca dei veicoli da fotografare in corso</h5>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="vehiclesToShoot == null" class="card">
            <div class="card-body">
                <div class="card-body-container">
                    <div class="row text-center">
                        <h5 class="material-icons material-symbols-outlined color-trivellato fs-1">
                            search
                        </h5>
                        <h5>Avvia la ricerca per visualizzare i risultati</h5>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="card">
            <div class="overflow-auto rounded">
                <div class="bg-white rounded p-2" style="position: sticky; left: 0;"
                    v-if="vehiclesToShoot != null && vehiclesToShoot.length > 0">
                    <p>Veicoli da fotografare: <b>{{ filters.Active ? vehiclesToShootFiltered.length :
                        vehiclesToShootFiltered.filter(x => x.stock == 'In Stock').length }}</b></p>
                    <p>Data ultima ricerca: <b>{{ latestSearchDate || '-' }}</b></p>
                </div>
                <table class="table bg-white rounded" v-if="vehiclesToShoot != null && vehiclesToShoot.length > 0">
                    <thead>

                        <tr class="align-middle">
                            <th class="px-2">Id veicolo</th>
                            <th class="px-2">Telaio</th>
                            <th class="px-2">Marca</th>
                            <th class="px-2">Modello</th>
                            <th class="px-2">Versione</th>
                            <th class="px-2">Ubicazione</th>
                            <th class="px-2">Azienda</th>
                            <th class="px-2">Status</th>
                            <th class="px-2">Giacenza</th>
                            <!-- <th class="px-2"></th> -->
                            <th class="px-2"></th>
                        </tr>
                        <tr class="align-middle">
                            <th class="py-0 px-1"><input type="text" class="form-control border py-0 ps-1 my-1"
                                    placeholder="Cerca" v-model="idFilter" /></th>
                            <th class="py-0 px-1"><input type="text" class="form-control border py-0 ps-1 my-1"
                                    placeholder="Cerca" v-model="vinFilter" /></th>
                            <th class="py-0 px-1"><input type="text" class="form-control border py-0 ps-1 my-1"
                                    placeholder="Cerca" v-model="brandFilter" /></th>
                            <th class="py-0 px-1"><input type="text" class="form-control border py-0 ps-1 my-1"
                                    placeholder="Cerca" v-model="modelFilter" /></th>
                            <th class="py-0 px-1"><input type="text" class="form-control border py-0 ps-1 my-1"
                                    placeholder="Cerca" v-model="versionFilter" /></th>
                            <th class="py-0 px-1"><input type="text" class="form-control border py-0 ps-1 my-1"
                                    placeholder="Cerca" v-model="locationFilter" /></th>
                            <th class="py-0 px-1"><input type="text" class="form-control border py-0 ps-1 my-1"
                                    placeholder="Cerca" v-model="companyFilter" /></th>
                            <th class="py-0 px-1"><input type="text" class="form-control border py-0 ps-1 my-1"
                                    placeholder="Cerca" v-model="statusFilter" /></th>
                            <th class="py-0 px-1"></th>
                            <!-- <th class="py-0 px-1"></th> -->
                            <th class="py-0 px-1"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(vehicle, index) in vehiclesToShootFiltered" :key="index" class="align-middle">
                            <td>{{ vehicle.id }}</td>
                            <td>{{ vehicle.vin }}</td>
                            <td>{{ vehicle.marca }}</td>
                            <td>{{ vehicle.modello }}</td>
                            <td>{{ vehicle.versione }}</td>
                            <td>{{ vehicle.ubicazione }}</td>
                            <td>{{ vehicle.company }}</td>
                            <td>{{ vehicle.stock }}</td>
                            <td><span class="rounded px-1 text-white small"
                                    :class="{ 'bg-danger': vehicle.giacenza >= 150, 'bg-warning': vehicle.giacenza >= 60 && vehicle.giacenza < 150, 'bg-success': vehicle.giacenza >= 0 && vehicle.giacenza < 60 }">{{
                                        vehicle.giacenza }}</span></td>
                            <!-- <td>
                                <a>
                                    <span class="material-symbols-outlined align-bottom">
                                        remove_selection
                                    </span>
                                </a>
                            </td> -->
                            <td>
                                <a href="javascript:"
                                    class="material-icons material-symbols-outlined align-bottom color-trivellato float-end"
                                    @click="openPhotoSearch(vehicle)">
                                    arrow_circle_right
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<style scoped>
thead>tr>td:first-child {
    position: sticky;
    left: 0;
    background-color: white;
}

tbody tr>th:last-child,
tbody tr>td:last-child {
    position: sticky;
    right: 0;
    background-color: white;
}
</style>

<script>
import fetchFromAPI from "../../script/api.js"
import helpers from '../../script/helpers.js'
// import _ from 'lodash'

export default {

    data() {
        return {
            filters: {},
            stock: [
                {
                    label: "Nuovo",
                    value: 1
                },
                {
                    label: "Usato",
                    value: 2
                }
            ],
            isInLoading: false,
            vehiclesToShoot: null,
            StatusVeicoli: [
                {
                    label: "In stock",
                    value: "A"
                },
                {
                    label: "Virtuali",
                    value: "C"
                },
                {
                    label: "In stock + virtuali",
                    value: "AC"
                }
            ],
            idFilter: null,
            vinFilter: null,
            brandFilter: null,
            versionFilter: null,
            locationFilter: null,
            modelFilter: null,
            companyFilter: null,
            statusFilter: null,
            latestSearchDate: null,
            showHelp: true
        }
    },
    computed: {
        vehiclesToShootFiltered() {
            var resArray = this.vehiclesToShoot;
            if (!this.filters.Active) {
                resArray = resArray.filter((x) => x.stock == 'In Stock');
            }
            if (this.idFilter != null && this.idFilter != '') {
                resArray = resArray.filter((x) => x.id.includes(this.idFilter));
            }
            if (this.vinFilter != null && this.vinFilter != '') {
                resArray = resArray.filter((x) => x.vin.toLowerCase().includes(this.vinFilter.toLowerCase()));
            }
            if (this.companyFilter != null && this.companyFilter != '') {
                resArray = resArray.filter((x) => x.company.toLowerCase().includes(this.companyFilter.toLowerCase()));
            }
            if (this.brandFilter != null && this.brandFilter != '') {
                resArray = resArray.filter((x) => x.marca.toLowerCase().includes(this.brandFilter.toLowerCase()));
            }
            if (this.modelFilter != null && this.modelFilter != '') {
                resArray = resArray.filter((x) => x.modello.toLowerCase().includes(this.modelFilter.toLowerCase()));
            }
            if (this.versionFilter != null && this.versionFilter != '') {
                resArray = resArray.filter((x) => x.versione.toLowerCase().includes(this.versionFilter.toLowerCase()));
            }
            if (this.locationFilter != null && this.locationFilter != '') {
                resArray = resArray.filter((x) => x.ubicazione.toLowerCase().includes(this.locationFilter.toLowerCase()));
            }
            // if (this.companyFilter != null && this.companyFilter != '') {
            //     resArray = resArray.filter((x) => x.company.includes(this.companyFilter.toLowerCase()));
            // }
            return resArray;
        }
    },
    created() {
        fetchFromAPI("VehiclesToShoot/BindFiltersForHub", { Token: helpers.token() }, (res) => {
            res.DateFrom = localStorage.getItem('latestPhotoSearchVirtualDateFrom') != null ? new Date(localStorage.getItem('latestPhotoSearchVirtualDateFrom')).toISOString().slice(0, 10) : new Date(res.DateFrom).toISOString().slice(0, 10);
            res.DateTo = localStorage.getItem('latestPhotoSearchVirtualDateTo') != null ? new Date(localStorage.getItem('latestPhotoSearchVirtualDateTo')).toISOString().slice(0, 10) : new Date(res.DateTo).toISOString().slice(0, 10);
            this.filters = res;
            this.vehiclesToShoot = JSON.parse(localStorage.getItem('latestPhotoSearchVehicles')) || null;
            this.latestSearchDate = new Date(localStorage.getItem('latestPhotoSearchDate')).toLocaleString() || null;
        });
    },
    methods: {
        searchVehicle() {
            this.isInLoading = true;
            this.showHelp = false;
            fetchFromAPI("VehiclesToShoot/GetVehiclesToShoot", this.filters, (res) => {
                this.isInLoading = false;
                this.vehiclesToShoot = res;
                var now = new Date();
                this.latestSearchDate = now.toLocaleString();
                localStorage.setItem('latestPhotoSearchVirtualDateFrom', this.filters.DateFrom);
                localStorage.setItem('latestPhotoSearchVirtualDateTo', this.filters.DateTo);
                localStorage.setItem('latestPhotoSearchDate', now);
                localStorage.setItem('latestPhotoSearchVehicles', JSON.stringify(res));
            }, (/*err*/) => {
                this.isInLoading = false;
            });
        },
        openPhotoSearch(vehicle) {
            var targetCtrl = "VehiclePhotos";
            // var routeData  = this.$router.resolve({
            //     name: targetCtrl,
            //     params: {
            //         InitialCommissionNumber: vehicle.id,
            //         // InitialStock: vehicle.stock.toLowerCase() == 'nuovo' ? 1 : 2,
            //         InitialStock: vehicle.stock,
            //         AutoSearch: true
            //     }
            // });
            // window.open(routeData.href, '_blank');

            this.$router.push({
                name: targetCtrl,
                params: {
                    InitialCommissionNumber: vehicle.vin,
                    InitialStock: 1,
                    AutoSearch: true
                }
            });
        }
    }
}
</script>