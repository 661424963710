<template>
  <section>
     <div class="bg-gradient-dark position-relative mx-n3 overflow-hidden">
        <!--<img src="../../assets/img/shapes/pattern-lines.svg" alt="pattern-lines" class="position-absolute start-0 top-md-0 w-100 opacity-6">-->
        <img src="../../assets/img/dg3.jpg" alt="pattern-lines" class="position-absolute start-0 top-md-0 w-100 opacity-10">
        <div class="container pb-lg-8 pb-7 pt-5 postion-relative z-index-2 position-relative">
           <div class="row">
              <div class="col-md-7 mx-auto text-center">
                 <h3 class="text-white">Un focus sui Servizi Trivellato</h3>
                 <!--<p class="text-white">Based on the license you get, you will have direct access to our team <br> of developers who built the product.</p>-->
              </div>
           </div>
        </div>
     </div>
     <div class="mt-n6 mt-4">
        <div class="container">
           <div class="row mb-4" v-for="item in list" :key="item.Id">
              <div class="card">
                <span v-if="item.IsSuggested" class="badge bg-success text-white w-10 mt-n2">Suggerito</span>
                 <div class="row">
                    <div class="col-lg-8">
                       <div class="card-body">
                          <h3 class="text-dark">{{item.ServiceName}}</h3>
                          <p class="font-weight-normal">{{item.Note}}</p>
                          <div class="row mt-5 mb-2">
                             <div class="col-lg-3 col-12">
                                <h6 class="text-dark tet-uppercase">Altre info</h6>
                             </div>
                             <div class="col-6">
                                <hr class="horizontal dark">
                             </div>
                          </div>
                          <div class="row">
                             <div class="col-lg-6 col-md-6 col-12 ps-0">
                                <div class="d-flex align-items-center p-2">
                                  <i v-if="item.Active" class="material-icons my-auto text-dark">done</i>
                                  <i v-else class="material-icons my-auto text-danger">remove</i>
                                   <span class="ps-2">Attivo</span>
                                </div>
                                <div class="d-flex align-items-center p-2">
                                  <i v-if="item.ServiceStockConfig == 1 || item.ServiceStockConfig == 2" class="material-icons my-auto text-dark">done</i>
                                  <i v-else class="material-icons my-auto text-danger">remove</i>
                                  <span class="ps-2">Abbinabile vetture nuove</span>
                                </div>
                             </div>
                             <div class="col-lg-6 col-md-6 col-12 ps-sm-2 ps-0">
                                <div class="d-flex align-items-center p-2">
                                  <i v-if="item.ServiceStockConfig == 2 || item.ServiceStockConfig == 2" class="material-icons my-auto text-dark">done</i>
                                  <i v-else class="material-icons my-auto text-danger">remove</i>
                                  <span class="ps-2">Abbinabile vetture usate</span>
                                </div>
                             </div>
                          </div>
                          
                       </div>
                    </div>
                    <div class="col-lg-4 my-auto">
                       <div class="card-body text-center">
                          <h6 class="mt-sm-4 mt-0 mb-0">Provvigione Venditore</h6>
                          <h1 class="mt-0">
                             <small>                          
                                <span v-if="item.IsEnasarco">{{item.AgentCommission}}</span>
                                <span v-else>{{item.EmployeeCommission}}</span>
                              </small>
                          </h1>
                          <!--<button type="button" class="btn bg-gradient-danger btn-lg mt-2">Get Access</button>-->
                          <div class="d-flex">
                           <a @click="openDialog(item)" class="btn btn-sm bg-gradient-dark w-100 border-radius-md mt-4 mb-2">Dettagli</a>
                          </div>
                          <div v-if="item.CanSee" class="d-flex">
                              <a :href="'#edit_'+item.Id" class="btn btn-sm bg-gradient-info w-100 border-radius-md mt-1 mb-2"  data-bs-toggle="collapse" role="button" aria-expanded="false" :aria-controls="'edit_'+item.Id">Modifica</a>
                              <!--<router-link class="btn btn-sm bg-gradient-info w-100 border-radius-md mt-1 mb-2" :to="{path:'/financialservice/edit', query: {id:item.Id}, params:{service:item}}">Modifica</router-link>-->
                          </div>
                          <p class="text-sm font-weight-normal">*Leggi tutti i dettagli</p>
                       </div>
                    </div>
                 </div>
               
                 <div v-if="item.Attachments && item.Attachments.length" class="row">
                  <hr class="dark" >
                  <div class="card">
                     <div class="card-body">
                        <h6>Allegati</h6>
                        <div class="col-md-4" v-for="att in item.Attachments" :key="att.Id">
                        <div class="d-flex">
                           <a target="_blank" :href="'https://sa.trivellato.it/'+att.VirtualFilePath"><i class="material-icons my-auto text-dark">download</i></a>  
                           <a target="_blank" :href="'https://sa.trivellato.it/'+att.VirtualFilePath"><small class="ps-2 text-muted">{{att.PublicFileName}}</small></a>
                        </div>
                        </div>
                     </div>
                  </div>
                 </div>
                 <div class="row">
                    <div class="collapse rounded bg-gray-400 col-lg-12 col-sm-12" :class="{'show': item.CantAttach}" :id="'edit_'+item.Id">
                       <edititem :model="item" @service-modified="handleModifiedService"></edititem>
                    </div>
                 </div>
              </div>
           </div>
           <div v-if="list.length && list[0].CanSee" class="row mb-5">
            <div class="card">
            <div class="d-flex">
               <button @click="newService()" type="button" name="button" class="btn bg-gradient-dark my-2">
                  <i v-if="isInLoading" class="material-icons text-sm text-warning rotating">autorenew</i>
                  <i v-else class="material-icons text-sm">add</i>
                  &nbsp;&nbsp;Nuovo
               </button>
            </div>
           </div>
           </div>

     
           
        </div>

     </div>
   
  </section>
</template>
<script>
  import fetchFromAPI from '../../script/api.js'
  import helpers from '../../script/helpers.js'
  import edititem from '../../components/FinancialServiceEdit.vue'
  
  export default {
    components: {
        edititem
      },
      data() {
           return {
           filters: {},
           list: [],
           isInLoading: false
        };
     },
     methods: {
      LoadData(){
            helpers.resetFilter('financialServiceList')
            this.isInLoading = true;
            fetchFromAPI("FinancialService/LoadList", this.filters, (res) => {
                 this.list = res;
                 this.isInLoading = false;
                 helpers.setObject('financialSericeList', res);
          });
      },
      openDialog(item){
        this.$swal.fire({
            title: item.ServiceName,
            width:1200,
            html:item.HtmlModalInfo,
            showCloseButton: false,
            showCancelButton: false,
            focusConfirm: false,
            cancelButtonText:
              'Chiudi'
          })
      },
      formatDate(dateString) {
        const date = new Date(dateString);
        let dateFormatted = new Intl.DateTimeFormat('it-IT', { dateStyle: 'full', timeStyle: 'long' }).format(date);
        if(dateFormatted != "1 gennaio 1"){
            return dateFormatted;
        }
        return "";
      },
      newService(){
         this.isInLoading = true
         fetchFromAPI("FinancialService/NewItem", {Token: localStorage.getItem('sa_trivellato')}, (res) => {
            this.list.push(res);
            this.isInLoading = false
         });
      },
      handleModifiedService(){
         this.LoadData();
      }
   },
   created() {
         fetchFromAPI("FinancialService/BindFiltersForHub", { Token : localStorage.getItem('sa_trivellato') }, (res) => {
            this.filters = res;
            this.LoadData();
         })
   }
}
</script>