<template>
  <div v-if="filters && customer.Id!=0" class="container-fluid py-4">
    <div class="card-body p-4">
      <div class="row pb-4">
        <div class="col-md-4">
          <a v-if="customer.CustomerCode!=''" class="text-xs" target="_blank" :href="'https://trivellato.lightning.force.com/'+customer.CustomerCode">
            Vedi in Salesforce
            <i class="material-icons pltext-dark p-0" style="font-size: 10px;">open_in_new</i>
          </a>
          
          <h2 v-if="customer.BusinessName" class="m-0 pb-2">
            {{ customer.BusinessName }}
            <button @click="loadCustomerData(customer)" class="btn m-0 p-0 ps-1" data-bs-toggle="modal" data-bs-target="#EditCustomerModal" title="modifica dati cliente">
              <i class="material-icons pltext-dark" style="font-size: 1.6rem;">edit</i>
            </button>
          </h2>
          <p class="h2 fw-normal" v-else>nome mancante</p>
          <div v-if="customer.SellerName"><span class="text-xs">venditore: </span><h7> {{customer.SellerName}}</h7></div>
          <div v-if="customer.FiscalCode"><span class="text-xs">codice fiscale: </span><h7> {{customer.FiscalCode}}</h7></div>
          <div v-if="customer.VatNumber"><span class="text-xs">p.iva: </span><h7> {{customer.VatNumber}}</h7></div>
          <div v-if="customer.CustomerCode"><span class="text-xs">c.salesforce: </span><h7> {{customer.CustomerCode}}</h7></div>
          <div v-if="customer.InfinityCode"><span class="text-xs">c.infinity: </span><h7> {{customer.InfinityCode}}</h7></div>
        </div>
        <div class="col-md-4 text-center pt-4">
          <button @click="onAddDocumentClick()" type="button" class="btn mb-0 bg-gradient-info shadow-dark" data-bs-toggle="modal" data-bs-target="#AddDocumentModal">
            <span class="material-icons material-symbols-outlined" style="font-size: 15px;">add</span>
            Aggiungi Documenti
          </button>
          <div class="input-group input-group-outline pt-5">
            <input type="text" class="form-control text-center mx-auto bg-white" v-model="searchQuery" @input="filterList" placeholder="cerca tra i documenti..." style="max-width: 25rem; min-width: 10rem;" >
          </div>
        </div>
        <div class="col-md-4 text-end px-5 py-3 align-middle opacity-9">
          <a href="#CustomerLogsModal" @click="getCustomerLogs()" data-bs-toggle="modal" aria-expanded="false" role="button">
            <span class="text-xm font-weight-bold mb-1">Storico delle modifiche</span>
          </a>
          <span> | </span>
          <a v-if="filters.GroupType!=3" target="_blank" href="\TypesManagement" role="button">
            <span class="text-xm font-weight-bold mb-1">Gestione tipologie</span>
            <i class="material-icons pltext-dark p-1" style="font-size: 13px;">open_in_new</i>
          </a>
        </div>
      </div>
        <div class="py-4">
          <div class="row px-1 h-100 d-flex align-items-center justify-content-center">
            <div v-if="!customer.Documents || customer.Documents.length==0 && !isInLoading" class="px-5 pb-3 opacity-6 text-center">
              nessun documento presente
            </div>
            <div v-for="year in yearSections(filteredList)" :key="year" class="row px-1 h-100 d-flex align-items-center justify-content-center">
              <div class="row text-center h5 py-2 col-12"> 
                <span class="col-5 text-center"><hr></span> <span class="col-2">{{ year }}</span> <span class="col-5 text-center"><hr></span>
              </div>
              <div v-for="item in filteredByYear(filteredList, year)" :key="item.Id" class="col-md-3 mb-4 px-4">
                <div class="card">
                  <div class="row p-3 py-2 col-12 pe-0">
                    <div class="col-2">
                      <div class="icon icon-lg icon-shape bg-gradient-danger shadow-dark border-radius-xl position-absolute mt-1 ms-n4 text-center p-0">
                        <i class="text-white opacity-10 w-7 font-weight-bolder">.pdf</i>
                      </div>
                    </div>
                    <div class="col-8 ps-4">
                      <div class="text-xs pt-1">
                        <a role="button" @click="openRemoteFile(item)">
                          <h6 class="mb-0 text-truncate" :title="item.FileName">{{item.FileName}}</h6>
                        </a>
                        <span>{{getDocumentTypeName(item.DocumentType)}}</span>
                        <p class="mb-0">{{formatDate(item.FileCreatedDate)}} </p>
                      </div>
                    </div>
                    <div class="col-2">
                        <div type="button" @click="loadDocumentData(item)" class="btn m-0 p-0 px-1" data-bs-toggle="modal" data-bs-target="#EditDocumentModal">
                          <i class="material-icons pltext-dark" style="font-size: 1.5rem;">edit</i>
                        </div>
                        <div type="button" @click="deleteDocumentModal(item)" class="btn pb-0 px-0 mb-0">
                          <i class="material-icons md-36 pltext-dark" style="font-size: 2rem;">delete</i>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="isInLoading" class="col-md-1 mb-4 pt-3 text-center">
              <i class="material-icons text-center rotating" style="font-size:3rem;">autorenew</i>
            </div>
          </div>
        </div>
      
      <div class="row">
         <div class="col-12">
          <div class="card my-4">
            <div class="h-100 d-flex align-items-center justify-content-center">
              <div class="card-header p-0 position-relative mt-n4 z-index-2" style="width: 96%">
                <div class="row bg-gradient-light shadow-dark border-radius-lg pt-4 pb-2 px-3">
                    <h4 class="text-dark text-capitalize col-md-10 pt-1 m-0">Pratiche Cliente</h4>
                    <button @click="createNewDossier()" type="button" class="btn btn-dark shadow-dark col-sm-2" data-bs-toggle="modal" data-bs-target="#AddDossierModal">
                      <span class="material-icons material-symbols-outlined" style="font-size: 15px;">add</span>
                      Nuova Pratica
                    </button>
                </div>
              </div>
            </div>
            <div v-if="customer.Dossiers && customer.Dossiers.length!=0" class="card-body px-0 pb-2">
              <div class="p-0" style="overflow-x: scroll;">
                <table class="table table-responsive align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8">Nome Pratica</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8">Tipo Cliente</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8">Tipo Pagamento</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8">Azienda</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8">Stato</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8">Data</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8">Note</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"></th>
                    </tr>
                  </thead>
                  <tbody style="border-top:0px" v-for="item in customer.Dossiers" :key="item.Id">
                    <tr>
                      <td>
                        <a :href="'#pratica_'+item.Id" class="pe-auto" data-bs-toggle="collapse" aria-expanded="false" role="button">
                          <h6 class="text-xm font-weight-bold mb-1 px-3">{{item.DossierName}}</h6>
                        </a>
                      </td>
                      <td>
                        <p v-if="item.DossierType!=0" class="text-xs text-secondary mb-0 px-3">{{item.DossierTypeName}}</p>
                        <button v-else @click="loadDossierData(item)" class="btn m-0 p-0 px-1" data-bs-toggle="modal" data-bs-target="#AddDossierModal">
                          <p class="text-xs text-secondary mb-0 px-3 text-danger">Necessario specificare tipo cliente</p>
                        </button>
                      </td>
                      <td>
                        <p v-if="item.PaymentType!=0" class="text-xs text-secondary mb-0 px-3">{{item.PaymentTypeName}}</p>
                        <button v-else @click="loadDossierData(item)" class="btn m-0 p-0 px-1" data-bs-toggle="modal" data-bs-target="#AddDossierModal">
                          <p class="text-xs text-secondary mb-0 px-3 text-danger">Necessario specificare tipo pagamento</p>
                        </button>
                      </td>
                      <td>
                        <p class="text-xs text-secondary mb-0 px-3">{{getCompanyName(item.Company)}}</p>
                      </td>
                      <td>
                        <span v-if="item.DossierStatus==0" class="text-xs mb-0 px-3 badge bg-warning">IN LAVORAZIONE</span>
                        <span v-if="item.DossierStatus==1" class="text-xs mb-0 px-3 badge bg-success">PRONTA</span>
                        <span v-if="item.DossierStatus==2" class="text-xs mb-0 px-3 badge bg-secondary">CHIUSA</span>
                      </td>
                      <td>
                        <p class="text-xs text-secondary mb-0 px-3">{{formatDate(item.DossierDate)}}</p>
                      </td>
                      <td class="text-wrap" style="max-width: 400px;">
                        <p class="text-xs text-secondary mb-0 px-3">{{item.Info}}</p>
                      </td>
                      <td>
                        <div type="button" @click="loadDossierData(item)" class="btn m-0 p-0 px-1" data-bs-toggle="modal" data-bs-target="#AddDossierModal">
                          <i class="material-icons pltext-dark">edit</i>
                        </div>
                        <div type="button" @click="deleteDossierModal(item.Id)" class="btn m-0 p-0">
                          <i class="material-icons pltext-dark">delete</i>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="p-0" colspan="8">
                        <div class="row collapse px-3 pb-3 col-md-12" :id="'pratica_'+item.Id">
                          <div class="col-md-3">
                            <div class="row">
                              <div v-if="item.ContractNumber"><span class="text-xs pt-2 px-2 font-weight-bold">Numero Contratto:</span><span class="text-xs pt-2 px-1">{{item.ContractNumber}}</span></div>
                              <div v-if="item.VehicleType"><span class="text-xs pt-1 px-2 font-weight-bold">Tipo Veicolo:</span><span class="text-xs pt-1 px-1">{{item.VehicleType}}</span></div>
                              <div v-if="item.CarModel"><span class="text-xs pt-1 px-2 font-weight-bold">Modello Auto:</span><span class="text-xs pt-1 px-1">{{item.CarModel}}</span></div>
                              <div v-if="item.CarFrame"><span class="text-xs pt-1 px-2 font-weight-bold">Telaio:</span><span class="text-xs pt-1 px-1">{{item.CarFrame}}</span></div>
                            </div>
                            <div class="row">
                              <div class="">
                                <h6 class="text-xs pt-3 px-2">Documenti richiesti:</h6>
                                <div class="col-md-6" v-for="docs in item.RequestedDocuments" :key="docs.Id">
                                  <div class="row align-middle pl-8">
                                    <div class="col-sm-1" style="width:40px;">
                                      <i v-if="isDocTypePresent(docs.Id)" class="material-icons text-success p-1">check_circle</i>
                                      <i v-else class="material-icons p-1">cancel</i>
                                    </div>
                                    <span class="col-sm-5 text-xs pt-2">{{ docs.TypeName }}</span>
                                  </div>
                                </div>
                              </div>
                              <!-- <div v-if="item.DossierStatus==1" class="col-md-3 py-6">
                                <a class="text-uppercase text-secondary font-weight-bolder" role="button" @click="downloadMultiPdfPath(item.DossierType, item.PaymentType)">
                                  Scarica tutti 
                                </a>
                              </div> -->
                            </div>
                          </div>
                          <div class="d-flex flex-wrap col-9 pt-3">
                            <div v-for="doc in item.AssociatedDocuments" :key="doc.Id" class="col-4 mb-n1 px-4">
                              <div class="card">
                                <div class="row p-3 py-2 col-12 pe-0">
                                  <div class="col-2">
                                    <div style="width: 3.5rem; height:3.5rem;" class="icon icon-lg icon-shape bg-gradient-danger shadow-dark border-radius-xl position-absolute mt-2 ms-n4 text-center p-0">
                                      <i class="text-white opacity-10 w-7 font-weight-bolder" style="font-size:1.3rem !important;">.pdf</i>
                                    </div>
                                  </div>
                                  <div class="col-8 ps-3">
                                    <div class="text-xs pt-1">
                                      <a role="button" @click="openRemoteFile(doc)">
                                        <h6 class="mb-0 text-truncate" :title="doc.FileName">{{doc.FileName}}</h6>
                                      </a>
                                      <span class="text-wrap">{{getDocumentTypeName(doc.DocumentType)}}</span>
                                      <p class="mb-0 text-xs">{{formatDate(doc.FileCreatedDate)}} </p>
                                    </div>
                                  </div>
                                  <div class="col-2 pt-3 pe-1">
                                    <div type="button" @click="loadDocumentData(doc)" class="d-block btn m-0 p-0 px-1" data-bs-toggle="modal" data-bs-target="#EditDocumentModal">
                                      <i class="material-icons pltext-dark" style="font-size: 1.3rem;">edit</i>
                                    </div>
                                    <div type="button" @click="deleteDocumentModal(doc)" class="d-block btn pb-0 px-0 mb-0">
                                      <i class="material-icons md-36 pltext-dark" style="font-size: 1.6rem;">delete</i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  
                </table>
              </div>
            </div>
            <div v-else class="px-5 py-4 opacity-6" >
              nessuna pratica presente
            </div>
            <div v-if="isInLoading" class="text-center py-4">
              <i class="material-icons text-center rotating" style="font-size:3rem;">autorenew</i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="h5 text-center py-7 opacity-6">Nessun cliente selezionato</div>
  
  <div class="modal fade" id="AddDocumentModal" tabindex="-1" role="dialog" aria-labelledby="AddDocumentModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 60rem;">
      <div class="modal-content p-5">
        <button type="button" class="btn-close top-right-corner py-3 px-5" data-bs-dismiss="modal" aria-label="Close">
          <span class="material-icons material-symbols-outlined light-dark">close</span>
        </button>
        <div class="row text-center mb-3">
          <h5>Carica nuovi documenti</h5>
          <p>Le immagini caricate verranno convertite in pdf</p>
        </div>
        <div class="row p-2">
          <DropFiles ApiPath="CustomerDocumentsSpace/UploadDocument" :Token="getToken()" @documentuploaded="handleUploadResponse($event)"></DropFiles>
        </div>
        <div v-if="uploadedDocuments.length>0" style="overflow-x: scroll;">
          <table class="table table-responsive align-items-center">
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody class="border-warning" style="border-style: dashed !important;" v-for="item in uploadedDocuments" :key="item.Id">
              <tr class="col-md-12">
                <td class="col-md-1 pe-0">
                  <button @click="removeDoc(item)" class="btn btn-icon-only btn-rounded btn-outline-danger mb-0 me-2 btn-sm d-flex align-items-center justify-content-center">
                    <i class="material-icons text-sm" aria-hidden="true">clear</i>
                  </button>
                </td>
                <td class="col-md-5 text-wrap pe-5 ps-0">
                  <span>{{ item.FileName }}</span>
                </td>
                <td class="col-md-6">
                  <div class="row input-group input-group-static mb-3">
                    <label class="p-0">Tipo documento <span class="text-xs text-danger text-end" v-if="item.DocumentType==0"> *(necessario specificare tipo documento)</span></label>
                    <select id="documentTypeListSelect" class="form-control" v-model="item.DocumentType" @change="onSelectChange($event)">
                      <option v-for="typ in filters.GroupTypeList" :key="typ.Key" :value="typ.Key">{{ typ.Value }}</option>
                      <option v-for="typ in filters.EntityList" :key="typ.Key" :value="typ.Key">{{ typ.Value }}</option>
                    </select>
                  </div>
                  <div class="row input-group input-group-static">
                    <label class="p-0">Pratica associata <span class="text-xxs"> (farà apparire il documento sotto la pratica scelta)</span></label>
                    <select id="documentDossierSelect" class="form-control" v-model="item.DossierId">
                      <option v-for="item in customer.Dossiers" :key="item.Id" :value="item.Id">{{ item.DossierName }}</option>
                      <option :value="0">Non associare</option>
                    </select>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="text-center text-danger py-4" v-if="!isPdf">Uno o più file non sono in formato corretto. Aggiungere solo file in formato PDF</div>
        <div class="d-flex justify-content-end mt-4 py-4">
          <button @click="saveDocumentData()" type="button" name="button" class="btn bg-gradient-success m-0 ms-2 shadow-dark" data-bs-dismiss="modal" :disabled="filters.Active">
              <i v-if="isInLoading" class="material-icons text-sm text-warning rotating">autorenew</i>
              <i v-else class="material-icons text-sm">save</i>
              &nbsp;&nbsp;Salva
          </button>
          <button @click="cancelUploadModal(uploadedDocuments)" type="button" name="button" class="btn bg-gradient-secondary m-0 ms-2 shadow-dark" data-bs-dismiss="modal">
              <i v-if="isInLoading" class="material-icons text-sm text-warning rotating">autorenew</i>
              <i v-else class="material-icons text-sm">delete</i>
              &nbsp;&nbsp;Annulla
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="AddDossierModal" tabindex="-1" role="dialog" aria-labelledby="AddDossierModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 50rem;">
      <div class="modal-content p-4">
        <button type="button" class="btn-close top-right-corner py-4 px-5" data-bs-dismiss="modal" aria-label="Close">
          <span class="material-icons material-symbols-outlined light-dark">close</span>
        </button>
        <div class="row text-center">
          <h5 v-if="dossier.Id==0">Creazione nuova pratica</h5>
          <h5 v-if="dossier.Id!=0">Modifica pratica: {{ dossier.DossierName }}</h5>
        </div>
        <div class="row input-group input-group-static mt-4">
          <div class="col-md-7 p-4">
            <label>Nome Pratica</label>
            <input type="text"  v-model="dossier.DossierName" class="form-control">
          </div>
          <div class="col-md-5 p-4">
            <label>Data stipulazione pratica</label>
            <input type="date" class="form-control" v-model="dossier.DossierDate">
          </div>
        </div>
        <div class="row input-group input-group-static">
          <div class="col-md-4 p-4">
            <label>Azienda</label>
            <select class="form-control" v-model="dossier.Company">
              <option v-for="typ in filters.CompanyList" :key="typ.Key" :value="typ.Key">{{ typ.Value }}</option>
            </select>
          </div>
          <div class="col-md-4 p-4">
            <label>Tipologia Cliente</label>
            <select class="form-control" v-model="dossier.DossierType" @change="onDossierTypeSelectChange($event)">
              <option v-for="typ in filters.DataTypeList" :key="typ.Key" :value="typ.Key">{{ typ.Value }}</option>
            </select>
            <div class="text-xs text-danger" v-if="dossier.DossierType==0">Specificare tipo cliente</div>
          </div>
          <div class="col-md-4 p-4">
            <label>Tipologia Pagamento</label>
            <select class="form-control" v-model="dossier.PaymentType" @change="onDossierTypeSelectChange($event)">
              <option v-for="typ in filters.ManagedList" :key="typ.Key" :value="typ.Key">{{ typ.Value }}</option>
            </select>
            <div class="text-xs text-danger" v-if="dossier.PaymentType==0">Specificare tipo pagamento</div>
          </div>
        </div>
        <div class="row input-group input-group-static ">
          <div class="p-4">
            <label>Informazioni/note aggiuntive</label>
            <input type="text" v-model="dossier.Info" class="form-control">
          </div>
        </div>
        <div class="d-flex justify-content-end mt-4 px-4">
          <button @click="saveDossierData()" type="button" name="button" class="btn bg-gradient-success m-0 ms-2 shadow-dark" data-bs-dismiss="modal" :disabled="filters.FilterActive">
              <i v-if="isInLoading" class="material-icons text-sm text-warning rotating">autorenew</i>
              <i v-else class="material-icons text-sm">save</i>
              &nbsp;&nbsp;Salva
          </button>
          <button @click="LoadData()" type="button" name="button" class="btn bg-gradient-secondary m-0 ms-2 shadow-dark" data-bs-dismiss="modal">
              <i v-if="isInLoading" class="material-icons text-sm text-warning rotating">autorenew</i>
              <i v-else class="material-icons text-sm">delete</i>
              &nbsp;&nbsp;Annulla
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="EditDocumentModal" tabindex="-1" role="dialog" aria-labelledby="EditDocumentModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 50rem;">
      <div class="modal-content p-4">
        <button type="button" class="btn-close top-right-corner py-4 px-5" data-bs-dismiss="modal" aria-label="Close">
          <span class="material-icons material-symbols-outlined light-dark">close</span>
        </button>
        <div class="row text-center">
          <h5>Modifica documento</h5>
          <div class="row input-group input-group-static mt-4">
            <div class="col-md-6 p-4">
              <label>Nome Documento</label>
              <input type="text"  v-model="document.FileName" class="form-control">
            </div>
            <div class="col-md-6 p-4">
              <label>Tipologia Documento</label>
              <select class="form-control" v-model="document.DocumentType" @change="onDossierTypeSelectChange($event)">
                <option v-for="typ in filters.GroupTypeList" :key="typ.Key" :value="typ.Key">{{ typ.Value }}</option>
                <option v-for="typ in filters.EntityList" :key="typ.Key" :value="typ.Key">{{ typ.Value }}</option>
              </select>
              <div class="text-xs text-danger" v-if="document.DocumentType==0">Specificare tipo documento</div>
            </div>
          </div>
          <div class="row input-group input-group-static mt-4">
            <div class="col-md-6 p-4">
              <label class="text-start">Associa ad un contratto</label>
              <select class="form-control p-2" v-model="document.DossierId">
                <option v-for="item in customer.Dossiers" :key="item.Id" :value="item.Id">{{ item.DossierName }}</option>
                <option :value="0">Non associare</option>
              </select>
            </div>
          </div>
          <div class="d-flex justify-content-end mt-4 px-4">
            <button @click="saveSingleDocument()" type="button" name="button" class="btn bg-gradient-success m-0 ms-2 shadow-dark" data-bs-dismiss="modal" :disabled="filters.FilterActive">
                <i v-if="isInLoading" class="material-icons text-sm text-warning rotating">autorenew</i>
                <i v-else class="material-icons text-sm">save</i>
                &nbsp;&nbsp;Salva
            </button>
            <button type="button" name="button" class="btn bg-gradient-secondary m-0 ms-2 shadow-dark" data-bs-dismiss="modal">
                <i v-if="isInLoading" class="material-icons text-sm text-warning rotating">autorenew</i>
                <i v-else class="material-icons text-sm">delete</i>
                &nbsp;&nbsp;Annulla
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="EditCustomerModal" tabindex="-1" role="dialog" aria-labelledby="EditCustomerModal" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 50rem;">
      <div class="modal-content p-4">
        <button type="button" class="btn-close top-right-corner py-4 px-5" data-bs-dismiss="modal" aria-label="Close">
          <span class="material-icons material-symbols-outlined light-dark">close</span>
        </button>
        <div class="row text-center">
          <h5>Modifica Dati Cliente</h5>
        </div>
        <div class="row input-group input-group-static">
          <div class="col-md-12 p-4">
            <label>Nome Cognome / Ragione Sociale</label>
            <input type="text"  v-model="customer.BusinessName" class="form-control">
          </div>
        </div>
        <div class="row input-group input-group-static">
          <div class="col-md-6 p-4">
            <label>Codice Fiscale</label>
            <input type="text"  v-model="customer.FiscalCode" class="form-control">
          </div>
          <div class="col-md-6 p-4">
            <label>P.IVA</label>
            <input type="text"  v-model="customer.VatNumber" class="form-control">
          </div>
        </div>
        <div class="d-flex justify-content-end mt-4 px-4">
          <button @click="saveCustomerData()" type="button" name="button" class="btn bg-gradient-success m-0 ms-2 shadow-dark" data-bs-dismiss="modal">
              <i class="material-icons text-sm">save</i>
              &nbsp;&nbsp;Salva
          </button>
          <button type="button" name="button" class="btn bg-gradient-secondary m-0 ms-2 shadow-dark" data-bs-dismiss="modal">
              <i v-if="isInLoading" class="material-icons text-sm text-warning rotating">autorenew</i>
              <i v-else class="material-icons text-sm">delete</i>
              &nbsp;&nbsp;Annulla
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="CustomerLogsModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog no-scroll-content overflow-y-auto overflow-x-hidden" style="max-width: 70rem;">
      <div class="modal-content p-5">
        <button type="button" class="btn-close top-right-corner py-3 px-5" data-bs-dismiss="modal" aria-label="Close">
          <span class="material-icons material-symbols-outlined light-dark">close</span>
        </button>
        <div class="row text-center mb-3">
          <h5>Storico delle modifiche</h5>
        </div>
        <div class="table-responsive">
          <table class="table align-items-center">
            <thead>
              <tr>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder">Data</th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder">Messaggio</th>
              </tr>
            </thead>
            <tbody class="text-xs" style="border-top:0px;" v-for="item in logs" :key="item.Id">
              <tr class="col-md-12">
                <td class="col-md-1">
                  <span>{{ formatDate(item.CreatedDate) }} {{ formatTime(item.CreatedDate) }}</span>
                </td>
                <td class="col-md-8">
                  <span class="text-wrap">{{ item.LogMessage }}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <Paginator :currentPage="currentPage" :totalPages="filters.TotalPages" :perPage="perPage" @pagechanged="onPageChange" class="pt-4"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fetchFromAPI from "../../script/api.js"
import helpers from '../../script/helpers.js'
import DropFiles from "../../components/DropFiles.vue"
import Paginator from "../../components/Paginator.vue"
import { toRaw } from 'vue';
//import store from "./store"
export default {
  components: {
    DropFiles,
    Paginator
  },
  data() {
    return {
      filters: {},
      customer: {},
      dossier: {},
      logs: [],
      isInLoading: false,
      isPdf: true,
      uploadedDocuments: [],
      documentTypesList: [],
      companies: [
                {
                    Id: 1,
                    Nome: "Trivellato Spa"
                },
                {
                    Id: 6,
                    Nome: "Padova Star Srl"
                },
            ],
      currentPage: 1,
      perPage: 10,
      document: {},
      filteredList: [],
      searchQuery: '',
      presentTypes: []
    }
  },
  methods: {
    formatDate: helpers.formatDate,
    formatTime: helpers.formatTime,

    filterList() {
      const query = this.searchQuery.toLowerCase();
      if(!query) this.filteredList = this.customer.Documents;
      this.filteredList = this.customer.Documents.filter(item =>
        item.FileName.toLowerCase().includes(query)
      );
    },

    yearSections(list){
      var years = [...new Set(list.map(item => new Date(item.FileCreatedDate).getFullYear().toString()))];
      return years;
    },

    filteredByYear(list, year) {
      return list.filter(item => new Date(item.FileCreatedDate).getFullYear().toString() === year);
    },

    handleUploadResponse(docs){
      this.filters.Active=true;
      this.isPdf=true;
      if(docs==true){
        this.isPdf=false;
      }
      else{
        var list = toRaw(this.uploadedDocuments);
        list=list.concat(docs);
        this.uploadedDocuments = list;
        this.$toast.success("Upload eseguito");
      }
    },
    onAddDocumentClick(){
      this.isPdf=true;
    },
    onSelectChange(){
      for(var i=0; i<this.uploadedDocuments.length; i++){
        if(this.uploadedDocuments[i].DocumentType==0) return;
      }
      this.filters.Active=false;
    },
    onDossierTypeSelectChange(){
      if(this.dossier.DossierType != 0 && this.dossier.PaymentType != 0) this.filters.FilterActive=false;
    },
    onPageChange(page) {
      this.filters.Page = page;
      this.currentPage = page;
      this.getCustomerLogs();
    },
    LoadData(){
      this.isInLoading =true;
      let id = this.customer.Id;
      fetchFromAPI("CustomerDocumentsSpace/GetCustomerSpace", {Token:helpers.token(), Id:id }, (res) => {
        this.customer = res;
        this.filteredList = this.customer.Documents;
        this.isInLoading =false;
        fetchFromAPI("CustomerDocumentsSpace/GetPresentDocTypes", this.customer, (res) => {
          this.presentTypes = res;
        });
        fetchFromAPI("CustomerDocumentsSpace/checkDossierStatuses", this.customer, (res) => {
          this.customer = res;
          this.filteredList = this.customer.Documents;
        });
      });
    },
    createNewDossier(){
      this.dossier = {};
      fetchFromAPI("CustomerDocumentsSpace/NewDossier", {Token:helpers.token()}, (res) => {
        this.dossier = res;
        this.dossier.DossierDate = this.dossier.DossierDate.toString().slice(0,10);
        this.filters.FilterActive=true;
      });
    },
    loadDossierData(dossier){
      this.dossier = dossier;
      this.dossier.DossierDate = this.dossier.DossierDate.toString().slice(0,10);
      this.dossier.DossierType==0 ? this.filters.FilterActive=true : this.filters.FilterActive=false;
    },
    loadDocumentData(item){
      this.document = item;
      this.filters.Active = true;
      this.filters.FilterActive = true;
      if(this.document.DocumentType != 0){
        this.filters.Active = false;
        this.filters.FilterActive = false;
      }
    },
    loadCustomerData(customer){
      this.customer = customer;
    },
    getDocumentTypeName(id){
      var list = this.filters.GroupTypeList.concat(this.filters.EntityList);
      for(var i=0; i<list.length; i++){
        if(list[i].Key==id){
          return list[i].Value;
        }
      }
      return "nessun tipo specificato";
    },
    getCompanyName(id){
      for(var i=0; i<this.companies.length; i++){
        if(this.companies[i].Id==id){
          return this.companies[i].Nome;
        }
      }
    },
    isDocTypePresent(typeId){
      for(let i=0; i<this.presentTypes.length; i++){
        if(this.presentTypes[i]==typeId) return true;
      }
      return false;
    },
    deleteDocument(doc){
      if(doc){
        doc.Token = helpers.token();
        fetchFromAPI("CustomerDocumentsSpace/DeleteDocument", doc, (res) => {
         if(res) this.LoadData();
        });
      }
    },
    cancelUploadModal(items){
      this.$swal.fire({
        title: 'Sicuro?',
        text: 'Vuoi annullare le modifiche?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si'
      }).then((result) => {
        if (result.isConfirmed) {
          for(var i=0; i<items.length; i++){
            this.removeDoc(items[i]);
          }
          this.$toast.success("Modifiche annullate");
          this.filters.Active=true;
        }
      })
    },
    deleteDocumentModal(item){
      this.$swal.fire({
        title: 'Sicuro?',
        text: 'Vuoi rimuovere il documento?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteDocument(item);
          this.$toast.success("Documento rimosso");
        }
      })
    },
    deleteDossierModal(id){
      this.$swal.fire({
        title: 'Sicuro?',
        text: 'Vuoi eliminare questa pratica?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si'
      }).then((result) => {
        if (result.isConfirmed) {
          fetchFromAPI("CustomerDocumentsSpace/DeleteDossier", { Token:helpers.token(), Id:id }, (res) => {
            if(res){
              this.LoadData();
              this.$toast.success("Pratica eliminata");
            }
          });
        }
      })
    },
    getToken(){
      return helpers.token();
    },
    saveDocumentData(){
      this.isInLoading =true;
      var documents = toRaw(this.uploadedDocuments);
      for(var i=0; i<documents.length; i++){
        documents[i].CustomerId = this.customer.Id;
        if (documents[i].DocumentType==0) this.filters.Active = true;
        documents[i].Token = helpers.token();
      }
      fetchFromAPI("CustomerDocumentsSpace/SaveDocument", documents, (res) => {
        if(res){
          this.$toast.success("Documenti salvati");
          this.LoadData();
          this.uploadedDocuments = [];
          this.filters.Active=true;
        }
      });
    },
    saveSingleDocument(){
      this.uploadedDocuments.push(this.document);
      fetchFromAPI("CustomerDocumentsSpace/SaveDocument", this.uploadedDocuments, (res) => {
        if(res){
          this.$toast.success("Documento salvato");
          this.LoadData();
          this.uploadedDocuments = [];
          this.filters.Active=true;
        }
      });
    },
    removeDoc(doc){
      var rawDoc = toRaw(doc);
      var list = toRaw(this.uploadedDocuments);
      var newList = helpers.removeItemFromList(list,rawDoc);
      this.uploadedDocuments = newList;
      this.deleteDocument(rawDoc);
    },
    saveDossierData(){
      this.dossier.Token = helpers.token();
      this.dossier.CustomerId = this.customer.Id;
      this.dossier.SellerId = this.customer.SellerId;
      fetchFromAPI("CustomerDocumentsSpace/SaveDossier", this.dossier, (res) => {
        if(res){
          this.$toast.success("Pratica salvata");
          this.filters.FilterActive=true;
          this.LoadData();
        }
      });
    },
    saveCustomerData(){
      this.customer.Token = helpers.token();
      this.customer.Name = this.customer.Name ? this.customer.Name : "" ;
      this.customer.Surname = this.customer.Surname ? this.customer.Surname : "" ;
      this.customer.FiscalCode = this.customer.FiscalCode ? this.customer.FiscalCode : "" ;
      this.customer.VatNumber = this.customer.VatNumber ? this.customer.VatNumber : "" ;
      fetchFromAPI("CustomerDocumentsSpace/SaveCustomer", this.customer, (res) => {
        if(res){
          this.$toast.success("Dati cliente salvati");
          this.LoadData();
        }
      });
    },
    getCustomerLogs(){
      this.filters.Page = this.currentPage;
      this.filters.TotalRows = this.perPage;
      this.filters.Id = this.customer.Id;
      fetchFromAPI("CustomerDocumentsSpace/GetCustomerSpaceLogs", this.filters, (res) => {
        this.logs = res.Logs;
        this.filters = res.Filter;
      });
    },
    downloadMultiPdfPath(typeId, paymentId){
      fetchFromAPI("CustomerDocumentsSpace/ExportUniquePdf", {Token:helpers.token(), Page:typeId, TotalPages:paymentId, Id: this.customer.Id}, (res) => {
        if(res){
          var virtualPath = res.virtualFilePath;
          this.downloadFile(virtualPath);
        }
      });
    },
    openRemoteFile(doc){
      fetchFromAPI("CustomerDocumentsSpace/GetDocumentFromRemoteServer", {Token:helpers.token(), Id: doc.Id}, (res) => {
        if(res){
          var virtualPath = res.virtualFilePath;
          this.downloadFile(virtualPath,doc);
        }
      });
    },
    downloadFile(url,doc) {
      const link = document.createElement("a");
      link.href = url
      var filename = (this.customer.BusinessName + " - " + doc.FileName).replace(/[\\/:*?"<>|]/g, '');
      link.setAttribute('download', filename);
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  },
  created() {
    let params = new URL(document.location).searchParams;
    let id = params.get("id");
    this.isInLoading =true;
    fetchFromAPI("CustomerDocumentsSpace/GetCustomerSpace", { Token:helpers.token(), Id:id }, (res) => {
      this.customer = res;
      this.filteredList = this.customer.Documents;
      this.isInLoading =false;
      fetchFromAPI("CustomerDocumentsSpace/GetPresentDocTypes", this.customer, (res) => {
        this.presentTypes = res;
      });
    });
    fetchFromAPI("CustomerDocumentsSpace/BindFiltersForHub", { Token:helpers.token(), Id:id }, (res) => {
      if(!res.ReducedView){
        this.$router.push({ name: 'PageNotFound' });
      }
      this.filters = res;
    });
    
  }
};
</script>
