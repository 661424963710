export default {

  tokenKey() {
    return 'sa_trivellato';
  },
  token() {
    return localStorage.getItem(this.tokenKey());
  },
  removeToken() {
    localStorage.removeItem(this.tokenKey());
    this.removeFiltersData();
  },
  removeFiltersData() {
    var filtersArray = ['quoteMarginList',
      'quoteMarginFilter',
      'fidelityCardFilter',
      'homeCards',
      'navMapping',
      'CommissionsDataModel',
      'ContractApprovalListNavMapping',
      'SpotlightHomeNavMapping',
      'SpotlightListNavMapping',
      'ContractApprovalDetailNavMapping',
      'contractApprovalFilter',
      'FidelityCardSearchNavMapping',
      'contractApprovalList',
      'FEAList',
      'FEAListFilter',
      'customersList',
      'customersFilter'];
    for (var i = 0; i <= filtersArray.length; i++) {
      localStorage.removeItem(filtersArray[i]);
    }
    //localStorage.clear();
  },
  setObject(filterEntity, data) {
    try {
      localStorage.setItem(filterEntity, JSON.stringify(data));
    }
    catch (err) {
      console.log(err)
    }
  },
  getFilters(filterEntity) {
    return JSON.parse(localStorage.getItem(filterEntity));
  },
  resetFilter(filterEntity) {
    localStorage.removeItem(filterEntity);
  },
  trimString(string, length) {
    return (string && typeof (string) !== 'undefined' && string !== '' && length && length > 0 && string.length >= length) ? string.substring(0, length) + '...' : string;
  },
  formatDate(dateString) {
    const date = new Date(dateString);
    let dateFormatted = new Intl.DateTimeFormat("default", { dateStyle: "long" }).format(date);
    if (dateFormatted != "1 gennaio 1") {
      return dateFormatted;
    }
    return "";
  },
  formatTime(datestring){
    datestring = datestring.split("T");
    var timestring = datestring[1].split(".");
    return timestring[0];
  },
  checkValidDate(date){
    return (date && date != '0001-01-01T00:00:00');
  },
  validateName(name) {
    if (!name || !name.length) {
      return { valid: false, error: "This field is required" };
    }
    return { valid: true, error: null };
  },

  validatePhone(phone) {
    if (!phone || !phone.length) {
      return { valid: false, error: 'This field is required.' };
    }

    if (!phone.match(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,6}$/)) {
      return { valid: false, error: 'Please, enter a valid international phone number.' };
    }

    return { valid: true, error: null };
  },

  validateEmail(email) {
    if (!email || !email.length) {
      return { valid: false, error: "This field is required" };
    }
    if (!email.match(/^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/) || (email.includes('@pec') || email.includes('@legalmail'))) {
      return { valid: false, error: "Please, enter a valid email." };
    }
    return { valid: true, error: null };
  },

  removeItemFromList(list, item){
    var newList=[];
    for(var i=0; i<list.length; i++){
        if(list[i]!=item){
          newList.push(list[i]);
        }
    }
    return newList;
  },

  getFileExtention(string){
    return string.split('.').pop();
  },

  setMaterialInput() {
    // Material Design Input function
    var inputs = document.querySelectorAll("input, textarea");

    for (var i = 0; i < inputs.length; i++) {
      if (inputs[i].hasAttribute("value")) {
        inputs[i].parentElement.classList.add("is-filled");
      }
      inputs[i].addEventListener(
        "focus",
        function () {
          this.parentElement.classList.add("is-focused");
        },
        false
      );

      inputs[i].onkeyup = function () {
        if (this.value != "") {
          this.parentElement.classList.add("is-filled");
        } else {
          this.parentElement.classList.remove("is-filled");
        }
      };

      inputs[i].addEventListener(
        "focusout",
        function () {
          if (this.value != "") {
            this.parentElement.classList.add("is-filled");
          }
          this.parentElement.classList.remove("is-focused");
        },
        false
      );
    }
  },
  stdErrorMessage: 'Qualcosa è andato storto, ricaricare la pagina e tentare di nuovo<br/>Se il problema persiste contattare un responsabile'
}