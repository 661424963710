<template>
   <link rel="stylesheet"
      href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0" />
   <link rel="stylesheet" href="https://unpkg.com/vue-select@latest/dist/vue-select.css">
   <div class="container-fluid py-4">
      <div class="card-body p-4">
         <div class="row">
            <div class="col-md-3 mb-md-0 mb-4">
               <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                  <div class="input-group input-group-dynamic">
                     <!-- <div class="choices" data-type="select-one" placeholder="Targa" tabindex="0" role="listbox"
                        aria-haspopup="true" aria-expanded="false">
                        <select type="form-control" class="form-control" id="fastItemLicense"
                           v-model="filters.CommissionNumber" aria-label="" placeholder="Targa" >
                           <option value="null">Seleziona targa</option>
                           <option v-for="lp in LicensePlates" :value="lp.id" :key="lp.targa">
                              {{ lp.targa }} | <span><i>{{ lp.modello }}</i></span> <i v-if="lp.is_deleted">(Non gestito)</i>
                           </option>
                        </select>
                     </div> -->
                     <v-select class="form-control" :options="LicensePlates" label="targa" value="id"
                        v-model="filters.CommissionNumber" placeholder="Tutti i veicoli">
                        <template #selected-option>
                           {{ filters.CommissionNumber.targa }} | {{ filters.CommissionNumber.modello }}
                        </template>
                        <template #option="{ targa, modello, is_deleted }">
                           <strong>{{ targa }}</strong> | <span>{{ modello }}</span> <i v-if="is_deleted">(Non gestito)</i>
                        </template>
                     </v-select>
                  </div>
               </div>
            </div>
            <div class="col-md-3">
               <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                  <div class="input-group input-group-dynamic">
                     <!-- <div class="choices" data-type="select-one" placeholder="ubicazione" tabindex="0" role="listbox"
                        aria-haspopup="true" aria-expanded="false">
                        <select type="form-control" class="form-control" placeholder="ubicazione" aria-label=""
                           v-model="filters.CodiceUbicazione">
                           <option value="null" hidden></option>
                           <option v-for="ubicazione in filters.Ubicazioni" :value="ubicazione.Codice"
                              :key="ubicazione.Codice">
                              {{ ubicazione.Descrizione }}
                           </option>
                        </select>
                     </div> -->
                     <v-select class="form-control" :options="filters.Ubicazioni" label="Descrizione" value="Codice"
                        v-model="filters.CodiceUbicazione" placeholder="Tutte le ubicazioni">
                        <template #selected-option>
                           {{ filters.CodiceUbicazione.Descrizione }}
                        </template>
                     </v-select>
                  </div>
               </div>
            </div>
            <div class="col-md-3">
               <div class="card card-body card-plain d-flex align-items-center flex-row">
                  <div class="input-group input-group-dynamic ">
                     <a class="float-right align-bottom btn bg-gradient-dark mb-0" :class="{ 'disabled': isInLoading }"
                        @click="LoadData()">
                        <i v-if="isInLoading" class="material-icons text-sm text-warning rotating">autorenew</i>
                        <i v-else class="material-icons text-sm">search</i>
                        &nbsp;&nbsp;Cerca
                     </a>
                  </div>
               </div>
            </div>
            <div class="col-md-3">
               <div class="card card-body card-plain d-flex align-items-center flex-row">
                  <div class="input-group input-group-dynamic ">
                     <a class="float-right align-bottom btn bg-gradient-dark mb-0" @click="OpenModal()">
                        <i class="material-icons text-sm">add</i>
                        &nbsp;&nbsp;Aggiunta rapida rifornimento
                     </a>
                  </div>
               </div>
            </div>
         </div>
         <div class="row">
            <div class="col-md-3">
               <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                  <div class="input-group input-group-dynamic">
                     <label class="form-label"></label>
                     <input type="date" class="form-control" :class="{ 'text-warning': invalidFilterDates }"
                        placeholder="Da" aria-label="" min="2010-01-01" v-model="filters.DateFrom" v-if="!filters.Active">

                  </div>
               </div>
            </div>
            <div class="col-md-3">
               <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                  <div class="input-group input-group-dynamic">
                     <label class="form-label"></label>
                     <input type="date" class="form-control" :class="{ 'text-warning': invalidFilterDates }"
                        placeholder="a" aria-label="" min="2010-01-01" v-model="filters.DateTo" v-if="!filters.Active">
                  </div>
               </div>
            </div>
            <div class="col md-4">
               <div class="card card-body card-plain d-flex align-items-center flex-row">
                  <div class="input-group input-group-dynamic ">
                     <button @click="ExportExcel" type="submit"
                        data-bind="click:$root.ExcelExport.bind($data), enable:!$root.IsOnElaboration()"
                        class="btn btn-sm btn-outline-dark mt-2 mr-2 small" :class="{ 'disabled': isInLoading }"><span
                           class="fa fa-file-excel-o" aria-hidden="true"></span> Esporta Excel</button>
                  </div>
               </div>
            </div>
            <div class="col md-3">
               <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row  my-2">
                  <div class="form-check form-switch float-right">
                     <input class="form-check-input my-2" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                        v-model="filters.Active">
                     <label class="form-check-label my-0" for="flexSwitchCheckDefault">Filtra senza date
                        rifornimento</label>
                  </div>
               </div>
            </div>
         </div>
         <span>
            <div class="row my-4" style="width:35%">
               <div class="col-12">
                  <div class="card" v-if="visible">
                     <div class="table-responsive">
                        <span v-if="isLoadingReport">
                           <div class="card mt-4" id="basic-info">
                              <div class="card-body pt-0">
                                 <h5>Generazione Report...</h5>
                                 <i class="material-icons text-sm text-warning rotating">autorenew</i>
                              </div>
                           </div>
                        </span>
                        <span v-else>
                           <div class="card mt-4" id="basic-info">
                              <div class="card-body pt-0">
                                 <h5>FATTO!</h5>
                                 <i class="material-icons text-sm">
                                    done&nbsp;&nbsp;</i>
                                 <a @click="visible = false"
                                    :href="endPoint.replace('/api/', '') + link.VirtualFilePath">{{
                                       link.FileName }}</a>
                              </div>
                           </div>
                        </span>
                     </div>
                  </div>
               </div>
            </div>
         </span>
      </div>
      <div class="row my-4">
         <div class="col-12">
            <div class="card">
               <div class="table-responsive">
                  <table class="table align-items-center mb-0">
                     <thead>
                        <tr>
                           <th
                              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-5 text-dark text-xs">
                              Targa</th>
                           <th
                              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 text-dark text-xs">
                              Modello</th>
                           <th
                              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 text-dark text-xs">
                              Km</th>
                           <th
                              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 text-dark text-xs">
                              Tipo</th>
                           <th
                              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 text-dark text-xs">
                              Azienda</th>
                           <th
                              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 text-dark text-xs">
                              Ubicazione</th>
                           <th
                              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 text-dark text-xs">
                              N°Rifornimenti</th>
                        </tr>
                     </thead>
                     <tbody v-for="(item, index) in list" :key="index">
                        <tr>
                           <td>
                              <a :href="'#Targa_' + item.Targa" data-bs-toggle="collapse" role="button"
                                 aria-expanded="false">
                                 <div class="d-flex px-2 py-1">
                                    <span data-bs-toggle="tooltip" data-bs-placement="top" title="Info" class="">
                                       <i class="collapse-close material-icons px-1 opacity-10 text-info text-dark">
                                          <span>info</span>
                                       </i>
                                    </span>
                                    <div class="d-flex flex-column justify-content-center">
                                       <h6 class="mb-0 text-xs">{{ item.Targa }}</h6>
                                    </div>
                                 </div>
                              </a>
                           </td>
                           <td>
                              <p class="text-xs font-weight-normal mb-0">{{ item.DescrizioneModello }}</p>
                           </td>
                           <td class="align-middle text-center">
                              <div class="d-flex align-items-center">
                                 <span class="me-2 text-xs">{{ item.Km }}</span>
                              </div>
                           </td>
                           <td class="align-middle">
                              <span class="badge badge-dot me-4">
                                 <span class="text-dark text-xs">{{ vehicleTypes.find(type => type.Codice ==
                                    item.Categoria) != null ? vehicleTypes.find(type => type.Codice ==
                                       item.Categoria).Descrizione : item.Categoria }}</span>
                              </span>
                           </td>
                           <td class="align-middle text-center">
                              <div class="d-flex align-items-center">
                                 <span class="me-2 text-xs">{{ item.CompanyName }}</span>
                              </div>
                           </td>
                           <td class="align-middle text-center">
                              <div class="d-flex align-items-center">
                                 <span class="me-2 text-xs">{{ item.Ubicazione }}</span>
                              </div>
                           </td>
                           <td class="align-middle">
                              <span class="badge badge-dot me-4">
                                 <span class="text-dark text-xs">{{ item.DemoFuelCheckModels.length }}</span>
                              </span>
                           </td>
                        </tr>
                        <tr>
                           <td class="p-0" colspan="7">
                              <div class="collapse rounded bg-gray-200" :id="'Targa_' + item.Targa">
                                 <fuelcheck :model="item"></fuelcheck>
                              </div>
                           </td>
                        </tr>
                     </tbody>
                  </table>
               </div>
            </div>
         </div>
      </div>
      <div class="modal" v-show="isModalOpen">
         <div class="modal-content">
            <h2>Aggiungi un rifornimento</h2>
            <div class="input-group input-group-dynamic">
               <div class="input-group input-group-dynamic">
                  <div class="choices" data-type="select-one" placeholder="Targa" tabindex="0" role="listbox"
                     aria-haspopup="true" aria-expanded="false">
                     <select class="form-control" id="fastItemLicense" v-model="fastItem.HubDemoManagementId"
                        placeholder="Targa">
                        <option value="null">Seleziona targa</option>
                        <option v-for="lp in LicensePlates" :value="lp.id" :key="lp.targa">
                           {{ lp.targa }} | <span><i>{{ lp.modello }}</i></span>
                        </option>
                     </select>
                  </div>
               </div>
            </div>
            <table class="table align-items-center mb-0" style="width:100%">
               <thead>
                  <tr>
                     <th
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 text-dark text-xs">
                        Km</th>
                     <th
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 text-dark text-xs">
                        Importo</th>
                     <th
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 text-dark text-xs">
                        Importo No Iva</th>
                     <th
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 text-dark text-xs">
                        Data</th>
                     <th
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3 text-dark text-xs">
                        Note</th>
                  </tr>
               </thead>
               <tbody>
                  <tr>
                     <td>
                        <div class="input-group input-group-dynamic">
                           <label class="form-label"></label>
                           <input id="fastItemKm" type="number" step="0.01" class="form-control text-dark text-xs"
                              placeholder="..." aria-label="" v-model="fastItem.Km">
                           <!-- &nbsp;Km -->
                        </div>
                     </td>
                     <td>
                        <div class="input-group input-group-dynamic">
                           <label class="form-label"></label>
                           <input id="fastItemAmount" type="number" step="0.01" class="form-control text-dark text-xs"
                              placeholder="€" aria-label="" v-model="fastItem.Amount">
                           <!-- &nbsp;€ -->
                        </div>
                     </td>
                     <td>
                        <div class="input-group input-group-dynamic">
                           <label class="form-label"></label>
                           <input id="fastItemAmount" type="text" class="form-control text-dark text-xs" placeholder="€"
                              aria-label="" v-model="fastItem.AmountExVat" readonly disabled>
                           <!-- &nbsp;€ -->
                        </div>
                     </td>
                     <td>
                        <div class="input-group input-group-dynamic">
                           <label class="form-label"></label>
                           <input id="fastItemFuelDate" type="date" class="form-control text-dark text-xs" aria-label=""
                              v-model="fastItem.FuelDate">
                           <!-- formatDate(item.FuelDate) -->
                        </div>
                     </td>
                     <td>
                        <div class="input-group input-group-dynamic">
                           <label class="form-label"></label>
                           <input id="fastItemNote" type="text" class="form-control text-dark text-xs" placeholder=""
                              aria-label="" v-model="fastItem.Note">
                           <!-- formatDate(item.FuelDate) -->
                        </div>
                     </td>
                  </tr>
               </tbody>
            </table>
            <div class="validation-msg" v-if="showErrorMsg">
               <span class="text-warning">Salvataggio non riuscito, controlla di aver compilato tutti i campi e che la data
                  sia corretta</span>
            </div>
            <div class="row">
               <div class="col-sm-6">
                  <a class="align-bottom btn mb-0 bg-gradient-light col-sm-12 " @click="closeModal()">
                     <i v-if="isInLoading" class="material-icons text-sm text-warning rotating">autorenew</i>
                     <i v-else class="material-icons text-sm">cancel</i>
                     &nbsp;&nbsp;Annulla
                  </a>
               </div>
               <div class="col-sm-6">
                  <a class="align-bottom btn bg-gradient-dark mb-0 col-sm-12" @click="saveFastItem()">
                     <i v-if="isInLoading" class="material-icons text-sm text-warning rotating">autorenew</i>
                     <i v-else class="material-icons text-sm">save</i>
                     &nbsp;&nbsp;Salva
                  </a>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import fetchFromAPI from "../../script/api.js"
import helpers from '../../script/helpers.js'
import fuelcheck from '../../components/DemoDatas.vue'
import _ from 'lodash'


export default {
   components: {
      fuelcheck
   },
   data() {
      return {
         endPoint: null,
         filters: {},
         list: {},
         isInLoading: false,
         isLoadingReport: false,
         i: 0,
         link: '',
         visible: false,
         isModalOpen: false,
         invalidFilterDates: false,
         fastItem: {},
         showErrorMsg: false,
         LicensePlates: null,
         vehicleTypes: [
            {
               Codice: null,
               Descrizione: "Tutti"
            },
            {
               Codice: 2,
               Descrizione: "Prodotto DEMO"
            },
            {
               Codice: 3,
               Descrizione: "Prodotto DIPENDENTI"
            },
            {
               Codice: 4,
               Descrizione: "Prodotto NOLEGGIO"
            }
         ]
      };
   },
   watch: {
      filters: {
         handler(newFilter) {
            //Check date
            if (newFilter.DateFrom > newFilter.DateTo) {
               this.invalidFilterDates = true;
               return
            }
            this.invalidFilterDates = false;
         },
         deep: true
      },
      fastItem: {
         handler(val) {
            this.AmountExVatFastItem(val);
         },
         deep: true
      }
   },
   methods: {
      LoadData() {
         this.visible = false
         this.list = {};
         var normalizedFilters = _.cloneDeep(this.filters);
         // Normalizzazione delle prop, quando uso il v-select viene assegnato l'intero oggetto
         if (typeof normalizedFilters.CodiceUbicazione === 'object' && normalizedFilters.CodiceUbicazione != null) {
            normalizedFilters.CodiceUbicazione = normalizedFilters.CodiceUbicazione.Codice;
         }
         if (typeof normalizedFilters.CommissionNumber === 'object' && normalizedFilters.CommissionNumber != null) {
            normalizedFilters.CommissionNumber = normalizedFilters.CommissionNumber.id;
         }

         helpers.resetFilter('DemoManagmentist')
         helpers.setObject('DemoManagmentFilter', this.filters);
         this.isInLoading = true;
         this.endPoint = process.env.VUE_APP_END_POINT
         this.fastItem = {};
         this.fastItem.token = helpers.token();
         this.fastItem.HubDemoManagementId = null;
         fetchFromAPI("DemoManagement/LoadList", normalizedFilters, (res) => {
            this.i++
            this.list = this.CheckVar(res);
            this.isInLoading = false;
            helpers.setObject('DemoManagement', res);
         });
         fetchFromAPI("DemoManagement/LoadLicensePlates", null, (res) => {
            this.LicensePlates = res;
         });
      },
      FormatDate(dateString) {
         const date = new Date(dateString);
         let dateFormatted = new Intl.DateTimeFormat("default", { dateStyle: "long" }).format(date);
         if (dateFormatted != "1 gennaio 1") {
            return dateFormatted;
         }
         return "";
      },
      ExportExcel() {
         this.visible = true
         this.isLoadingReport = true;
         fetchFromAPI("DemoManagement/ExcelExport", { filters: this.filters, demolist: this.list }, (res) => {
            this.link = res
            this.isLoadingReport = false;
         });
      },
      CheckVar(list) {
         for (let x = 0; x < list.length; x++) {
            for (let i = 0; i < list[x].DemoFuelCheckModels.length; i++) {
               list[x].DemoFuelCheckModels[i].FuelDate = new Date(list[x].DemoFuelCheckModels[i].FuelDate).toISOString().slice(0, 10)
               list[x].DemoFuelCheckModels[i].AmountExVat = list[x].DemoFuelCheckModels[i].Amount - list[x].DemoFuelCheckModels[i].Amount * 0.22
            }
         }
         return list;
      },
      DateInRange(value) {
         var datemin = this.getDateMin();
         var datemax = this.getDatemax()
         if (datemin.getTime() < Date.parse(value) && Date.parse(value) < datemax.getTime()) {
            return true;
         }
         return false;
      },
      getDateMin() {
         return new Date(2015, 0, 1);
      },
      getDatemax() {
         const date = new Date()
         date.month = date.getMonth() + 1;
         return date;
      },
      OpenModal() {
         this.fastItem.HubDemoManagementId = this.filters.CommissionNumber;
         this.isModalOpen = true;
      },
      closeModal() {
         this.isModalOpen = false;
      },
      saveFastItem() {
         if (this.fastItem.HubDemoManagementId == null || this.fastItem.HubDemoManagementId == '') {
            this.showErrorMsg = true;
            return;
         }
         if (this.fastItem.Km == null || this.fastItem.Km == '') {
            this.showErrorMsg = true;
            return;
         }

         if (this.fastItem.Amount == null || this.fastItem.Amount == '') {
            this.showErrorMsg = true;
            return;
         }

         if (this.fastItem.FuelDate == null || this.fastItem.FuelDate == '' || !this.DateInRange(this.fastItem.FuelDate)) {
            this.showErrorMsg = true;
            return;
         }

         this.showErrorMsg = false;
         fetchFromAPI('DemoManagement/SaveFastItem', this.fastItem, (res) => {
            console.log(res);
            this.isModalOpen = false;
            this.LoadData();
         }, (err) => {
            console.error(err);
         })
      },
      AmountExVatFastItem(val) {
         this.fastItem.AmountExVat = val.Amount - (val.Amount * 0.22) || 0;
      }
   },
   created() {
      // let params = new URL(document.location).searchParams;
      // let sid = params.get("SID");
      /*let filterData = helpers.getFilters('FEAListFilter');
      if (filterData && typeof filterData !== "undefined") {
         this.filters = filterData;
         this.filters.JobId = sid;
         this.LoadData();
         return;
       }*/
      fetchFromAPI("DemoManagement/BindFiltersForHub", { Token: helpers.token() }, (res) => {
         res.DateFrom = new Date(res.DateFrom).toISOString().slice(0, 10)
         res.DateTo = new Date(res.DateTo).toISOString().slice(0, 10)
         this.filters = res;
         this.filters.CodiceUbicazione = null;
         this.LoadData();
      });
   }
}
</script>

<style scoped>
.modal {
   display: block;
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.7);
   justify-content: center;
   align-items: center;
   z-index: 1000;
}

.modal-content {
   background-color: #fff;
   padding: 20px;
   border-radius: 5px;
   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
   position: relative;
   width: 80%;
   margin: auto;
   top: 20%;
}

.close {
   position: absolute;
   top: 10px;
   right: 10px;
   font-size: 20px;
   cursor: pointer;
}

.table-responsive {
   overflow-x: hidden !important;
}

.v-select input {
   font-size: 0.875rem !important;
}

.v-select .vs__actions {
   transform: scale(0.7) !important;
}</style>