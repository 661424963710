<template>
   <div class="container-fluid py-4" style="zoom: 90%;">
      <div class="card-body p-4">
         <div class="row">
            <div class="col-md-3 mb-md-0 mb-4">
               <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                  <div class="input-group input-group-dynamic">
                     <label class="form-label"></label>
                     <input class="form-control" aria-label="" placeholder="Id vettura, telaio o n° ordine commerciale"  type="text" v-model="filters.VehicleCode">
                  </div>
               </div>
            </div>
            <div class="col-md-3">
               <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                  <div class="input-group input-group-dynamic">
                     <label class="form-label"></label>
                     <select v-model="filters.Managed" class="form-control text-capitalize">
                        <option value="0">Tutti gli stati</option>
                        <option value="1">Regolari</option>
                        <option value="2">In ritardo</option>
                        <option value="3">Pronte</option>
                     </select>
                  </div>
               </div>
            </div>
            <div class="col-md-3">
               <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                  <div class="input-group input-group-dynamic">
                     <label class="form-label"></label>
                     <select class="form-control text-capitalize" v-model="filters.GroupType">
                        <option class="text-capitalize" v-for="work in processList" :key="work.Id" :value="work.Id" :label="work.Name">{{ work }}</option>
                     </select>
                  </div>
               </div>
            </div>
            <div class="col-md-3">
               <div class="card card-body card-plain d-flex align-items-center flex-row">
                  <div class="input-group input-group-dynamic ">
                     <a class="float-right align-bottom btn bg-gradient-dark mb-0" @click="LoadData()">
                        <i v-if="isInLoading" class="material-icons text-sm text-warning rotating">autorenew</i>
                        <i v-else class="material-icons text-sm">search</i>
                           &nbsp;&nbsp;Cerca
                     </a>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="d-flex row col-md-12 mx-auto">
         <div class="col-md-3 mb-4">
            <div class="card">
               <div class="card-body px-4 py-2">
                  <div class="icon icon-lg icon-shape bg-gradient-info shadow text-center border-radius-xl mt-n4 position-absolute">
                     <i class="material-icons text-white opacity-10 px-2">
                     <span>settings</span>
                     </i>
                  </div>
                  <div class="d-flex align-items-center justify-content-end text-end pt-1">
                     <p class="text-capitalize pt-2 pe-3">Numero vetture:</p>
                     <h4 class=""> {{ list.length }} </h4>
                  </div>
               </div>
            </div>
         </div>
         <div class="col-md-3 mb-4">
            <div class="card">
               <div class="card-body px-4 py-2">
                  <div class="icon icon-lg icon-shape bg-gradient-danger shadow text-center border-radius-xl mt-n4 position-absolute">
                     <i class="material-icons text-white opacity-10 px-2">
                     <span>priority_high</span>
                     </i>
                  </div>
                  <div class="d-flex align-items-center justify-content-end text-end pt-1">
                     <p class="text-capitalize pt-2 pe-3">vetture in ritardo:</p>
                     <h4 class=""> 
                     {{ list.filter(item => item.DelayStatus == 2).length }} 
                  </h4>
                  </div>
               </div>
            </div>
         </div>
      </div>
     <div class="row my-4">
       <div class="col-12">
       <div class="card">
         <div class="" style="overflow-x: scroll">
           <table class="table table-responsive align-items-center mb-0">
             <thead>
               <tr>
                 <th class="text-uppercase text-secondary text-xxs font-weight-bolder">id vettura</th>
                 <th class="text-uppercase text-secondary text-xxs font-weight-bolder px-2">telaio</th>
                 <th class="text-uppercase text-secondary text-xxs font-weight-bolder px-2">n° ord. commerciale</th>
                 <th class="text-uppercase text-secondary text-xxs font-weight-bolder px-3">stato</th>
                 <th class="text-uppercase text-secondary text-xxs font-weight-bolder px-2">lavorazione corrente</th>
                 <th class="text-uppercase text-secondary text-xxs font-weight-bolder px-2">prossima lavorazione</th>
               </tr>
             </thead>
             <tbody style="border-top:0px" v-for="item in list" :key="item.Vid">
               <tr>
                  <td>
                     <a :href="'#veicolo_'+item.Vid" data-bs-toggle="collapse" role="button" aria-expanded="false">
                        <div class="ms-3 fs-5">{{ item.Vid }}</div>
                     </a>
                  </td>
                  <td>
                     {{ item.Frame }}
                  </td>
                  <td>
                     {{ item.CommOrder }}
                  </td>
                  <td>
                     <div>
                        <span v-if="item.DelayStatus==2" class="text-xs mb-0 px-3 badge bg-danger">IN RITARDO</span>
                        <span v-else-if="item.DelayStatus==1" class="text-xs mb-0 px-3 badge bg-info">REGOLARE</span>
                        <span v-else class="text-xs mb-0 px-3 badge bg-success">COMPLETATO</span>
                     </div>
                  </td>
                  <td>
                     <div v-if="item.CurrentWork != null" class="text-capitalize fw-bolder fs-5">{{ item.CurrentWork.Name }}</div>
                     <div v-else class="text-capitalize fw-bolder fs-5 opacity-5">Nessuno</div>
                  </td>
                  <td>
                     <div class="row col-12">
                        <div class="col-1 my-auto"><i class="material-icons text-sm opacity-10 fw-bold">arrow_forward</i></div>
                        <div v-if="item.NextWork != null" class="col-10">
                           <div class="text-capitalize">{{ item.NextWork.Name }}</div>
                           <div class="text-xxs opacity-9">{{ formatDate(item.NextWork.ExpectedDate) }}</div>
                        </div>
                        <div class="col-10 opacity-5" v-else>Nessuno</div>
                     </div>
                  </td>
               </tr>
               <tr>
                 <td class="p-0" colspan="8"> 
                   <div class="collapse rounded bg-gray-200" :id="'veicolo_'+item.Vid">
                     <div class="row px-5 py-4 col-12">
                        <div class="col-3 mt-4">
                           <ul v-for="(work, index) in item.Passages" :key="work.Id" class="timeline-with-icons mb-0" :class="{ 'timeline-line' : index != item.Passages.length-1}">
                              <li v-if="new Date(work.Date) < new Date() && work.Date != null" class="timeline-item text-xxs">
                                 <span class="timeline-icon bg-success text-white">
                                    <i  class="material-icons text-sm">check</i>
                                 </span>
                                 <h6 class="fw-bold mb-0 pt-n1 text-capitalize">{{ work.Name }}</h6>
                                 <div v-if="new Date(work.Date) < new Date(work.ExpectedDate)" class="d-flex align-items-center">
                                    <span class="fw-bold text-xs mb-0 pb-3 text-success">{{ formatDate(work.Date) }}</span>
                                    <i class="material-icons text-sm pb-3 opacity-10 fw-bold px-2">arrow_back</i>
                                    <span class="fw-bold text-xs mb-0 pb-3">{{ formatDate(work.ExpectedDate) }}</span>
                                 </div>
                                 <div v-else-if="new Date(work.Date) > new Date(work.ExpectedDate)" class="d-flex align-items-center">
                                    <span class="fw-bold text-xs mb-0 pb-3">{{ formatDate(work.ExpectedDate) }}</span>
                                    <i class="material-icons text-sm pb-3 opacity-10 px-2 fw-bold">arrow_forward</i>
                                    <span class="fw-bold text-xs mb-0 pb-3 text-danger">{{ formatDate(work.Date) }}</span>
                                 </div>
                                 <div v-else>
                                    <p class="fw-bold text-xs mb-0 pb-3">{{ formatDate(work.Date) }}</p>
                                 </div>
                              </li>
                              <li v-else-if="work.Name == item.CurrentWork.Name" class="timeline-item text-xxs">
                                 <span class="timeline-icon bg-info text-white">
                                    <i class="material-icons text-sm rotating">autorenew</i>
                                 </span>
                                 <h6 class="fw-bold mb-0 text-capitalize text-info fs-5">{{ work.Name }}</h6>
                                 <p v-if="new Date() > new Date(work.ExpectedDate)" class="fw-bold mb-0 pb-3 text-xs text-danger">{{ formatDate(work.ExpectedDate) }}</p>
                                 <p v-else class="fw-bold mb-0 opacity-6 pb-3">{{ formatDate(work.ExpectedDate) }}</p>
                              </li>
                              <li v-else class="timeline-item text-xxs">
                                 <span class="timeline-icon bg-secondary text-white">
                                    <i class="material-icons text-sm">hourglass_empty</i>
                                 </span>
                                 <h6 class="fw-bold mb-0 opacity-6 text-capitalize">{{ work.Name }}</h6>
                                 <p class="fw-bold mb-0 opacity-6 pb-3 text-xs">{{ formatDate(work.ExpectedDate) }}</p>
                              </li>
                           </ul>
                        </div>
                        <div class="col-9 card my-3 h-50 w-auto mx-auto">
                           <div class="card-body border-radius-lg p-5 pb-4">
                              <h4 class="">Informazioni aggiuntive</h4>
                              <div class="row mb-4 fs-6">
                                 <div v-if="item.DelayDays > 0">
                                    Questa vettura è <span class="text-danger fw-bolder fs-5"> {{ item.DelayDays }} </span> giorni in <span class="text-danger text-uppercase fw-bolder">ritardo</span>
                                 </div>
                                 <div v-else>
                                    Questa vettura è <span class="text-success fw-bolder fs-5"> {{ item.DelayDays * -1 }} </span> giorni in <span class="text-success text-uppercase fw-bolder">anticipo</span>
                                 </div>
                              </div>
                              <div class="row col-12 text-wrap">
                                 <div class="col-6">
                                    <div class="mb-3"><div class="fw-bolder text-xs text-primary">Targa:</div> <span class="text-capitalize fs-6 opacity-9">{{ item.Plate }}</span></div>
                                    <div class="mb-3"><div class="fw-bolder text-xs text-primary">Marca:</div> <span class="text-capitalize fs-6 opacity-9">{{ item.Brand }}</span></div>
                                    <div class="mb-3"><div class="fw-bolder text-xs text-primary">Modello:</div> <span class="text-capitalize fs-6 opacity-9">{{ item.Model }}</span></div>
                                    <div class="mb-3"><div class="fw-bolder text-xs text-primary">Versione:</div> <span class="text-capitalize fs-6 opacity-9">{{ item.Version }}</span></div>
                                    <div class="mb-3"><div class="fw-bolder text-xs text-primary">Ubicazione:</div> <span class="text-capitalize fs-6 opacity-9">{{ item.Location }}</span></div>
                                 </div>
                                 <div class="col-6">
                                    <div class="mb-3"><div class="fw-bolder text-xs text-primary">Cliente:</div> <span class="text-capitalize fs-6 opacity-9">{{ item.Customer }}</span></div>
                                    <div class="mb-3"><div class="fw-bolder text-xs text-primary">Venditore:</div> <span class="text-capitalize fs-6 opacity-9">{{ item.Seller }}</span></div>
                                    <div class="mb-3"><div class="fw-bolder text-xs text-primary">Ricevuto da:</div> <span class="text-capitalize fs-6 opacity-9">{{ item.RecivedFrom }}</span></div>
                                    <div class="mb-3"><div class="fw-bolder text-xs text-primary">Stato veicolo:</div> <span class="text-capitalize fs-6 opacity-9">{{ item.VehicleStatus }}</span></div>
                                    <div class="mb-3"><div class="fw-bolder text-xs text-primary">Note:</div> <span class="text-capitalize fs-6 opacity-9">{{ item.Notes }}</span></div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                   </div>
                 </td>
               </tr>
             </tbody>
           </table>
           <div v-if="isInLoading" class="text-center py-4">
               <i class="material-icons text-center rotating" style="font-size:3rem;">autorenew</i>
            </div>
           <div v-if="list && list.length>0">
               <Paginator :currentPage="currentPage" :totalPages="filters.TotalPages" :perPage="perPage" @pagechanged="onPageChange" class="pt-4"/>
            </div>
         </div>
       </div>
       </div>
     </div>
   </div>
</template>

<script>
import fetchFromAPI from "../../script/api.js"
import helpers from '../../script/helpers.js'
import Paginator from "../../components/Paginator.vue"

export default {
   components: {
      Paginator
   },
   data() {
      return {
         currentPage: 1,
         perPage: 10,
         filters: {},
         list: [],
         isInLoading: false,
         processList: []
      };
   },

   methods: {
      formatDate: helpers.formatDate,

      LoadData(){
         this.isInLoading = true;
         this.filters.Page = this.currentPage;
         this.filters.TotalRows = this.perPage;
         fetchFromAPI("VehicleStatusDashboard/GetVehicleStatuses", this.filters, (res) => {
            this.filters = res.Filter;
            this.list = res.Vehicles;
            this.isInLoading = false;
         });
      }
   },

   created() {
      fetchFromAPI("VehicleStatusDashboard/GetVehicleJobsList", { Token : helpers.token() }, (res) => {
         this.processList = res;
      })

      fetchFromAPI("VehicleStatusDashboard/BindFiltersForHub", { Token : helpers.token() }, (res) => {
         this.filters = res;
         this.LoadData();
      })
   }
}
</script>

<style scoped>

.timeline-with-icons {
  border-left: 3px solid transparent;
  position: relative;
  list-style: none;
}

.timeline-line {
   border-left: 3px solid #7b809a;
}

.timeline-with-icons .timeline-item {
  position: relative;
}

.timeline-with-icons .timeline-item:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline-with-icons .timeline-icon {
  position: absolute;
  left: -46px;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>