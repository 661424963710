<template>
<footer class="page-footer teal darken-4">
    <div style="height:5px;width:5px;margin:0 auto"></div>
    <div class="footer-copyright">
        <div class="container font-weight-bolder mb-0 small">
            Designed & Developed by Trivellato Spa
        </div>
    </div>
</footer>
</template>
<style>
        html {
            position: relative;
            min-height: 100%;
        }

        body {
            margin-bottom: 60px;
        }

        footer {
            position: absolute;
            bottom: 0;
            width: 100%;
            margin-top: 30px;
        }

        body > .container {
            padding: 60px 15px 0;
        }

        footer > .container {
            padding-right: 15px;
            padding-left: 15px;
        }
</style>