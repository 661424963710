<template>
   <link rel="stylesheet"
      href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0" />
   <div class="container-fluid py-4 all-small">
      <div class="card-body p-4">
         <div class="row">
            <div class="col-md-4 mb-md-0 mb-4">
               <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                  <div class="input-group input-group-dynamic trivellato">
                     <label class="form-label">Descrizione veicolo</label>
                     <input type="text" class="form-control" id="fastItemLicense" v-model="filters.Description"
                        aria-label="" v-on:keyup.enter="LoadData()" />
                  </div>
               </div>
            </div>
            <!-- <div class="col-md-3">
               <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                  <div class="input-group input-group-dynamic trivellato"
                     :class="{ 'is-filled': filters.LineaVeicoloCortesia != null && filters.LineaVeicoloCortesia.Codice != null }">
                     <label class="form-label">Tipologia veicolo</label>
                     <v-select class="form-control" :options="filters.LineeVeicoli" label="Descrizione" value="Codice"
                        v-model="filters.LineaVeicoloCortesia">
                        <template #selected-option>
                           {{ filters.LineaVeicoloCortesia.Descrizione }}
                        </template>
                     </v-select>
                  </div>
               </div>
            </div> -->
            <div class="col-md-4">
               <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                  <div class="input-group input-group-dynamic trivellato"
                     :class="{ 'is-filled': filters.CodiceUbicazione != null && filters.CodiceUbicazione.Codice != null }">
                     <label class="form-label">Località</label>
                     <v-select class="form-control" :options="filters.Ubicazioni" label="Descrizione" value="Codice"
                        v-model="filters.CodiceUbicazione">
                        <template #selected-option>
                           {{ filters.CodiceUbicazione.Descrizione }}
                        </template>
                     </v-select>
                     <!-- <div class="choices" data-type="select-one" placeholder="ubicazione" tabindex="0" role="listbox"
                        aria-haspopup="true" aria-expanded="false">
                        <select type="form-control" class="form-control" placeholder="ubicazione" aria-label=""
                           v-model="filters.CodiceUbicazione">
                           <option value="null" hidden></option>
                           <option v-for="ubicazione in filters.Ubicazioni" :value="ubicazione.Codice"
                              :key="ubicazione.Codice">
                              {{ ubicazione.Descrizione }}
                           </option>
                        </select>
                     </div> -->
                  </div>
               </div>
            </div>
            <div class="col-md-4">
               <div class="card card-body card-plain d-flex align-items-center flex-row">
                  <div class="input-group input-group-dynamic ">
                     <a class="float-right align-bottom btn bg-gradient-dark mb-0" @click="LoadData()"
                        :class="{ 'disabled': isInLoading }">
                        <i v-if="isInLoading" class="material-icons text-sm text-warning rotating">autorenew</i>
                        <i v-else class="material-icons text-sm">search</i>
                        &nbsp;&nbsp;Cerca
                     </a>
                  </div>
               </div>
            </div>
         </div>
         <div class="row">
            <div class="col-md-4">
               <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                  <div class="input-group input-group-dynamic trivellato">
                     <label class="form-label"></label>
                     <input type="date" class="form-control" :class="{ 'text-warning': invalidFilterDates }"
                        placeholder="Da" aria-label="" min="2010-01-01" v-model="filters.DateFrom" v-if="!filters.Active"
                        v-on:keyup.enter="LoadData()">
                  </div>
               </div>
            </div>
            <div class="col-md-4">
               <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                  <div class="input-group input-group-dynamic trivellato">
                     <label class="form-label"></label>
                     <input type="date" class="form-control" :class="{ 'text-warning': invalidFilterDates }"
                        placeholder="a" aria-label="" min="2010-01-01" v-model="filters.DateTo" v-if="!filters.Active"
                        v-on:keyup.enter="LoadData()">
                  </div>
               </div>
            </div>
            <div class="col-md-3">
               <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row  my-2">
                  <div class="form-check form-switch float-right">
                     <input class="form-check-input trivellato" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                        v-model="filters.Active" @click="set3MonthPreview()">
                     <label class="form-check-label" for="flexSwitchCheckDefault">Mostra prossimi 3 mesi</label>
                  </div>
               </div>
            </div>
            <!-- <div class="col md-3">
               <div class="card card-body card-plain d-flex align-items-center flex-row">
                  <div class="input-group input-group-dynamic ">
                     <button @click="ExportExcel" type="submit" :class="{ 'disabled': isInLoading }"
                        data-bind="click:$root.ExcelExport.bind($data), enable:!$root.IsOnElaboration()"
                        class="btn btn-sm btn-outline-dark mt-2 mr-2 small"><span class="fa fa-file-excel-o"
                           aria-hidden="true"></span> Esporta Excel</button>
                  </div>
               </div>
            </div> -->
         </div>
         <!-- <div class="row my-4" style="width:35%" v-if="reportVisible">
            <div class="col-12">
               <div class="card">
                  <div class="table-responsive">
                     <span v-if="isLoadingReport">
                        <div class="card mt-4" id="basic-info">
                           <div class="card-body pt-0">
                              <h5>
                                 Generazione Report...
                                 <span class="material-icons align-middle text-warning rotating">
                                    autorenew
                                 </span>
                              </h5>
                           </div>
                        </div>
                     </span>
                     <span v-else>
                        <div class="card mt-4" id="basic-info">
                           <div class="card-body pt-0">
                              <h5>
                                 Report generato
                                 <span class="material-icons align-middle text-success">
                                    done
                                 </span>
                                 <a href="javascript:" @click="reportVisible = false"
                                    class="material-symbols-outlined float-end text-black-50">
                                    close
                                 </a>
                              </h5>

                              <a @click="reportVisible = false"
                                 :href="endPoint.replace('/api/', '') + link.VirtualFilePath">{{
                                    link.FileName }}</a>
                           </div>
                        </div>
                     </span>
                  </div>
               </div>
            </div>
         </div> -->
      </div>
      <div class="row card flex-row m-4" v-if="list">
         <div v-if="isInLoading">
            <div class="card-body p-4 text-center">
               <span class="material-icons material-symbols-outlined fs-1 color-trivellato rotating">
                  autorenew
               </span>
               <h5>Caricamento in corso</h5>
            </div>
         </div>
         <div v-else-if="list.length == 0">
            <div class="card-body p-4 text-center">
               <span class="material-icons material-symbols-outlined fs-1 color-trivellato">
                  search_off
               </span>
               <h5>Nessuna vettura trovata per i filtri impostati</h5>
            </div>
         </div>
         <div v-else class="col-12">
            <table class="table" style="table-layout:fixed; border-spacing: 10px;">
               <thead class="col-sm-12 border-0">
                  <tr>
                     <th class="px-2" style="width: 30%">Vetture <span v-if="list != null && list.length > 0"> - {{ list.length }} elementi</span> </th>
                     <th class="px-0" v-for="slot in slots" :key="slot.slotStart" :title="slot.dayOfWeek">{{slot.dayOfWeek.substring(0, 1) }}</th>
                  </tr>
               </thead>
               <tbody class="col-12">
                  <tr v-for="vehicle in list" :key="vehicle.IdOfficina" class="rounded">
                     <td class="align-middle p-1 d-flex align-items-center">
                     <span class="circle me-2" :style="{ backgroundColor: getVehicleColor(vehicle.Color) }"></span>
                     <span class="w-80 text-truncate" style="white-space: nowrap; font-size: 0.8rem;" :title="vehicle.DescrizioneModello">
                        <strong>{{ vehicle.Targa }}</strong> | {{ vehicle.DescrizioneModello }}
                     </span>
                     <a href="javascript:" :id="'location-popover-' + vehicle.Targa"
                        class="material-symbols-outlined pe-auto ms-auto" @mouseover="showLocationInfo(vehicle)"
                        @mouseleave="locationHover = false">
                        pin_drop
                     </a>
                     </td>
                     <td v-for="slot in vehicle.VehicleRentingSlots" :key="slot.slotStart" class="p-1 rounded align-middle">
                        <div type="button" class="my-1 rounded" :class="{
                           'bg-dark striped': slot.status == -1,
                           'bg-danger': slot.status == 1,
                           'bg-warning': slot.status == 2,
                           'bg-success': slot.status == 0,
                           'bg-info': slot.status == 3,
                           }" @mouseover="showRentingInfo(slot)" @mouseleave="dateHover = false"
                           @click="showBookingModal(vehicle)"
                           style="width: 100%;">
                           &nbsp;
                        </div>
                     </td>
                  </tr>
               </tbody>
            </table>
         </div>
      </div>
   </div>

   <div id="availability-popover" class="popover-info rounded all-small border border-2 border-dark" v-show="dateHover"
      style="width: 370px;max-height: 200px;"
      :style="{ 'top': 'calc(' + this.mouseY + 'px + 20px)', 'left': 'calc(' + this.mouseX + 'px + 20px)', 'transform': calcPopoverTransform('availability') }">
      <div v-if="rentingInfo">
         <i class="small col-sm-12 text-end">
            <strong>{{ FormatDateTime(rentingInfo.slotStart) }} - {{ FormatDateTime(rentingInfo.slotEnd)}}</strong>
         </i>
         <h5 v-if="rentingInfo.status == -1">Vettura non gestita in questo periodo</h5>
         <h5 v-if="rentingInfo.status == 1" class="text-danger">Vettura prenotata</h5>
         <h5 v-if="rentingInfo.status == 0" class="text-success">Vettura disponibile</h5>
         <h5 v-if="rentingInfo.status == 2" class="text-warning">Prenotazione in attesa di conferma</h5>
         <h5 v-if="rentingInfo.status == 3" class="text-info">Vettura prenotata da te</h5>
         
         <i v-if="rentingInfo.status == 0">Clicca per inviare una richiesta di prenotazione</i>
         <i v-else>Clicca per vedere i dati delle prenotazioni</i>
      </div>
   </div>

   <div id="location-popover" class="popover-info rounded all-small border border-2 border-dark" v-show="locationHover"
      style="width: 200px;max-height: 60px;"
      :style="{ 'top': 'calc(' + this.mouseY + 'px + 20px)', 'left': 'calc(' + this.mouseX + 'px + 20px)', 'transform': calcPopoverTransform('location') }">
      <div v-if="locationInfo">
         {{ locationInfo }}
      </div>
   </div>

   <div class="modal fade" :class="{ 'show': rentingInfoStatic != null, 'd-none': rentingInfoStatic == null }"
      :style="{ 'z-index: 1055': rentingInfoStatic != null }" id="bookingModal" tabindex="-1"
      aria-labelledby="bookingModalLabel" aria-hidden="true">
      <div class="modal-dialog" v-if="rentingInfoStatic != null" style="max-width: 50rem;">
         <div class="modal-content">
            <div class="modal-header">
               <p class="small m-0 mx-1">
                     Inizio: <b>{{ FormatDateTime(rentingInfoStatic.slotStart) }}</b>
                  </p>
                  <p class="small m-0 mx-1">
                     Fine: <b>{{ FormatDateTime(rentingInfoStatic.slotEnd) }}</b>
                  </p>
            </div>
            <div class="modal-body">
               <h4 v-if="rentingInfoStatic.status==0" class="modal-title text-center text-success text-uppercase" id="exampleModalLabel">slot libero</h4>
               <h4 v-if="rentingInfoStatic.status==1" class="modal-title text-center text-danger text-uppercase" id="exampleModalLabel">slot occupato</h4>
               <h4 v-if="rentingInfoStatic.status==2" class="modal-title text-center text-warning text-uppercase" id="exampleModalLabel">in attesa di conferma</h4>
               <h4 v-if="rentingInfoStatic.status==3" class="modal-title text-center text-info text-uppercase" id="exampleModalLabel">occupato da te</h4>
               <h6 v-if="rentingInfoStatic.status==0" class="modal-title text-center" id="exampleModalLabel">Desideri richiedere un appuntamento per il seguente veicolo in questo slot?</h6>
               <h6 v-if="rentingInfoStatic.status==1" class="modal-title text-center" id="exampleModalLabel">Questo slot per il seguente veicolo è già stato prenotato</h6>
               <h6 v-if="rentingInfoStatic.status==2" class="modal-title text-center" id="exampleModalLabel">Hai prenotato questo slot per un appuntamento ma deve ancora essere approvato</h6>
               <h6 v-if="rentingInfoStatic.status==3" class="modal-title text-center" id="exampleModalLabel">Hai prenotato questo slot per il seguente veicolo</h6>
               <br>
               <div class="modal-title text-center" id="exampleModalLabel"><b>Modello: </b>{{ currentVehicle.DescrizioneModello }}</div>
               <div class="modal-title text-center" id="exampleModalLabel"><b>Targa: </b>{{ currentVehicle.Targa }}</div><br>
               <div v-if="rentingInfoStatic.status==0" class="input-group input-group-dynamic">
                  <input type="number" class="form-control center-placeholder" v-model="userPhone" aria-label="" placeholder="Inserire un numero di telefono"/>
               </div>
               <div class="mx-auto text-center">
                  <button v-if="rentingInfoStatic.status==0 && !isOldDate()" type="button" class="btn btn-lg bg-gradient-info mx-auto my-3" @click="sendBookingRequest()" :disabled="bookingLoading || userPhone==''">Prenota</button>
                  <button v-if="rentingInfoStatic.status==2 && !isOldDate()" type="button" class="btn btn-lg bg-gradient-danger mx-auto my-3" @click="deleteBookingRequest()" :disabled="bookingLoading">Annulla prenotazione</button>
                  <div v-if="bookingLoading"><i class="material-icons text-warning rotating">autorenew</i></div>
                  <div v-if="rentingInfoStatic.status==1 || rentingInfoStatic.status==3" >
                     <div class="booking-div" v-for="rent in getSlotBooking()" :key="rent.IdPrenotazione">
                        <h6 class="color-trivellato m-0 p-0">Dati Prenotazione n. <b>{{ rent.IdPrenotazione }}</b></h6>
                        <p class="small m-0">
                           Consegna: <b>{{ FormatDateTime(rent.DataInizioPrenotazione) }}</b>
                        </p>
                        <p class="small m-0">
                           Restituzione: <b>{{ FormatDateTime(rent.DataFinePrenotazione) }}</b>
                        </p>
                        <p class="small m-0">
                           Cliente: <b>{{ rent.Cliente }}</b>
                        </p>
                     </div>
                     <div v-if="getSlotBooking().length==0" class="booking-div">
                        <h6 class="color-trivellato p-0 m-0">Dati Prenotazione</h6>
                        <p class="fst-italic">Le date della prenotazione corrispondono esattamente a questo slot</p>
                     </div>
                  </div>
                  <div v-if="isOldDate() && (rentingInfoStatic.status==0 || rentingInfoStatic.status==2)" class="fst-italic">
                     Non è possibile prenotare questo slot perché antecedente alla data di oggi
                  </div>
               </div>
            </div>
            <div class="modal-footer">
               <button type="button" class="btn bg-gradient-dark" @click="closeModal()">Chiudi</button>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import fetchFromAPI from "../../script/api.js"
import helpers from '../../script/helpers.js'
import _ from 'lodash'

export default {
   data() {
      return {
         filters: {},
         list: {},
         currentVehicle: {},
         isInLoading: false,
         isLoadingReport: false,
         isModalOpen: false,
         invalidFilterDates: false,
         reportVisible: false,
         showErrorMsg: false,
         mouseX: null,
         mouseY: null,
         dateHover: false,
         locationHover: false,
         rentingInfo: null,
         rentingInfoStatic: null,
         locationInfo: null,
         searchDateFrom: null,
         searchDateTo: null,
         link: null,
         endPoint: process.env.VUE_APP_END_POINT,
         slots: [],
         bookingLoading: false,
         noInfinityBooking: false,
         userPhone: ""
      };
   },
   mounted() {
      this.$nextTick(() => {
         helpers.setMaterialInput();
      });
      document.addEventListener("mousemove", (event) => {
         this.mouseX = event.clientX;
         this.mouseY = event.clientY;
      });
   },
   watch: {
      filters: {
         handler(newFilter) {
            if (newFilter.DateFrom > newFilter.DateTo) {
               this.invalidFilterDates = true;
               return;
            }
            this.invalidFilterDates = false;
         },
         deep: true
      },
   },
   methods: {
      LoadData() {
         if (this.isInLoading || this.invalidFilterDates) {
            return;
         }
         this.reportVisible = false
         this.list = {};
         helpers.resetFilter('DemoManagmentist')
         this.isInLoading = true;
         var payload = _.cloneDeep(this.filters);
         payload.LineaVeicoloCortesia = (this.filters.LineaVeicoloCortesia || {}).Codice || null;
         payload.CodiceUbicazione = (this.filters.CodiceUbicazione || {}).Codice || null;
         fetchFromAPI("VehicleBooking/LoadList", payload, (res) => {
            this.list = res;
            if(this.list.length>0) this.slots = res[0].VehicleRentingSlots;
            this.isInLoading = false;
            this.searchDateFrom = this.filters.DateFrom;
            this.searchDateTo = this.filters.DateTo;
         }, (err) => {
            console.error("Something went wrong while fetching results. Error: " + err)
         });
      },
      FormatDate(dateString) {
         const date = new Date(dateString);
         let dateFormatted = new Intl.DateTimeFormat("default", { dateStyle: "long" }).format(date);
         if (dateFormatted != "1 gennaio 1") {
            return dateFormatted;
         }
         return "";
      },
      FormatDateTime(dateString) {
         const date = new Date(dateString);
         var dateFormatted = new Intl.DateTimeFormat("default", { dateStyle: "long", weekDay: 'long', timeStyle: "short"}).format(date);
         if (dateFormatted != "1 gennaio 1") {
            return dateFormatted;
         }
         return "";
      },
      getFilterDaysDifference() {
         return (new Date(this.searchDateTo) - new Date(this.searchDateFrom)) / (1000 * 60 * 60 * 24);
      },
      showRentingInfo(dateObj) {
         this.rentingInfo = dateObj;
         this.dateHover = true;
      },
      showBookingModal(vehicle) {
         this.userPhone = '';
         if(this.rentingInfo.status != -1) this.rentingInfoStatic = this.rentingInfo;
         this.currentVehicle = vehicle;
         document.getElementsByTagName('body')[0].classList.add('overflow-hidden');
      },
      closeModal() {
         if (this.rentingInfoStatic != null) {
            this.rentingInfoStatic = null;
            document.getElementsByTagName('body')[0].classList.remove('overflow-hidden');
         }
      },
      calcPopoverTransform(entity) {
         if (entity == "availability" && !this.dateHover) {
            return 'translateX(0px) translateY(0px)';
         }
         if (entity == "location" && !this.locationHover) {
            return 'translateX(0px) translateY(0px)';
         }

         var transform = '';
         var p = document.getElementById(entity + '-popover');

         // check horizontal position
         var ww = window.innerWidth;
         var pw = parseInt(p.style.width.replace('px', ''));
         if (ww <= pw + this.mouseX + 32) {
            transform += 'translateX(calc(-100% - 26px)) ';
         }

         // check vertical position
         var wh = window.innerHeight;
         var ph = parseInt(p.style.maxHeight.replace('px', ''));
         if (wh <= ph + this.mouseY + 18) {
            transform += 'translateY(calc(-100% - 26px)) ';
         }

         return transform;
      },
      set3MonthPreview() {
         this.filters.DateFrom = new Date().toISOString().slice(0, 10);
         this.filters.DateTo = new Date();
         this.filters.DateTo = new Date(this.filters.DateTo.setMonth(this.filters.DateTo.getMonth() + 3)).toISOString().slice(0, 10);
      },
      showLocationInfo(vehicle) {
         //this.locationInfo = this.filters.Ubicazioni.find((x) => x.Codice == vehicle.Ubicazione).Descrizione;
         this.locationInfo = vehicle.Ubicazione;
         this.locationHover = true;
      },
      ExportExcel() {
         this.reportVisible = true
         this.isLoadingReport = true;
         this.list.forEach(element => {
            element.Disponibile = element.VehicleRentingDatesModels.find((el) => el.rentId != -1) == null ? "Si" : "No";
            element.DescrizioneUbicazione = this.filters.Ubicazioni.find((el) => el.Codice == element.Ubicazione).Descrizione || element.Ubicazione;
         });
         fetchFromAPI("VehicleBooking/ExcelExport", { filters: this.filters, vehiclelist: this.list }, (res) => {
            this.link = res
            this.isLoadingReport = false;
         });
      },
      getSlotBooking(){
         var books = [];
         var bookings = this.currentVehicle.VehicleRentingDatesModels;
         var slot = this.rentingInfoStatic;
         for(var i=0; i<bookings.length; i++){
            if((slot.slotStart<=bookings[i].DataFinePrenotazione && slot.slotEnd>=bookings[i].DataInizioPrenotazione) ||
               (slot.slotStart<=bookings[i].DataFinePrenotazione && slot.slotStart>=bookings[i].DataInizioPrenotazione) ||
               (slot.slotEnd<=bookings[i].DataFinePrenotazione && slot.slotEnd>=bookings[i].DataInizioPrenotazione))
               {
                  books.push(bookings[i]);
               }
         }
         return books;
      },
      sendBookingRequest(){
         this.bookingLoading = true;
         fetchFromAPI("VehicleBooking/SfBookingRequest", { Token: helpers.token(), Slot: this.rentingInfoStatic, Id: this.currentVehicle.IdOfficina, Tel: this.userPhone }, () => {
            this.bookingLoading = false;
            this.$toast.success("Richiesta prenotazione inviata");
            this.closeModal()
            this.LoadData();
         });
      },
      deleteBookingRequest(){
         this.bookingLoading = true;
         fetchFromAPI("VehicleBooking/SfBookingDelete", { Token: helpers.token(), Slot: this.rentingInfoStatic, Id: this.currentVehicle.IdOfficina }, () => {
            this.bookingLoading = false;
            this.$toast.success("Richiesta prenotazione annullata");
            this.closeModal()
            this.LoadData();
         });
      },
      isOldDate(){
         var oggi = new Date();
         var anno = oggi.getFullYear();
         var mese = String(oggi.getMonth() + 1).padStart(2, '0'); 
         var giorno = String(oggi.getDate()).padStart(2, '0');
         var dataOggi = `${anno}-${mese}-${giorno}`;

         var today = new Date(dataOggi);
         var slotDate = new Date(this.rentingInfoStatic.slotStart);

         if(slotDate<today) return true;
         else return false;
      },
      getVehicleColor(color){
         switch(color) {
            case 'A':
               return '#1E90FF';
            case 'B':
               return '#FFFFFF';
            case 'BL_':
            case 'N':
               return '#000000';
            case 'BL_opaco':
               return '#262626';
            case 'BB':
               return '#2442b0';
            case 'CG':
               return '#004d00';
            case 'KW':
               return '#F0F0F0';
            case 'R':
               return '#b13336';
            case 'VM':
               return '#878752';
            case 'BL':
               return '#002db3';
            case 'GR':
               return '#8c8c8c';
            default:
               return '#FFFFFF';
         }
      }
   },
   created() {
      fetchFromAPI("VehicleBooking/BindFiltersForHub", { Token: helpers.token() }, (res) => {
         res.DateFrom = new Date(res.DateFrom).toISOString().slice(0, 10)
         res.DateTo = new Date(res.DateTo).toISOString().slice(0, 10)
         this.filters = res;
         this.filters.LineaVeicoloCortesia = null;
         this.filters.LineeVeicoli = [
            {
               Codice: '02',
               Descrizione: "Prodotto DEMO"
            },
            {
               Codice: '03',
               Descrizione: "Prodotto DIPENDENTI"
            },
            {
               Codice: '04',
               Descrizione: "Prodotto NOLEGGIO"
            }
         ];
         this.filters.CodiceUbicazione = null;
         helpers.setObject('VehicleBookingFilter', this.filters);
         this.LoadData();
      });
   }
}
</script>

<style scoped>
.modal {
   display: block;
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.7);
   justify-content: center;
   align-items: center;
   z-index: 1000;
}

.modal-content {
   background-color: #fff;
   padding: 20px;
   border-radius: 5px;
   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
   position: relative;
   width: 80%;
   margin: auto;
   top: 20%;
}

.close {
   position: absolute;
   top: 10px;
   right: 10px;
   font-size: 20px;
   cursor: pointer;
}

.table-responsive {
   overflow-x: hidden !important;
}

.timeline.renting-timeline::before {
   border: none;
}

.timeline.renting-timeline .timeline-block:before {
   content: '';
   position: absolute;
   top: 0;
   left: 1rem;
   height: 100%;
}

.timeline.renting-timeline .timeline-block.start-reservation:before {
   border-right: 2px dotted rgba(255, 0, 0, 0.7);
}

.timeline.renting-timeline .timeline-block.end-reservation:before {
   /* border-right: 2px solid #e5e5e5; */
   border-right: 2px solid rgba(0, 0, 255, 0.5);
}

.timeline.renting-timeline .timeline-block.last-node:before {
   /* border-right: 2px solid #e5e5e5; */
   border-right: none;
}

.timeline-step.bg-gradient-dark {
   background-image: linear-gradient(195deg, #42424a 0%, #191919 100%);
}

.timeline.renting-timeline .timeline-block h6 {
   border-bottom: dotted 2px lightgray;
}

.timeline.renting-timeline .timeline-step {
   padding-top: 8px;
}

.popover-info {
   position: fixed;
   background-color: #fff;
   padding: 5px 10px;
}

.availability-cell {
   padding: 8px 0;
}

.availability-cell+.availability-cell:not(.continues-prev) {
   padding-left: 8px;
}

.booking-div+.booking-div {
   margin-top: 1em;
   padding-top: 1em;
   border-top: dashed 1px lightgray;
}

.center-placeholder {
  text-align: center;
}

.center-placeholder::placeholder {
  text-align: center;
}

.circle {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 8px;
  border: solid 1px #00000057;
}

</style>