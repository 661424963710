<template>
  <link rel="stylesheet" href="https://unpkg.com/vue-select@latest/dist/vue-select.css">
  <nav class="navbar navbar-transparent navbar-expand-lg top-0 z-index-3 shadow-none position-absolute my-3 py-2 start-0 end-0 mx-4">
      <div class="container-fluid ps-2 pe-0">
          <a class="navbar-brand align-items-center font-weight-bolder ms-lg-0 me-2 text-dark" href="/">
              <i class="fa fa-atom opacity-9 text-dark me-1" aria-hidden="true"></i>
              Trivellato Hub 
          </a>
      </div>
  </nav>
  <div class="container-fluid all-small no-scroll-content pt-6 px-5">
      <h4>Criptatore Password</h4>
      <div class="row no-scroll-content pt-4">
          <div class="col-sm-5">
              <div class="row no-scroll-content">
                  <div class="card mh-100">
                      <div class="card-body overflow-auto">
                          <div class="row justify-content-between align-items-center mb-2">
                              <div class="col-sm-6"></div>
                              <div class="col-sm-6">
                                  <button class="btn bg-gradient-info text-white px-3 py-2 m-0 float-sm-end" @click="createAuth()">
                                      <span class="material-icons material-symbols-outlined">add</span>
                                      Aggiungi account
                                  </button>
                              </div>
                          </div>
                          <table class="table mh-100 scrollbar-thin table-hover">
                              <thead>
                                  <tr>
                                      <th class="text-uppercase text-secondary font-weight-bolder px-1">Account</th>
                                      <th class="text-uppercase text-secondary font-weight-bolder px-1"></th>
                                  </tr>
                              </thead>
                              <tbody v-if="list">
                                  <tr v-for="item in list" :key="item.Id">
                                      <td class="px-1">{{ item.Username }}</td>
                                      <td><button class="btn bg-gradient-danger mx-1 py-1" @click="deleteAuth(item)">Elimina</button></td>
                                  </tr>
                              </tbody>
                              <tbody v-else>
                                  <tr>
                                      <td colspan="3" class="text-center">
                                          <i class="spinner-border text-dark align-middle" role="status">
                                              <span class="visually-hidden">Loading...</span>
                                          </i>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-sm-7 no-scroll-content px-4">
              <div class="card mh-100" v-if="isAdding">
                  <div class="card-body overflow-auto">
                      <h3 class="mb-4">Aggiungi account da salvare</h3>
                      <div class="form-group d-flex flex-column align-items-start">
                          <div class="input-group input-group-static my-2 trivellato">
                              <label class="text-uppercase text-secondary font-weight-bolder">Username</label>
                              <input type="text" class="form-control" aria-label="" v-model="selectedItem.Username"/>
                          </div>
                          <div class="input-group input-group-static my-2 trivellato">
                              <label class="text-uppercase text-secondary font-weight-bolder">Password da criptare</label>
                              <input type="text" class="form-control" aria-label="" v-model="selectedItem.EncryptedPassword"/>
                          </div>
                      </div>
                      <div class="buttons-container mt-6">
                          <button class="btn bg-gradient-dark mx-1 float-end" @click="saveAuth()">Salva</button>
                          <button class="btn bg-gradient-light mx-1 float-end" @click="refreshItem()">Annulla</button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import fetchFromAPI from "../../script/api.js"
import helpers from '../../script/helpers.js'

export default {
  data() {
      return {
          list: [],
          selectedItem: {},
          isAdding: false
      }
  },

  methods: {
      LoadData() {
          fetchFromAPI("PasswordEncryptor/LoadList", { Token: helpers.token() }, (res) => {
              this.list = res;
          });
      },
      createAuth() {
          var n = {
              Id: 0,
              Username: "",
              EncryptedPassword: ""
          };
          this.selectedItem = n;
          this.isAdding = true;
      },
      saveAuth() {
        this.selectedItem.Token = helpers.token();
        fetchFromAPI("PasswordEncryptor/SaveAuth", this.selectedItem, () => {
            this.LoadData();
            this.isAdding = false;
            this.$toast.success("Password aggiunta");
        });
      },
      deleteAuth(item) {
          this.$swal.fire({
              title: 'Eliminare password criptata?',
              text: 'Potrebbero sorgere problemi se questa password criptata è utilizzata da qualche parte, procedere?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Si'
          }).then((result) => {
              if (result.isConfirmed) {
                  fetchFromAPI("PasswordEncryptor/DeleteAuth", { Id:item.Id, Token:helpers.token() }, () => {
                      this.selectedItem = null;
                      this.LoadData();
                      this.$toast.success("Password eliminata");
                  });
                  }
          })
      },
      refreshItem(){
          this.LoadData();
          this.isAdding = false;
      }
  },
  created() {
      this.LoadData();
  }
}
</script>

<style scoped>
#app {
  max-height: 100vh;
}

.container-fluid {
  max-height: calc(100vh - 100px);
}

::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}</style>