<template>
    <link rel="stylesheet" href="https://unpkg.com/vue-select@latest/dist/vue-select.css">
    <div class="container-fluid all-small no-scroll-content">
        <div class="row no-scroll-content">
            <div class="col-sm-4 no-scroll-content">
                <div class="card mh-100">
                    <div class="card-body overflow-auto">
                        <div class="row justify-content-between align-items-center mb-2">
                            <div class="col-sm-6">
                                <button class="btn bg-gradient-dark text-white px-3 py-2 m-0" @click="createLicense"
                                    :disabled="!entities">
                                    <span class="material-icons material-symbols-outlined">
                                        add
                                    </span>
                                    Nuova targa
                                </button>
                            </div>
                            <div class="col-sm-6">
                                <div class="input-group input-group-outline trivellato" ref="license-search">
                                    <label class="form-label">Cerca una targa</label>
                                    <input type="text" class="form-control" aria-label="" :disabled="!entities"
                                        v-model="searched" />
                                </div>
                            </div>
                        </div>
                        <table class="table mh-100 scrollbar-thin table-hover">
                            <thead>
                                <tr>
                                    <th>Targa</th>
                                    <th>Descrizione</th>
                                    <th>Azienda</th>
                                </tr>
                            </thead>
                            <tbody v-if="entities">
                                <tr v-for="entity in filteredAndSorted" :key="entity.Id" class="cursor-pointer hover-bg-trivellato"
                                    @click="selectEntity(entity)">
                                    <td>{{ entity.Targa }}</td>
                                    <td>{{ entity.Descrizione }}</td>
                                    <td>{{ entity.CompanyName || "Nessuna azienda" }}</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="3" class="text-center">
                                        <i class="spinner-border text-dark align-middle" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-sm-8 no-scroll-content">
                <div class="card mh-100" v-if="selectedEntity">
                    <div class="card-body overflow-auto">
                        <h3 class="mb-4">Gestisci targa speciale</h3>
                        <div class="form-group d-flex flex-column align-items-start">
                            <div class="input-group input-group-dynamic my-2 trivellato"
                                :class="{ 'is-filled': selectedEntity.Targa != null && selectedEntity.Targa != '' }">
                                <label class="form-label">Targa</label>
                                <input type="text" class="form-control" aria-label="" v-model="selectedEntity.Targa"
                                    :disabled="selectedEntity.Id != -1" />
                            </div>
                            <div class="input-group input-group-dynamic">
                                <v-select class="form-control" :options="companies" label="Nome" value="Id"
                                    v-model="this.selectedEntity.Company" placeholder="Nessuna azienda">
                                    <template #selected-option>
                                        {{ this.selectedEntity.Company.Nome }}
                                    </template>
                                </v-select>
                            </div>
                            <div class="input-group input-group-outline my-2 trivellato"
                                :class="{ 'is-filled': selectedEntity.Descrizione != null && selectedEntity.Descrizione != '' }">
                                <label class="form-label">Descrizione Targa</label>
                                <textarea name="message" class="form-control" id="message" placeholder="" rows="4"
                                    v-model="selectedEntity.Descrizione"></textarea>
                            </div>
                            <div class="form-check form-switch my-2 d-flex align-items-center trivellato">
                                <input class="form-check-input trivellato" type="checkbox"
                                    v-model="selectedEntity.Visibile">
                                <label class="form-check-label d-block ms-3 mb-0" for="flexSwitchCheckDefault">
                                    Abilita visualizzazione dispositivo
                                </label>
                            </div>
                        </div>
                        <div class="buttons-container mt-6">
                            <button class="btn bg-gradient-danger mx-1" @click="del()">Elimina</button>
                            <button class="btn bg-gradient-dark mx-1 float-end" @click="save()">Salva</button>
                            <button class="btn bg-gradient-light mx-1 float-end" @click="refreshItem()">Annulla</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import fetchFromAPI from "../../script/api.js"
import helpers from '../../script/helpers.js'

export default {
    data() {
        return {
            filters: null,
            entities: null,
            selectedEntity: null,
            searched: '',
            companies: [
                {
                    Id: 1,
                    Nome: "Trivellato Spa"
                },
                {
                    Id: 6,
                    Nome: "Padova Star Srl"
                },
            ]
        }
    },
    computed: {
        filteredAndSorted() {
            // function to compare names
            function compare(a, b) {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            }

            return this.entities.filter(entity => {
                return entity.Targa.toLowerCase().includes(this.searched.toLowerCase()) || entity.Descrizione.toLowerCase().includes(this.searched.toLowerCase()) || entity.CompanyName.toLowerCase().includes(this.searched.toLowerCase());
            }).sort(compare)
        }
    },

    methods: {
        LoadData() {
            fetchFromAPI("SpecialLicensePlates/LoadList", this.filters, (res) => {
                this.entities = res;
            }, (err) => {
                console.error("Something went wrong fetching data. Error: " + err);
            });
        },
        createLicense() {
            var x = {
                Id: -1,
                Targa: null,
                Descrizione: null,
                Visibile: false,
                Token: helpers.token()
            };
            this.selectedEntity = x;
        },
        selectEntity(entity) {
            entity.Company = this.companies.find((el) => el.Id == entity.CompanyId);
            this.selectedEntity = entity;
        },
        save() {
            var payload = this.selectedEntity;
            fetchFromAPI("SpecialLicensePlates/SaveEntity", payload, (res) => {
                this.selectedEntity.Id = res.Id;
                this.LoadData();
                this.$toast.success('Targa salvata');
            }, (err) => {
                console.error("Something went wrong saving data. Error: " + err);
                this.$toast.error('Qualcosa è andato storto, ricaricare la pagina e tentare di nuovo<br/>Se il problema persiste contattare un responsabile');
            });
        },
        del() {
            fetchFromAPI("SpecialLicensePlates/DeleteEntity", this.selectedEntity, (/*res*/) => {
                this.selectedEntity = null;
                this.LoadData();
                this.$toast.success('Targa eliminata');
            }, (err) => {
                console.error("Something went wrong deleting data. Error: " + err);
                this.$toast.error('Qualcosa è andato storto, ricaricare la pagina e tentare di nuovo<br/>Se il problema persiste contattare un responsabile');
            });
        },
        refreshItem() {
            if (this.selectedEntity.Id == -1) {
                this.selectedEntity.Targa = null;
                this.selectedEntity.Descrizione = null;
                this.selectedEntity.Visibile = false;
                this.$toast.info('Dati originali ricaricati');
                return;
            }

            fetchFromAPI("SpecialLicensePlates/GetSingleEntity", this.selectedEntity, (res) => {
                this.selectedEntity.Descrizione = res.Descrizione;
                this.selectedEntity.Visibile = res.Visibile;
                this.selectedEntity.Company = this.companies.find((el) => el.Id == res.CompanyId);
                this.$toast.info('Dati originali ricaricati');
            }, (err) => {
                console.error("Something went wrong refreshing the selected element. Error: " + err);
                this.$toast.error('Qualcosa è andato storto, ricaricare la pagina e tentare di nuovo<br/>Se il problema persiste contattare un responsabile');
            })
        }
    },
    created() {
        fetchFromAPI("SpecialLicensePlates/BindFiltersForHub", { Token: helpers.token() }, (res) => {
            res.DateFrom = new Date(res.DateFrom).toISOString().slice(0, 10)
            res.DateTo = new Date(res.DateTo).toISOString().slice(0, 10)
            this.filters = res;
            this.filters.CodiceUbicazione = null;
            this.LoadData();
        });
    },
    mounted() {
        this.$nextTick(() => {
            helpers.setMaterialInput();
        });
    }
}
</script>

<style scoped>
#app {
    max-height: 100vh;
}

.container-fluid {
    max-height: calc(100vh - 100px);
}

::-webkit-scrollbar {
    width: 6px;
    border-radius: 10px;
}</style>