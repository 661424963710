<template>
    <div class="container-fluid">
      <div class="row justify-content-left">
        <div class="col-12 text-center">
          <div class="multisteps-form mt-5 mb-3">
            <!--progress bar-->
            <div class="row">
              <div class="col-12 col-lg-8">
                <div class="card">
                  <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                    <div class="bg-gradient-dark shadow-dark border-radius-lg pt-4 pb-1">
                      <div class="multisteps-form__progress">
                        <button class="multisteps-form__progress-btn js-active" type="button" @click="GoTo(1,item.ContractBaseData.ContractId+ '_' + item.FEADataModel.FEAType, item)" :id="'step_1_'+item.ContractBaseData.ContractId+ '_' + item.FEADataModel.FEAType" title="Dati Cliente">
                          <span>Dati Cliente</span>
                        </button>
                        <button class="multisteps-form__progress-btn" type="button" @click="GoTo(2,item.ContractBaseData.ContractId+ '_' + item.FEADataModel.FEAType, item)" :id="'step_2_'+item.ContractBaseData.ContractId+ '_' + item.FEADataModel.FEAType" title="Tipo Firma">
                          <span>Tipo Firma</span>
                        </button>
                        <button class="multisteps-form__progress-btn" type="button" @click="GoTo(3,item.ContractBaseData.ContractId+ '_' + item.FEADataModel.FEAType, item)" :id="'step_3_'+item.ContractBaseData.ContractId+ '_' + item.FEADataModel.FEAType" title="Riepilogo">
                          <span>Riepilogo</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <form class="multisteps-form__form" style="height: 430px;">
                      <!--single form panel-->
                      <div class="multisteps-form__panel js-active" data-animation="FadeIn" :id="'step_panel_1_'+item.ContractBaseData.ContractId+ '_' + item.FEADataModel.FEAType">
                        <div class="row text-center mt-2">
                          <div class="col-10 mx-auto">
                            <h5 class="font-weight-normal">Controlla i dati del cliente e del firmatario</h5>
                            <p>Nome e cognome del firmatario ed il suo codice fiscale sono campi obbligatori.</p>
                          </div>
                        </div>
                        <div class="multisteps-form__content">
                          <div class="row mt-3">
                            <div class="col-12 col-sm-4">
                              <div class="avatar avatar-xxl position-relative">
                                <img src="../assets/img/fea.jpg" class="border-radius-md" alt="team-2">
                                <a href="javascript:;" class="btn btn-sm btn-icon-only bg-gradient-primary position-absolute bottom-0 end-0 mb-n2 me-n2">
                                  <span class="material-icons text-xs top-0" data-bs-toggle="tooltip" data-bs-placement="top" title="" aria-hidden="true" data-bs-original-title="Edit Image" aria-label="Edit Image">
                                    edit
                                  </span>
                                </a>
                              </div>
                            </div>
                            <div class="col-12 col-sm-8 mt-4 mt-sm-0 text-start">
                              <div class="input-group input-group-static mb-4">
                                  <label>Azienda</label>
                                  <input type="text" class="form-control" v-model="item.ContractBaseData.AccountDescription" disabled>
                              </div>
                              <div class="input-group input-group-static mb-4">
                                  <label>Nome e Cognome firmatario</label>
                                  <input type="text" class="form-control" v-model="item.ContractBaseData.SignNameAndSurname">
                              </div>
                              <div class="input-group input-group-static mb-4">
                                  <label>Codice Fiscale Firmatario</label>
                                  <input type="text" class="form-control" v-model="item.ContractBaseData.FiscalCode">
                              </div>
                            </div>
                          </div>
                          <div class="button-row align-bottom d-flex mt-4">
                            <button class="btn btn-outline-dark mb-0 js-btn-prev" type="button" @click="DeleteItem(item)" title="Prev">Cancella File</button>
                            <button class="btn bg-gradient-dark ms-auto mb-0 js-btn-next" type="button" @click="GoTo(2,item.ContractBaseData.ContractId + '_' + item.FEADataModel.FEAType, item)" title="Next">Avanti</button>
                          </div>
                        </div>
                      </div>
                      <!--single form panel-->
                      <div class="multisteps-form__panel" data-animation="FadeIn" :id="'step_panel_2_'+item.ContractBaseData.ContractId + '_' + item.FEADataModel.FEAType">
                        <div class="row text-center mt-2">
                          <div class="col-10 mx-auto">
                            <h5 class="font-weight-normal">Seleziona il Tipo di Firma</h5>
                            <p>Seleziona il tipo di firma e completa i dati richiesti</p>
                          </div>
                        </div>
                        <div class="multisteps-form__content">
                          <div class="row mt-3">
                            <div class="col-12 col-sm-4">
                              <div class="avatar avatar-xxl position-relative">
                                <img src="../assets/img/fea.jpg" class="border-radius-md" alt="team-2">
                                <a href="javascript:;" class="btn btn-sm btn-icon-only bg-gradient-primary position-absolute bottom-0 end-0 mb-n2 me-n2">
                                  <span class="material-icons text-xs top-0" data-bs-toggle="tooltip" data-bs-placement="top" title="" aria-hidden="true" data-bs-original-title="Edit Image" aria-label="Edit Image">
                                    edit
                                  </span>
                                </a>
                              </div>
                            </div>
                            <div class="col-12 col-sm-8 mt-4 mt-sm-0 text-start">
                                <div class="form-check form-switch mb-5">
                                  <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault"  v-model="item.ContractBaseData.IsOTP">
                                  <label class="form-check-label" for="flexSwitchCheckDefault">FEA Remota {{(item.ContractBaseData.IsOTP ? "[Attiva]" : "")}}</label>
                                </div>
                              <div class="input-group input-group-static mb-4">
                                  <label>Cellulare Firmatario</label>
                                  <input type="text" class="form-control" v-model="item.ContractBaseData.PhoneNumber" >
                              </div>
                              <div class="input-group input-group-static mb-4">
                                  <label>Email Firmatario</label>
                                  <input type="text" class="form-control" v-model="item.ContractBaseData.SignEmail">
                              </div>
                            </div>
                          </div>
                          <div class="button-row align-bottom d-flex mt-4">
                              <button class="btn btn-outline-dark mb-0 js-btn-prev" type="button" @click="GoTo(1,item.ContractBaseData.ContractId + '_' + item.FEADataModel.FEAType, item)" title="Prev">In Dietro</button>
                              <button class="btn bg-gradient-dark ms-auto mb-0" type="button" @click="GoTo(3,item.ContractBaseData.ContractId + '_' + item.FEADataModel.FEAType, item)"  title="Send">Avanti</button>
                          </div>
                        </div>
                      </div>
                      <!--single form panel-->
                      <div class="multisteps-form__panel bg-light" data-animation="FadeIn" :id="'step_panel_3_'+item.ContractBaseData.ContractId + '_' + item.FEADataModel.FEAType">
                        <div class="row text-center mt-4">
                          <div class="col-10 mx-auto">
                            <h5 class="font-weight-normal">Ricontrolliamo i dati</h5>
                            <p>Controlla tutti i dati inseriti e invia la procedura.</p>
                          </div>
                        </div>
                        <div class="multisteps-form__content">
                          <div class="row text-start px-2">
                            <div class="col-12 col-md-6">
                              <div class="input-group input-group-static mb-3">
                                  <label class="">Azienda</label>
                                  <input type="text" class="form-control" v-model="item.ContractBaseData.AccountDescription" disabled>
                              </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-check form-switch mb-3 mt-3">
                                  <input class="form-check-input" type="checkbox" id="optcheck"  v-model="item.ContractBaseData.IsOTP" disabled>
                                  <label class="form-check-label" for="optcheck">FEA {{(item.ContractBaseData.IsOTP ? "Remota" : "Locale")}}</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                               <div class="input-group input-group-static mb-4">
                                  <label :class="{'text-danger': !isNameAndSurnameValidated}">Nome e Cognome firmatario</label>
                                  <input type="text" class="form-control" v-model="item.ContractBaseData.SignNameAndSurname" disabled>
                              </div>
                            </div>
                            <div class="col-12 col-md-6">
                              <div class="input-group input-group-static mb-4">
                                  <label :class="{'text-danger': !isPhoneValidated}">Cellulare Firmatario</label>
                                  <input type="text" class="form-control" v-model="item.ContractBaseData.PhoneNumber" disabled> 
                              </div>
                            </div>
                            <div class="col-12 col-md-6">
                              <div class="input-group input-group-static mb-4">
                                  <label :class="{'text-danger': !isFiscalCodeValidated}">Codice Fiscale Firmatario</label>
                                  <input type="text" class="form-control" v-model="item.ContractBaseData.FiscalCode" disabled> 
                              </div>
                            </div>
                            <div class="col-12 col-md-6">
                              <div class="input-group input-group-static mb-4">
                                  <label :class="{'text-danger': !isMailValidated}">Email Firmatario</label>
                                  <input type="text" class="form-control" v-model="item.ContractBaseData.SignEmail" disabled> 
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="button-row align-bottom d-flex mt-4  col-12">
                              <button class="btn btn-outline-dark mb-0 js-btn-prev" type="button" @click="GoTo(2,item.ContractBaseData.ContractId + '_' + item.FEADataModel.FEAType, item)" title="Prev">In Dietro</button>
                              <button class="btn bg-gradient-dark ms-auto mb-0" type="button" title="Send" @click="GoToSign(item)" :disabled="(!enabled || !isNameAndSurnameValidated || !isFiscalCodeValidated || !isPhoneValidated || !isMailValidated)">Invia</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import helpers from '../script/helpers';
import fetchFromAPI from "../script/api.js"
export default {
      props: ['model'],
      data() {
      return{
          item : this.model,
          enabled: true,
          isNameAndSurnameValidated : false,
          isFiscalCodeValidated : false,
          isPhoneValidated : false,
          isMailValidated : false
      }
   },
   
   methods:{
      DeleteItem(item){
        this.$swal.fire({
          title: 'Sicuro?',
          text: "Vuoi eliminare la stampa OTP di questo contratto?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, vai!'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$swal.fire(
              'Ok fatto!','',
              'success'
            )
            this.Delete(item)
          }
        })
      },
      Delete(item){
        fetchFromAPI("FEAWebHook/Delete", item, (res) => {
              console.log(res);
          });
      },
       stepNext(stepIndex){
        return stepIndex;
       },
       GoToSign(item){
        this.$swal.fire({
          title: 'Sicuro?',
          text: "Vuoi avviare il processo di firma?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, vai!'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$swal.fire(
              'Ok fatto!',
              item.ContractBaseData.IsOTP ? 'A breve arriveranno le istruzioni al numero indicato.' : 'Attendi, a breve verrai redirezionato...',
              'success'
            )
            this.Sign(item)
          }
        })
       },
       Sign(item){
          this.enabled = false;
          fetchFromAPI("FEAWebHook/GoToSign", item, (res) => {
               if(!item.ContractBaseData.IsOTP){
                  window.open(res, '_blank');
               }
          });
       },
       GoTo(step, contract_id, item){
         document.getElementById('step_'+step+'_'+contract_id).classList.add("js-active")
         document.getElementById('step_panel_'+step+'_'+contract_id).classList.add("js-active")
         switch(step){
           case 1:
             document.getElementById('step_2'+'_'+contract_id).classList.remove("js-active")
             document.getElementById('step_3'+'_'+contract_id).classList.remove("js-active")

             document.getElementById('step_panel_2'+'_'+contract_id).classList.remove("js-active")
             document.getElementById('step_panel_3'+'_'+contract_id).classList.remove("js-active")
             break;
            case 2:
             document.getElementById('step_1'+'_'+contract_id).classList.remove("js-active")
             document.getElementById('step_3'+'_'+contract_id).classList.remove("js-active")

             document.getElementById('step_panel_1'+'_'+contract_id).classList.remove("js-active")
             document.getElementById('step_panel_3'+'_'+contract_id).classList.remove("js-active")
             break;
            case 3:
             document.getElementById('step_1'+'_'+contract_id).classList.remove("js-active")
             document.getElementById('step_2'+'_'+contract_id).classList.remove("js-active")
             document.getElementById('step_panel_1'+'_'+contract_id).classList.remove("js-active")
             document.getElementById('step_panel_2'+'_'+contract_id).classList.remove("js-active")
             this.isNameAndSurnameValidated = helpers.validateName(item.ContractBaseData.SignNameAndSurname).valid
             this.isFiscalCodeValidated = helpers.validateName(item.ContractBaseData.FiscalCode).valid
             this.isPhoneValidated = helpers.validatePhone(item.ContractBaseData.PhoneNumber).valid
             this.isMailValidated = helpers.validateEmail(item.ContractBaseData.SignEmail).valid
             break;
         }
       }
   }
}
</script>
