<template>
    <div v-if="MonthstatusData" class="container-fluid my-3 py-3">
      <div class="card-body p-2">
      <div class="row">
      <div class="col-md-3 mb-md-0 mb-4">
      <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
         <div class="input-group input-group-dynamic">
            <label class="form-label"></label>
            <input class="form-control" aria-label="" placeholder="Mario Rossi"  type="text" v-model="search">
         </div>
      </div>
      </div>
      <div class="col-md-3">
      </div>
      <div class="col-md-3">
      </div>
      <div class="col-md-3">
      </div>
   </div>
</div>
   <div class="row mt-4">
      <div class="col-12">
         <div class="card">
            <div class="card-header pb-0 px-3">
               <h6 class="mb-0">Riepilogo straordinari {{ MonthstatusData.StampsPeriod }}</h6>
            </div>
            <div class="card-body pt-1 p-3">
               <ul class="list-group">
                  <li v-for="(item, index) in filteredItems" :key="index" class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-200 border-radius-lg">
                     <div class="d-flex flex-column">
                        <h6 class="mb-1 text-sm">{{item.NameAndSurname}}</h6>
                        <!--<span class="mb-2 text-xs">Azienda: <span class="text-dark font-weight-bold ms-sm-2">{{ item.Company }}</span></span>-->
                        <!--<span class="mb-2 text-xs">Identificativo <span class="text-dark ms-sm-2 font-weight-bold">{{item.UserKey}}</span></span>-->
                        <span class="text-xs">Straordinari: <span class="text-dark ms-sm-2 font-weight-bold">{{item.OriginalExtraHours}}</span></span>
                     </div>
                     <div class="ms-auto text-end">
                        <a @click="GoToDatails(item.NameAndSurname, item.Company, MonthstatusData.StampsPeriod)" class="btn btn-link text-dark px-3 mb-0" ><i class="material-icons text-sm me-2">edit</i>Dettagli</a>
                     </div>
                  </li>
               </ul>
            </div>
         </div>
      </div>
   </div>
</div>
</template>
<script>
import router from '../../router';
import helpers from '../../script/helpers.js'
   export default {
      data() {
         return {
         MonthstatusData:{},
         isInLoading3: false,
         search:""
      };
   },
   methods:{
      GetUrlParams(param_name){
         let params = new URL(document.location).searchParams;
         return params.get(param_name);
      },
      GoToDatails(nameAndSurname, company, stampPeriod){
         let filters = helpers.getFilters('ZucchettiHackListFilter');
         filters.NameAndSurname = nameAndSurname;
         filters.Description = company;
         filters.ActionDescription = stampPeriod
         helpers.setObject('ZucchettiHackListFilter',filters);
         router.push({path:'/zucchettihacklist'})
      }

   },
   computed: {
    filteredItems() {
      return this.MonthstatusData.StampsHackExtraHoursRecapModelList.filter(item => {
         return item.NameAndSurname.toLowerCase().indexOf(this.search.toLowerCase()) > -1
      })
    }
   },
   created() {
      let data = helpers.getFilters('MonthstatusData');
      if (data && typeof data !== "undefined") {
         this.MonthstatusData = data;
         return;
      }
   }

}
</script>