<template>
    <div class="container-fluid py-4">
        <div class="card-body p-4">

            <div class="row">
                <div class="col-md-3 mb-md-0 mb-4">
                    <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                        <div class="input-group input-group-dynamic trivellato">
                            <label class="form-label">Targa</label>
                            <input class="form-control" aria-label="" type="text" v-model="filters.CommissionNumber">
                        </div>
                    </div>
                </div>
                <div class="col-md-3 mb-md-0 mb-4">
                    <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                        <div class="input-group input-group-dynamic trivellato">
                            <v-select class="form-control" :options="sellers" value="id_gruppo_agenti" label="descrizione"
                                v-model="filters.Agente" placeholder="Nessun venditore" multiple>
                                <template #option="{ id_gruppo_agenti, descrizione }">
                                    {{ descrizione }} <i>(ID: {{ id_gruppo_agenti }})</i>
                                </template>
                            </v-select>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                        <div class="input-group input-group-dynamic trivellato">
                            <v-select class="form-control" :options="['Con contratto', 'Senza Contratto']"
                                v-model="filters.ActionDescription" placeholder="Tutti">
                            </v-select>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card card-body card-plain d-flex align-items-center flex-row">
                        <div class="input-group input-group-dynamic">
                            <a class="float-right align-bottom btn bg-gradient-dark mb-0" @click="LoadData()"
                                :class="{ 'disabled': isInLoading }">
                                <i v-if="isInLoading" class="material-icons text-sm text-warning rotating">autorenew</i>
                                <i v-else class="material-icons text-sm">search</i>
                                &nbsp;&nbsp;Cerca
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                        <div class="input-group input-group-dynamic trivellato">
                            <label class="form-label"></label>
                            <input type="date" class="form-control" placeholder="Ritirato Da" aria-label=""
                                v-model="filters.DateFrom">
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                        <div class="input-group input-group-dynamic trivellato">
                            <label class="form-label"></label>
                            <input type="date" class="form-control" placeholder="Ritirato A" aria-label=""
                                v-model="filters.DateTo">
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                        <div class="input-group input-group-dynamic trivellato">
                            <v-select class="form-control" :options="canaliVendita" :reduce="canale => canale.codice" label="descrizione"
                                v-model="filters.CanaleVendita" placeholder="Canale di vendita">
                                <template #option="{descrizione}">
                                    {{ descrizione }}
                                </template>
                            </v-select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="expertise-card-container">
            <div v-if="isInLoading" class="card mb-3 col-md-9 col-xs-12 m-auto">
                <div class="card-body p-4 text-center">
                    <span class="material-icons material-symbols-outlined fs-1 color-trivellato rotating">
                        autorenew
                    </span>
                    <h5>Caricamento in corso</h5>
                </div>
            </div>
            <div v-else-if="list == null" class="card mb-3 col-md-9 col-xs-12 m-auto">
                <div class="card-body p-4 text-center">
                    <span class="material-icons material-symbols-outlined fs-1 color-trivellato">
                        sync_alt
                    </span>
                    <h5>Esegui una ricerca per visualizzare i dati sul trade-in</h5>
                </div>
            </div>
            <div v-else-if="list.length == 0" class="card mb-3 col-md-9 col-xs-12 m-auto">
                <div class="card-body p-4 text-center">
                    <span class="material-icons material-symbols-outlined fs-1 color-trivellato">
                        search_off
                    </span>
                    <h5>Nessun dato trovato relativo al trade-in</h5>
                </div>
            </div>
            <div v-else class="card mb-3 col-md-9 col-xs-12 m-auto shadow-secondary" v-for="expertise in list"
                :key="expertise.id">
                <div class="card-body expertise-card-content" v-if="expertise">
                    <div class="heading d-flex justify-content-between mb-2">
                        <h5 class="title light-dark">{{ expertise.descrizione.toUpperCase() }}</h5>
                        <div>
                            {{ expertise.dataRitiro.toUpperCase() }} <span class="giacenza">(GIACENZA: <b
                                    class="fw-bold">{{ expertise.giacenza.toUpperCase() }}</b>
                                GG - INTERESSI: <b>{{ Intl.NumberFormat('it-IT',{style: 'currency', currency: 'EUR', maximumFractionDigits: 0, minimumFractionDigits: 0}).format(expertise.interessi != null ? Math.floor(parseInt(expertise.interessi)) : 0) }}</b>)</span></div>
                    </div>
                    <div class="row  align-items-center">
                        <div class="col col-xs-12 col-md-5">
                            <div class="header d-flex flex-column">
                                <div class="text-muted author mb-2 align-text-bottom">
                                    <span>
                                        <i class="fa fa-user" aria-hidden="true"></i>&nbsp;{{ expertise.User }}
                                    </span>
                                </div>
                                <div class="text-muted">
                                    <b>Targa</b>: {{ expertise.targa }}
                                </div>
                                <div class="text-muted">
                                    <b>Azienda</b>: {{ expertise.company }}
                                </div>
                                <div class="text-muted">
                                    <b>Stock</b>: {{ expertise.stock }}
                                </div>
                                <div v-if="expertise.withContrattoVendita" class="text-muted">
                                    <b>Canale vendita</b>: {{expertise.canaleVendita}}
                                </div>
                            </div>
                        </div>
                        <div class="col col-xs-12 col-md-7">

                            <div class="values-details d-flex flex-row text-center flex-wrap">
                                <div class="detail">
                                    <p class="text-muted m-0">Valore di Ritiro</p>
                                    <div class="heading value fw-bold">{{ Intl.NumberFormat('it-IT',{style: 'currency', currency: 'EUR', maximumFractionDigits: 0, minimumFractionDigits: 0}).format(Math.floor(expertise.valoreRitiro)) }}</div>
                                </div>
                                <div class="detail">
                                    <p class="text-muted m-0">
                                        Valore {{ (!expertise.withContrattoVendita ? 'presunto' : '') }} di vendita
                                    </p>
                                    <div class="heading value fw-bold">
                                        <!-- <span v-if="expertise.withContrattoVendita">
                                            € {{ expertise.valoreVenditaEffettivo.toFixed(2) }}
                                        </span>
                                        <span v-else>
                                            € {{ expertise.valoreVenditaPresunto.toFixed(2) }}
                                        </span> -->
                                        <span>
                                            {{ Intl.NumberFormat('it-IT',{style: 'currency', currency: 'EUR', maximumFractionDigits: 0, minimumFractionDigits: 0}).format(Math.floor(expertise.revenue)) }}
                                        </span>
                                    </div>
                                </div>
                                
                                <div class="detail">
                                    <p class="text-muted m-0">Ripristini</p>
                                    <div class="heading value fw-bold">{{ Intl.NumberFormat('it-IT',{style: 'currency', currency: 'EUR', maximumFractionDigits: 0, minimumFractionDigits: 0}).format(Math.floor(expertise.ripristini)) }}</div>
                                </div>

                                <div class="detail">
                                    <p class="text-muted m-0">Supervalutazione</p>
                                    <div class="heading value fw-bold">{{ Intl.NumberFormat('it-IT',{style: 'currency', currency: 'EUR', maximumFractionDigits: 0, minimumFractionDigits: 0}).format(Math.floor(expertise.valoreRitiro) - Math.floor(expertise.valoreRitiroGestionale)) }}</div>
                                </div>

                                <div class="detail">
                                    <p class="text-muted m-0">Margine</p>
                                    <div class="heading value fw-bold">
                                        <!-- <span :class="{
                                            'text-success': (expertise.withContrattoVendita ?
                                                expertise.valoreVenditaEffettivo - expertise.ripristini - expertise.valoreRitiro :
                                                expertise.valoreVenditaPresunto - expertise.ripristini - expertise.valoreRitiro) > 0, 'text-danger': (expertise.withContrattoVendita ?
                                                    expertise.valoreVenditaEffettivo - expertise.ripristini - expertise.valoreRitiro :
                                                    expertise.valoreVenditaPresunto - expertise.ripristini - expertise.valoreRitiro) <= 0
                                        }">
                                            € {{ (Math.round(expertise.withContrattoVendita ?
                                                expertise.valoreVenditaEffettivo - expertise.ripristini - expertise.valoreRitiro
                                                :
                                                expertise.valoreVenditaPresunto - expertise.ripristini -
                                                expertise.valoreRitiro)).toFixed(2) }}
                                        </span> -->
                                        <span :class="{
                                            'text-success': parseInt(expertise.revenue) - expertise.ripristini - expertise.valoreRitiro > 0,
                                            'text-danger': parseInt(expertise.revenue) - expertise.ripristini - expertise.valoreRitiro <= 0
                                            }">
                                            {{ (Intl.NumberFormat('it-IT',{style: 'currency', currency: 'EUR', maximumFractionDigits: 0, minimumFractionDigits: 0}).format(Math.floor(parseInt(expertise.revenue)) - Math.floor(expertise.ripristini) - Math.floor(expertise.valoreRitiro)))}}
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="custom-footer text-center">
                        <div class="footer-text">
                            <i
                                :class="{ 'fa fa-check text-success': expertise.withContrattoVendita, 'fa fa-times text-warning': !expertise.withContrattoVendita }"
                                aria-hidden="true"></i>
                            <span class="text-muted">
                                {{ expertise.withContrattoVendita ?
                                    ' Con Contratto di vendita' :
                                    ' Senza contratto di vendita' }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import fetchFromAPI from "../../script/api.js"
import helpers from '../../script/helpers.js'
export default {
    data() {
        return {
            filters: {},
            list: null,
            sellers: [],
            isInLoading: false,
            canaliVendita: []
        };
    },
    methods: {
        LoadData() {
            /*helpers.resetFilter('tiresSearchList')
            helpers.setObject('tiresSearchFilter',this.filters);*/
            this.isInLoading = true;
            this.list = [];
            fetchFromAPI("TradeInCheck/LoadExpertiseList", this.filters, (res) => {
                this.list = res.expertises || [];
                this.isInLoading = false;
                /*helpers.setObject('tiresSearchList', res);*/
            }, (err) => {
                if (((((err || {}).response || {}).data || {}).Message || null) == "filter_not_set") {
                    this.$toast.warning('Per ricercare un trade-in è necessario specificare una targa e/o un venditore');
                }
                else {
                    this.$toast.error(helpers.stdErrorMessage);
                }
                this.isInLoading = false;
            });
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            let dateFormatted = new Intl.DateTimeFormat('it-IT', { dateStyle: 'full', timeStyle: 'long' }).format(date);
            if (dateFormatted != "1 gennaio 1") {
                return dateFormatted;
            }
            return "";
        }
    },
    created() {
        let filterData = helpers.getFilters('TradeInCheckFilter');
        if (filterData && typeof filterData !== "undefined") {
            this.filters = filterData;
            //this.LoadData();
        }
        fetchFromAPI("TradeInCheck/BindFiltersForHub", { Token: helpers.token() }, (res) => {
            res.DateFrom = new Date(res.DateFrom).toISOString().slice(0, 10)
            res.DateTo = new Date(res.DateTo).toISOString().slice(0, 10)
            this.filters = res;
            helpers.setObject('TradeInCheckFilter', this.filters);
            this.filters.NameAndSurname = null;
        });
        fetchFromAPI("TradeInCheck/LoadUsersList", { Token: helpers.token() }, (res) => {
            this.sellers = res;
        });
        fetchFromAPI("TradeInCheck/CanaliVendita", {Token: helpers.token() }, (res) => {
            this.canaliVendita = res;
        })
    },
    mounted() {
        this.$nextTick(() => {
            helpers.setMaterialInput();
        });
    }

}
</script>

<style scoped>
.expertise-card-content {
    padding: 0.5rem;
}

.heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    .title {
        font-weight: 700;
        font-size: 1.3rem;
    }
}

.author {
    margin-bottom: 1rem;
}

.header {
    i {
        padding-right: 0.4rem;
        font-size: 1.1rem;
    }
}

@media only screen and (max-width: 800px) {
    .header {
        margin-bottom: 1rem;
    }
}

.values-details {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .detail {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        text-align: center;

        p {
            margin: 0;
        }
    }

    .value {
        font-size: 1.25rem;
        font-weight: 600;
        color: #525f7f;
    }
}

.custom-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid rgba(230, 230, 230, 0.692);
    margin-top: 1rem;

    .footer-text {
        margin-top: 1rem;

        i {
            font-size: 1.5rem;
            padding-right: 0.4rem;
        }
    }
}

.giacenza {
    font-size: 0.87rem;
    font-weight: 400;
}
</style>