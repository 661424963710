<template>
<nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" navbar-scroll="true">
      <div class="container-fluid py-1 px-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
            <li v-for="navItem in nav.BreadCrumbObj" :key="navItem.Index" class="breadcrumb-item text-sm"><router-link class="text-secondary" :to="{ path: navItem.Path}" exact-path>{{navItem.PageLabel}}</router-link></li>
          </ol>
          <h6 class="font-weight-bolder mb-0">{{nav.PageLabel}}</h6>
        </nav>
        <div class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4 ps ps--active-x ps--active-y" id="navbar">
          <div class="ms-md-auto pe-md-3 d-flex align-items-center">
          </div>
          </div>
      </div>
      <div v-if="nav.IsTest" class="alert alert-warning alert-dismissible text-white fade show position-absolute top-10 start-50 translate-middle-x" role="alert" style="z-index: 999;">
        <span class="alert-text text-xs"><strong>Attenzione!</strong> <br> Questo portale è ancora in fase di test pertanto potrebbe non funzionare correttamente o subire delle future modifiche.</span>
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!--MB-test-->
    </nav>
</template>
<script>

import fetchFromAPI from "../script/api.js"
import helpers from '../script/helpers.js'
export default {
      data() {
         return {
         nav: {},
         isInLoading: false
      };
   },
   created() {
       let data = helpers.getFilters(this.$route.name +'NavMapping');
        if (data && typeof data !== "undefined" && data.length) {
            this.nav = data
            return;
          }
        fetchFromAPI("HubPermission/LoadNavMapping", { Token : helpers.token(), Description: this.$route.name }, (res) => {
          this.nav = res;
          helpers.setObject(this.$route.name +'NavMapping', res);
        })
    },
    
}
</script>
<style>
.router-link-active{
  font-weight: bold;
}
</style>

