<template>
    <div>
        <div class="card mh-100" v-if="selectedEntity">
            <div class="card-body overflow-auto">
                <h3 class="mb-4">Gestisci header di navigazione</h3>
                <div class="form-group d-flex flex-column align-items-start">
                    <div class="input-group input-group-dynamic my-2 trivellato"
                        :class="{ 'is-filled': selectedEntity.PageName != null && selectedEntity.PageName != '' }">
                        <label class="form-label">Nome pagina</label>
                        <input type="text" class="form-control" aria-label="" v-model="selectedEntity.PageName" />
                    </div>
                    <div class="input-group input-group-dynamic my-2 trivellato"
                        :class="{ 'is-filled': selectedEntity.PageLabel != null && selectedEntity.PageLabel != '' }">
                        <label class="form-label">Etichetta pagina</label>
                        <input type="text" class="form-control" aria-label="" v-model="selectedEntity.PageLabel" />
                    </div>
                    <div class="form-check form-switch my-2 d-flex align-items-center trivellato">
                        <input class="form-check-input trivellato" type="checkbox"
                            v-model="selectedEntity.IsTest">
                        <label class="form-check-label d-block ms-3 mb-0" for="flexSwitchCheckDefault">
                            Mostra banner Test
                        </label>
                    </div>
                    <br>
                    <h5>Anteprima navigazione</h5>
                    <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
                        <li v-for="navItem in filteredAndSortedBreadcrumb" :key="navItem.Index" class="breadcrumb-item">
                            <a href="javascript:" class="color-trivellato hover-trivellato"
                                :class="{ 'font-weight-bold': navItem.Active }" :to="{ path: navItem.Path }" exact-path
                                @click="selectedBreadcrumbItem = navItem">
                                {{ navItem.PageLabel }}
                            </a>
                        </li>
                        <li class="breadcrumb-item text-sm">
                            <a href="javascript:" class="material-icons material-symbols-outlined hover-trivellato"
                                title="Aggiungi elemento di navigazione" @click="addBreadcrumbItem">
                                add_circle
                            </a>
                        </li>
                    </ol>
                    <div class="card my-4 px-4 py-3 bg-gray-200 col-md-12" v-if="selectedBreadcrumbItem">
                        <div class="input-group input-group-dynamic my-2 trivellato"
                            :class="{ 'is-filled': selectedBreadcrumbItem != null && selectedBreadcrumbItem.Index !== null }">
                            <label class="form-label">Index</label>
                            <input type="number" step="1" class="form-control" aria-label=""
                                v-model="selectedBreadcrumbItem.Index" />
                        </div>
                        <div class="input-group input-group-dynamic my-2 trivellato"
                            :class="{ 'is-filled': selectedBreadcrumbItem != null && selectedBreadcrumbItem.PageLabel && selectedBreadcrumbItem.PageLabel != '' }">
                            <label class="form-label">Label</label>
                            <input type="text" class="form-control" aria-label=""
                                v-model="selectedBreadcrumbItem.PageLabel" />
                        </div>
                        <div class="input-group input-group-dynamic my-2 trivellato"
                            :class="{ 'is-filled': selectedBreadcrumbItem != null && selectedBreadcrumbItem.Path && selectedBreadcrumbItem.Path != '' }">
                            <label class="form-label">Path</label>
                            <input type="text" class="form-control" aria-label="" v-model="selectedBreadcrumbItem.Path" />
                        </div>
                        <div class="form-check form-switch my-2 d-flex align-items-center trivellato">
                            <input class="form-check-input trivellato" type="checkbox"
                                v-model="selectedBreadcrumbItem.Active">
                            <label class="form-check-label d-block ms-3 mb-0" for="flexSwitchCheckDefault">
                                Active
                            </label>
                        </div>
                        <div class="top-right-corner m-2">
                            <span class="btn material-icons material-symbols-outlines p-0  close-breadcrumb-item"
                                @click="closeBradCrumbItem">
                                remove
                            </span>
                            <span class="btn material-icons material-symbols-outlines p-0 ms-2 del-breadcrumb-item"
                                @click="deleteBradCrumbItem">
                                cancel
                            </span>
                        </div>
                    </div>
                    <!-- <div class="d-flex flex-row col-md-12 justify-content-between">
                                <div v-for="item in selectedEntity.BreadcrumbItems"
                                    class="card mb-4 px-4 pt-3 bg-gray-200 col-md-3" :key="item">
                                    <div class="input-group input-group-dynamic my-2 trivellato"
                                        :class="{ 'is-filled': item != null && item.Index && item.Index != '' }">
                                        <label class="form-label">Indice</label>
                                        <input type="text" class="form-control" aria-label="" v-model="item.Index" />
                                    </div>
                                    <span class="btn material-icons material-symbols-outlines del-breadcrumb-item p-0 m-2"
                                        @click="deleteBradCrumbItem(item)">
                                        cancel
                                    </span>
                                </div>
                            </div>
                            <button class="btn bg-gradient-dark text-white px-3 py-2 m-0" @click="addBreadcrumbItem"
                                :disabled="!entities">
                                <span class="material-icons material-symbols-outlined">
                                    add
                                </span>
                                Aggiungi elemento
                            </button> -->
                </div>
                <div class="buttons-container mt-6">
                    <button class="btn bg-gradient-danger mx-1" @click="del()">Elimina</button>
                    <button class="btn bg-gradient-dark mx-1 float-end" @click="save()">Salva</button>
                    <button class="btn bg-gradient-light mx-1 float-end" @click="refreshItem()">Annulla</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import fetchFromAPI from "../script/api.js"
import helpers from "../script/helpers.js"
import _ from 'lodash'
import mitt from 'mitt'


export default {
    props: ['parentSelectedEntity', 'entityPageName'],
    watch: {
        parentSelectedEntity: {
            handler(val) {
                this.selectedEntity = val;
                this.selectedBreadcrumbItem = null;
            },
            deep: true
        },
        entityPageName: {
            handler(val) {
                if (!this.parentSelectedEntity) {
                    fetchFromAPI("HeaderMapper/LoadList", { Token: helpers.token() }, (res) => {
                        this.selectedEntity = res.find((el) => el.PageName == val);
                        this.selectedBreadcrumbItem = null;
                        if (this.selectedEntity == null) {
                            var x = {
                                Id: -1,
                                PageName: null,
                                BreadcrumbItems: [],
                                PageLabel: null,
                                Token: helpers.token()
                            };
                            this.selectedEntity = x;
                        }
                    }, (/*err*/) => {
                        this.$toast.error(helpers.stdErrorMessage);
                    });
                }
            },
            deep: true
        }
    },
    data() {
        return {
            selectedEntity: this.parentSelectedEntity || null,
            selectedBreadcrumbItem: null,
            eventHub: mitt(),
            userGroups: null
        }
    },
    computed: {
        filteredAndSortedBreadcrumb() {
            // function to compare names
            function compare(a, b) {
                if (a.Index < b.Index) return -1;
                if (a.Index > b.Index) return 1;
                return 0;
            }

            return this.selectedEntity.BreadcrumbItems.filter(() => 1 == 1).sort(compare)
        }
    },
    methods: {
        save() {
            var payload = _.cloneDeep(this.selectedEntity);
            payload.CompanyId = (this.selectedEntity.Company || {}).Id || null;
            payload.CompanyName = (this.selectedEntity.Company || {}).Nome || null;
            delete payload.Company;
            fetchFromAPI("HeaderMapper/SaveEntity", payload, (res) => {
                this.selectedEntity.Id = res.Id;
                this.$toast.success('Mappatura salvata');
                this.eventHub.emit('header/mapping/saved');
            }, (err) => {
                console.error("Something went wrong saving data. Error: " + err);
                this.$toast.error('Qualcosa è andato storto, ricaricare la pagina e tentare di nuovo<br/>Se il problema persiste contattare un responsabile');
            });
        },
        del() {
            fetchFromAPI("HeaderMapper/DeleteEntity", this.selectedEntity, (/*res*/) => {
                this.selectedEntity = null;
                this.$toast.success('Mappatura eliminata');
                this.eventHub.emit('header/mapping/deleted');
            }, (err) => {
                console.error("Something went wrong deleting data. Error: " + err);
                this.$toast.error('Qualcosa è andato storto, ricaricare la pagina e tentare di nuovo<br/>Se il problema persiste contattare un responsabile');
            });
        },
        refreshItem() {
            if (this.selectedEntity.Id == -1) {
                this.selectedEntity.Targa = null;
                this.selectedEntity.Descrizione = null;
                this.selectedEntity.Visibile = false;
                this.$toast.info('Dati originali ricaricati');
                return;
            }

            fetchFromAPI("HeaderMapper/GetSingleEntity", this.selectedEntity, (res) => {
                this.selectedEntity.PageName = res.PageName;
                this.selectedEntity.BreadcrumbItems = res.BreadcrumbItems;
                this.selectedEntity.PageLabel = res.PageLabel;
                this.$toast.info('Dati originali ricaricati');
            }, (err) => {
                console.error("Something went wrong refreshing the selected element. Error: " + err);
                this.$toast.error('Qualcosa è andato storto, ricaricare la pagina e tentare di nuovo<br/>Se il problema persiste contattare un responsabile');
            })
        },
        addBreadcrumbItem() {
            var item = {
                Index: this.selectedEntity.BreadcrumbItems.length,
                PageLabel: "New",
                Path: "pathToPage",
                Icon: ""
            };
            this.selectedEntity.BreadcrumbItems.push(item);
            this.selectedBreadcrumbItem = item;
        },
        deleteBradCrumbItem() {
            var idx = this.selectedEntity.BreadcrumbItems.indexOf(this.selectedBreadcrumbItem);
            this.selectedEntity.BreadcrumbItems.splice(idx, 1);
            this.selectedBreadcrumbItem = null;
        },
        closeBradCrumbItem() {
            this.selectedBreadcrumbItem = null;
        }
    },
    created() {
        if (this.entityPageName) {
            fetchFromAPI("HeaderMapper/LoadList", { Token: helpers.token() }, (res) => {
                this.selectedEntity = res.find((el) => el.PageName == this.entityPageName);
                this.selectedBreadcrumbItem = null;
                if (this.selectedEntity == null) {
                    var x = {
                        Id: -1,
                        PageName: null,
                        BreadcrumbItems: [],
                        PageLabel: null,
                        Token: helpers.token()
                    };
                    this.selectedEntity = x;
                }
            }, (/*err*/) => {
                this.$toast.error(helpers.stdErrorMessage);
            });
        }
    },
    mounted() {
        this.$nextTick(() => {
            helpers.setMaterialInput();
        });
    }
}
</script>

<style scoped>
.del-breadcrumb-item:hover {
    color: red;
}

.close-breadcrumb-item:hover {
    color: lightskyblue;
}
</style>