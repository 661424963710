<template>
<nav class="navbar navbar-transparent navbar-expand-lg top-0 z-index-3 shadow-none position-absolute my-3 py-2 start-0 end-0 mx-4 row">
    <div class="container-fluid ps-2 pe-0 col-sm-12">
      <a class="navbar-brand align-items-center font-weight-bolder ms-lg-0 me-2 text-white col-3" href="/">
          <i class="fa fa-atom opacity-9 text-white me-1" aria-hidden="true"></i>
        Trivellato Hub 
      </a>
      <div class="col-6 z-n1"></div>
        <ul v-if="!isLogin" class="navbar-nav navbar-toggler ">
          <li class="nav-item">
            <a href="#" @click="LogOut" class="btn btn-sm mb-0 me-1 bg-gradient-dark" style="margin-right: 1rem;">Log Out</a>
          </li>
        </ul>
      <div class="collapse navbar-collapse ps" id="navigation">
        <ul class="navbar-nav mx-auto">
          <!--<li class="nav-item">
            <a class="nav-link d-flex align-items-center me-2 active" aria-current="page" href="../pages/dashboard.html">
              <i class="fa fa-chart-pie opacity-6 text-dark me-1" aria-hidden="true"></i>
              Dashboard
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link me-2" href="../pages/profile.html">
              <i class="fa fa-user opacity-6 text-dark me-1" aria-hidden="true"></i>
              Profile
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link me-2" href="../pages/sign-up.html">
              <i class="fas fa-user-circle opacity-6 text-dark me-1" aria-hidden="true"></i>
              Sign Up
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link me-2" href="../pages/sign-in.html">
              <i class="fas fa-key opacity-6 text-dark me-1" aria-hidden="true"></i>
              Sign In
            </a>
          </li>-->
        </ul>
        <ul v-if="!isLogin" class="navbar-nav">
          <li class="nav-item">
            <a href="#" @click="LogOut" class="btn btn-sm mb-0 me-1 bg-gradient-dark">Log Out</a>
          </li>
        </ul>
      <div class="ps__rail-x" style="left: 0px; bottom: 0px;"><div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div></div><div class="ps__rail-y" style="top: 0px; right: 0px;"><div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px;"></div></div></div>
    </div>
  </nav>
</template>
<script>
import helpers from "../script/helpers.js";

export default {
  methods: {
    LogOut() {
      helpers.removeToken();
      helpers.removeFiltersData();
      this.$router.push('Login') 
    }
  },
  computed: {
  isLogin() {
     return this.$route.name === 'Login'
  }
}
};
</script>