<template>
    <div v-for="item in opportunityData" :key="item.CommissionChain[0].CommissionDataHeader.ContractId" class="container-fluid py-2 contract-approval-detail">
     <div v-for="(subItem) in item.CommissionChain" :key="subItem.CommissionDataHeader.ContractId" class="section">
      <!--<div class="row myboxbold">
        <div  class="col-sm-12 mt-sm-2 mb-2">
          <div :class="[index==itemIndex ? 'text-white bg-gradient-dark' : '']">
            <div class="p-2 position-relative">
              <div class="row">
                <div class="col-8 text-start">
                    <p v-if="subItem.CommissionDataHeader.IsUsedWithoutContract" class="text-sm mb-1 font-weight-bold">Contratto non Presente</p>
                    <p v-else class="mb-0 text-capitalize font-weight-bold">Contratto {{subItem.CommissionDataHeader.ContractNumber}} | {{formatDate(subItem.CommissionDataHeader.ContractDate)}} {{subItem.CommissionDataHeader.AccountDescription}}</p>
                  <h6 class="font-weight-bolder mb-0">
                    Margine: {{subItem.CommissionDataFooter.ContractMarginWithOvervalation}} €
                  </h6>
                  <span class="text-end font-weight-bolder mt-auto mb-0"><i class="fa fa-car" aria-hidden="true"></i> <span class="font-weight-normal">
                    <strong>{{subItem.CommissionDataHeader.StockMainTypeDescription}}</strong> - <span>{{subItem.CommissionDataHeader.LicensePlate}}</span> - <span>Giacenza {{subItem.OpportunityDetails.StockDays}}</span></span></span>
                </div>
                <div class="col-4">
                  <div class="dropdown text-end bottom-0">
                      <span >
                          <span v-if="subItem.CommissionDataHeader.IsUsedWithoutContract" class="text-warnig" >Margine Presunto</span> 
                          <span v-else>{{formatDate(subItem.CommissionDataHeader.ContractDate)}}</span>
                          <p v-if="!subItem.CommissionDataHeader.IsUsedWithoutContract">{{subItem.CommissionDataHeader.SellerName}}</p>
                      </span>
                  </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>-->
      <div class="row ">
        <div class="col-lg-4 mt-lg-2 mb-2 mybox">
          <div>
            <div class="pb-0 p-3">
              <h4>
                <span v-if="subItem.CommissionDataHeader.IsUsedWithoutContract">Contratto non Presente</span>
                <span v-else>Contratto {{subItem.CommissionDataHeader.ContractNumber}} | {{formatDate(subItem.CommissionDataHeader.ContractDate)}}</span><br>
                
              </h4>
              <h5 class="font-weight-bolder mb-2"  v-if="!subItem.CommissionDataHeader.IsUsedWithoutContract">
                  <span>{{subItem.CommissionDataHeader.AccountDescription}}</span>
              </h5>
              <h6 class="mb-0 pl-2">---------------------------------------</h6>
              <h5 class="font-weight-bolder mb-0">
                Margine <span v-if="!subItem.CommissionDataHeader.IsUsedWithoutContract"> Contratto:</span><span v-else> Preventivo:</span> {{subItem.CommissionDataFooter.ContractMarginWithOvervalationForPrintTemplate}} €
              </h5>
              <!--<h6 class="font-weight-bolder mb-0">
                    Margine Contratto(senza superval): {{subItem.CommissionDataFooter.ContractMarginForPrintTemplate}} €
              </h6>-->
              <h6 class="font-weight-bolder mb-0" v-if="!subItem.CommissionDataHeader.IsUsedWithoutContract">
                    Margine Ferro: {{subItem.CommissionDataFooter.ContractIronMarginWithOvervalationForPrintTemplate}} €
              </h6>
              <h6 class="font-weight-bolder mb-2" v-if="!subItem.CommissionDataHeader.IsUsedWithoutContract && subItem.CommissionDataFooter.ContractFinancialMarginWithOvervalationForPrintTemplate != '0,00'">
                    Margine Finanziario: {{subItem.CommissionDataFooter.ContractFinancialMarginWithOvervalationForPrintTemplate}} €
              </h6>
              <h6 class="font-weight-bolder mb-2" v-if="!subItem.CommissionDataHeader.IsUsedWithoutContract && subItem.CommissionDataFooter.ContractFinancialMarginWithOvervalationForPrintTemplate == '0,00'">
                    Margine Finanziario: 0,00€
              </h6>
              
              <span v-if="!subItem.CommissionDataHeader.IsUsedWithoutContract">
                <span class="font-weight-bolder">SuperValutazione Pagata: {{subItem.CommissionDataFooter.PaidOvervaluation}} €</span>
                <br><span class="font-weight-bolder">SuperValutazione Ricevuta: {{subItem.CommissionDataFooter.ReceivedOvervaluation}} €</span>
              </span>
              <span v-else>
                <br><span class="font-weight-bolder">SuperValutazione: {{subItem.CommissionDataFooter.OverEvaluationForPrintTemplate}} €</span>
              </span>

              <h6 class="mb-0 pl-2">---------------------------------------</h6>
              <span class="mt-4"><span class="text-end font-weight-bolder"><span>
              <span class="font-weight-bolder">{{subItem.CommissionDataHeader.VehicleDescription}}</span>
              <br> <strong>{{subItem.CommissionDataHeader.StockMainTypeDescription}}</strong> - <span>{{subItem.CommissionDataHeader.LicensePlate}}</span> - <span>Giacenza {{subItem.OpportunityDetails.StockDays}} gg</span></span></span></span>
              <br> <span class="font-weight-bolder" v-if="!subItem.CommissionDataHeader.IsUsedWithoutContract">{{subItem.CommissionDataHeader.SellerName}}</span>
            <h6 class="mb-0">---------------------------------------</h6>
              <div class="d-flex align-items-center ps-0">
              </div>
            </div>
          </div>
          <div>
            <div class="">
              <ul class="list-group list-group-flush list my--4">
                <li class="list-group-item px-0 border-0">
                  <div class="row align-items-center">
                    <div class="col-auto">

                    </div>
                    <div class="col">
                      <h6 class="font-weight-normal mb-0">Costi Trattativa</h6>
                    </div>
                    <div class="col text-center">
                      <h6 v-if="item.CommissionChain[item.CommissionChain.length -1].CommissionDataHeader.IsUsedWithoutContract" class="font-weight-normal mb-0">{{item.CommissionChain[item.CommissionChain.length -1].OpportunityDetails.OpportunityCost}} €</h6>
                      <h6 class="font-weight-normal mb-0" v-else>{{subItem.OpportunityDetails.OpportunityCost}} €</h6>
                    </div>
                  </div>
                  <hr class="horizontal dark mt-0 mb-0">
                </li>
                <li class="list-group-item px-0 border-0">
                  <div class="row align-items-center">
                    <div class="col-auto">
                    </div>
                    <div class="col">
                      <h6 class="font-weight-normal mb-0">Ricavi Trattativa</h6>
                    </div>
                    <div class="col text-center">
                      <h6 v-if="item.CommissionChain[item.CommissionChain.length -1].CommissionDataHeader.IsUsedWithoutContract" class="font-weight-normal mb-0">{{item.CommissionChain[item.CommissionChain.length -1].OpportunityDetails.OpportunityRevenueForPrintTemplate}} €</h6>
                      <h6 class="font-weight-normal mb-0" v-else>{{subItem.OpportunityDetails.OpportunityRevenueForPrintTemplate}} €</h6>
                    </div>
                  </div>
                  <hr class="horizontal dark mt-0 mb-0">
                </li>
                <li class="list-group-item px-0 border-0">
                  <div class="row align-items-center">
                    <div class="col-auto">
                    </div>
                    <div class="col">
                      <h5 class="font-weight-bolder mb-0">Margine Trattativa</h5>
                    </div>
                    <div class="col text-center">
                      <h5 class="font-weight-bolder mb-0" v-if="item.CommissionChain[item.CommissionChain.length -1].CommissionDataHeader.IsUsedWithoutContract" >{{item.CommissionChain[item.CommissionChain.length -1].OpportunityDetails.OpportunityAllegedMarginForPrintTemplate}} €</h5>
                      <h5 class="font-weight-bolder mb-0" v-else>{{subItem.OpportunityDetails.OpportunityMarginForPrintTemplate}} €</h5>
                    </div>
                  </div>
                  <hr class="horizontal dark mt-0 mb-0">
                </li>
                <!--<li v-if="subItem.CommissionDataHeader.IsUsedWithoutContract" class="list-group-item px-0 border-0">
                  <div class="row align-items-center">
                    <div class="col-auto">
                    </div>
                    <div class="col">
                      <h6 class="font-weight-bolder mb-0">Margine Presunto</h6>
                      <p class="font-weight-normal mb-0" >vettura senza contratto in catena</p>
                    </div>
                    <div class="col text-center">
                      <h6 class="font-weight-normal mb-0">{{subItem.OpportunityDetails.OpportunityAllegedMarginForPrintTemplate}} €</h6>
                    </div>
                  </div>
                  <hr class="horizontal dark mt-0 mb-0">
                </li>-->
              </ul>
            </div>
          </div>
        </div>
      <div style="padding-right:0!important" class="col-lg-8 mt-lg-2 mb-2">
          <div class="mybox">
            <div class="d-flex">
              <h6 class="mt-3 mb-2 ms-3 ">Dettaglio Costi</h6>
            </div>
            <div class="card-body px-0 pt-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-uppercase text-secondary text-xs font-weight-bolder">Fornitore</th>
                      <th class="text-uppercase text-secondary text-xs font-weight-bolder">Documento</th>
                      <th class="text-uppercase text-secondary text-xs font-weight-bolder ps-2">Descrizione</th>
                      <th class="text-center text-uppercase text-secondary text-xs font-weight-bolder">Imponibile</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(costItem, index) in subItem.Costs.CostList" :key="index">
                      <td :class="[costItem.DontSum ? 'text-decoration-line-through' : '']">
                        <div class="d-flex px-1 py-0">
                          <div class="d-flex flex-column justify-content-center">
                            <h6 class="mb-0 text-sm">{{trimString(costItem.Supplier, 28)}}</h6>
                            
                          </div>
                        </div>
                      </td>
                      <td :class="[costItem.DontSum ? 'text-decoration-line-through' : '']">
                        <p class="text-sm font-weight-normal text-secondary mb-0">{{costItem.DocumentNumber}} {{formatDate(costItem.DocumentDate)}}</p>
                      </td>
                      <td :class="[costItem.DontSum ? 'text-decoration-line-through' : '']">
                        <p class="text-sm font-weight-normal mb-0" v-html="costItem.CostDescription"></p>
                      </td>
                      <td class="align-middle text-center text-sm" :class="[costItem.DontSum && !costItem.SumForApprovalPrint ? 'text-decoration-line-through' : '']">
                        <p class="text-sm font-weight-normal mb-0">{{costItem.MoneyFormatTaxable}} €</p>
                      </td>
                    </tr>
                    <tr>
                      <td></td><td></td>
                      <td colspan="2"><p class="text-sm font-weight-normal text-secondary m-1 text-end"><b>Totale Costi:</b> {{subItem.CommissionDataFooter.ContractCost}} €</p></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            
          </div>
          <div class="mt-2 mybox">
            <div class="d-flex">
              <h6 class="mt-3 mb-2 ms-3 ">Dettaglio Ricavi</h6>
            </div>
            <div class="px-0 pt-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-uppercase text-secondary text-xs font-weight-bolder">Cliente</th>
                      <th class="text-uppercase text-secondary text-xs font-weight-bolder">Documento</th>
                      <th class="text-uppercase text-secondary text-xs font-weight-bolder ps-2">Descrizione</th>
                      <th class="text-center text-uppercase text-secondary text-xs font-weight-bolder">Imponibile</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(revItem, index) in subItem.Revenues.RevenueList"  :key="index" >
                      <td :class="[revItem.DontSum && !revItem.SumForApprovalPrint ? 'text-decoration-line-through' : '']">
                        <div class="d-flex px-1 py-1">
                          <div class="d-flex flex-column justify-content-center" :class="[revItem.DontSum && !revItem.SumForApprovalPrint ? 'text-decoration-line-through' : '']">
                            <h6 class="mb-0 text-sm">{{trimString(revItem.Customer, 28)}}</h6>

                          </div>
                        </div>
                      </td>
                      <td :class="[revItem.DontSum && !revItem.SumForApprovalPrint ? 'text-decoration-line-through' : '']">
                        <p class="text-sm text-secondary mb-0">{{revItem.DocumentNumber}} {{formatDate(revItem.DocumentDate)}}</p>
                      </td>
                      <td :class="[revItem.DontSum && !revItem.SumForApprovalPrint ? 'text-decoration-line-through' : '']">
                        <p class="text-sm font-weight-normal mb-0" v-html="revItem.RevenueDescription"></p>
                      </td>
                      <td class="align-middle text-center text-sm" :class="[revItem.DontSum && !revItem.SumForApprovalPrint ? 'text-decoration-line-through' : '']">
                        <p class="text-sm font-weight-normal mb-0">{{revItem.MoneyFormatTaxable}} €</p>
                      </td>
                    </tr>
                    <tr>
                      <td></td><td></td>
                      <td colspan="2"><p class="text-sm font-weight-normal text-secondary m-1 text-end"><b>Totale Ricavi:</b> {{subItem.CommissionDataFooter.ContractRevenueForPrintTemplate}} €</p></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    </div >

</template>
<script>
import helpers from '../../script/helpers.js'
export default {
  data() {
    return {
      opportunityData: {},
      itemData:{},
    };
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      let dateFormatted = new Intl.DateTimeFormat("default", { dateStyle: "long" }).format(date);
      if(dateFormatted != "1 gennaio 1"){
          return dateFormatted;
      }
      return "";
    },
    trimString(string, length){
        return helpers.trimString(string,length)
    },
    bindData(data){
        this.itemData = data; 
    },
    printPage() {
      setTimeout(() => {
        window.print();
      }, 500); // Ritardo di 1 secondo
    }
  },
  created() {
        let data = helpers.getFilters('contractApprovalList');
           if (data && typeof data !== "undefined") {
              this.opportunityData = data;
              this.itemData = data[0].CommissionChain[0];
              console.log(data)
              console.log(data[0].CommissionChain[0])
              return;
        }
  },
  mounted() {
    this.printPage();
  }
}
</script>
<style>
.contract-approval-detail{
  background-color: white;
}
.mybox{
  border: 1px solid rgb(30, 30, 30); 
  border-radius: 0.5em;
  padding-left: 0.5em;
  padding-right: 0.5em;
}
.myboxbold{
  border: 2px solid rgb(30, 30, 30); border-radius: 0.45em;
}
/* Stile per la stampa */
@media print {
  /* Stile per suddividere il contenuto in più pagine */
  .section {
    page-break-before:always;
  }
}
</style>