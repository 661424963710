<template>

    <div v-for="item in opportunityData" :key="item.CommissionChain[0].CommissionDataHeader.ContractId" class="container-fluid py-4 small">
    <div v-if="item.OpportunityApprovalModel.ApprovalStatus != 1" class="card mb-4">
      <div class="card-body p-3">
     <div class="d-flex justify-content-start">
      <div class="p-2"><button class="btn btn-sm bg-gradient-success  my-auto" @click="showAlertTrue" disabled>Approva</button></div>
      <div class="p-2"><button class="btn btn-sm bg-gradient-warning mb-0 my-auto" @click="showAlertFalse" disabled>Rivedi</button></div>
      <div class="p-2"><router-link class="btn btn-sm bg-gradient-info mb-0 my-auto" target="_blank" :to="'/printablecontractapprovaldetail'">Stampa</router-link></div>
      <div v-if="item.OpportunityApprovalModel.ApprovalStatus == 2 && item.OpportunityApprovalModel.ApprovalNotes && item.OpportunityApprovalModel.ApprovalNotes.length" class="p-2 my-auto"><p class="text-sm font-weight-normal mb-0 " >Note: {{item.OpportunityApprovalModel.ApprovalNotes}}</p></div>
    </div>
        </div>
    </div>
      <div class="row">
        <div v-for="(subItem, index) in item.CommissionChain" :key="subItem.CommissionDataHeader.ContractId" class="col-sm-4 mt-sm-0 mb-4">
        <a href="#" @click="bindData(item.CommissionChain, index)">
          <div class="card" :class="[index==itemIndex ? 'text-white bg-gradient-dark' : '']">
              <img v-if="index==itemIndex" src="../../assets/img/illustrations/pattern-tree.svg" class="position-absolute opacity-2 start-0 top-0 w-100 z-index-1 h-100" alt="pattern-tree">
            <div class="card-body p-3 position-relative">
              <div class="row">
                <div class="col-8 text-start" :class="[index==itemIndex ? 'text-white font-weight-bold' : '']">
                    <p v-if="subItem.CommissionDataHeader.IsUsedWithoutContract" class="text-sm mb-1 font-weight-bold">Contratto non Presente</p>
                    <p v-else class="text-sm mb-1 text-capitalize font-weight-bold">Contratto {{subItem.CommissionDataHeader.ContractNumber}} | {{subItem.CommissionDataHeader.AccountDescription}}</p>
                  <h6 class="font-weight-bolder mb-0" :class="[index==itemIndex ? 'text-white' : '']">
                    Margine: {{subItem.CommissionDataFooter.ContractMarginWithOvervalation}} €
                  </h6>
                  <span class="text-sm text-end text-success font-weight-bolder mt-auto mb-0"><i class="fa fa-car" aria-hidden="true"></i> <span class="font-weight-normal" :class="[index==itemIndex ? 'text-white' : ' text-secondary']"><strong>{{subItem.CommissionDataHeader.StockMainTypeDescription}}</strong> - <span>{{subItem.CommissionDataHeader.LicensePlate}}</span> - <span>Giacenza {{subItem.OpportunityDetails.StockDays}}</span></span></span>
                </div>
                <div class="col-4">
                  <div class="dropdown text-end bottom-0">
                      <span class="text-xs" >
                          <span v-if="subItem.CommissionDataHeader.IsUsedWithoutContract" class="text-warnig" >Margine Presunto</span> 
                          <span v-else :class="[index==itemIndex ? 'text-white' : ' text-secondary']">{{formatDate(subItem.CommissionDataHeader.ContractDate)}}</span>
                          <p class="small" v-if="!subItem.CommissionDataHeader.IsUsedWithoutContract" :class="[index==itemIndex ? 'text-white' : ' text-secondary']">{{subItem.CommissionDataHeader.SellerName}}</p>
                      </span>
                  </div>
                  </div>
              </div>
            </div>
          </div>
         </a>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 mt-lg-0 mb-4">
          <div class="card">
            <div class="card-header pb-0 p-3">
              <div class="d-flex justify-content-between">
                <h6 class="mb-0">Riepilogo Trattativa</h6>
              </div>
            </div>
            <div class="card-body p-3">
              <ul class="list-group list-group-flush list my--3">
                <li class="list-group-item px-0 border-0">
                  <div class="row align-items-center">
                    <div class="col-auto">
                        <div class="icon icon-shape">
                         <i class="material-icons opacity-8 text-danger">expand_more</i>
                        </div>
                    </div>
                    <div class="col">
                      <h6 class="text-sm font-weight-normal mb-0">Costi Trattativa</h6>
                    </div>
                    <div class="col text-center">
                      <h6 class="text-sm font-weight-normal mb-0">{{itemData.OpportunityDetails.OpportunityCost}} €</h6>
                    </div>
                  </div>
                  <hr class="horizontal dark mt-0 mb-0">
                </li>
                <li class="list-group-item px-0 border-0">
                  <div class="row align-items-center">
                    <div class="col-auto">
                        <div class="icon icon-shape">
                         <i class="material-icons opacity-8 text-success">expand_less</i>
                        </div>
                    </div>
                    <div class="col">
                      <h6 class="text-sm font-weight-normal mb-0">Ricavi Trattativa</h6>
                    </div>
                    <div class="col text-center">
                      <h6 class="text-sm font-weight-normal mb-0">{{itemData.OpportunityDetails.OpportunityRevenue}} €</h6>
                    </div>
                  </div>
                  <hr class="horizontal dark mt-0 mb-0">
                </li>
                <li v-if="!itemData.CommissionDataHeader.IsUsedWithoutContract" class="list-group-item px-0 border-0">
                  <div class="row align-items-center">
                    <div class="col-auto">
                        <div class="icon icon-shape">
                         <i class="material-icons opacity-8 text-success">insights</i>
                        </div>
                    </div>
                    <div class="col">
                      <h5 class="text-sm font-weight-bolder mb-0">Margine Trattativa</h5>
                    </div>
                    <div class="col text-center">
                      <h5 class="text-sm font-weight-bolder mb-0">{{itemData.OpportunityDetails.OpportunityMargin}} €</h5>
                    </div>
                  </div>
                  <hr class="horizontal dark mt-0 mb-0">
                </li>
                <li v-if="itemData.OpportunityDetails.OpportunityAllegedMargin" class="list-group-item px-0 border-0">
                  <div class="row align-items-center">
                    <div class="col-auto">
                        <div class="icon icon-shape">
                         <i class="material-icons opacity-8 text-info">try</i>
                        </div>
                    </div>
                    <div class="col">
                      <h6 class="text-sm font-weight-bolder mb-0">Margine Presunto</h6>
                      <p class="text-sm font-weight-normal mb-0" >vettura senza contratto in catena</p>
                    </div>
                    <div class="col text-center">
                      <h6 class="text-sm font-weight-normal mb-0">{{itemData.OpportunityDetails.OpportunityAllegedMargin}} €</h6>
                    </div>
                  </div>
                  <hr class="horizontal dark mt-0 mb-0">
                </li>
              </ul>
            </div>
          </div>
        </div>
      <div class="col-lg-8">
          <div class="card mb-0">
            <div class="d-flex">
              <div class="icon icon-shape icon-lg bg-gradient-dark shadow text-center border-radius-xl mt-n3 ms-4">
                <i class="material-icons opacity-10" aria-hidden="true">payments</i>
              </div>
              <h6 class="mt-3 mb-2 ms-3 ">Dettaglio Costi</h6>
            </div>
            <div class="card-body px-0 pt-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Fornitore</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Descrizione</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Imponibile</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(costItem, index) in itemData.Costs.CostList" :key="index">
                      <td :class="[costItem.DontSum ? 'text-decoration-line-through' : '']">
                        <div class="d-flex px-3 py-0">
                          <div class="d-flex flex-column justify-content-center">
                            <h6 class="mb-0 text-sm">{{trimString(costItem.Supplier, 28)}}</h6>
                            <p class="text-sm font-weight-normal text-secondary mb-0">{{costItem.DocumentNumber}} {{formatDate(costItem.DocumentDate)}}</p>
                          </div>
                        </div>
                      </td>
                      <td :class="[costItem.DontSum ? 'text-decoration-line-through' : '']">
                        <p class="text-sm font-weight-normal mb-0" v-html="costItem.CostDescription"></p>
                      </td>
                      <td class="align-middle text-center text-sm" :class="[costItem.DontSum ? 'text-decoration-line-through' : '']">
                        <p class="text-sm font-weight-normal mb-0">{{costItem.MoneyFormatTaxable}} €</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            
          </div>
          <div class="card mt-4">
            <div class="d-flex">
              <div class="icon icon-shape icon-lg bg-gradient-dark shadow text-center border-radius-xl mt-n3 ms-4">
                <i class="material-icons opacity-10" aria-hidden="true">insights</i>
              </div>
              <h6 class="mt-3 mb-2 ms-3 ">Dettaglio Ricavi</h6>
            </div>
            <div class="card-body px-0 pt-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Cliente</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Descrizione</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Imponibile</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(revItem, index) in itemData.Revenues.RevenueList"  :key="index" >
                      <td :class="[revItem.DontSum ? 'text-decoration-line-through' : '']">
                        <div class="d-flex px-3 py-1">
                          <div class="d-flex flex-column justify-content-center" :class="[revItem.DontSum ? 'text-decoration-line-through' : '']">
                            <h6 class="mb-0 text-sm">{{trimString(revItem.Customer, 28)}}</h6>
                            <p class="text-sm font-weight-normal text-secondary mb-0">{{revItem.DocumentNumber}} {{formatDate(revItem.DocumentDate)}}</p>
                          </div>
                        </div>
                      </td>
                      <td :class="[revItem.DontSum ? 'text-decoration-line-through' : '']">
                        <p class="text-sm font-weight-normal mb-0" v-html="revItem.RevenueDescription"></p>
                      </td>
                      <td class="align-middle text-center text-sm" :class="[revItem.DontSum ? 'text-decoration-line-through' : '']">
                        <p class="text-sm font-weight-normal mb-0">{{revItem.MoneyFormatTaxable}} €</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="row mt-4">

      </div>
    </div>
</template>
<script>
import fetchFromAPI from "../../script/api.js"
import helpers from '../../script/helpers.js'
export default {
  data() {
    return {
      opportunityData: {},
      itemData:{},
      itemIndex:0
    };
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      let dateFormatted = new Intl.DateTimeFormat("default", { dateStyle: "long" }).format(date);
      if(dateFormatted != "1 gennaio 1"){
          return dateFormatted;
      }
      return "";
    },
    trimString(string, length){
        return helpers.trimString(string,length)
    },
    bindData(data, index){
        this.itemData = data[index]; 
        this.itemIndex = index;
    },
    showAlertTrue() {
      this.$swal.fire({
          title: 'Sicuro?',
          text: "La trattativa verrà approvata e sarà possibile chiuderla",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, vai!'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$swal.fire(
              'Ok fatto!',
              'Il backoffice la troverà tra le trattative approvate.',
              'success'
            )
            this.SaveApproval(1, "")
          }
        })
    },
    showAlertFalse(){
      const { value: text } = this.$swal.fire({
          input: 'textarea',
          inputLabel: 'Messaggio',
          inputPlaceholder: 'Motivo della richiesta di revisione',
          inputAttributes: {
            'aria-label': 'Motivo della richiesta di revisione'
          },
          showCancelButton: true
        }).then((result) => {
          if (result.isConfirmed) {
            this.SaveApproval(2, result.value)
            console.log(text);
          }
        })

    },
    /*SaveApproval(approvalStatus, approvalNotes){
        fetchFromAPI("ContractApproval/SaveApprovalStatus", { token:helpers.token(), Id: this.opportunityData[0].Id, ApprovalStatus: approvalStatus, Description:approvalNotes}, (res) => {
            if(approvalStatus == 1 || approvalStatus == 2){
                helpers.resetFilter('contractApprovalList')
                helpers.resetFilter('contractApprovalFilter')
            }
            this.$router.push('contractapprovallist?refresh=1') 
            console.log(res);

        });
    }*/
  },
  created() {
        let params = new URL(document.location).searchParams;
        /*let commission = params.get("commission");
        let company = params.get("company");
        let stock = params.get("stock");*/
        let item = params.get("item");
        let token = helpers.token()
        let details = helpers.getFilters('contractApprovalList');
        if(details){
            this.opportunityData = details;
            this.itemData = details[0].CommissionChain[0];
        }
        else{
            fetchFromAPI("ContractApproval/LoadOpportunityData", { token:token, CommissionNumber: item }, (res) => {
                this.opportunityData = res;
                helpers.setObject('contractApprovalList',this.opportunityData);
                this.itemData = res[0].CommissionChain[0];
            });
        }
        /*fetchFromAPI("ContractApproval/LoadOpportunityData", { token:token, CommissionNumber: item }, (res) => {
        this.opportunityData = res;
        helpers.setObject('contractApprovalDetail',this.opportunityData);
        this.itemData = res[0].CommissionChain[0];*/
        //this.$router.push('/printablecontractapprovaldetail');
        window.open(this.$router.resolve({ path: '/printablecontractapprovaldetail' }).href, '_blank');

    }
  }
</script>
