<template>
    <div class="container-fluid py-4">   
        <div class="card-body p-4">
            <div class="row">
                <div class="col-md-2 mb-md-0 mb-4">
                    <div class="input-group input-group-dynamic">
                        <label class="form-label"></label>
                        <input class="form-control" aria-label="" placeholder="Targa"  type="text" v-model="filters.CommissionNumber">
                    </div>
                </div>
                <div class="col-md-2">
                  <div class="input-group input-group-dynamic">
                      <select v-model="filters.CompanyId" class="form-control" id="exampleFormControlSelect1" placeholder="Azienda" >
                          <option value="1">Trivellato Spa</option>
                          <option value="2">Trivellato VI</option>
                          <option value="6">Padovastar</option>
                      </select>
                      </div>
                </div>
                <div class="col-md-2">
                  <div class="input-group input-group-dynamic">
                      <select v-model="filters.StockMainType" class="form-control" id="exampleFormControlSelect1" placeholder="Tipo Stock" >
                          <option value="2">Usato</option>
                          <option value="1">Nuovo</option>
                      </select>
                      </div>
                </div>
                <!--<div class="col-md-2">
                  <div class="input-group input-group-dynamic">
                      <select v-model="filters.ApprovalStatus" class="form-control" id="exampleFormControlSelect1" placeholder="Stato Approvazione" >
                          <option value="0">Tutti</option>
                          <option value="3">Da approvare</option>
                          <option value="1">Approvata</option>
                          <option value="2">Revisione</option>
                      </select>
                      </div>
                </div>-->
                <div class="col-md-2">
                </div>
                <div class="col-md-2">
                <div class="card card-body card-plain d-flex flex-row">
                    <div class="input-group input-group-dynamic ">
                        <a class="float-right align-bottom btn bg-gradient-dark mb-0" @click="LoadData">
                           <i v-if="isInLoading" class="material-icons text-sm text-warning rotating">autorenew</i>
                            <i v-else class="material-icons text-sm">search</i>
                              &nbsp;&nbsp;Cerca
                        </a>
                    </div>
                </div>
                </div>
            </div>
        </div>

        <div class="row mt-4">
        <div class="col-12">
          <div class="card mb-4">
            <div class="card-header pb-0">
              <h6>Trattative con Richiesta Approvazione <span v-if="list.length > 0">| {{list.length}} Elementi</span></h6>
            </div>
            <div class="card-body px-0 pt-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Trattativa</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Margine</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Stato Approv.</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Note</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in list" :key="item.OpportunintyVehicleId">
                      <td>
                        <div class="d-flex px-3 py-1">
                          <div class="d-flex flex-column justify-content-center">
                            <h6 class="mb-0 text-sm"><router-link :to="{path:'/contractapprovaldetail', query: { item: item.OpportunintyVehicleId }}" class="text-secondary" exact-path ><i class="material-icons opacity-8 text-info text-sm">launch</i> <i class=" text-xs"> {{item.OpportunintyVehicleId}}</i></router-link></h6>
                            <span class="text-sm font-weight-normal text-secondary mb-0" v-for="(subItem, index) in item.CommissionChain" :key="subItem.CommissionDataHeader.ContractId">
                                <i class="small fa fa-hashtag" :class="'pl-' + index*2" aria-hidden="true"></i><small class="text-dark" >{{index+1}} </small>
                                <small v-html="'<strong> Venditore:</strong> ' + subItem.CommissionDataHeader.SellerName"></small>
                                <small v-html="'<strong> Cliente:</strong> ' + subItem.CommissionDataHeader.AccountDescription"></small>
                                <small v-html="'<strong> Commissione/Targa:</strong> ' + subItem.CommissionDataHeader.CommissionNumber"></small>
                                <small v-html="'<strong> Stock:</strong> ' + (subItem.CommissionDataHeader.StockMainType == 1 ? 'Nuovo' : 'Usato')"></small>
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p class="text-sm font-weight-normal mb-0">{{item.CommissionChain[0].OpportunityDetails.OpportunityMargin}} €</p>
                      </td>
                      <td class="align-middle text-center text-sm" v-if="item.OpportunityApprovalModel">
                        <i v-if="item.OpportunityApprovalModel.ApprovalStatus == 1"  class="material-icons text-success opacity-8" aria-hidden="true">done</i>
                        <i v-else-if="item.OpportunityApprovalModel.ApprovalStatus == 2"  class="material-icons text-warning opacity-8" aria-hidden="true">report_problem</i>
                        <i v-else  class="material-icons text-secondary opacity-8" aria-hidden="true">remove</i>
                      </td>
                      <td class="align-middle text-end" v-if="item.OpportunityApprovalModel">
                        <div class="d-flex px-3 py-1 justify-content-center align-items-center">
                          <small class="text-xs" v-if="item.OpportunityApprovalModel.ApprovalStatus == 1">Approvata il {{formatDate(item.OpportunityApprovalModel.CreatedDate)}}</small>
                          <p class="text-sm font-weight-normal mb-0">{{item.OpportunityApprovalModel.ApprovalNotes}}</p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import fetchFromAPI from "../../script/api.js"
import helpers from '../../script/helpers.js'
export default {
      data() {
         return {
         filters: {},
         list: [],
         isInLoading: false
      };
   },
   methods: {
        LoadData(){
          helpers.resetFilter('contractApprovalList')
          helpers.setObject('contractApprovalFilter',this.filters);
          this.isInLoading = true;
          fetchFromAPI("ContractApproval/LoadOpportunityDataList", this.filters, (res) => {
               this.list = res;
               this.isInLoading = false;
               helpers.setObject('contractApprovalList', res);
        });

      },
      parseVehicleId(json){
         return JSON.parse(json).VehicleId
      },
      parseStock(json){
         return JSON.parse(json).StockMainType
      },
      parseCompany(json){
         return JSON.parse(json).Company
      },
      formatDate(date){
        return helpers.formatDate(date);
      },
     },
   created() {
        let filterData = helpers.getFilters('contractApprovalFilter');
        if (filterData && typeof filterData !== "undefined") {
            this.filters = filterData

            let data = helpers.getFilters('contractApprovalList');
              if (data && typeof data !== "undefined") {
                this.list = data;
            }
            return;
        }
        fetchFromAPI("ContractApproval/BindFiltersForHub", { Token : helpers.token() }, (res) => {
          this.filters = res;
            this.LoadData()
        })
    }
}
</script>