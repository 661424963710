<template>
    <link rel="stylesheet" href="https://unpkg.com/vue-select@latest/dist/vue-select.css">
    <nav class="navbar navbar-transparent navbar-expand-lg top-0 z-index-3 shadow-none position-absolute my-3 py-2 start-0 end-0 mx-4">
        <div class="container-fluid ps-2 pe-0">
            <a class="navbar-brand align-items-center font-weight-bolder ms-lg-0 me-2 text-dark" href="/">
                <i class="fa fa-atom opacity-9 text-dark me-1" aria-hidden="true"></i>
                Trivellato Hub 
            </a>
        </div>
    </nav>
    <div class="container-fluid all-small no-scroll-content p-6">
        <div class="row no-scroll-content">
            <h4>Gestione Tipologie</h4>
            <div class="col-sm-4 no-scroll-content">
                <div class="row m-0">
                    <div class="col-12 m-0 p-0">
                        <button class="btn bg-gradient-dark text-white px-3 py-2 mb-2 col-6" @click="manageTypes('documento')">
                            Documenti
                        </button>
                        <button class="btn bg-gradient-dark text-white px-3 py-2 float-sm-end mb-2 col-6" @click="manageTypes('documento combinato')">
                            Combinati
                        </button>
                    </div>
                </div>
                <div class="row m-0">
                    <button class="btn bg-gradient-dark text-white px-3 py-2 float-sm-end mb-2" @click="manageTypes('pratica')">
                        Clienti
                    </button>
                </div>
                <div class="row m-0">
                    <button class="btn bg-gradient-dark text-white px-3 py-2 float-sm-end mb-4" @click="manageTypes('pagamento')">
                        Pagamenti
                    </button>
                </div>
                <div class="card mh-100 px-0" style="height: 70%">
                    <div class="card-body overflow-y-auto overflow-x-hidden">
                        <div class="row justify-content-between align-items-center mb-2">
                            <div class="col-sm-6"><h4 class="">Associazioni</h4></div>
                            <div class="col-sm-6">
                                <button class="btn bg-gradient-info text-white px-3 py-1 m-0 float-sm-end" @click="createNewAssociation()">
                                    <span class="material-icons material-symbols-outlined">add</span>
                                    Nuova associazione
                                </button>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table mh-100 scrollbar-thin table-hover mw-100">
                                <thead>
                                    <tr>
                                        <th class="text-uppercase text-secondary text-end font-weight-bolder px-1">Tipo di cliente</th>
                                        <th class="text-uppercase text-secondary text-center font-weight-bolder px-1">|</th>
                                        <th class="text-uppercase text-secondary font-weight-bolder px-1">Tipo di pagamento</th>
                                    </tr>
                                </thead>
                                <tbody v-if="associations && associations.length > 0">
                                    <tr v-for="item in associations" :key="item.Id" class="cursor-pointer hover-bg-trivellato" @click="selectItem(item)">
                                        <td class="px-1 text-end">{{ item.DossierType.Value }}</td>
                                        <td class="px-1 text-center"><span class="material-icons material-symbols-outlined">link</span></td>
                                        <td class="px-1">{{ item.PaymentType.Value }}</td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="3" class="text-center">
                                            <i class="spinner-border text-dark align-middle" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </i>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-8 no-scroll-content px-4 pb-5">
                <div class="card mh-100" v-if="selectedItem">
                    <div class="card-body overflow-auto">
                        <div>
                            <h3 v-if="selectedItem.Id==0" class="mb-4">Creazione nuova associazione</h3>
                            <h3 v-else class="mb-4">Modifica associazione</h3>
                        </div>
                        <div class="form-group d-flex flex-column align-items-start">
                            <div class="input-group input-group-static my-2 trivellato">
                                <label class="text-uppercase text-secondary font-weight-bolder">Tipo di cliente</label>
                                <select class="form-control" v-model="selectedItem.DossierType">
                                    <option v-for="typ in filters.DataTypeList" :key="typ.Key" :value="typ">{{ typ.Value }}</option>
                                </select>
                            </div>
                            <div class="input-group input-group-static my-2 trivellato">
                                <label class="text-uppercase text-secondary font-weight-bolder">Tipo di pagamento</label>
                                <select class="form-control" v-model="selectedItem.PaymentType">
                                    <option v-for="typ in filters.ManagedList" :key="typ.Key" :value="typ">{{ typ.Value }}</option>
                                </select>
                            </div>
                        </div>
                        <table class="table mh-100 scrollbar-thin align-items-center mt-4">
                            <thead>
                                <tr>
                                    <th class="text-uppercase text-secondary font-weight-bolder px-0">Tipologie di documento richiesti</th>
                                </tr>
                                <tr></tr>
                            </thead>
                            <tbody v-if="selectedItem.RequestedDocs">
                                <tr v-for="item in selectedItem.RequestedDocs" :key="item.Id">
                                    <td>{{ item.TypeName }}</td>
                                    <td class="col-md-1">
                                        <button @click="removeTempAssociation(item)" class="btn btn-icon-only btn-rounded btn-outline-danger mb-0 me-2 btn-sm d-flex align-items-center justify-content-center">
                                            <i class="material-icons text-sm" aria-hidden="true">clear</i>
                                        </button>
                                    </td>
                                </tr>
                                <tr v-if="isAdding">
                                    <td class="input-group input-group-static col-md-3">
                                        <select class="form-control" v-model="newDossierDocType" @change="onSelectChange($event)">
                                            <option v-for="typ in filters.GroupTypeList" :key="typ.Key" :value="typ">{{ typ.Value }}</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <div class="p-2">
                                        <button @click="addDossierDocType()" class="btn btn-icon-only btn-rounded btn-outline-success mb-0 me-2 btn-sm d-flex align-items-center justify-content-center">
                                            <i class="material-icons text-sm" aria-hidden="true">add</i>
                                        </button>
                                    </div>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="3" class="text-center">
                                        <i class="spinner-border text-dark align-middle" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="buttons-container mt-4 ">
                            <button class="btn bg-gradient-success mx-1" @click="saveAssociation()">Salva</button>
                            <button class="btn bg-gradient-secondary mx-1" @click="duplicateAssociation()">Duplica</button>
                            <button class="btn bg-gradient-light mx-1 float-end" @click="refreshItem()">Annulla</button>
                            <button class="btn bg-gradient-danger mx-1 float-end" @click="deleteAssociation()">Elimina</button>
                        </div>
                    </div>
                </div>
                <div v-if="typeManage" class="card mh-100">
                    <div class="card-body overflow-auto">
                        <h3 class="mb-4">Gestione tipologie di {{ (managedType == "pratica" ? "cliente" : managedType) }}</h3>
                        <table class="table mh-100 scrollbar-thin align-items-center">
                            <thead>
                                <tr>
                                    <th class="text-uppercase text-secondary font-weight-bolder px-0">Tipi di {{ (managedType == "pratica" ? "cliente" : managedType) }}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody v-if="typesList">
                                <tr v-for="item in typesList" :key="item.Key">
                                    <div v-if="typeToSave==item && managedType != 'documento combinato'">
                                        <td class="input-group input-group-static">
                                            <input type="text" v-model="item.Value" class="form-control">
                                        </td>
                                        <td>
                                            <button @click="saveType(managedType)" class="btn m-0 p-0 px-1">
                                                <i class="material-icons text-success">check</i>
                                            </button>
                                            <button @click="cancelType()" class="btn m-0 p-0">
                                                <i class="material-icons text-danger">clear</i>
                                            </button>
                                        </td>
                                    </div>
                                    <div v-else>
                                        <td>{{ item.Value }}</td>
                                        <td class="col-md-1">
                                            <button v-if="managedType == 'documento combinato'" class="btn btn-outline-secondary text-secondary px-3 py-1 m-0 mx-2" @click="modifyComboType(item)" data-bs-toggle="modal" data-bs-target="#EditComboTypeModal">
                                                Modifica
                                            </button>
                                            <button v-else class="btn btn-outline-secondary text-secondary px-3 py-1 m-0 mx-2" @click="renameType(item)">
                                                Rinomina
                                            </button>
                                            <button class="btn btn-outline-danger text-danger px-3 py-1 m-0" @click="deleteType(item.Key,managedType)">
                                                Elimina
                                            </button>
                                        </td>
                                    </div>
                                </tr>
                                <tr v-if="isAddingType">
                                    <td class="input-group input-group-static col-md-3">
                                        <input type="text" v-model="typeToSave.Value" class="form-control">
                                    </td>
                                    <td>
                                        <button @click="saveType(managedType)" class="btn m-0 p-0 px-1">
                                            <i class="material-icons text-success">check</i>
                                        </button>
                                        <button @click="cancelType()" class="btn m-0 p-0">
                                            <i class="material-icons text-danger">clear</i>
                                        </button>
                                    </td>
                                </tr>
                                <tr v-if="!isAddingType">
                                    <div class="p-2">
                                        <button v-if="managedType == 'documento combinato'" class="btn bg-gradient-success text-white px-3 py-2 m-0" @click="addComboType()" data-bs-toggle="modal" data-bs-target="#EditComboTypeModal">
                                            Crea nuova combinazione
                                        </button>
                                        <button v-else class="btn bg-gradient-success text-white px-3 py-2 m-0" @click="addType()">
                                            Aggiungi nuovo tipo
                                        </button>
                                    </div>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="3" class="text-center">
                                        <i class="spinner-border text-dark align-middle" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="EditComboTypeModal" tabindex="-1" role="dialog" aria-labelledby="EditComboTypeModalLabel" aria-hidden="true">
            <div class="modal-dialog" style="max-width: 50rem;">
                <div class="modal-content p-4">
                    <button type="button" class="btn-close top-right-corner py-4 px-5" data-bs-dismiss="modal" aria-label="Close">
                    <span class="material-icons material-symbols-outlined light-dark">close</span>
                    </button>
                    <div class="row text-center">
                        <table class="table mh-100 scrollbar-thin align-items-center mt-4">
                            <thead>
                                <tr>
                                    <th class="text-uppercase text-secondary font-weight-bolder px-0">Documenti combinati</th>
                                </tr>
                                <tr></tr>
                            </thead>
                            <tbody v-if="subTypesList">
                                <tr v-for="item in subTypesList" :key="item.Key">
                                    <td>{{ item.Value }}</td>
                                    <td class="col-md-1">
                                        <button @click="removeTempSubType(item)" class="btn btn-icon-only btn-rounded btn-outline-danger mb-0 me-2 btn-sm d-flex align-items-center justify-content-center">
                                            <i class="material-icons text-sm" aria-hidden="true">clear</i>
                                        </button>
                                    </td>
                                </tr>
                                <tr v-if="isAdding">
                                    <td class="input-group input-group-static col-md-3">
                                        <select class="form-control" v-model="typeToCombo" @change="onSelectSubTypeChange($event)">
                                            <option v-for="typ in filters.GroupTypeList" :key="typ.Key" :value="typ">{{ typ.Value }}</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <div class="p-2">
                                        <button @click="addDossierDocType()" class="btn btn-icon-only btn-rounded btn-outline-success mb-0 mx-auto btn-sm d-flex align-items-center justify-content-center">
                                            <i class="material-icons text-sm" aria-hidden="true">add</i>
                                        </button>
                                    </div>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="3" class="text-center">
                                        <i class="spinner-border text-dark align-middle" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="d-flex justify-content-end mt-4 px-4">
                            <button @click="saveType(managedType)" type="button" name="button" class="btn bg-gradient-success m-0 ms-2 shadow-dark" data-bs-dismiss="modal">
                                <i v-if="isInLoading" class="material-icons text-sm text-warning rotating">autorenew</i>
                                <i v-else class="material-icons text-sm">save</i>
                                &nbsp;&nbsp;Salva
                            </button>
                            <button type="button" name="button" class="btn bg-gradient-secondary m-0 ms-2 shadow-dark" data-bs-dismiss="modal">
                                <i v-if="isInLoading" class="material-icons text-sm text-warning rotating">autorenew</i>
                                <i v-else class="material-icons text-sm">delete</i>
                                &nbsp;&nbsp;Annulla
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import fetchFromAPI from "../../script/api.js"
import helpers from '../../script/helpers.js'

export default {
    data() {
        return {
            filters: {},
            associations: [],
            typesList: [],
            subTypesList: [],
            selectedItem: null,
            tempItem: null,
            typeManage: false,
            isAdding: false,
            isAddingType: false,
            isRenamingType: 0,
            newDossierDocType: {},
            dossierType: {},
            paymentType: {},
            typeToSave: {
                Key: 0,
                Value: ""
            },
            typeToCombo: {},
            managedType: ""
        }
    },

    methods: {
        LoadData() {
            fetchFromAPI("CustomerDocumentsSpace/BindFiltersForHub", { Token: helpers.token() }, (res) => {
                this.filters = res;
                this.setTypeToManage(this.managedType);
            });
            fetchFromAPI("CustomerDocumentsSpace/GetDossierAssociation", { Token: helpers.token() }, (res) => {
                this.associations = res;
            });
        },
        createNewAssociation() {
            var n = {
                DossierType: {},
                PaymentType: {},
                RequestedDocs: []
            };
            this.typeManage = false;
            this.selectedItem = n;
            this.tempItem = n;
        },
        selectItem(item) {
            this.isAdding = false;
            this.typeManage = false;
            this.newDossierDocType = {};
            item.Token = helpers.token();
            this.tempItem = item;
            fetchFromAPI("CustomerDocumentsSpace/GetRequestedDocs", item, (res) => {
                var x = {
                    DossierType: item.DossierType,
                    PaymentType: item.PaymentType,
                    RequestedDocs: res
                };
                this.selectedItem = x;
            }, (err) => {
                console.error("Something went wrong fetching data. Error: " + err);
            });
        },
        addDossierDocType(){
            this.typeToCombo = {
                Key: 0,
                Value: ""
            };
            this.isAdding = true;
        },
        onSelectChange(){
            var newType = {
                Id: this.newDossierDocType.Key,
                TypeName: this.newDossierDocType.Value
            }
            this.selectedItem.RequestedDocs.push(newType);
            this.isAdding = false;
            this.newDossierDocType = {};
        },
        onSelectSubTypeChange(){
            var newType = {
                Key: this.typeToCombo.Key,
                Value: this.typeToCombo.Value
            }
            this.subTypesList.push(newType);
            this.isAdding = false;
            this.newDossierDocType = {};
        },
        removeTempAssociation(item){
            var newList = helpers.removeItemFromList(this.selectedItem.RequestedDocs, item);
            this.selectedItem.RequestedDocs = newList;
        },
        removeTempSubType(item){
            var newList = helpers.removeItemFromList(this.subTypesList, item);
            this.subTypesList = newList;
        },
        saveAssociation() {
            fetchFromAPI("CustomerDocumentsSpace/SaveDossierAssociation", {Token: helpers.token(), OldAssociation: this.tempItem, NewAssociation: this.selectedItem}, () => {
                this.LoadData();
                this.$toast.success("Modifiche salvate");
            });
        },
        deleteAssociation() {
            this.$swal.fire({
                title: 'Eliminare associazione?',
                text: 'L\'operazione non sarà reversibile, procedere?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.selectedItem.Token = helpers.token();
                    fetchFromAPI("CustomerDocumentsSpace/DeleteDossierAssociation", this.selectedItem, () => {
                        this.selectedItem = null;
                        this.LoadData();
                        this.$toast.success("Associazione eliminata");
                    });
                    }
            })
        },
        duplicateAssociation(){
            fetchFromAPI("CustomerDocumentsSpace/SaveDossierAssociation", {Token: helpers.token(), OldAssociation: null, NewAssociation: this.selectedItem}, (res) => {
                this.LoadData();
                this.selectedItem = res;
                this.$toast.success("Associazione duplicata");
            });
        },
        refreshItem(){
            this.LoadData();
            this.selectedItem = null;
            this.typeManage = false
        },

        setTypeToManage(type){
            if(type == "documento") {
                this.typesList = this.filters.GroupTypeList; // lista dei tipi di documento
                this.managedType = "documento";
            }
            if(type == "documento combinato") {
                this.typesList = this.filters.EntityList; // lista dei tipi di documento
                this.managedType = "documento combinato";
            }
            if(type == "pratica") {
                this.typesList = this.filters.DataTypeList; // lista dei tipi di pratica
                this.managedType = "pratica";
            }
            if(type == "pagamento") {
                this.typesList = this.filters.ManagedList; // lista dei tipi di pagamento
                this.managedType = "pagamento";
            }
        },
        manageTypes(type) {
            this.selectedItem = null;
            this.typeManage = true;
            this.setTypeToManage(type);
        },
        addType(){
            this.typeToSave = {
                Key: 0,
                Value: ""
            };
            this.isRenamingType= 0;
            this.isAddingType= true;
        },
        addComboType(){
            this.typeToCombo = {
                Key: 0,
                Value: ""
            };
            this.typeToSave = {
                Key: 0,
                Value: ""
            };
            this.subTypesList = [];
            this.isRenamingType= 0;
            this.isAddingType= false;
            this.isAdding = false;
        },
        renameType(item){
            this.typeToSave = item;
            this.isAddingType= false;
        },
        cancelType(){
            this.typeToSave = {
                Key: 0,
                Value: ""
            };
            this.isAddingType = false;
        },
        saveType(type){
            if(type == "documento combinato"){
                var name = "";
                for(var i=0; i<this.subTypesList.length; i++){
                    name += this.subTypesList[i].Value;
                    if((i+1)<this.subTypesList.length) name += " + ";
                }
                this.typeToSave.Value = name;
            }
            fetchFromAPI("CustomerDocumentsSpace/SaveType", { Token: helpers.token(), TypeToSave: this.typeToSave, TypeToManage: type, SubTypes: this.subTypesList }, () => {
                this.isAddingType = false;
                this.isRenamingType = 0;
                this.LoadData();
                this.$toast.success("Tipo "+type+" salvato");
                this.typeManage = true;
            });
        },
        deleteType(id,type){
            this.$swal.fire({
                title: "Eliminare tipo "+type+"?",
                text: 'Questo lo rimuoverà anche dalle relative associazioni, procedere?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si'
            }).then((result) => {
                if (result.isConfirmed) {
                    fetchFromAPI("CustomerDocumentsSpace/DeleteType", { Token: helpers.token(), Id: id, TypeToManage: type }, () => {
                        this.LoadData();
                        this.$toast.success("Tipo "+type+" eliminato");
                        this.typeManage = true;
                    });
                }
            })
        },
        modifyComboType(item){
            fetchFromAPI("CustomerDocumentsSpace/GetDocumentSubTypes", { Token: helpers.token(), Id: item.Key }, (res) => {
                this.subTypesList = res;
                console.log(this.subTypesList);
                this.typeToSave = item;
                this.isRenamingType= 0;
                this.isAddingType = false;
                this.isAdding = false;
                this.typeToCombo = {
                    Key: 0,
                    Value: ""
                };
            });
        }
    },
    created() {
        this.LoadData();
    }
}
</script>

<style scoped>
#app {
    max-height: 100vh;
}

.container-fluid {
    max-height: calc(100vh - 100px);
}

::-webkit-scrollbar {
    width: 6px;
    border-radius: 10px;
}

.is-selected {
    background-color: rgb(149, 216, 238);    
}

</style>