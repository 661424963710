<template>
<!-- Navbar Light -->

<nav
  class="navbar navbar-expand-lg navbar-light bg-white z-index-3 py-3">
  <div class="container">
    <a class="navbar-brand" href="https://www.trivellato.it" rel="tooltip" title="Designed and Coded by Trivellato Spa" data-placement="bottom" target="_blank">
    Trivellato SpA - Gestione Documentale clienti
    </a>
  </div>
</nav>
<!-- End Navbar  -->
<div  v-if="!list || !list.length" class="row justify-content-center">
    <div class="spinner-grow mx-auto my-8" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>
    <div v-if="list && list.length" class="container-fluid py-6">
        <div class="row justify-content-center">
        <div class="col-lg-10">
            <div class="card">
            <div class="table-responsive">

                <table class="table align-items-center mb-0">
                <thead>
                    <tr>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Firmatario</th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Documento</th>
                    <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Tipo FEA</th>
                    <th class="text-secondary opacity-7"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in list" :key=index>
                    <td>
                        <div class="d-flex px-2 py-1">
                        <div>
                            <img v-if="item.FEAType == 1" src="../../assets/img/fea.jpg" class="avatar avatar-sm me-3">
                            <img v-else src="../../assets/img/handshake.jpg" class="avatar avatar-sm me-3">
                        </div>
                        <div class="d-flex flex-column justify-content-center">
                            <h6 class="mb-0 text-xs">{{item.SignNameAndSurname}}</h6>
                            <p class="text-xs text-secondary mb-0">{{item.CustomerFiscalCode}}</p>
                        </div>
                        </div>
                    </td>
                    <td>
                        <p class="text-xs font-weight-bold mb-0">{{item.AttachmentModel.PublicFileName}}</p>
                        <p class="text-xs text-secondary mb-0">{{formatDate(item.CreatedDate)}}</p>
                    </td>
                    <td class="align-middle text-center text-sm">
                        <span v-if="item.FEAType == 1" class="badge badge-sm badge-info">Sottoscrizione</span>
                        <span v-else-if="item.FEAType == 2" class="badge badge-sm badge-success">Contratto</span>
                        <span v-else-if="item.FEAType == 3" class="badge badge-sm badge-secondary">Proposta Irrevocabile di Vendita</span>
                    </td>
                    <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold">
                            <a target="_blank" v-if="item.AttachmentModel"  :href="'https://sa.trivellato.it' + item.AttachmentModel.VirtualFilePath" :download="item.AttachmentModel.PublicFileName">Scarica</a>
                        </span>
                    </td>
                    </tr>
                </tbody>
                </table>
            </div>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
import fetchFromAPI from "../../script/api";

export default {
  data() {
    return {
      list: [],
    };
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat("default", { dateStyle: "long" }).format(date);
    },
  },
  mounted() {

    let params = new URL(document.location).searchParams;
    let guid = params.get("guid");
    let sid = params.get("SID");
    fetchFromAPI("FEAWebHook/SignedDocumentsByUserToken", { guid: guid, JobId: sid }, (res) => {
      this.list = res;
      console.log(res);
    });
  },
};
</script>