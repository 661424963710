<template>
<div class="container-fluid py-4">   
<div class="row mb-4">
   <div class="col-8">
      <div class="card bg-gradient-dark">
         <div class="card-body">
            <div class="d-flex align-items-center mb-sm-0 mb-4">
               <div class="w-70" style="">
                  <h5 class="align-top text-white">Lancia elaborazione mensile ({{filters.CarData}})</h5>
                  <i class="material-icons align-text-bottom text-info">info</i> <span class="text-sm pr-4 mb-0 text-light">Seleziona mese-anno e lancia. Verranno cancellati tutti i dati dello stesso mese-anno se presente e verrà rieseguito il calcolo ripartizioni. <br/> &nbsp;&nbsp;&nbsp; processo completato riceverai una mail.</span>
               </div>
               <div class="w-30 text-end">
                  <div class="mb-4">
                     <div class="input-group input-group-dynamic"><input v-model="filters.CarData" type="month" class="form-control text-white"/></div>
                  </div>
                  <a :class="{'disabled': isInLoading2 || MonthstatusData.StampsProcessingStatus === 1}" class="float-right align-bottom btn bg-gradient-warning mb-0" @click="GoToHackModal(filters)">
                     <i v-if="isInLoading2 || MonthstatusData.StampsProcessingStatus === 1" class="material-icons text-sm text-dark rotating">autorenew</i>
                        <i v-else class="material-icons text-sm">flash_on</i>
                        &nbsp;&nbsp;Esegui
                  </a>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="col-4 mt-md-0 mt-4">
   <div class="card h-100 mb-4">
      <div class="card-header pb-0 py-2 px-3">
         <div class="row">
            <div class="col-md-6">
               <h6 class="mb-0">Stato Processi</h6>
            </div>
            <div class="col-md-6 d-flex justify-content-start justify-content-md-end align-items-center">
               <i class="material-icons me-2 text-lg">date_range</i>
               <small>{{filters.CarData}}</small>
            </div>
         </div>
      </div>
      <div class="card-body pt-0 pb-0 p-3">
         <ul v-if="!isInLoading3 && MonthstatusData" class="list-group">
            <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-0 border-radius-lg">
               <div class="d-flex align-items-center">
                  <button v-if="MonthstatusData.StampsProcessingStatus === 3" class="btn btn-icon-only btn-rounded btn-outline-success mb-0 me-3 p-3 btn-sm d-flex align-items-center justify-content-center"><i class="material-icons text-lg">done</i></button>
                  <button v-if="MonthstatusData.StampsProcessingStatus === 2" class="btn btn-icon-only btn-rounded btn-outline-danger mb-0 me-3 p-3 btn-sm d-flex align-items-center justify-content-center"><i class="material-icons text-lg">error</i></button>
                  <button v-if="MonthstatusData.StampsProcessingStatus === 1" class="btn btn-icon-only btn-rounded btn-outline-warning mb-0 me-3 p-3 btn-sm d-flex align-items-center justify-content-center"><i class="material-icons text-lg">warning</i></button>
                  <button v-if="MonthstatusData.StampsProcessingStatus === 0" class="btn btn-icon-only btn-rounded btn-outline-secondary mb-0 me-3 p-3 btn-sm d-flex align-items-center justify-content-center"><i class="material-icons text-lg">remove</i></button>
                  <div class="d-flex flex-column">
                     <h6 class="mb-1 text-dark text-sm">Elaborazione {{ filters.CarData }}</h6>
                     <span v-if="MonthstatusData.StampsProcessingStatus>0" class="text-xs">data processo: {{formatDate(MonthstatusData.CreatedDate, "full")}} | <b>{{ MonthstatusData.LaunchedByNameAndSurname }}</b></span>
                     <span v-else class="text-xs">Non ancora eseguita</span>
                  </div>
               </div>
               <div v-if="MonthstatusData.StampsProcessingStatus === 3" class="d-flex align-items-center text-success text-gradient text-sm font-weight-bold">
                  {{MonthstatusData.StampProcessingStatusDescriprion}}
               </div>
               <div v-if="MonthstatusData.StampsProcessingStatus === 2" class="d-flex align-items-center text-danger text-gradient text-sm font-weight-bold">
                  {{MonthstatusData.StampProcessingStatusDescriprion}}
               </div>
               <div v-if="MonthstatusData.StampsProcessingStatus === 1" class="d-flex align-items-center text-warning text-gradient text-sm font-weight-bold">
                  {{MonthstatusData.StampProcessingStatusDescriprion}}
               </div>
               <div v-if="MonthstatusData.StampsProcessingStatus === 0" class="d-flex align-items-center text-secondary text-gradient text-sm font-weight-bold">
                  {{MonthstatusData.StampProcessingStatusDescriprion}}
               </div>
            </li>
            <hr class="dark mt-0 mb-1" v-if="MonthstatusData.Id>0 && MonthstatusData.StampsHackExtraHoursRecapModelList.length">
            <li v-if="MonthstatusData.Id>0 && MonthstatusData.StampsHackExtraHoursRecapModelList.length" class="list-group-item border-0 d-flex ps-0 mb-0 border-radius-lg text-sm"> <a target='_blank' :href="'/zucchettiextrahoursgrouping?period='+filters.CarData" class="text-info"><i class="material-icons text-lg mb-0 text-align-center">link</i>&nbsp; Vai al riepilogo degli straordinari</a></li>
         </ul>
         <div v-else class="spinner-border text-default mt-2" role="status">
            <span class="sr-only">Loading...</span>
         </div>
      </div>
   </div>
</div>
</div>
   <div class="card-body p-4">
      <div class="row">
      <div class="col-md-3 mb-md-0 mb-4">
      <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
         <div class="input-group input-group-dynamic">
            <label class="form-label"></label>
            <input class="form-control" aria-label="" placeholder="Mario Rossi"  type="text" v-model="filters.NameAndSurname">
         </div>
      </div>
      </div>
      <div class="col-md-3">
      <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
         <div class="input-group input-group-dynamic">
         <div class="input-group input-group-dynamic mb-4">
            <select v-model="filters.Description" class="form-control p-2" id="exampleFormControlSelect1">
               <option value="000001">Trivellato Spa</option>
               <option value="000003">Trivellato V.I.</option>
               <option value="000002">Trivellato Partecipazioni</option>
               <option value="000004">City Car</option>
               <option value="000005">Padova Star</option>
               <option value="000006">Atlante</option>
            </select>
         </div>
         </div>
      </div>
      </div>
      <div class="col-md-3">
      <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
         <div class="input-group input-group-dynamic"><input v-model="filters.ActionDescription" type="month" class="form-control"/></div>
      </div>
      </div>
      <div class="col-md-3">
      <div class="card card-body card-plain d-flex align-items-center flex-row">
         <div class="input-group input-group-dynamic ">
            <a class="float-right align-bottom btn bg-gradient-dark mb-0" @click="LoadData()">
               <i v-if="isInLoading" class="material-icons text-sm text-warning rotating">autorenew</i>
                  <i v-else class="material-icons text-sm">search</i>
                  &nbsp;&nbsp;Cerca
            </a>
         </div>
      </div>
      </div>
   </div>
</div>
<div class="row mb-4" v-if="list && list.Stamps && list.Stamps.length>0">
   <div class="col-sm-4">
      <div class="card">
      <div class="card-body p-3 position-relative">
         <div class="row">
            <div class="col-7 text-start">
            <p class="text-sm mb-1 text-capitalize font-weight-bold">Straordinari originali</p>
            <h5 class="font-weight-bolder mb-0">
               Totale {{list.TotalOriginalExtraHours}}h
            </h5>
            <span class="text-sm text-end font-weight-bolder mt-auto mb-0" :class="AnalyticsDataCss(list.OriginalAnalyticsData)">{{list.OriginalAnalyticsData}} <span class="font-weight-normal text-secondary">rispetto al mese scorso.</span></span>
            </div>
            <div class="col-5">
            <div class="dropdown text-end">
               <a href="javascript:;" class="cursor-pointer text-secondary" id="dropdownUsers2" data-bs-toggle="dropdown" aria-expanded="false">
                  <span class="text-xs text-secondary">{{filters.ActionDescription}}</span>
               </a>
            </div>
            </div>
         </div>
      </div>
      </div>
   </div>
   <div class="col-sm-4 mt-sm-0 mt-4">
   <div class="card">
      <div class="card-body p-3 position-relative">
         <div class="row">
            <div class="col-7 text-start">
            <p class="text-sm mb-1 text-capitalize font-weight-bold">Straordinari elaborati</p>
            <h5 class="font-weight-bolder mb-0">
               Totale {{list.TotalExtraHours}}h
            </h5>
            <span class="text-sm text-end font-weight-bolder mt-auto mb-0" :class="AnalyticsDataCss(list.AnalyticsData)">{{list.AnalyticsData}} <span class="font-weight-normal text-secondary">rispetto al mese scorso.</span></span>
            </div>
            <div class="col-5">
            <div class="dropdown text-end">
               <a href="javascript:;" class="cursor-pointer text-secondary" id="dropdownUsers1" data-bs-toggle="dropdown" aria-expanded="false">
                  <span class="text-xs text-secondary">{{filters.ActionDescription}}</span>
               </a> 
            </div>
            </div>
         </div>
      </div>
      </div>
   </div>
   <div class="col-sm-4 mt-sm-0 mt-4">
      <div class="card">
      <div class="card-body p-3 position-relative">
         <div class="row">
            <div class="col-7 text-start">
            <p class="text-sm mb-1 font-weight-bold">...di cui al sabato</p>
            <h5 class="font-weight-bolder mb-0">
               Totale {{list.SaturdayHours}}h
            </h5>
            <span class="font-weight-normal text-secondary text-sm"><span class="font-weight-bolder" :class="AnalyticsDataCss(list.SaturdayAnalyticsData)">{{list.SaturdayAnalyticsData}} </span> rispetto al mese scorso.</span>
            </div>
            <div class="col-5">
            <div class="dropdown text-end">
               <a href="javascript:;" class="cursor-pointer text-secondary" id="dropdownUsers3" data-bs-toggle="dropdown" aria-expanded="false">
                  <span class="text-xs text-secondary">{{filters.ActionDescription}}</span>
               </a>
            </div>
            </div>
         </div>
      </div>
      </div>
   </div>
</div>
<!--<div class="row">
   <div class="col-lg-6 col-sm-4 mt-sm-0 mb-4">
      <div class="card">
      <div class="card-header pb-0 p-3">
         <div class="d-flex justify-content-between">
            <h6 class="mb-0">Revenue</h6>
            <button type="button" class="btn btn-icon-only btn-rounded btn-outline-secondary mb-0 ms-2 btn-sm d-flex align-items-center justify-content-center" data-bs-toggle="tooltip" data-bs-placement="left" data-bs-original-title="See which ads perform better">
            <i class="material-icons text-sm">priority_high</i>
            </button>
         </div>
         <div class="d-flex align-items-center">
            <span class="badge badge-md badge-dot me-4">
            <i class="bg-primary"></i>
            <span class="text-dark text-xs">Ore Mensili</span>
            </span>
         </div>
      </div>
      <div class="card-body p-3">
         <div class="chart">
            <canvas id="chart-line" class="chart-canvas" height="150"></canvas>
         </div>
      </div>
      </div>
   </div>
</div>-->
 <div class="row">
   <div class="col-12">
      <div class="card">
  <div class="table-responsive">
    <table class="table align-items-center mb-0">
      <thead>
        <tr>
         <th v-if="list.Stamps && list.Stamps.length" colspan="7" class="text-uppercase text-info text-xxs font-weight-bolder opacity-7">{{list.NameAndSurname}} | {{ filters.ActionDescription }}</th>
        </tr>
        <tr>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Oggetto</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Entrata 1</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Uscita 1</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Entrata 2</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Uscita 2</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Ore</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Straordinari</th>
        </tr>
      </thead>
      <tbody style="border-top:0px" v-for="(item, index) in list.Stamps" :key="index">
         <tr :class="{ 'bg-gray-100' : item.DayOfWeek == 0 }" >
            <td>
            <a :href="'#multistep_' + item.Id"  data-bs-toggle="collapse" role="button" aria-expanded="false" :aria-controls="'multistep_'+item.Id">
               <div class="d-flex px-2 py-1">
               <span data-bs-toggle="tooltip" data-bs-placement="top" :title="'Vedi Dettagli'">                    
                  <i class="collapse-close material-icons px-1 opacity-10" >
                     <span class="text-success" v-if="item.OriginalRow && item.OriginalRow.Id == 0">done</span>
                     <span class="text-info" v-else>move_up</span>
                  </i>
               </span>
                  <div class="d-flex flex-column justify-content-center">
                  <h6 :class="{ 'text-muted' : item.DayOfWeek == 0 }" class="mb-0 text-xs">{{formatDate(item.ObjectDay, "long")}}</h6>
                  <p class="text-xs text-secondary mb-0" :class="{ 'text-muted' : item.DayOfWeek == 0 }">{{Weekday[item.DayOfWeek]}} - Settimana {{item.ObjectYearWeek}}</p>
                  </div>
               </div>
               </a>

            </td>
            <td style="vertical-align:top!important">
               <p :class="{ 'text-muted' : item.DayOfWeek == 0, 'text-warning' : item.OriginalRow && item.OriginalRow.Id > 0 && item.Star1DateTime != item.OriginalRow.Star1DateTime }" :title="item.Star1TerminalCode + ' - ' + item.Star1TerminalDescription" class="text-xs font-weight-bold mb-0 align-top">{{formatHours(item.Star1DateTime, "")}}</p>
               <p v-if="item.OriginalRow && item.OriginalRow.Id > 0 && item.Star1DateTime != item.OriginalRow.Star1DateTime" class="text-xxs text-muted mb-0">{{formatHours(item.OriginalRow.Star1DateTime, "in origine: ")}}</p>
            </td>
            <td style="vertical-align:top!important">
               <p :class="{ 'text-muted' : item.DayOfWeek == 0, 'text-warning' : item.OriginalRow && item.OriginalRow.Id > 0 && item.End1DateTime != item.OriginalRow.End1DateTime }" :title="item.End1TerminalCode + ' - ' + item.End1TerminalDescription" class="text-xs font-weight-bold mb-0">{{formatHours(item.End1DateTime, "")}}</p>
               <p v-if="item.OriginalRow && item.OriginalRow.Id > 0 && item.End1DateTime != item.OriginalRow.End1DateTime" class="text-xxs text-muted mb-0">{{formatHours(item.OriginalRow.End1DateTime,"in origine: ")}}</p>
            </td>
            <td style="vertical-align:top!important">
               <p :class="{ 'text-muted' : item.DayOfWeek == 0, 'text-warning' : item.OriginalRow && item.OriginalRow.Id > 0 && item.Star2DateTime != item.OriginalRow.Star2DateTime }" :title="item.Star2TerminalCode + ' - ' + item.Star2TerminalDescription" class="text-xs font-weight-bold mb-0">{{formatHours(item.Star2DateTime, "")}}</p>
               <p v-if="item.OriginalRow && item.OriginalRow.Id > 0 && item.Star2DateTime != item.OriginalRow.Star2DateTime" class="text-xxs text-muted mb-0">{{formatHours(item.OriginalRow.Star2DateTime, "in origine: ")}}</p>
            </td>
            <td style="vertical-align:top!important">
               <p :class="{ 'text-muted' : item.DayOfWeek == 0, 'text-warning' : item.OriginalRow && item.OriginalRow.Id > 0 && item.End2DateTime != item.OriginalRow.End2DateTime }" :title="item.End2TerminalCode + ' - ' + item.End2TerminalDescription" class="text-xs font-weight-bold mb-0">{{formatHours(item.End2DateTime, "")}}</p>
               <p v-if="item.OriginalRow && item.OriginalRow.Id > 0 && item.End2DateTime != item.OriginalRow.End2DateTime" class="text-xxs text-muted mb-0">{{formatHours(item.OriginalRow.End2DateTime, "in origine: ")}}</p>
            </td>
            <td style="vertical-align:top!important">
               <p class="text-xs font-weight-bold mb-0" :class="{ 'text-muted' : item.DayOfWeek == 0, 'text-warning' : item.OriginalRow && item.OriginalRow.Id > 0 && item.WorkHours != item.OriginalRow.WorkHours }">{{item.WorkHours}}</p>
               <p v-if="item.OriginalRow && item.OriginalRow.Id > 0 && item.WorkHours != item.OriginalRow.WorkHours" class="text-xxs text-muted mb-0">{{(item.OriginalRow.WorkHours > 0 ? "in origine: " + item.OriginalRow.WorkHours : "")}}</p>
            </td>
            <td style="vertical-align:top!important">
               <p class="text-xs font-weight-bold mb-0" :class="{ 'text-muted' : item.DayOfWeek == 0, 'text-warning' : item.OriginalRow && item.OriginalRow.Id > 0 && item.ExtraHours != item.OriginalRow.ExtraHours }">{{item.ExtraHours}}</p>
               <p v-if="item.OriginalRow && item.OriginalRow.Id > 0 && item.ExtraHours != item.OriginalRow.ExtraHours" class="text-xxs mb-0 text-muted">{{(item.OriginalRow.ExtraHours > 0 ? "in origine: " + item.OriginalRow.ExtraHours : "")}}</p>
            </td>
         </tr>
         <tr v-if="item.StampsHackLogModels.length">
            <td class="p-0" colspan="7"> 
               <div class="collapse rounded bg-gray-300" :id="'multistep_'+item.Id">
                  <zucchettilog :model="item"></zucchettilog>
               </div>
            </td>
         </tr>
      </tbody>
    </table>
  </div>
</div>
   </div>
</div>
    </div>
</template>
<script>
import fetchFromAPI from "../../script/api.js"
import helpers from '../../script/helpers.js'
import zucchettilog from '../../components/ZucchettiLog.vue'
//import zucchettiExtraHoursRecap from '../../components/ZucchettiExtraHoursGrouping.vue'
export default {
   components: {
         zucchettilog,
         //zucchettiExtraHoursRecap
      },
      data() {
         return {
         filters: {},
         list: [],
         isInLoading: false,
         isInLoading2: false,
         isInLoading3: false,
         Weekday : ["Domenica","Lunedi","Martedi","Mercoledi","Giovedi","Venerdi","Sabato"],
         MonthstatusData:{}
      };

   },
   /*watch:{
      filters:{
         handler(val){
            this.LoadMonthStatus(val)
         },
         deep: true
      }
   },*/
   computed: {
    CarData() {
      return this.filters.CarData;
    }
  },
  watch: {
   CarData() {
      this.LoadMonthStatus(this.filters)
    }
   },
   methods: {
      LoadMonthStatus(val){
         this.isInLoading3 = true;
            fetchFromAPI("StampsHacks/LoadMonthstatusData", val, (res) => {
                  this.MonthstatusData = res;
                  this.isInLoading3 = false;
                  helpers.setObject('MonthstatusData', this.MonthstatusData);
            });
      },
      SetMonthStatusToElaboration(){
         this.MonthstatusData.StampsProcessingStatus = 1;
         this.MonthstatusData.StampProcessingStatusDescriprion = "Processo in Corso";
         this.MonthstatusData.StampsHackExtraHoursRecapModelList = []

      },
      AnalyticsDataCss(analyticsdata){
         if(analyticsdata.startsWith('-')){
            return 'text-success'
         }
         else{
            return 'text-danger'
         }

      },
      LoadData(){
          helpers.resetFilter('ZucchettiHackListFilter')
          helpers.setObject('ZucchettiHackListFilter',this.filters);
          if(!this.filters.NameAndSurname || !this.filters.NameAndSurname.length){
               return;
          }
          this.isInLoading = true;
          fetchFromAPI("StampsHacks/LoadHistoricData", this.filters, (res) => {
               this.list = res;
               this.isInLoading = false;
               helpers.setObject('ZucchettiHackList', res);
          });
      },
      formatDate(dateString, datestyle) {
        const date = new Date(dateString);
        let dateFormatted = new Intl.DateTimeFormat("default", { dateStyle: datestyle }).format(date);
        if(dateFormatted != "1 gennaio 1"){
            return dateFormatted;
        }
        return "";
      },
      formatHours(dateString, string) {
        const date = new Date(dateString);
        let dateFormatted = new Intl.DateTimeFormat("it", { timeStyle: "short" }).format(date);
        if(dateFormatted != "00:00"){
            return string + dateFormatted;
        }
        return "";
        
      },
      GoToHack(item){
         this.isInLoading2 = true;
          fetchFromAPI("StampsHacks/GoToHack", item, (res) => {
            if(res){
               this.MonthstatusData = res;
               console.log(res);
               this.isInLoading2 = false;
               this.LoadMonthStatus(item)
               this.$swal.fire(
                  'Ok Fatto',
                  'Dati del periodo '+item.CarData+' elaborati',
                  'success'
                  )
            }
          })
      },
      GoToHackModal(item){
        this.$swal.fire({
          title: 'Sicuro?',
          text: "Vuoi avviare il processo per il "+ item.CarData +"?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, vai!'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$swal.fire(
              'Partito!',
               'Processo avviato, riceverai una mail a processo completato.',
              'success'
            )
            this.GoToHack(item)
            this.SetMonthStatusToElaboration()
          }
        })
       },
   },
   created() {
         let filterData = helpers.getFilters('ZucchettiHackListFilter');
         if (filterData && typeof filterData !== "undefined") {
            this.filters = filterData;
            this.LoadData();
            return;
          }
          fetchFromAPI("StampsHacks/BindFiltersForHub", { Token : helpers.token() }, (res) => {
          this.filters = res;
          this.LoadData();
       });
   }

}

</script>