<template>
   <div v-if="filters" class="container-fluid py-4">
        <div class="card-body p-4">
            <div class="row" v-if="filters.GroupType==1 || filters.GroupType==2">
                <div class="col-md-3 mb-md-0 mb-4">
                <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                        <div class="input-group input-group-dynamic">
                        <input class="form-control" placeholder="glc, classe a, gle, classe c..." aria-label="" type="text" v-model="filters.CarData">
                    </div>
                </div>
                </div>
                  <div class="col-md-3">
                   <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                     <div class="input-group input-group-dynamic mb-4">
                        <select v-model="filters.StockMainType" class="form-control" id="exampleFormControlSelect1">
                           <option value="1">Nuovo</option>
                           <option value="2">Usato</option>
                        </select>
                     </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                     <div class="form-check">
                     <input class="form-check-input" v-model="filters.IsRed" type="checkbox" id="fcustomCheck1">
                     <label class="custom-control-label" for="customCheck1">Rosso</label>
                     </div>
                     <div class="form-check">
                     <input class="form-check-input" v-model="filters.IsOrange"  type="checkbox" id="fcustomCheck1">
                     <label class="custom-control-label" for="customCheck">Giallo</label>
                     </div>
                     <div class="form-check">
                     <input class="form-check-input" v-model="filters.IsGreen"  type="checkbox" id="fcustomCheck1" >
                     <label class="custom-control-label" for="customCheck1">Verde</label>
                  </div>
                </div>
                </div>

                <div class="col-md-3">
                </div>
            </div>
            <div class="row">
                <div class="col-md-3 mb-md-0 mb-4">
                <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                    <div class="input-group input-group-dynamic">
                        <label class="form-label"></label>
                        <input class="form-control" aria-label="" placeholder="venditore 1, venditore 2..."  type="text" v-model="filters.NameAndSurname" :disabled="filters.GroupType==3">
                    </div>
                </div>
                </div>
                <div class="col-md-3">
                <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                    <div class="input-group input-group-dynamic">
                        <label class="form-label"></label>
                        <input type="date" class="form-control" placeholder="Da" aria-label="" v-model="filters.DateFrom" >
                    </div>
                </div>
                </div>
                <div class="col-md-3">
                <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                    <div class="input-group input-group-dynamic">
                        <label class="form-label"></label>
                        <input type="date" class="form-control" placeholder="a" aria-label="" v-model="filters.DateTo" >
                    </div>
                </div>
                </div>
                <div class="col-md-3">
                <div class="card card-body card-plain d-flex align-items-center flex-row">
                    <div class="input-group input-group-dynamic ">
                        <a class="float-right align-bottom btn bg-gradient-dark mb-0" @click="LoadData">
                           <i v-if="isInLoading" class="material-icons text-sm text-warning rotating">autorenew</i>
                            <i v-else class="material-icons text-sm">search</i>
                              &nbsp;&nbsp;Cerca
                        </a>
                    </div>
                </div>
                </div>
            </div>
        </div>
      <div class="row">
         <div class="col-12">
            <div class="card my-4">
               <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                  <div class="bg-gradient-light shadow-dark border-radius-lg pt-4 pb-3">
                     <h6 class="text-dark text-capitalize ps-3">Lista Preventivi <span v-if="list.QuoteProfitabilityList" class="text-muted">({{list.QuoteProfitabilityList.length}} elementi)</span></h6>
                  </div>
               </div>
               <div class="card-body px-0 pb-2">
                  <div class="table-responsive p-0">
                     <table class="table align-items-center mb-0">
                        <thead>
                           <tr>
                              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Preventivo</th>
                              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Vettura/Cliente</th>
                              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Margine Parziarle</th>
                              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Margine Totale</th>
                              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Permuta</th>
                              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Finanziamento</th>
                           </tr>
                        </thead>
                        <tbody v-if="!isInLoading">
                           <tr v-for="item in list.QuoteProfitabilityList" :key="item.QuoteMarginHeader.QuoteId">
                              <td v-if="item.QuoteMarginHeader">
                                 <div class="d-flex px-2 py-1">
                                    <div class="d-flex flex-column justify-content-center">
                                       <h6 class="mb-0 text-sm"><router-link :to="{path:'/spotlighthome', params:{itemData : item}, query: {id:item.QuoteMarginHeader.QuoteId, company:item.QuoteMarginHeader.CompanyId, stock:item.QuoteMarginHeader.StockMainType}, params:{marginDataItem:item}}" class="text-secondary" exact-path ><span class="icon-xs text-sm text-info material-icons">open_in_new</span> Prev. {{item.QuoteMarginHeader.QuoteNumber}} - {{item.QuoteMarginHeader.QuoteDate}}</router-link></h6>
                                       <p class="text-xs text-secondary mb-0"><span class="text-info">{{item.QuoteMarginHeader.SellerName}}</span> - {{item.QuoteMarginHeader.Company}} </p>
                                    </div>
                                 </div>
                              </td>
                              <td v-if="item.QuoteMarginHeader">
                                 <p class="text-xs font-weight-bold mb-0">{{trimString(item.QuoteMarginHeader.VehicleDescription, 30)}}</p>
                                 <p v-if="ItemsContains(item.QuoteMarginHeader.AccountDescription)" class="text-xs mb-0"><span class="icon-xs text-sm material-icons">question_mark</span> {{item.QuoteMarginHeader.AccountDescription}}</p>
                                  <p v-else class="text-xs text-secondary mb-0">{{trimString(item.QuoteMarginHeader.AccountDescription, 30)}}</p>
                              </td>
                              <td v-if="item.QuoteMarginFooter">
                                <div class="text-xs font-weight-bold mb-0">
                                    <div class="progress"><div :class="['progress-bar', 'progress-bar-striped', 'progress-bar-animated', item.QuoteMarginFooter.MarginProgressBarData.Color]" role="progressbar" :aria-valuenow=item.QuoteMarginFooter.MarginProgressBarData.Percentage aria-valuemin="0" aria-valuemax="100" :style="{width: item.QuoteMarginFooter.MarginProgressBarData.Percentage + '%'}"></div></div>
                                </div>
                                 <p class="text-xs text-secondary mb-0">
                                   {{item.QuoteMarginFooter.GranTotalMarginWithoutBarter}} &euro; <span class="align-middle icon text-dark material-icons">trending_flat</span> {{item.QuoteMarginFooter.MarginProgressBarData.MinMargin}} &euro; 
                                 </p>
                              </td>
                              <td v-if="item.QuoteMarginFooter">
                                <div class="text-xs font-weight-bold mb-0">
                                    <div class="progress"><div :class="['progress-bar', 'progress-bar-striped', 'progress-bar-animated', item.QuoteMarginFooter.UsedMarginProgressBarData.Color]" role="progressbar" :aria-valuenow=item.QuoteMarginFooter.UsedMarginProgressBarData.Percentage aria-valuemin="0" aria-valuemax="100" :style="{width: item.QuoteMarginFooter.UsedMarginProgressBarData.Percentage + '%'}"></div></div>
                                </div>
                                 <p class="text-xs text-secondary mb-0">
                                   {{item.QuoteMarginFooter.GranTotalMargin}} &euro; <span class="align-middle icon text-dark material-icons">trending_flat</span>  {{item.QuoteMarginFooter.UsedMarginProgressBarData.MinMargin}} &euro; 
                                 </p>
                              </td>
                                <td v-if="item.QuoteMarginFooter.BarterData.length">
                                 <div class="d-flex px-2 py-1">
                                    <div class="d-flex flex-column justify-content-center">
                                       <h6 class="text-secondary mb-0 text-sm">{{trimString(item.QuoteMarginFooter.BarterData[0].Description, 30)}} </h6>
                                       <p class="text-xs text-secondary mb-0">Targa {{item.QuoteMarginFooter.BarterData[0].Plate}} - Km {{item.QuoteMarginFooter.BarterData[0].Km}} - Imm. {{item.QuoteMarginFooter.BarterData[0].RegistrationDate}}</p>
                                        <p class="text-xs text-secondary mb-0"><strong>Margine {{item.QuoteMarginFooter.BarterTotalMargin}} &euro;</strong></p>
                                       
                                    </div>
                                 </div>
                              </td>
                                <td v-else>
                                 <div class="d-flex px-2 py-1">
                                    <div class="d-flex flex-column justify-content-center">
                                       <p class="text-secondary mb-0 text-sm"><span class="icon-xs text-sm material-icons">report_problem</span> Permuta non presente</p>
                                    </div>
                                 </div>
                              </td>
                               <td v-if="item.QuoteMarginFooter.HasFinancialPractice">
                                 <div class="d-flex px-2 py-1">
                                    <div class="d-flex flex-column justify-content-center">
                                       <h6 class="text-secondary mb-0 text-sm">{{item.FinancialData.FinancialInstitute}}</h6>
                                       <p class="text-xs text-secondary mb-0">{{item.FinancialData.FinancialType}}</p>
                                        <p class="text-xs text-secondary mb-0"><strong>Margine {{item.FinancialData.FinancialMargin}} &euro;</strong></p>
                                       
                                    </div>
                                 </div>
                                 </td>
                                <td v-else>
                                 <div class="d-flex px-2 py-1">
                                    <div class="d-flex flex-column justify-content-center">
                                       <p class="text-secondary mb-0 text-sm"><span class="icon-xs text-sm material-icons">report_problem</span> Finanziamento non presente</p>
                                    </div>
                                 </div>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import fetchFromAPI from "../../script/api.js"
import helpers from '../../script/helpers.js'
export default {
      data() {
         return {
         filters: {},
         list: [],
         items: ['8 - CLIENTE INTERNO COMMERCIALE NUOVO'],
         isInLoading: false,
      };
   },
   methods: {
      LoadData(){
          helpers.resetFilter('quoteMarginList')
          helpers.setObject('quoteMarginFilter',this.filters);
          this.isInLoading = true;
          fetchFromAPI("QuoteMargin/LoadList", this.filters, (res) => {
               this.list = res;
               this.isInLoading = false;
               helpers.setObject('quoteMarginList', res);
            });
      },
      ItemsContains(n) {
         return this.items.indexOf(n) > -1
      },
      trimString(string, length){
         return helpers.trimString(string, length);
      }
   },
   created() {
         let filterData = helpers.getFilters('quoteMarginFilter');
         if (filterData && typeof filterData !== "undefined") {
            this.filters = filterData;
          }
           let data = helpers.getFilters('quoteMarginList');
           if (data && typeof data !== "undefined") {
              this.list = data;
              return;
          }
          fetchFromAPI("QuoteMargin/BindFiltersForHub", { Token : helpers.token() }, (res) => {
          res.DateFrom = new Date(res.DateFrom).toISOString().slice(0,10)
          res.DateTo = new Date(res.DateTo).toISOString().slice(0,10)
          this.filters = res;
      });
   }
}
</script>