import fetchFromAPI from './api.js';
import router from '../router/index.js';



let User = {
  debug: true,
  state: {
    email: "",
    isLoggedIn: false,
    isSa: false,
    token: ""
  },
  status() {
    return this.state.isLoggedIn
  },
  isAdmin() {
    return this.state.isSa;
  },
  getEmail() {
    return this.state.email;
  },
  getToken() {
    return this.state.token;
  },
  login(username, password, cb) {
    let params = new URL(document.location).searchParams;
    let redirect = params.get("redirect");
    fetchFromAPI('Accounts/GetToken', { UserName: username, Password: password }, (res) => {
      this.state.email = res.username;
      this.state.isLoggedIn = res.IsLogged;
      this.state.token = res.Token;
      localStorage.setItem('sa_trivellato', res.Token);
      if (res.IsLogged) {
        router.push(redirect || '/');
      } else {
        cb();
      }
    });
  },
};

export default User;