import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Header from '../components/Nav.vue'
import AppsNav from '../components/AppsNav.vue'
import SpotlightList from '../views/SpotLight/SpotlightList.vue'
import SpotlightHome from '../views/SpotLight/SpotlightHome.vue'
import FidelityCardSearch from '../views/Fidelity/FidelityCardSearch.vue'
import ContractApprovalDetail from '../views/ContractApproval/ContractApprovalDetail.vue'
import ContractApprovalList from '../views/ContractApproval/ContractApprovalList.vue'
import PrintableContractApprovalDetail from '../views/ContractApproval/PrintableContractApprovalDetail.vue'
import CarServiceSearch from '../views/ServiceSearch/CarServiceSearch.vue'
import FEAList from '../views/FEAManagement/FEAList.vue'
import FEACustomersDocuments from '../views/FEAManagement/FEACustomersDocuments.vue'
import TiresSearch from '../views/TiresLocationSearch/TiresSearch.vue'
import FinancialServiceView from '../views/ServiceSearch/FinancialServiceView.vue'
import ZucchettiHackList from '../views/HR/ZucchettiHackList.vue'
import ZucchettiExtraHoursGrouping from '../views/HR/ZucchettiExtraHoursGrouping.vue'
import ImageManager from '../views/ImageManager/ImageDelete.vue'
import ExpertiseViewerList from '../views/ExpertiseViewer/ExpertiseViewerList.vue'
import DemoManagement from '@/views/DemoManagement/DemoManagement'
import VehicleBooking from '@/views/VehicleBooking/VehicleBooking'
import SpecialLicensePlates from '@/views/SpecialLicensePlates/SpecialLicensePlates'
import HeaderMapper from '@/views/HeaderMapper/HeaderMapper'
import HomeCardsConfig from '@/views/HomeCardsConfig/HomeCardsConfig'
import VehiclePhotos from '@/views/VehiclePhotos/VehiclePhotos'
import VehiclesToShoot from '@/views/VehiclePhotos/VehiclesToShoot'
import ExteriorKeywordsConfig from '@/views/VehiclePhotos/ExteriorKeywordsConfig'
import CustomersList from '../views/CustomerDocuments/CustomersList.vue'
import CustomerDocuments from '../views/CustomerDocuments/CustomerDocuments.vue'
import TypesManagement from '../views/CustomerDocuments/TypesManagement.vue'
import PasswordEncryptor from '../views/PasswordEncryptor/PasswordEncryptor.vue'
import RankingExpertise from '../views/RankingExpertise/RankingExpertise.vue'
import RibaRequests from '../views/RibaRequests/RibaRequests.vue'
import VehicleAutomationApproval from '../views/VehicleAutomationApproval/VehicleAutomationApproval.vue'
import VehicleStatusDashboard from '../views/VehicleStatusDashboard/VehicleStatusDashboard.vue'
import CommissionConfiguration from '../views/CommissionConfiguration/CommissionConfiguration.vue'
import Footer from '../components/Footer.vue'
import Login from '../components/Login.vue'
import fetchFromAPI from '../script/api.js'
import helpers from '../script/helpers.js'


const routes = [
  {
    path: '/login',
    name: 'Login',
    components: { default: Login, header: Header, footer: Footer },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Login.vue'),
  },
  {
    path: '/',
    name: 'Home',
    components: { default: Home, header: Header, footer: Footer },
    props: { isLogged: false, msg: 'ciao' },
    component: () => import('@/views/Home.vue'),
    beforeEnter: (to, from, next) => {
      let token = helpers.token();
      if (token || typeof token !== "undefined") {
        fetchFromAPI('Accounts/IsValidAndAuthenticated', { Token: token }, (res) => {
          if (res) {
            next();
          } else {
            next('/login');
          }
        })
      }
    }
  },
  {
    path: '/spotlightlist',
    name: 'SpotlightList',
    components: { default: SpotlightList, header: AppsNav, footer: Footer },
    props: { header: { default: true, isLogged: false, } },
    component: () => import('@/views/SpotLight/SpotlightList.vue'),
    beforeEnter: (to, from, next) => {
      let token = helpers.token();
      if (token || typeof token !== "undefined") {
        fetchFromAPI('Accounts/IsValidAndAuthenticated', { Token: token }, (res) => {
          if (res) {
            next();
          } else {
            next('/login?redirect=' + encodeURIComponent(to.fullPath));
          }
        })
      }
    }
  },
  {
    path: '/spotlighthome',
    name: 'SpotlightHome',
    components: { default: SpotlightHome, header: AppsNav, footer: Footer },
    props: { isLogged: false, },
    component: () => import('@/views/SpotLight/SpotlightHome.vue'),
    beforeEnter: (to, from, next) => {
      let token = helpers.token();
      if (token || typeof token !== "undefined") {
        fetchFromAPI('Accounts/IsValidAndAuthenticated', { Token: token }, (res) => {
          if (res) {
            next();
          } else {
            next('/login?redirect=' + encodeURIComponent(to.fullPath));
          }
        })
      }
    }
  },
  {
    path: '/fidelitycardsearch',
    name: 'FidelityCardSearch',
    components: { default: FidelityCardSearch, header: AppsNav, footer: Footer },
    props: { isLogged: false, },
    component: () => import('@/views/Fidelity/FidelityCardSearch.vue'),
    beforeEnter: (to, from, next) => {
      let token = helpers.token();
      if (token || typeof token !== "undefined") {
        fetchFromAPI('Accounts/IsValidAndAuthenticated', { Token: token }, (res) => {
          if (res) {
            next();
          } else {
            next('/login?redirect=' + encodeURIComponent(to.fullPath));
          }
        })
      }
    }
  },
  {
    path: '/contractapprovaldetail',
    name: 'ContractApprovalDetail',
    components: { default: ContractApprovalDetail, header: AppsNav, footer: Footer },
    props: { isLogged: false, },
    component: () => import('@/views/ContractApproval/ContractApprovalDetail.vue'),
    beforeEnter: (to, from, next) => {
      let token = helpers.token();
      if (token || typeof token !== "undefined") {
        fetchFromAPI('Accounts/IsValidAndAuthenticated', { Token: token }, (res) => {
          if (res) {
            next();
          } else {
            next('/login?redirect=' + encodeURIComponent(to.fullPath));
          }
        })
      }
    }
  },
  {
    path: '/printablecontractapprovaldetail',
    name: 'PrintableContractApprovalDetail',
    components: { default: PrintableContractApprovalDetail },
    props: { isLogged: false, },
    component: () => import('@/views/ContractApproval/PrintableContractApprovalDetail.vue'),
    beforeEnter: (to, from, next) => {
      let token = helpers.token();
      if (token || typeof token !== "undefined") {
        fetchFromAPI('Accounts/IsValidAndAuthenticated', { Token: token }, (res) => {
          if (res) {
            next();
          } else {
            next('/login?redirect=' + encodeURIComponent(to.fullPath));
          }
        })
      }
    }
  },
  {
    path: '/contractapprovallist',
    name: 'ContractApprovalList',
    components: { default: ContractApprovalList, header: AppsNav, footer: Footer },
    props: { isLogged: false, },
    component: () => import('@/views/ContractApproval/ContractApprovalList.vue'),
    beforeEnter: (to, from, next) => {
      let token = helpers.token();
      if (token || typeof token !== "undefined") {
        fetchFromAPI('Accounts/IsValidAndAuthenticated', { Token: token }, (res) => {
          if (res) {
            next();
          } else {
            next('/login?redirect=' + encodeURIComponent(to.fullPath));
          }
        })
      }
    }
  },
  {
    path: '/carservicesearch',
    name: 'CarServiceSearch',
    components: { default: CarServiceSearch, header: AppsNav, footer: Footer },
    props: { isLogged: false, },
    component: () => import('@/views/ServiceSearch/CarServiceSearch.vue'),
    beforeEnter: (to, from, next) => {
      let token = helpers.token();
      if (token || typeof token !== "undefined") {
        fetchFromAPI('Accounts/IsValidAndAuthenticated', { Token: token }, (res) => {
          if (res) {
            next();
          } else {
            next('/login?redirect=' + encodeURIComponent(to.fullPath));
          }
        })
      }
    }
  },
  {
    path: '/fealist',
    name: 'FEAList',
    components: { default: FEAList, header: AppsNav, footer: Footer },
    props: { isLogged: false, },
    component: () => import('@/views/FEAManagement/FEAList.vue'),
    beforeEnter: (to, from, next) => {
      let token = helpers.token();
      if (token || typeof token !== "undefined") {
        fetchFromAPI('Accounts/IsValidAndAuthenticated', { Token: token }, (res) => {
          if (res) {
            next();
          } else {
            next('/login?redirect=' + encodeURIComponent(to.fullPath));
          }
        })
      }
    }
  },
  {
    path: '/tiressearch',
    name: 'TiresSearch',
    components: { default: TiresSearch, header: AppsNav, footer: Footer },
    props: { isLogged: false, },
    component: () => import('@/views/TiresLocationSearch/TiresSearch.vue'),
    beforeEnter: (to, from, next) => {
      let token = helpers.token();
      if (token || typeof token !== "undefined") {
        fetchFromAPI('Accounts/IsValidAndAuthenticated', { Token: token }, (res) => {
          if (res) {
            next();
          } else {
            next('/login?redirect=' + encodeURIComponent(to.fullPath));
          }
        })
      }
    }
  },
  {
    path: '/financialservice',
    name: 'FinancialService',
    components: { default: FinancialServiceView, header: AppsNav, footer: Footer },
    props: { isLogged: false, },
    component: () => import('@/views/ServiceSearch/FinancialServiceView.vue'),
    beforeEnter: (to, from, next) => {
      let token = helpers.token();
      if (token || typeof token !== "undefined") {
        fetchFromAPI('Accounts/IsValidAndAuthenticated', { Token: token }, (res) => {
          if (res) {
            next();
          } else {
            next('/login?redirect=' + encodeURIComponent(to.fullPath));
          }
        })
      }
    }
  },
  {
    path: '/zucchettihacklist',
    name: 'ZucchettiHackList',
    components: { default: ZucchettiHackList, header: AppsNav, footer: Footer },
    props: { isLogged: false, },
    component: () => import('@/views/HR/ZucchettiHackList.vue'),
    beforeEnter: (to, from, next) => {
      let token = helpers.token();
      if (token || typeof token !== "undefined") {
        fetchFromAPI('Accounts/IsValidAndAuthenticated', { Token: token }, (res) => {
          if (res) {
            next();
          } else {
            next('/login?redirect=' + encodeURIComponent(to.fullPath));
          }
        })
      }
    }
  },
  {
    path: '/ZucchettiExtraHoursGrouping',
    name: 'zucchettiextrahoursgrouping',
    components: { default: ZucchettiExtraHoursGrouping, header: AppsNav, footer: Footer },
    props: { isLogged: false, },
    component: () => import('@/views/HR/ZucchettiExtraHoursGrouping.vue'),
    beforeEnter: (to, from, next) => {
      let token = helpers.token();
      if (token || typeof token !== "undefined") {
        fetchFromAPI('Accounts/IsValidAndAuthenticated', { Token: token }, (res) => {
          if (res) {
            next();
          } else {
            next('/login?redirect=' + encodeURIComponent(to.fullPath));
          }
        })
      }
    }
  },
  {
    path: '/ImageDelete',
    name: 'ImageDelete',
    components: { default: ImageManager, header: AppsNav, footer: Footer },
    props: { isLogged: false, },
    component: () => import('@/views/ImageManager/ImageDelete.vue'),
    beforeEnter: (to, from, next) => {
      let token = helpers.token();
      if (token || typeof token !== "undefined") {
        fetchFromAPI('Accounts/IsValidAndAuthenticated', { Token: token }, (res) => {
          if (res) {
            next();
          } else {
            next('/login?redirect=' + encodeURIComponent(to.fullPath));
          }
        })
      }
    }
  },
  {
    path: '/ExpertiseViewerList',
    name: 'ExpertiseViewerList',
    components: { default: ExpertiseViewerList, header: AppsNav, footer: Footer },
    props: { isLogged: false, },
    component: () => import('@/views/ExpertiseViewer/ExpertiseViewerList.vue'),
    beforeEnter: (to, from, next) => {
      let token = helpers.token();
      if (token || typeof token !== "undefined") {
        fetchFromAPI('Accounts/IsValidAndAuthenticated', { Token: token }, (res) => {
          if (res) {
            next();
          } else {
            next('/login?redirect=' + encodeURIComponent(to.fullPath));
          }
        })
      }
    }
  },
  {
    path: '/demomanagement',
    name: 'DemoManagement',
    components: { default: DemoManagement, header: AppsNav, footer: Footer },
    props: { isLogged: false, },
    component: () => import('@/views/DemoManagement/DemoManagement.vue'),
    beforeEnter: (to, from, next) => {
      let token = helpers.token();
      if (token || typeof token !== "undefined") {
        fetchFromAPI('Accounts/IsValidAndAuthenticated', { Token: token }, (res) => {
          if (res) {
            next();
          } else {
            next('/login?redirect=' + encodeURIComponent(to.fullPath));
          }
        })
      }
    }
  },
  {
    path: '/vehiclebooking',
    name: 'VehicleBooking',
    components: { default: VehicleBooking, header: AppsNav, footer: Footer },
    props: { isLogged: false, },
    component: () => import('@/views/VehicleBooking/VehicleBooking.vue'),
    beforeEnter: (to, from, next) => {
      let token = helpers.token();
      if (token || typeof token !== "undefined") {
        fetchFromAPI('Accounts/IsValidAndAuthenticated', { Token: token }, (res) => {
          if (res) {
            next();
          } else {
            next('/login?redirect=' + encodeURIComponent(to.fullPath));
          }
        })
      }
    }
  },
  {
    path: '/speciallicenseplates',
    name: 'SpecialLicensePlates',
    components: { default: SpecialLicensePlates, header: AppsNav, footer: Footer },
    props: { isLogged: false, },
    component: () => import('@/views/SpecialLicensePlates/SpecialLicensePlates.vue'),
    beforeEnter: (to, from, next) => {
      let token = helpers.token();
      if (token || typeof token !== "undefined") {
        fetchFromAPI('Accounts/IsValidAndAuthenticated', { Token: token }, (res) => {
          if (res) {
            next();
          } else {
            next('/login?redirect=' + encodeURIComponent(to.fullPath));
          }
        })
      }
    }
  },
  {
    path: '/headermapper',
    name: 'HeaderMapper',
    components: { default: HeaderMapper, header: AppsNav, footer: Footer },
    props: { isLogged: false, },
    component: () => import('@/views/HeaderMapper/HeaderMapper.vue'),
    beforeEnter: (to, from, next) => {
      let token = helpers.token();
      if (token || typeof token !== "undefined") {
        fetchFromAPI('Accounts/IsValidAndAuthenticated', { Token: token }, (res) => {
          if (res) {
            next();
          } else {
            next('/login?redirect=' + encodeURIComponent(to.fullPath));
          }
        })
      }
    }
  },
  {
    path: '/homecardsconfig',
    name: 'homecardsconfig',
    components: { default: HomeCardsConfig, header: AppsNav, footer: Footer },
    props: { isLogged: false, },
    component: () => import('@/views/HomeCardsConfig/HomeCardsConfig.vue'),
    beforeEnter: (to, from, next) => {
      let token = helpers.token();
      if (token || typeof token !== "undefined") {
        fetchFromAPI('Accounts/IsValidAndAuthenticated', { Token: token }, (res) => {
          if (res) {
            next();
          } else {
            next('/login?redirect=' + encodeURIComponent(to.fullPath));
          }
        })
      }
    }
  },
  {
    path: '/VehiclePhotos',
    name: 'VehiclePhotos',
    components: { default: VehiclePhotos, header: AppsNav, footer: Footer },
    props: { isLogged: false, },
    component: () => import('@/views/VehiclePhotos/VehiclePhotos.vue'),
    beforeEnter: (to, from, next) => {
      let token = helpers.token();
      if (token || typeof token !== "undefined") {
        fetchFromAPI('Accounts/IsValidAndAuthenticated', { Token: token }, (res) => {
          if (res) {
            next();
          } else {
            next('/login?redirect=' + encodeURIComponent(to.fullPath));
          }
        })
      }
    }
  },
  {
    path: '/VehiclesToShoot',
    name: 'VehiclesToShoot',
    components: { default: VehiclesToShoot, header: AppsNav, footer: Footer },
    props: { isLogged: false, },
    component: () => import('@/views/VehiclePhotos/VehiclesToShoot.vue'),
    beforeEnter: (to, from, next) => {
      let token = helpers.token();
      if (token || typeof token !== "undefined") {
        fetchFromAPI('Accounts/IsValidAndAuthenticated', { Token: token }, (res) => {
          if (res) {
            next();
          } else {
            next('/login?redirect=' + encodeURIComponent(to.fullPath));
          }
        })
      }
    }
  },
  {
    path: '/ExteriorKeywordsConfig',
    name: 'ExteriorKeywordsConfig',
    components: { default: ExteriorKeywordsConfig, header: AppsNav, footer: Footer },
    props: { isLogged: false, },
    component: () => import('@/views/VehiclePhotos/ExteriorKeywordsConfig.vue'),
    beforeEnter: (to, from, next) => {
      let token = helpers.token();
      if (token || typeof token !== "undefined") {
        fetchFromAPI('Accounts/IsValidAndAuthenticated', { Token: token }, (res) => {
          if (res) {
            next();
          } else {
            next('/login?redirect=' + encodeURIComponent(to.fullPath));
          }
        })
      }
    }
  },
  {
    path: '/CustomersList',
    name: 'CustomersList',
    components: { default: CustomersList, header: AppsNav, footer: Footer },
    props: { header: { default: true, isLogged: false, } },
    component: () => import('@/views/CustomerDocuments/CustomersList.vue'),
    beforeEnter: (to, from, next) => {
      let token = helpers.token();
      if (token || typeof token !== "undefined") {
        fetchFromAPI('Accounts/IsValidAndAuthenticated', { Token: token }, (res) => {
          if (res) {
            next();
          } else {
            next('/login?redirect=' + encodeURIComponent(to.fullPath));
          }
        })
      }
    }
  },
  {
    path: '/CustomerDocuments',
    name: 'CustomerDocuments',
    components: { default: CustomerDocuments, header: AppsNav, footer: Footer },
    props: { isLogged: false, },
    component: () => import('@/views/CustomerDocuments/CustomerDocuments.vue'),
    beforeEnter: (to, from, next) => {
      let token = helpers.token();
      if (token || typeof token !== "undefined") {
        fetchFromAPI('Accounts/IsValidAndAuthenticated', { Token: token }, (res) => {
          if (res) {
            next();
          } else {
            next('/login?redirect=' + encodeURIComponent(to.fullPath));
          }
        })
      }
    }
  },
  {
    path: '/TypesManagement',
    name: 'TypesManagement',
    components: { default: TypesManagement, footer: Footer },
    props: { isLogged: false, },
    component: () => import('@/views/CustomerDocuments/TypesManagement.vue'),
    beforeEnter: (to, from, next) => {
      let token = helpers.token();
      if (token || typeof token !== "undefined") {
        fetchFromAPI('Accounts/IsValidAndAuthenticated', { Token: token }, (res) => {
          if (res) {
            next();
          } else {
            next('/login?redirect=' + encodeURIComponent(to.fullPath));
          }
        })
      }
    }
  },
  {
    path: '/PasswordEncryptor',
    name: 'PasswordEncryptor',
    components: { default: PasswordEncryptor, footer: Footer },
    props: { isLogged: false, },
    component: () => import('@/views/PasswordEncryptor/PasswordEncryptor.vue'),
    beforeEnter: (to, from, next) => {
      let token = helpers.token();
      if (token || typeof token !== "undefined") {
        fetchFromAPI('Accounts/IsValidAndAuthenticated', { Token: token }, (res) => {
          if (res) {
            next();
          } else {
            next('/login?redirect=' + encodeURIComponent(to.fullPath));
          }
        })
      }
    }
  },
  {
    path: '/RankingExpertise',
    name: 'RankingExpertise',
    components: { header: AppsNav, default: RankingExpertise, footer: Footer },
    props: { isLogged: false, },
    component: () => import('@/views/RankingExpertise/RankingExpertise.vue'),
    beforeEnter: (to, from, next) => {
      let token = helpers.token();
      if (token || typeof token !== "undefined") {
        fetchFromAPI('Accounts/IsValidAndAuthenticated', { Token: token }, (res) => {
          if (res) {
            next();
          } else {
            next('/login?redirect=' + encodeURIComponent(to.fullPath));
          }
        })
      }
    }
  },
  {
    path: '/RibaRequests',
    name: 'RibaRequests',
    components: { header: AppsNav, default: RibaRequests, footer: Footer },
    props: { isLogged: false, },
    component: () => import('@/views/RibaRequests/RibaRequests.vue'),
    beforeEnter: (to, from, next) => {
      let token = helpers.token();
      if (token || typeof token !== "undefined") {
        fetchFromAPI('Accounts/IsValidAndAuthenticated', { Token: token }, (res) => {
          if (res) {
            next();
          } else {
            next('/login?redirect=' + encodeURIComponent(to.fullPath));
          }
        })
      }
    }
  },
  {
    path: '/VehicleAutomationApproval',
    name: 'VehicleAutomationApproval',
    components: { header: AppsNav, default: VehicleAutomationApproval, footer: Footer },
    props: { isLogged: false, },
    component: () => import('@/views/VehicleAutomationApproval/VehicleAutomationApproval.vue'),
    beforeEnter: (to, from, next) => {
      let token = helpers.token();
      if (token || typeof token !== "undefined") {
        fetchFromAPI('Accounts/IsValidAndAuthenticated', { Token: token }, (res) => {
          if (res) {
            next();
          } else {
            next('/login?redirect=' + encodeURIComponent(to.fullPath));
          }
        })
      }
    }
  },
  {
    path: '/VehicleStatusDashboard',
    name: 'VehicleStatusDashboard',
    components: { header: AppsNav, default: VehicleStatusDashboard, footer: Footer },
    props: { isLogged: false, },
    component: () => import('@/views/VehicleStatusDashboard/VehicleStatusDashboard.vue'),
    beforeEnter: (to, from, next) => {
      let token = helpers.token();
      if (token || typeof token !== "undefined") {
        fetchFromAPI('Accounts/IsValidAndAuthenticated', { Token: token }, (res) => {
          if (res) {
            next();
          } else {
            next('/login?redirect=' + encodeURIComponent(to.fullPath));
          }
        })
      }
    }
  },
  {
    path: '/CommissionConfiguration',
    name: 'CommissionConfiguration',
    components: { header: AppsNav, default: CommissionConfiguration, footer: Footer },
    props: { isLogged: false, },
    component: () => import('@/views/CommissionConfiguration/CommissionConfiguration.vue'),
    beforeEnter: (to, from, next) => {
      let token = helpers.token();
      if (token || typeof token !== "undefined") {
        fetchFromAPI('Accounts/IsValidAndAuthenticated', { Token: token }, (res) => {
          if (res) {
            next();
          } else {
            next('/login?redirect=' + encodeURIComponent(to.fullPath));
          }
        })
      }
    }
  },
  {
    path: '/feadoc',
    name: 'FEACustomersDocuments.vue',
    components: { default: FEACustomersDocuments, footer: Footer },
    component: () => import('@/views/FEAManagement/FEACustomersDocuments.vue')
  },
  {
    path: '/about',
    name: 'About',
    components: { default: About, header: Header, footer: Footer },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/:catchAll(.*)*',
    name: "PageNotFound",
    component: () => import('@/views/PageNotFound.vue'),
  },
  {
    path: '/PortaleProvvigioni',
    beforeEnter() {location.href = 'https://sa.trivellato.it/views/Tools/CommissionHelper/commission_data_home.html'}
  },
  {
    path: '/UniversalQueryTool',
    beforeEnter() {location.href = 'https://sa.trivellato.it/views/Tools/UniversalQueryTool.html'}
  },
  {
    path: '/HelpDesk',
    beforeEnter() {location.href = 'https://help.trivellato.it'}
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
