<template>
    <div v-if="filters" class="container-fluid py-4">
        <div class="card-body p-4">
            <div class="row">
                <div class="col-md-3 mb-md-0 mb-4">
                    <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                    <div class="input-group input-group-dynamic">
                        <label class="form-label"></label>
                        <input class="form-control" aria-label="" placeholder="cerca Cod. Fiscale o P.IVA..."  type="text" v-model="filters.Description">
                    </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card card-body card-plain d-flex align-items-center flex-row">
                        <a class="float-right align-bottom btn bg-gradient-dark mb-0" @click="LoadData()">
                            <i v-if="isInLoading" class="material-icons text-sm text-warning rotating">autorenew</i>
                            <i v-else class="material-icons text-sm">search</i>
                            &nbsp;&nbsp;Cerca
                        </a>
                        <a href="#RibaListModal" class="float-right align-bottom btn bg-gradient-info mb-0 ms-2" data-bs-toggle="modal" data-bs-target="#RibaListModal" @click="getRibaList">
                            <i class="material-icons text-sm">inventory</i>
                            &nbsp;&nbsp;Elenco RIBA
                        </a>
                    </div>
                </div>
            </div>
            <div v-if="isInLoading" class="d-flex flex-column justify-content-center align-items-center mt-7 opacity-6">
                <div class="material-icons rotating mb-2" style="font-size:11rem;">settings</div>
                <div class="fs-4">Sto recuperando le informazioni</div>
                <div>L'operazione potrebbe richiedere alcuni secondi...</div>
            </div>
            <div v-else-if="list.length > 0 && filteredList.length == 0" class="d-flex flex-column justify-content-center align-items-center mt-8 opacity-6">
                <div class="fs-5">Nessuna anagrafica trovata per i codici ricercati</div>
            </div>
            <div v-else-if="person.IdAnagrafica" class="row">
                <h4 class="ms-3 fs-2 mt-3">{{ person.NameSurname }}</h4>
                <div class="ms-2 d-flex align-items-center">
                    <button type="button" name="button" class="btn bg-gradient-success m-0 ms-2 shadow-dark" @click="saveData()">
                        <i v-if="isSaving" class="material-icons text-sm text-warning rotating">autorenew</i>
                        <i v-else class="material-icons text-sm">save</i>
                        &nbsp;&nbsp;Salva informazioni
                    </button>
                    <button type="button" name="button" class="btn btn-outline-success m-0 ms-2 shadow-dark" @click="ExportExcel()">
                        <i v-if="isExporting" class="material-icons text-sm text-warning rotating">autorenew</i>
                        <i v-else class="material-icons text-sm">download</i>
                        &nbsp;&nbsp;Esporta Excel
                    </button>
                </div>
                <div class="m-3 mb-5">
                    <div class="row col-md-12 input-group input-group-static mt-4 me-3">
                        <div class="col-md-5 text-end">
                            <div class="d-flex align-items-center">
                                <span class="col-3 me-3"><b>Sede</b></span>
                                <input type="text" v-model="person.Site" class="form-control" placeholder="da indicare">
                            </div>
                            <div class="d-flex align-items-center">
                                <span class="col-3 me-3"><b>Richiedente</b></span>
                                <input type="text" v-model="person.Applicant" class="form-control" placeholder="da indicare">
                            </div>
                            <div class="d-flex align-items-center">
                                <span class="col-3 me-3"><b>Cond. p.to richiesta</b></span>
                                <input type="text" v-model="person.RequestedPaymentCondition" class="form-control" placeholder="da indicare">
                            </div>
                            <div class="d-flex align-items-center">
                                <span class="col-3 me-3"><b>Importo ivato</b></span>
                                <input type="text" v-model="person.ImportAmount" class="form-control" placeholder="da indicare">
                            </div>
                            <br>
                            <div class="d-flex align-items-center">
                                <span class="me-3 col-3"><b>Data richiesta</b></span>
                                <input type="date" v-model="person.RequestDate" class="form-control" placeholder="da indicare">
                            </div>
                            <br>
                            <div class="d-flex align-items-center">
                                <span class="col-3 me-3"><b>CRIBIS</b></span>
                                <input type="text" v-model="person.CRIBIS" class="form-control" placeholder="da indicare">
                            </div>
                            <div class="d-flex align-items-center">
                                <span class="col-3 me-3"><b>Autorizzato da</b></span>
                                <input type="text" v-model="person.AuthorizedFrom" class="form-control" placeholder="da indicare">
                            </div>
                        </div>
                        <div class="col-md-5 ms-3 text-end">
                            <div class="d-flex align-items-center p-2">
                                <span class="col-3 me-3"><b>Prestazione</b></span>
                                <div class="form-check me-3">
                                    <input type="radio" id="singola" value="Singola" v-model="person.Prestation" class="form-check-input me-2">
                                    <label for="singola" class="form-check-label">Singola</label>
                                </div>
                                <div class="form-check">
                                    <input type="radio" id="continuativa" value="Continuativa" v-model="person.Prestation" class="form-check-input me-2">
                                    <label for="continuativa" class="form-check-label">Continuativa</label>
                                </div>
                            </div>
                            <div class="d-flex align-items-center">
                                <span class="col-3 me-3"><b>Nome banca</b></span>
                                <input type="text" v-model="person.BankName" class="form-control" placeholder="da indicare">
                            </div>
                            <div class="d-flex align-items-center">
                                <span class="col-3 me-3"><b>ABI</b></span>
                                <input type="text" v-model="person.BankABI" class="form-control" placeholder="da indicare">
                            </div>
                            <div class="d-flex align-items-center">
                                <span class="col-3 me-3"><b>CAB</b></span>
                                <input type="text" v-model="person.BankCAB" class="form-control" placeholder="da indicare">
                            </div>
                            <br>
                            <div class="d-flex align-items-center">
                                <span class="me-3 col-3"><b>Data consegna</b></span>
                                <input type="date" v-model="person.DeliveryDate" class="form-control" placeholder="da indicare">
                            </div>
                            <br><br>
                            <div class="d-flex align-items-center">
                                <span class="col-3 me-3"><b>Note varie</b></span>
                                <textarea v-model="person.Notes" class="form-control p-1" placeholder="da indicare" style="border: 1px solid lightgrey"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-wrap" style="gap: 10px;">
                    <div v-for="(item, index) in filteredList" :key="item.IdAnagrafica" class="card flex-grow-1" style="flex-basis: calc(100% / 5); flex-grow: 0;">
                        <div class="card-body">
                            <h4 class="text-primary">{{ item.Company.replace("Veicoli Industriali","V.I.") }}</h4>
                            <div class="row col-12 ps-2">
                                <div class="col-12">
                                    <div><b>Cliente dal:</b> {{ item.CustomerFromYear }}</div>
                                    <div><b>Vetture Nuove:</b> {{ item.NewCars }}</div>
                                    <div><b>Vetture Usate:</b> {{ item.UsedCars }}</div>
                                    <div>
                                        <div class="d-flex align-items-center">
                                            <span type="button" @click="toggleContent(index)"><b>Insoluti ({{ item.Unpaid.length }}) </b></span>
                                            <span type="button" class="p-0 m-0" @click="toggleContent(index)">
                                                <i v-if="showContent[index]" class="material-icons" style="font-size: 30px;">arrow_drop_down</i>
                                                <i v-else class="material-icons" style="font-size: 30px;">arrow_drop_up</i>
                                            </span>
                                        </div>
                                        <div v-if="showContent[index]">
                                            <div class="ps-3 text-xs" v-if="item.Unpaid.length == 0"><span class="fst-italic">nessuno</span></div>
                                            <div v-else>
                                                <div class="ps-2 text-sm"><b>Non saldati ({{ item.Unpaid.filter(ins => !ins.Payed).length }}): </b>
                                                    <div class="ps-3 text-xs" v-for="ins in item.Unpaid" :key="ins">
                                                        <div v-if="!ins.Payed">
                                                            - {{ ins.Dare.toFixed(2) }} € | {{ ins.Reference }}
                                                        </div>
                                                    </div>
                                                    <div class="ps-3 pt-1 text-xs">
                                                        <b>Totale: </b>{{ item.Unpaid.filter(ins => !ins.Payed).reduce((x, y) => x + y.Dare, 0).toFixed(2) }} €
                                                    </div>
                                                </div>
                                                <div class="ps-2 pt-2 text-sm"><b>Saldati ({{ item.Unpaid.filter(ins => ins.Payed).length }}): </b>
                                                    <div class="ps-3 text-xs" v-for="ins in item.Unpaid" :key="ins">
                                                        <div v-if="ins.Payed">
                                                            - {{ ins.Dare.toFixed(2)}} € | saldato dopo {{ ins.Days }} giorni
                                                        </div>
                                                    </div>
                                                    <div class="ps-3 pt-1 text-xs">
                                                        <b>Totale: </b>{{ item.Unpaid.filter(ins => ins.Payed).reduce((x, y) => x + y.Dare, 0).toFixed(2) }} €
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 mt-2">
                                    <h6 class="opacity-6 text-warning mt-2 mb-0">CLIENTE</h6>
                                    <div><b>Codici Cliente:</b> <span class="opacity-8">{{ item.CustomerCode }}</span></div>
                                    <div><b>Cond. Pag.to Cliente Attuale: </b> 
                                        <div class="ps-3 text-xs" v-if="item.ActualCustomerPaymentCondition.length == 0"><span class="fst-italic">nessuna</span></div>
                                        <div class="ps-3 text-xs" v-for="pag in item.ActualCustomerPaymentCondition" :key="pag">- {{ pag }}</div>
                                    </div>
                                    <div><b>Pass. Officina:</b> {{ item.CustomerGaragePassages }}</div>
                                    <div><b>Pass. Garanzia:</b> {{ item.CustomerWarrantyPassages }}</div>
                                    <div><b>Pass. Magazzino:</b> {{ item.CustomerWarehousePassages }}</div>
                                    <div><b>Fatturato:</b> {{ item.CustomerTurnoverSales.toFixed(2) }} €</div>
                                </div>
                                <div class="col-12 mt-2">
                                    <h6 class="opacity-6 text-info mt-2 mb-0">FORNITORE</h6>
                                    <div><b>Codici Fornitore:</b> {{ item.SupplierCode }}</div>
                                    <div><b>Cond. Pag.to Fornitore Attuale: </b>
                                        <div class="ps-3 text-xs" v-if="item.ActualSupplierPaymentCondition.length == 0"><span class="fst-italic">nessuna</span></div> 
                                        <div class="ps-3 text-xs" v-for="pag in item.ActualSupplierPaymentCondition" :key="pag">- {{ pag }}</div>
                                    </div>
                                    <div><b>Pass. Contratto:</b> {{ item.SupplierPassagesContracts }}</div>
                                    <div><b>Pass. Generici:</b> {{ item.SupplierPassagesGeneric }}</div>
                                    <div><b>Fatturato:</b> {{ item.SupplierTurnoverSales.toFixed(2) }} €</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="RibaListModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog no-scroll-content overflow-y-auto overflow-x-hidden" style="max-width: 60rem;">
      <div class="modal-content p-5">
        <button type="button" class="btn-close top-right-corner py-3 px-5" data-bs-dismiss="modal" aria-label="Close">
          <span class="material-icons material-symbols-outlined light-dark">close</span>
        </button>
        <div class="row text-center mb-3">
          <h5 class="text-primary">RIBA Salvate</h5>
        </div>
        <div v-if="!isRibasLoading" class="table-responsive">
          <table class="table align-items-center">
            <thead>
              <tr>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder">Cliente</th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder">Cod. Fiscale</th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder">P. Iva</th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder">Ultima modifica</th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder"></th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder"></th>
              </tr>
            </thead>
            <tbody class="text-xs" style="border-top:0px;" v-for="item in clients" :key="item.Id">
              <tr class="col-sm-12">
                <td class="text-wrap">{{ item.NameSurname }}</td>
                <td class="">{{ item.FiscalCode }}</td>
                <td class="">{{ item.P_IVA }}</td>
                <td class=""><span>{{ formatDate(item.LastModifyDate) }} {{ formatTime(item.LastModifyDate) }}</span></td>
                <td class="">
                    <div type="button" class="btn bg-gradient-info m-0 ms-2 shadow-dark btn-sm" 
                    @click="loadSelectedRiba(getIdentifier(item))" data-bs-dismiss="modal">carica informazioni</div>
                </td>
                <td class="">
                    <div type="button" class="btn bg-gradient-danger m-0 shadow-dark btn-sm" 
                    @click="deleteRiba(item.Id)" data-bs-dismiss="modal">
                        <i class="material-icons md-36" style="font-size: 1rem;">delete</i>
                    </div>
                </td>
              </tr>
            </tbody>
          </table>
          <Paginator :currentPage="currentPage" :totalPages="filters.TotalPages" :perPage="perPage" @pagechanged="onPageChange" class="pt-4"/>
        </div>
        <div v-else class="text-center py-4">
            <i class="material-icons text-center rotating" style="font-size:3rem;">autorenew</i>
        </div>
        <div v-if="clients.length==0" class="text-center py-4">
            Non ci sono RIBA salvate al momento
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import fetchFromAPI from "../../script/api.js"
import helpers from '../../script/helpers.js'
import Paginator from "../../components/Paginator.vue"
export default {
    components: {
        Paginator
    },

    data() {
        return {
            filters: {},
            list: [],
            filteredList: [],
            person: {},
            clients: [],
            isInLoading: false,
            isExporting: false,
            isSaving: false,
            isRibasLoading: false,
            showContent: [],
            currentPage: 1,
            perPage: 10
        };
    },

    methods: {

        formatDate: helpers.formatDate,
        formatTime: helpers.formatTime,

        LoadData(){
            this.person = {};
            this.isInLoading = true;
            fetchFromAPI("RibaRequests/GetCustomerData", { Description : this.filters.Description, Token: helpers.token() }, (res) => {
                if(res){
                    this.list = res;
                    this.filteredList = res.filter(x => x.IdAnagrafica != null);  // prendo solo le aziende in cui sono presenti dati
                    if(this.filteredList.length > 0){
                        this.person = this.filteredList[0];
                        this.person.RequestDate = new Date(this.person.RequestDate).toISOString().slice(0,10);
                        this.person.DeliveryDate = new Date(this.person.DeliveryDate).toISOString().slice(0,10);
                    }
                }
                this.isInLoading = false;
            })
        },

        saveData(){
            this.isSaving = true;
            fetchFromAPI("RibaRequests/SaveCustomerData", this.person, (res) => {
                if(res){
                    this.isSaving = false;
                    this.$toast.success("Dati aggiornati");
                }
            })
        },

        toggleContent(index){
            this.showContent[index] = !this.showContent[index];
        },

        getRibaList(){
            this.filters.Page = this.currentPage;
            this.filters.TotalRows = this.perPage;
            this.isRibasLoading = true;
            fetchFromAPI("RibaRequests/GetRibaList", this.filters, (res) => {
                this.clients = res.Ribas;
                this.filters = res.Filter;
                this.isRibasLoading = false;
            });
        },

        onPageChange(page) {
            this.filters.Page = page;
            this.currentPage = page;
            this.getRibaList();
        },

        getIdentifier(item) {
            return item.P_IVA !== "" ? item.P_IVA : item.FiscalCode;
            },

        loadSelectedRiba(code){
            this.filters.Description = code;
            this.LoadData();
        },

        deleteRiba(id){
            this.$swal.fire({
                title: 'Sicuro?',
                text: 'Cancellerai i dati salvati per questa RIBA',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si'
            }).then((result) => {
                if (result.isConfirmed) {
                    fetchFromAPI("RibaRequests/DeleteRiba", { Id: id, Token : helpers.token() }, () => {
                        this.$toast.success("Cliente rimosso");
                        this.getRibaList();
                    });
                }
            })
        },

        ExportExcel() { 
            this.isExporting = true;
            fetchFromAPI("RibaRequests/ExcelExport", this.list, (res) => {
                this.downloadFile(res);
                this.isExporting = false;
            });
        },

        downloadFile(obj) {
            var link = document.createElement('a');
            link.href = obj.virtualPath;
            link.setAttribute('download', obj.fileName);
            link.click();
            link.remove();
        }
    },

    created() {
        fetchFromAPI("RibaRequests/BindFiltersForHub", { Token : helpers.token() }, (res) => {
            this.filters = res;
            this.person.Token = helpers.token();
            this.person.Id = 0;
        })
    }    
}
</script>

<style>
</style>
  