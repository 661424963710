<template>
  <router-view name="header" />
  <router-view name=default />
  <router-view name="footer" />
</template>

<style>
@import 'https://unpkg.com/vue-select@latest/dist/vue-select.css';

.all-small {
  font-size: 0.875em;
}

.striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 10px 10px;
}


.input-group .v-select:not(.default-style) .vs__selected {
  margin-top: 0;
}

.input-group .v-select.vs--multiple:not(.default-style) .vs__selected {
  border-radius: 1000px;
  padding: 0 0.5rem;
  background-color: rgba(0, 180, 255, 0.3);
  border: none;
  margin-top: 5px;
}

.input-group .v-select.vs--multiple:not(.default-style) .vs__selected+.vs__selected {
  margin-left: 5px;
}

.input-group .v-select:not(.default-style) .vs__dropdown-toggle {
  background: none;
}

.input-group .v-select:not(.default-style) .vs__dropdown-toggle {
  border: none;
  padding-bottom: 2px;
}

.input-group .v-select:not(.default-style) .vs__search,
.input-group .vs__search:focus,
.input-group .v-select:not(.default-style) .vs__selected {
  margin: 0;
  font-size: 0.875rem;
  padding: 0;
}

.input-group .v-select:not(.default-style) .vs__actions {
  padding: 0;
  transform: scale(0.8);
  height: 17px;
  width: 40px;
  justify-content: flex-end;
}

.input-group .v-select:not(.default-style) .vs__selected-options {
  padding: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.border-trivellato {
  border: solid 1px #00b4ff;
}

.color-trivellato {
  color: #00b4ff;
}

.bg-trivellato {
  background-color: #00b4ff;
}

.btn.bg-trivellato {
  background-color: #00b4ff;
}

.hover-trivellato:hover {
  color: rgb(0, 180, 255);
}

.hover-bg-trivellato:hover {
  background-color: rgba(0, 180, 255, 0.1);
}

.input-group.input-group-outline.is-focused.trivellato .form-label,
.input-group.input-group-outline.is-filled.trivellato .form-label {
  color: #00b4ff;
}

.input-group.input-group-outline.is-focused.trivellato .form-label+.form-control,
.input-group.input-group-outline.is-filled.trivellato .form-label+.form-control {
  border-color: #00b4ff !important;
  border-top-color: transparent !important;
  box-shadow: inset 1px 0 #00b4ff, inset -1px 0 #00b4ff, inset 0 -1px #00b4ff;
}

.input-group.input-group-outline.is-focused.trivellato .form-label:before,
.input-group.input-group-outline.is-focused.trivellato .form-label:after,
.input-group.input-group-outline.is-filled.trivellato .form-label:before,
.input-group.input-group-outline.is-filled.trivellato .form-label:after {
  border-top-color: #00b4ff;
  box-shadow: inset 0 1px #00b4ff;
}

.input-group.input-group-dynamic.is-focused.trivellato .form-label,
.input-group.input-group-dynamic.is-filled.trivellato .form-label {
  color: #00b4ff;
}

/* .input-group.input-group-dynamic.is-focused.trivellato .form-label+.form-control, .input-group.input-group-dynamic.is-filled.trivellato .form-label+.form-control {
    border-color: #00b4ff !important;
    border-top-color: transparent !important;
    box-shadow: inset 1px 0 #00b4ff, inset -1px 0 #00b4ff, inset 0 -1px #00b4ff;
} */

.input-group.input-group-dynamic.trivellato .form-control,
.input-group.input-group-dynamic.trivellato .form-control:focus,
.input-group.input-group-static.trivellato .form-control,
.input-group.input-group-static.trivellato .form-control:focus {
  background-image: linear-gradient(0deg, #00b4ff 2px, rgba(156, 39, 176, 0) 0), linear-gradient(0deg, #d2d2d2 1px, rgba(209, 209, 209, 0) 0);
  border-radius: 0 !important;
}

/* .input-group.input-group-dynamic.is-focused.trivellato .form-label:before, .input-group.input-group-dynamic.is-focused.trivellato .form-label:after, .input-group.input-group-dynamic.is-filled.trivellato .form-label:before, .input-group.input-group-dynamic.is-filled.trivellato .form-label:after {
    border-top-color: #00b4ff;
    box-shadow: inset 0 1px #00b4ff;
} */

.form-switch .form-check-input.trivellato:checked {
  border-color: #42424a;
  background-color: #00b4ff;
}

.no-scroll-content {
  max-height: calc(100vh - 105px);
}

body .v-toast__item--info {
  background-color: #00b4ff;
}

.light-dark {
  color: #525f7f;
}

.top-right-corner {
  position: absolute;
  top: 0;
  right: 0;
}

.input-group.input-group-dynamic.trivellato input:-webkit-autofill,
.input-group.input-group-dynamic.trivellato input:-webkit-autofill:focus, 
.input-group.input-group-static.trivellato input:-webkit-autofill, 
.input-group.input-group-static.trivellato input:-webkit-autofill:focus 
{
  transition: background-color 0s 600000s, color 0s 600000s;
}

a.btn.disabled {
  color: lightslategray !important;;
}
</style>