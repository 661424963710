<template>
    <link rel="stylesheet" href="https://unpkg.com/vue-select@latest/dist/vue-select.css">
    <div class="container-fluid all-small no-scroll-content">
        <div class="row no-scroll-content">
            <div class="col-sm-4 no-scroll-content">
                <div class="card mh-100">
                    <div class="card-body overflow-auto">
                        <div class="row justify-content-between align-items-center mb-2">
                            <div class="col-sm-4">
                                <button class="btn bg-gradient-dark text-white px-3 py-2 m-0" @click="createMapping"
                                    :disabled="!entities">
                                    <span class="material-icons material-symbols-outlined">
                                        add
                                    </span>
                                    Nuovo mapping
                                </button>
                            </div>
                            <div class="col-sm-6">
                                <div class="input-group input-group-outline trivellato" ref="license-search">
                                    <label class="form-label">Cerca mapping</label>
                                    <input type="text" class="form-control" aria-label="" :disabled="!entities"
                                        v-model="searched" />
                                </div>
                            </div>
                        </div>
                        <table class="table mh-100 scrollbar-thin table-hover">
                            <thead>
                                <tr>
                                    <th>Nome pagina</th>
                                    <th>Etichetta</th>
                                </tr>
                            </thead>
                            <tbody v-if="entities">
                                <tr v-for="entity in filteredAndSorted" :key="entity.Id"
                                    class="cursor-pointer hover-bg-trivellato" @click="selectEntity(entity)">
                                    <td>{{ entity.PageName }}</td>
                                    <td>{{ entity.PageLabel }}</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="2" class="text-center">
                                        <i class="spinner-border text-dark align-middle" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <headerMapperDetail v-if="selectedEntity" class="col-sm-8 no-scroll-content" :parentSelectedEntity="selectedEntity"></headerMapperDetail>
            <!-- <div class="col-sm-8 no-scroll-content">
                <div class="card mh-100" v-if="selectedEntity">
                    <div class="card-body overflow-auto">
                        <h3 class="mb-4">Gestisci header di navigazione</h3>
                        <div class="form-group d-flex flex-column align-items-start">
                            <div class="input-group input-group-dynamic my-2 trivellato"
                                :class="{ 'is-filled': selectedEntity.PageName != null && selectedEntity.PageName != '' }">
                                <label class="form-label">Nome pagina</label>
                                <input type="text" class="form-control" aria-label="" v-model="selectedEntity.PageName" />
                            </div>
                            <div class="input-group input-group-dynamic my-2 trivellato"
                                :class="{ 'is-filled': selectedEntity.PageLabel != null && selectedEntity.PageLabel != '' }">
                                <label class="form-label">Etichetta pagina</label>
                                <input type="text" class="form-control" aria-label="" v-model="selectedEntity.PageLabel" />
                            </div>
                            <br>
                            <h5>Anteprima navigazione</h5>
                            <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
                                <li v-for="navItem in filteredAndSortedBreadcrumb" :key="navItem.Index"
                                    class="breadcrumb-item">
                                    <a href="javascript:" class="color-trivellato hover-trivellato"
                                        :class="{ 'font-weight-bold': navItem.Active }" :to="{ path: navItem.Path }"
                                        exact-path @click="selectedBreadcrumbItem = navItem">
                                        {{ navItem.PageLabel }}
                                    </a>
                                </li>
                                <li class="breadcrumb-item text-sm">
                                    <a href="javascript:" class="material-icons material-symbols-outlined hover-trivellato"
                                        title="Aggiungi elemento di navigazione" @click="addBreadcrumbItem">
                                        add_circle
                                    </a>
                                </li>
                            </ol>
                            <div class="card my-4 px-4 py-3 bg-gray-200 col-md-12" v-if="selectedBreadcrumbItem">
                                <div class="input-group input-group-dynamic my-2 trivellato"
                                    :class="{ 'is-filled': selectedBreadcrumbItem != null && selectedBreadcrumbItem.Index !== null }">
                                    <label class="form-label">Index</label>
                                    <input type="number" step="1" class="form-control" aria-label=""
                                        v-model="selectedBreadcrumbItem.Index" />
                                </div>
                                <div class="input-group input-group-dynamic my-2 trivellato"
                                    :class="{ 'is-filled': selectedBreadcrumbItem != null && selectedBreadcrumbItem.PageLabel && selectedBreadcrumbItem.PageLabel != '' }">
                                    <label class="form-label">Label</label>
                                    <input type="text" class="form-control" aria-label=""
                                        v-model="selectedBreadcrumbItem.PageLabel" />
                                </div>
                                <div class="input-group input-group-dynamic my-2 trivellato"
                                    :class="{ 'is-filled': selectedBreadcrumbItem != null && selectedBreadcrumbItem.Path && selectedBreadcrumbItem.Path != '' }">
                                    <label class="form-label">Path</label>
                                    <input type="text" class="form-control" aria-label=""
                                        v-model="selectedBreadcrumbItem.Path" />
                                </div>
                                <div class="form-check form-switch my-2 d-flex align-items-center trivellato">
                                    <input class="form-check-input trivellato" type="checkbox"
                                        v-model="selectedBreadcrumbItem.Active">
                                    <label class="form-check-label d-block ms-3 mb-0" for="flexSwitchCheckDefault">
                                        Active
                                    </label>
                                </div>
                                <div class="top-right-corner m-2">
                                    <span class="btn material-icons material-symbols-outlines p-0  close-breadcrumb-item"
                                        @click="closeBradCrumbItem">
                                        remove
                                    </span>
                                    <span class="btn material-icons material-symbols-outlines p-0 ms-2 del-breadcrumb-item"
                                        @click="deleteBradCrumbItem">
                                        cancel
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="buttons-container mt-6">
                            <button class="btn bg-gradient-danger mx-1" @click="del()">Elimina</button>
                            <button class="btn bg-gradient-dark mx-1 float-end" @click="save()">Salva</button>
                            <button class="btn bg-gradient-light mx-1 float-end" @click="refreshItem()">Annulla</button>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
import fetchFromAPI from "../../script/api.js"
import helpers from '../../script/helpers.js'
// import _ from 'lodash'
import headerMapperDetail from '../../components/HeaderMapperDetail.vue'
import mitt from 'mitt'

export default {
    components: {
        headerMapperDetail
    },
    data() {
        return {
            filters: null,
            entities: null,
            selectedEntity: null,
            selectedBreadcrumbItem: null,
            searched: '',
            companies: [
                {
                    Id: 1,
                    Nome: "Trivellato Spa"
                },
                {
                    Id: 6,
                    Nome: "Padova Star Srl"
                },
            ],
            eventHub: mitt()
        }
    },
    computed: {
        filteredAndSorted() {
            // function to compare names
            function compare(a, b) {
                if (a.PageName < b.PageName) return -1;
                if (a.PageName > b.PageName) return 1;
                return 0;
            }

            return this.entities.filter(entity => {
                return entity.PageName.toLowerCase().includes(this.searched.toLowerCase()) || entity.PageLabel.toLowerCase().includes(this.searched.toLowerCase());
            }).sort(compare)
        },
        filteredAndSortedBreadcrumb() {
            // function to compare names
            function compare(a, b) {
                if (a.Index < b.Index) return -1;
                if (a.Index > b.Index) return 1;
                return 0;
            }

            return this.selectedEntity.BreadcrumbItems.filter(() => 1 == 1).sort(compare)
        }
    },

    methods: {
        LoadData() {
            return fetchFromAPI("HeaderMapper/LoadList", this.filters, (res) => {
                this.entities = res;
            }, (err) => {
                console.error("Something went wrong fetching data. Error: " + err);
            });
        },
        createMapping() {
            var x = {
                Id: -1,
                PageName: null,
                BreadcrumbItems: [],
                PageLabel: null,
                Token: helpers.token()
            };
            this.selectedBreadcrumbItem = null;
            this.selectedEntity = x;
        },
        selectEntity(entity) {
            this.selectedBreadcrumbItem = null;
            this.selectedEntity = entity;
        },
    },
    created() {
        fetchFromAPI("HeaderMapper/BindFiltersForHub", { Token: helpers.token() }, (res) => {
            res.DateFrom = new Date(res.DateFrom).toISOString().slice(0, 10)
            res.DateTo = new Date(res.DateTo).toISOString().slice(0, 10)
            this.filters = res;
            this.filters.CodiceUbicazione = null;
            this.LoadData().then(() => {
                if ((this.$route.query || {}).PageName != null) {
                    this.selectedEntity = this.entities.find((el) => el.PageName == this.$route.query.PageName)
                }
            })
        });
    },
    mounted() {
        this.$nextTick(() => {
            helpers.setMaterialInput();
        });
        this.eventHub.on('header/mapping/saved', this.LoadData());
        this.eventHub.on('header/mapping/deleted', this.LoadData());
    },
    unmounted() {
        this.eventHub.off('header/mapping/saved')
        this.eventHub.off('header/mapping/deleted')
    }
}
</script>

<style scoped>
#app {
    max-height: 100vh;
}

.container-fluid {
    max-height: calc(100vh - 100px);
}

::-webkit-scrollbar {
    width: 6px;
    border-radius: 10px;
}

.del-breadcrumb-item:hover {
    color: red;
}

.close-breadcrumb-item:hover {
    color: lightskyblue;
}
</style>