<template>
   <div class="container-fluid py-3 ">
      <div class="card-body p-4 ">
         <div class="row">
            <div class="col-5">
               <div class="card" style="width:247%">
                  <div class="table-responsive">
                     <table class="table align-items-center mb-0" style="width:100%">
                        <thead>
                           <tr>
                              <th
                                 class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-dark text-xs">
                                 #</th>
                              <th
                                 class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 text-dark text-xs">
                                 Km</th>
                              <th
                                 class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 text-dark text-xs">
                                 Importo</th>
                              <th
                                 class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 text-dark text-xs">
                                 Importo No Iva</th>
                              <th
                                 class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 text-dark text-xs">
                                 Data</th>
                              <th
                                 class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3 text-dark text-xs">
                                 Note</th>
                              <th
                                 class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3 text-dark text-xs">
                                 Creato da
                              </th>
                              <th
                                 class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3 text-dark text-xs">
                              </th>
                           </tr>
                        </thead>
                        <tbody
                           v-for="(item, index) in list.DemoFuelCheckModels.slice(pageSize * (currentNavIdx - 1), pageSize * currentNavIdx)"
                           :key="index">
                           <tr>
                              <td>
                                 <div class="d-flex px-3 py-1">
                                    <div class="d-flex flex-column justify-content-center">
                                       <h6 class="mb-0 text-dark text-xs">{{ list.DemoFuelCheckModels.indexOf(item) }}</h6>
                                    </div>
                                 </div>
                              </td>
                              <td>
                                 <div class="input-group input-group-dynamic">
                                    <label class="form-label"></label>
                                    <input type="number" step="0.01" class="form-control text-dark text-xs"
                                       v-model="item.Km" :disabled="!item.IsEditable" placeholder="..." aria-label="">
                                    <!-- &nbsp;Km -->
                                 </div>
                              </td>
                              <td>
                                 <div class="input-group input-group-dynamic">
                                    <label class="form-label"></label>
                                    <input type="number" step="0.01" class="form-control text-dark text-xs"
                                       v-model="item.Amount" :disabled="!item.IsEditable" placeholder="€" aria-label="">
                                    <!-- &nbsp;€ -->
                                 </div>
                              </td>
                              <td>
                                 <div class="input-group input-group-dynamic">
                                    <label class="form-label"></label>
                                    <input type="text" class="form-control text-dark text-xs" v-model="item.AmountExVat"
                                       disabled placeholder="..." aria-label="">
                                    <!-- &nbsp;€ -->
                                 </div>
                              </td>
                              <td>
                                 <div class="input-group input-group-dynamic">
                                    <label class="form-label"></label>
                                    <input type="date" class="form-control text-dark text-xs" v-model="item.FuelDate"
                                       :disabled="!item.IsEditable" aria-label="">
                                    <!-- formatDate(item.FuelDate) -->
                                 </div>
                              </td>
                              <td>
                                 <div class="input-group input-group-dynamic">
                                    <label class="form-label"></label>
                                    <input type="text" class="form-control text-dark text-xs" v-model="item.Note"
                                       :disabled="!item.IsEditable" placeholder="" aria-label="">
                                    <!-- formatDate(item.FuelDate) -->
                                 </div>
                              </td>
                              <td>
                                    <span class="form-label">{{ item.CreatedByFullName }}</span>
                                    <!-- formatDate(item.FuelDate) -->
                              </td>
                              <td>
                                 <a :role="button" aria-expanded="false">
                                    <div class="d-flex px-2 py-1">
                                       <span v-if="!item.IsEditable" data-bs-toggle="tooltip" data-bs-placement="top"
                                          title="Edit">
                                          <i @click="MakeEditable(item)"
                                             class="collapse-close material-icons px-1 opacity-10 text-edit text-dark"
                                             role="button">
                                             <span>edit</span>
                                          </i></span>
                                       <span v-if="item.IsEditable" data-bs-toggle="tooltip" data-bs-placement="top"
                                          title="Edit">
                                          <i @click="Savedata(item)"
                                             class="collapse-close material-icons px-1 opacity-10 text-edit text-dark"
                                             role="button">
                                             <span>save</span>
                                          </i></span>
                                       <span data-bs-toggle="tooltip" data-bs-placement="top" title="Edit">
                                          <i @click="DeleteConfirm(item)"
                                             class="collapse-close material-icons px-1 opacity-10 text-edit text-dark"
                                             role="button">
                                             <span>delete</span>
                                          </i></span>
                                    </div>
                                 </a>
                              </td>
                              <!-- <td v-if="showbin">
                                 <span data-bs-toggle="tooltip" data-bs-placement="top" class="">
                                 <a class="material-icons btn btn-sm border-radius-md mt-0 mb-0 align-items-center">
                                 <i v-if="isInLoading" class="material-icons text-sm text-warning rotating">autorenew</i>
                                 <i v-else class="material-icons text-sm">delete</i>
                                 </a>
                                 </span>
                                 </td> -->
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="pager col-md-10">
                  <ul class="pagination pagination-primary m-4">
                     <!-- Doppia freccia primo elemento -->
                     <!-- <li class="page-item">
                        <a class="page-link" href="javascript:;" aria-label="Previous">
                           <span aria-hidden="true"><i class="material-icons" aria-hidden="true"
                                 @click="navToPage(1)">first_page</i></span>
                        </a>
                     </li> -->

                     <!-- Freccia sinistra -->
                     <li class="page-item">
                        <a class="page-link" href="javascript:;" aria-label="Previous">
                           <span class="material-icons" aria-hidden="true"
                              @click="navToPage(currentNavIdx - 1)">chevron_left</span>
                        </a>
                     </li>

                     <!-- Fast nav + pulsante con puntini -->
                     <li class="page-item" v-if="currentNavIdx > 2 && listChunks.length > 7"
                        :class="{ 'active': currentNavIdx == 1 }">
                        <a class="page-link" href="javascript:;" @click="navToPage(1)">{{ 1 }}</a>
                     </li>
                     <li class="page-item" v-if="currentNavIdx > 3 && listChunks.length > 7">
                        <a class="page-link" href="javascript:;">...</a>
                     </li>

                     <!-- Pulsanti precedenti la sfera di adiacenza all'elemento attivo -->
                     <li class="page-item" v-for="(x, idx) in listChunks" :key="idx">
                        <a v-if="idx + 1 < currentNavIdx - 1 && Math.abs(currentNavIdx - (idx + 1)) < (7 - (listChunks.length - currentNavIdx + 2))"
                           class="page-link" href="javascript:;" @click="navToPage(idx + 1)">{{ idx + 1 }}</a>
                     </li>


                     <!-- Active item e adiacenti -->
                     <li class="page-item" v-for="(x, idx) in listChunks" :key="idx"
                        :class="{ 'active': currentNavIdx == idx + 1 }">
                        <a v-if="Math.abs(currentNavIdx - (idx + 1)) < 2" class="page-link" href="javascript:;"
                           @click="navToPage(idx + 1)">{{ idx + 1 }}</a>
                     </li>

                     <!-- Pulsanti successivi la sfera di adiacenza all'elemento attivo -->
                     <li class="page-item" v-for="(x, idx) in listChunks" :key="idx">
                        <a v-if="idx + 1 > currentNavIdx + 1 && Math.abs(currentNavIdx - (idx + 1)) < 7 - (currentNavIdx + 1)"
                           class="page-link" href="javascript:;" @click="navToPage(idx + 1)">{{ idx + 1 }}</a>
                     </li>


                     <!-- Fast nav + pulsante con puntini -->
                     <li class="page-item" v-if="listChunks.length - currentNavIdx > 2 && listChunks.length > 7">
                        <a class="page-link" href="javascript:;">...</a>
                     </li>
                     <li class="page-item" v-if="listChunks.length - currentNavIdx > 1 && listChunks.length > 7"
                        :class="{ 'active': currentNavIdx == listChunks.length }">
                        <a class="page-link" href="javascript:;" @click="navToPage(listChunks.length)">{{
                           listChunks.length }}</a>
                     </li>

                     <!-- Freccia destra -->
                     <li class="page-item">
                        <a class="page-link" href="javascript:;" aria-label="Previous">
                           <span aria-hidden="true" class="material-icons"
                              @click="navToPage(currentNavIdx + 1)">chevron_right</span>
                        </a>
                     </li>

                     <!-- Doppia freccia ultimo elemento -->
                     <!-- <li class="page-item">
                        <a class="page-link" href="javascript:;" aria-label="Previous">
                           <span aria-hidden="true"><i class="material-icons" aria-hidden="true"
                                 @click="navToPage(listChunks.length)">last_page</i></span>
                        </a>
                     </li> -->
                  </ul>
               </div>
               <div class="card card-body card-plain d-flex align-items-center flex-row col-md-2">
                  <div class="input-group input-group-dynamic flex-row justify-content-end">
                     <a class="float-right align-bottom btn bg-gradient-dark mb-0" @click="AddInstance()"
                        v-if="!list.IsDeleted">
                        <!--@click="AddIstance(list)"-->
                        <i v-if="isInLoading" class="material-icons text-sm text-warning rotating">autorenew</i>
                        <i v-else class="material-icons text-sm">add</i>
                     </a>
                  </div>
               </div>
               <!--<p>{{ Amount }}         Con Iva =        {{ AmountExVat }}</p>-->
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import fetchFromAPI from "../script/api.js"

export default {
   props: ['model'],

   data() {
      return {
         filters: {},
         list: this.model,
         isInLoading: false,
         i: 0,
         added: false,
         showbin: false,
         currentNavIdx: 1,
         pageSize: 5
      };
   },
   computed: {
      // TODO install lodash and add orderBy capabilities
      // orderedFuelModels: function () {
      //    return this.sortBy(this.list.DemoFuelCheckModels, 'FuelDate')
      // },
      listChunks: function () {
         var chunks = [];
         for (let i = 0; i < this.list.DemoFuelCheckModels.length; i += this.pageSize) {
            chunks.push(this.list.DemoFuelCheckModels.slice(i, i + this.pageSize));
         }
         return chunks;
      },
   },
   //    computed: {
   //     Amount() {
   //       console.log(this.list.Amount)
   //       return this.list.Amount;
   //     }
   //   },
   //   watch: {
   //    Amount() {
   //       console.log('Amount Has Changed')
   //       this.AmountExVat(this.Amount)
   //     }
   //    },
   watch: {
      list: {
         handler(val) {
            this.AmountExVat(val)
            //alert(JSON.stringify(val))
         },
         deep: true
      }
   },
   methods: {
      AmountExVat(val) {
         for (var i = 0; i < val.DemoFuelCheckModels.length; i++) {
            this.list.DemoFuelCheckModels[i].AmountExVat = val.DemoFuelCheckModels[i].Amount - (val.DemoFuelCheckModels[i].Amount * 0.22);
         }
      },
      Savedata(item) {

         if (this.CheckData(item)) {
            fetchFromAPI("DemoManagement/SaveItem", item, (res) => {
               let index = this.list.DemoFuelCheckModels.findIndex((obj => obj.Id == item.Id));
               //this.list.DemoFuelCheckModels[index] = res
               //this.checkParameters(res)
               // this.list.DemoFuelCheckModels[index].IsEditable = !item.IsEditable
               item.IsEditable = !item.IsEditable;
               item.CreatedByFullName = res.CreatedByFullName;
               item.Id = res.Id;
               this.list.DemoFuelCheckModels[index].FuelDate = new Date(this.list.DemoFuelCheckModels[index].FuelDate).toISOString().slice(0, 10)
               //this.list.DemoFuelCheckModels[index].AmountExVat=this.list.DemoFuelCheckModels[index].Amount - this.list.DemoFuelCheckModels[index].Amount*0.22
               console.log(res)
               this.added = false;
            });

         }
      },
      CheckData(item) {
         var datemin = new Date(2015, 0, 1);
         var datemax = this.getDatemax()
         console.log(datemax)
         if (item.Km != null && item.Km != '' && item.Amount != null && item.Amount != '' && datemin.getTime() < Date.parse(item.FuelDate) && Date.parse(item.FuelDate) < datemax.getTime()) {
            return true
         }
         else {
            this.OpenErrorDialog()
         }
      },
      getDatemax() {
         const date = new Date()
         date.month = date.getMonth() + 1;
         return date;
      },
      MakeEditable(item) {
         let index = this.list.DemoFuelCheckModels.findIndex((obj => obj.Id == item.Id));
         this.list.DemoFuelCheckModels[index].IsEditable = !item.IsEditable
         //this.showbin=true
      },
      OpenErrorDialog() {
         this.$swal.fire({
            title: 'Errore',
            width: 400,
            html: 'Compila tutti i campi e verifica che la data sia corretta prima di salvare',
            showCloseButton: false,
            showCancelButton: false,
            focusConfirm: false,
            cancelButtonText:
               'Chiudi'
         })
      },
      FormatDate(dateString) {
         const date = new Date(dateString);
         let dateFormatted = new Intl.DateTimeFormat("default", { dateStyle: "long" }).format(date);
         if (dateFormatted != "1 gennaio 1") {
            return dateFormatted;
         }
         return "";
      },
      AddInstance() {

         if (this.added == false) {
            this.added = true
            this.isInLoading = true;
            fetchFromAPI("DemoManagement/CreateNewInstance", this.list, (res) => {
               this.list.DemoFuelCheckModels.push(res);
               this.navToPage(this.listChunks.length);
               this.isInLoading = false;
            }, (err) => {
               console.error(err);
               this.isInLoading = false;
               this.$swal.fire({
                  title: "Errore nell'aggiunta del nuovo rifornimento",
                  text: "Ricaricare la pagina e riprovare",
                  confirmButtonColor: '#191919'
               });
            });
         }
      },
      DeleteInstance(item) {

         fetchFromAPI("DemoManagement/DeleteItem", item, (res) => {
            if (res) {
               let index = this.list.DemoFuelCheckModels.findIndex((obj => obj.Id == item.Id));
               this.list.DemoFuelCheckModels.splice(index, 1);
               this.added = false;
            }
         });
      },
      DeleteConfirm(item) {
         this.$swal.fire({
            title: 'Sicuro?',
            text: 'Vuoi cancellare questo record ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#191919',
            cancelButtonColor: '#f0f2f5',
            confirmButtonText: 'Si, vai!'
         }).then((result) => {
            if (result.isConfirmed) {
               this.$swal.fire({
                  title: 'Ok fatto!',
                  text: 'Oggetto cancellato con successo.',
                  confirmButtonColor: '#191919'
               })
               this.DeleteInstance(item);
            }
         })
      },
      created() {
         // let params = new URL(document.location).searchParams;
         // let sid = params.get("SID");
         /*let filterData = helpers.getFilters('FEAListFilter');
         if (filterData && typeof filterData !== "undefined") {
            this.filters = filterData;
            this.filters.JobId = sid;
            this.LoadData();
            return;
          }*/
      },
      navToPage(idx) {
         console.log('targetIdx: ' + idx);
         console.log('pageSize: ' + this.pageSize);
         // console.log('startingPoint: ' + this.pageSize*this.idx);
         if (idx <= 0 || idx > this.listChunks.length) {
            return;
         }
         console.log('currentNavIdx: ' + idx);
         this.currentNavIdx = idx;
      }
   }
}
</script>
<style>
.dropzone__message {
   text-align: center;
   vertical-align: middle;
}

.table-responsive {
   overflow-x: hidden !important;
}

.pager .pagination.pagination-primary .page-item.active>.page-link,
.pager .pagination.pagination-primary .page-item.active>.page-link:focus,
.pager .pagination.pagination-primary .page-item.active>.page-link:hover {
   background-image: linear-gradient(195deg, #42424a 0%, #191919 100%);
}

.pager .page-item.active .page-link {
   z-index: 3;
   color: #fff;
   background-color: #191919;
   border-color: #191919;
}

.pager .page-link {
   position: relative;
   display: block;
   color: #191919;
   background-color: #fff;
   border: 1px solid #dee2e6;
   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

body .swal2-styled.swal2-confirm:hover,
body .swal2-styled.swal2-confirm:focus {
   background-image: linear-gradient(195deg, #464646 0%, #464646 100%);
   outline: 0;
   box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.09), 0 2px 3px -1px rgba(0, 0, 0, 0.07);
   border: solid black 3px;
}

body .swal2-styled.swal2-confirm {
   border: solid white 3px;
   border-radius: 0.5rem;
}

body .swal2-styled.swal2-cancel {
   color: black;
   border: solid white 3px;
   border-radius: 0.5rem;
}

body .swal2-styled.swal2-cancel:hover,
body .swal2-styled.swal2-cancel:focus {
   background-image: linear-gradient(195deg, #f0f2f5 0%, #f0f2f5 100%);
   outline: 0;
   box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.09), 0 2px 3px -1px rgba(0, 0, 0, 0.07);
   border: solid black 3px;
}
</style>