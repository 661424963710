<template>
  <div class="page-header min-vh-100" style="background-image: url('https://images.unsplash.com/photo-1627850991511-fd5640f0b472?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1951&q=80');" loading="lazy">
    <div class="col-12 top-0 position-absolute row">
      <div class="col-3"></div>
      <nav class="navbar navbar-transparent navbar-expand-lg top-0 z-index-3 shadow-none my-3 py-2 mx-auto col-6">
        <div class="container-fluid ">
          <div class="navbar-collapse">
            <div class="d-flex align-items-center mx-2 col-12">
              <div class="input-group input-group-outline">
                <input type="text" class="form-control text-white  mx-auto" v-model="searchQuery" @input="filterList" placeholder="cerca..." style="max-width: 25rem; min-width: 10rem;" >
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div class="col-sm-3"></div>
    </div> 
    <section class="py-lg-6 col-sm-12">
      <div class="mx-4">
        <div class="row mt-lg-6 mt-8">
          <div class="col-lg-3 col-md-6 mb-5" v-for="item in filteredList" :key="item.Id">
            <div class="card card">
              <div class="ps-4 mt-n4">
                <div :class="['icon', 'icon-lg', 'icon-shape', 'shadow', 'text-center', 'border-radius-xl', item.IconColor]">
                  <i class="material-icons opacity-10">{{item.IconPath}}</i>
                </div>
              </div>
              <div class="row col-12">
                <div class="col-10"></div>
                <a class="col-2" type="button" @click="setFavorite(item)" :title=setTitle(item)><i class="material-icons" style="font-size: 2rem;" :class="setFavouriteClass(item)">bookmark</i></a>
              </div>
              <div class="card-body border-radius-lg position-relative overflow-hidden pb-4 pt-0">
                <h5 class="mt-2">{{item.Title}}</h5>
                <p class="mb-3">{{item.Description}}</p>
                <router-link class="font-weight-bold text-xs text-uppercase font-weight-bolder text-primary icon-move-right" :to="{ path: item.Url}" exact-path>{{item.LinkLabel}}
                  <i class="fas fa-arrow-right text-xs ms-1" aria-hidden="true"></i>
                </router-link>
              </div>
            </div>
          </div>
          </div>
      </div>
    </section>
  </div>
</template>
<script>
import fetchFromAPI from "../script/api.js"
import helpers from '../script/helpers.js'
export default {
  data() {
      return {
        list: [],
        filteredList: [],
        isInLoading: false,
        searchQuery: ''
    };
  },
  methods: {
    filterList() {
      const query = this.searchQuery.toLowerCase();
      if(!query) this.filteredList = this.list;
      this.filteredList = this.list.filter(item =>
        item.Title.toLowerCase().includes(query)
      );
    },
    setFavorite(item){
      fetchFromAPI("HubPermission/SetCardFavorite", { Token : helpers.token(), Id: item.Id, ViewMode: item.IsSelected }, (res) => {
        if(res){
          this.loadCards();
        }
      })
    },
    loadCards(){
      fetchFromAPI("HubPermission/LoadHomeCards", { Token : helpers.token() }, (res) => {
        this.list = res;
        this.filteredList = this.list;
      })
    },
    setFavouriteClass(item){
      return !item.IsSelected ? 'bookmark-icon' : 'bookmark-icon-selected';
    },
    setTitle(item){
      return  item.IsSelected ? 'Rimuovi dai preferiti' : 'Aggiungi ai preferiti';
    }
  },
  created() {
    this.loadCards();
  }
}
</script>
<style>
.bookmark-icon{
  color: white;
  text-shadow: 0 0 3px black;
}

.bookmark-icon-selected{
  color: #FB8C00 ;
}
</style>
