<template>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="mt-3 mb-3">
            <div class="row">
                <div class="col-12 col-lg-8">
                  <div class="card">
                     <div class="card-header pb-0">
                        <h6 class="">Dettaglio Ripartizioni</h6>
                     </div>
                     <div class="card-body p-3">
                        <div class="timeline timeline-one-side" data-timeline-axis-style="dotted">
                           <div v-for="(subItem, index) in model.StampsHackLogModels" :key="index" class="timeline-block mb-3">
                              <span class="timeline-step opacity-9 p-3" :class="{'bg-danger': subItem.AllocationType === 0, 'bg-success': subItem.AllocationType === 1}">
                              <i v-if="subItem.AllocationType === 0" class="material-icons text-white text-sm opacity-10">
                                playlist_remove
                              </i>
                              <i v-if="subItem.AllocationType === 1" class="material-icons text-white text-sm opacity-10">
                                playlist_add
                              </i>
                              </span>
                              <div class="timeline-content pt-1">
                                 <h6 class="text-sm font-weight-bold mb-0">Totale: {{subItem.AllocationQty}}h {{(subItem.AllocationType === 0 ? "rimosse" : "allocate")}}</h6>
                                 <p class="text-secondary text-xs mt-1 mb-0">giorno di riferimento {{(subItem.AllocationType === 0 ? formatDate(subItem.ObjectDay, "long") : formatDate(subItem.SourceDay, "long"))}}</p>
                                 <p class="text-sm mt-3 mb-2 text-dark">
                                    Note: {{subItem.Note}}
                                 </p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
export default {
      props: ['model'],
      data() {
      return{
          AllocationTypeResolved:["Rimozione", "Aggiunta"]
      }
    },
    methods:{
        formatDate(dateString, datestyle) {
        const date = new Date(dateString);
        let dateFormatted = new Intl.DateTimeFormat("default", { dateStyle: datestyle }).format(date);
        if(dateFormatted != "1 gennaio 1"){
            return dateFormatted;
        }
        return "";
      },
    }
}
</script>