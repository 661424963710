<template>
   <div class="container-fluid py-4" style="zoom: 90%;">
      <div class="card-body p-4">
         <div class="row">
            <div class="col-md-3 mb-md-0 mb-4">
               <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                  <div class="input-group input-group-dynamic">
                     <input type="text" class="form-control" placeholder="Cerca codice o nome venditore" aria-label="" v-model="filters.Code">
                  </div>
               </div>
            </div>
            <div class="col-md-2">
               <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                  <div class="input-group input-group-dynamic">
                     <label class="form-label"></label>
                     <input type="date" class="form-control" placeholder="Da" aria-label="" v-model="filters.DateFrom">
                  </div>
               </div>
            </div>
            <div class="col-md-2">
               <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                  <div class="input-group input-group-dynamic">
                     <label class="form-label"></label>
                     <input type="date" class="form-control" placeholder="a" aria-label="" v-model="filters.DateTo">
                  </div>
               </div>
            </div>
            <div class="col-md-2">
               <div class="card card-body card-plain d-flex align-items-center flex-row">
                  <div class="input-group input-group-dynamic ">
                     <a class="float-right align-bottom btn bg-gradient-dark mb-0" @click="search()">
                        <i v-if="isInLoading" class="material-icons text-sm text-warning rotating">autorenew</i>
                        <i v-else class="material-icons text-sm">search</i>
                           &nbsp;&nbsp;Cerca
                     </a>
                  </div>
               </div>
            </div>
            <div class="col-md-3">
               <div class="card card-body card-plain d-flex align-items-center flex-row float-right">
                  <div class="input-group input-group-dynamic ">
                     <a class="float-right align-bottom btn bg-gradient-info mb-0" @click="createDuplicate({Token: getToken(), })" data-bs-toggle="modal" data-bs-target="#EditConfigurationModal">
                        <i class="material-icons text-sm">add</i> nuova configurazione
                     </a>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="row my-4">
         <div class="col-12">
            <div class="card">
               <div style="overflow-x: scroll">
                  <table class="table table-responsive align-items-center mb-0">
                     <thead>
                        <tr>
                           <th class="text-uppercase text-secondary text-xxs font-weight-bolder">Provvigione</th>
                           <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center">Codice</th>
                           <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center">Tipo</th>
                           <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center">Azienda</th>
                           <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center">Cod. Prod.</th>
                           <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center">Tipo Calcolo</th>
                           <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center">Commissione</th>
                           <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center">Comm. Fissa</th>
                           <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center">Cond. speciale</th>
                           <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center">Data contr. da</th>
                           <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center">Data contr. a</th>
                           <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center"></th>
                           <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center"></th>
                           <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center"></th>
                        </tr>
                     </thead>
                     <tbody style="border-top:0px" v-for="item in list" :key="item.Id">
                        <tr :class="{'bg-light' : item.ContractDateTo < new Date().toISOString().split('T')[0]}">
                           <td>
                              <div v-if="item.Id == itemToEdit" class="input-group input-group-dynamic ps-3">
                                 <input type="text" class="form-control text-xs" v-model="item.Description">
                              </div>
                              <a v-else :href="'#config_'+item.Id" data-bs-toggle="collapse" role="button" aria-expanded="false" @click="getSellersList(item)">
                                 <div class="ms-3 text-xs">{{ item.Description }}</div>
                              </a>
                           </td>
                           <td>
                              <div v-if="item.Id == itemToEdit" class="input-group input-group-dynamic">
                                 <input type="text" class="form-control text-center text-xs" v-model="item.Code">
                              </div>
                              <div v-else class="d-flex flex-column justify-content-center text-center text-xs">
                                 {{ item.Code }}
                              </div>
                           </td>
                           <td>
                              <div v-if="item.Id == itemToEdit" class="input-group input-group-dynamic">
                                 <select class="form-control text-center text-xs p-1 py-2" v-model="item.CommissionTableType">
                                    <option v-for="typ in CommissionTableTypeList" :key="typ.value" :value="typ.value">{{ typ.label }}</option>
                                 </select>
                              </div>
                              <div v-else class="d-flex flex-column justify-content-center text-center text-xs">
                                 {{ getLabelFromValue(CommissionTableTypeList, item.CommissionTableType) }}
                              </div>
                           </td>
                           <td>
                              <div v-if="item.Id == itemToEdit" class="input-group input-group-dynamic">
                                 <select class="form-control text-center text-xs p-1 py-2" v-model="item.Company">
                                    <option v-for="typ in CompanyList" :key="typ.value" :value="typ.value">{{ typ.label }}</option>
                                 </select>
                              </div>
                              <div v-else class="d-flex flex-column justify-content-center text-center text-xs">
                                 {{ getLabelFromValue(CompanyList, item.Company) }}
                              </div>
                           </td>
                           <td>
                              <div v-if="item.Id == itemToEdit" class="input-group input-group-dynamic">
                                 <input type="text" class="form-control text-center text-xs" v-model="item.ProductCode">
                              </div>
                              <div v-else class="d-flex flex-column justify-content-center text-center text-xs">
                                 {{ item.ProductCode }}
                              </div>
                           </td>
                           <td>
                              <div v-if="item.Id == itemToEdit" class="input-group input-group-dynamic">
                                 <select class="form-control text-center text-xs p-1 py-2" v-model="item.CommissionCalcType">
                                    <option v-for="typ in CommissionCalcTypeList" :key="typ.value" :value="typ.value">{{ typ.label }}</option>
                                 </select>
                              </div>
                              <div v-else class="d-flex flex-column justify-content-center text-center text-xs">
                                 {{ getLabelFromValue(CommissionCalcTypeList, item.CommissionCalcType) }}
                              </div>
                           </td>
                           <td>
                              <div v-if="item.Id == itemToEdit" class="input-group input-group-dynamic">
                                 <input type="number" class="form-control text-center text-xs" v-model="item.Commission" step="0.01" min="0" lang="it"> 
                                 <span class="text-xs pt-2"> €</span>
                              </div>
                              <div v-else class="d-flex flex-column justify-content-center text-center text-xs">
                                 {{ item.Commission }} %
                              </div>
                           </td>
                           <td>
                              <div v-if="item.Id == itemToEdit" class="input-group input-group-dynamic">
                                 <input type="number" class="form-control text-center text-xs" v-model="item.FixedCommission" step="0.01" min="0" lang="it"> 
                                 <span class="text-xs pt-2"> €</span>
                              </div>
                              <div v-else class="d-flex flex-column justify-content-center text-center text-xs">
                                 {{ item.FixedCommission.toFixed(2) }} €
                              </div>
                           </td>
                           <td>
                              <div v-if="item.Id == itemToEdit" class="input-group input-group-dynamic">
                                 <select class="form-control text-center text-xs p-1 py-2" v-model="item.HasSpecialConditions">
                                    <option :value=true>SI</option>
                                    <option :value=false>NO</option>
                                 </select>
                              </div>
                              <div v-else class="d-flex flex-column justify-content-center text-center text-xs">
                                 <div v-if="item.HasSpecialConditions">SI</div>
                                 <div v-else>NO</div>
                              </div>
                           </td>
                           <td>
                              <div v-if="item.Id == itemToEdit" class="input-group input-group-dynamic">
                                 <input type="date" class="form-control text-center text-xs" placeholder="a" aria-label="" v-model="item.ContractDateFrom">
                              </div>
                              <div v-else class="d-flex flex-column justify-content-center text-center text-xs">
                                 {{ formatDate(item.ContractDateFrom) }}
                              </div>
                           </td>
                           <td>
                              <div v-if="item.Id == itemToEdit" class="input-group input-group-dynamic">
                                 <input type="date" class="form-control text-center text-xs" placeholder="a" aria-label="" v-model="item.ContractDateTo">
                              </div>
                              <div v-else class="d-flex flex-column justify-content-center text-center text-xs">
                                 {{ formatDate(item.ContractDateTo) }}
                              </div>
                           </td>
                           <td>
                              <div v-if="item.Id == itemToEdit" class="d-flex flex-column justify-content-center text-center" type="button" @click="saveCommission(item)">
                                 <i class="material-icons text-center text-success fw-bold" style="font-size:1.5rem;">check</i>
                              </div>
                              <div v-else class="d-flex flex-column justify-content-center text-center" type="button" @click="editCommission(item.Id)">
                                 <i class="material-icons text-center" style="font-size:1rem;">edit</i>
                              </div>
                           </td>
                           <td>
                              <div v-if="item.Id == itemToEdit" class="d-flex flex-column justify-content-center text-center" type="button" @click="itemToEdit = 0">
                                 <i class="material-icons text-center text-danger fw-bold" style="font-size:1.5rem;">close</i>
                              </div>
                              <div v-else class="d-flex flex-column justify-content-center text-center" type="button" @click="createDuplicate(item)" data-bs-toggle="modal" data-bs-target="#EditConfigurationModal">
                                 <i class="material-icons text-center" style="font-size:1rem;">content_copy</i>
                              </div>
                           </td>
                           <td>
                              <div v-if="item.Id != itemToEdit" class="d-flex flex-column justify-content-center text-center" type="button" @click="deleteCommissionModal(item.Id)">
                                 <i class="material-icons text-center" style="font-size:1rem;">delete</i>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td class="p-0 bg-gray-200" colspan="14"> 
                              <div class="collapse rounded col-md-12 row" :id="'config_'+item.Id">
                                 <div class="col-md-8">
                                    <div v-if="item.Sellers.length == 0 && !isSellersLoading" class="text-center text-xs ps-4 py-4 opacity-6" style="max-width: 15rem;">
                                       Nessun venditore associato
                                    </div>
                                    <div v-else>
                                       <table class="table table-responsive align-items-center m-5 mt-2 me-3" style="max-width: 15rem;">
                                          <thead>
                                             <tr>
                                             <th class="text-uppercase text-secondary text-xxs font-weight-bolder ps-2">Nome</th>
                                             <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center">Cod. venditore</th>
                                             <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center">Cod. Configurazione</th>
                                             <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center">Azienda</th>
                                             <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center">Stock</th>
                                             <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center">Data contr. da</th>
                                             <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center">Data contr. a</th>
                                             <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center"></th>
                                             <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center"></th>
                                             <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center"></th>
                                             <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center"></th>
                                             </tr>
                                          </thead>
                                          <tbody style="border-top:0px">
                                             <tr v-for="seller in item.Sellers" :key="seller.Id">
                                                <td>
                                                   <div class="text-xs">
                                                      {{ seller.SellerName }}
                                                   </div>
                                                </td>
                                                <td class="text-center">
                                                   <div v-if="seller.Id == sellerToEdit" class="input-group input-group-dynamic">
                                                      <input type="text" class="form-control text-center text-xs" v-model="seller.SellerCode">
                                                   </div>
                                                   <div v-else class="d-flex flex-column justify-content-center text-center text-xs">
                                                      {{ seller.SellerCode }}
                                                   </div>
                                                </td>
                                                <td class="text-center">
                                                   <div class="text-xs">
                                                      {{ seller.ConfigurationCode }}
                                                   </div>
                                                </td>
                                                <td class="text-center">
                                                   <div v-if="seller.Id == sellerToEdit" class="input-group input-group-dynamic">
                                                      <select class="form-control text-center text-xs p-1 py-2" v-model="seller.Company">
                                                         <option v-for="typ in CompanyList" :key="typ.value" :value="typ.value">{{ typ.label }}</option>
                                                      </select>
                                                   </div>
                                                   <div v-else class="d-flex flex-column justify-content-center text-center text-xs">
                                                      {{ getLabelFromValue(CompanyList, seller.Company) }}
                                                   </div>
                                                </td>
                                                <td class="text-center">
                                                   <div v-if="seller.Id == sellerToEdit" class="input-group input-group-dynamic">
                                                      <select class="form-control text-center text-xs p-1 py-2" v-model="seller.Stock">
                                                         <option value="1">NUOVO</option>
                                                         <option value="2">USATO</option>
                                                      </select>
                                                   </div>
                                                   <div v-else class="text-xs">
                                                      <div v-if="seller.Stock == 1">NUOVO</div> 
                                                      <div v-else>USATO</div>
                                                   </div>
                                                </td>
                                                <td class="text-center">
                                                   <div v-if="seller.Id == sellerToEdit" class="input-group input-group-dynamic">
                                                      <input type="date" class="form-control text-center text-xs" placeholder="a" aria-label="" v-model="seller.ContractDateFrom">
                                                   </div>
                                                   <div v-else class="d-flex flex-column justify-content-center text-center text-xs">
                                                      {{ formatDate(seller.ContractDateFrom) }}
                                                   </div>
                                                </td>
                                                <td class="text-center">
                                                   <div v-if="seller.Id == sellerToEdit" class="input-group input-group-dynamic">
                                                      <input type="date" class="form-control text-center text-xs" placeholder="a" aria-label="" v-model="seller.ContractDateTo">
                                                   </div>
                                                   <div v-else class="d-flex flex-column justify-content-center text-center text-xs">
                                                      {{ formatDate(seller.ContractDateTo) }}
                                                   </div>
                                                </td>
                                                <td>
                                                   <div v-if="seller.Id == sellerToEdit" class="d-flex flex-column justify-content-center text-center" type="button" @click="saveSeller(seller, item)">
                                                      <i class="material-icons text-center text-success fw-bold" style="font-size:1.5rem;">check</i>
                                                   </div>
                                                   <div v-else class="d-flex flex-column justify-content-center text-center" type="button" @click="editSeller(seller.Id)">
                                                      <i class="material-icons text-center" style="font-size:1rem;">edit</i>
                                                   </div>
                                                </td>
                                                <td>
                                                   <div v-if="seller.Id == sellerToEdit" class="d-flex flex-column justify-content-center text-center" type="button" @click="sellerToEdit = 0">
                                                      <i class="material-icons text-center text-danger fw-bold" style="font-size:1.5rem;">close</i>
                                                   </div>
                                                   <div v-else class="d-flex flex-column justify-content-center text-center" type="button" @click="createNewSeller(seller)">
                                                      <i class="material-icons text-center" style="font-size:1rem;">content_copy</i>
                                                   </div>
                                                </td>
                                                <td>
                                                   <div v-if="seller.Id != sellerToEdit" class="d-flex flex-column justify-content-center text-center" type="button" @click="deleteSellerModal(seller.Id)">
                                                      <i class="material-icons text-center" style="font-size:1rem;">delete</i>
                                                   </div>
                                                </td>
                                             </tr>
                                             <tr v-if="newSellerCompiling">
                                                <td></td>
                                                <td class="text-center">
                                                   <div class="input-group input-group-dynamic">
                                                      <input type="text" class="form-control text-center text-xs" v-model="newSeller.SellerCode">
                                                   </div>
                                                </td>
                                                <td class="text-center">
                                                   <div class="text-xs">
                                                      {{ item.Code }}
                                                   </div>
                                                </td>
                                                <td class="text-center">
                                                   <div class="input-group input-group-dynamic">
                                                      <select class="form-control text-center text-xs p-1 py-2" v-model="newSeller.Company">
                                                         <option v-for="typ in CompanyList" :key="typ.value" :value="typ.value">{{ typ.label }}</option>
                                                      </select>
                                                   </div>
                                                </td>
                                                <td class="text-center">
                                                   <div class="input-group input-group-dynamic">
                                                      <select class="form-control text-center text-xs p-1 py-2" v-model="newSeller.Stock">
                                                         <option :value=1>NUOVO</option>
                                                         <option :value=2>USATO</option>
                                                      </select>
                                                   </div>
                                                </td>
                                                <td class="text-center">
                                                   <div class="input-group input-group-dynamic">
                                                      <input type="date" class="form-control text-center text-xs" placeholder="a" aria-label="" v-model="newSeller.ContractDateFrom">
                                                   </div>
                                                </td>
                                                <td class="text-center">
                                                   <div class="input-group input-group-dynamic">
                                                      <input type="date" class="form-control text-center text-xs" placeholder="a" aria-label="" v-model="newSeller.ContractDateTo">
                                                   </div>
                                                </td>
                                                <td>
                                                   <div class="d-flex flex-column justify-content-center text-center" type="button" @click="saveSeller(newSeller, item)">
                                                      <i class="material-icons text-center text-success fw-bold" style="font-size:1.5rem;">check</i>
                                                   </div>
                                                </td>
                                                <td>
                                                   <div class="d-flex flex-column justify-content-center text-center" type="button" @click="newSellerCompiling = false;">
                                                      <i class="material-icons text-center text-danger fw-bold" style="font-size:1.5rem;">close</i>
                                                   </div>
                                                </td>
                                             </tr>
                                             <tr v-else>
                                                <td>
                                                   <div v-if="!isSellersLoading" type="button" class="btn bg-dark btn-sm shadow" @click="createNewSeller()">
                                                      <i class="material-icons text-center text-white" style="font-size:1rem;">add</i>
                                                   </div>
                                                </td>
                                             </tr>
                                             <div v-if="isSellersLoading" class="text-center py-4 mx-auto">
                                                <i class="material-icons text-center rotating" style="font-size:3rem;">autorenew</i>
                                             </div>
                                          </tbody>
                                       </table>
                                    </div>
                                 </div>
                                 <div class="col-md-4">
                                    <div v-if="item.HasSpecialConditions" class="mt-4 me-5 pb-6" style="height: 95%;">
                                       <h6 class="text-uppercase opacity-7 text-xs">Setup Condizione speciale</h6>
                                       <textarea class="p-3 mt-2 w-100 border-none h-100 border-0 text-xs" style="height: auto;" v-model="item.ConditionSetup"></textarea>
                                    </div>
                                 </div>
                              </div>
                           </td>
                        </tr>
                     </tbody>
                  </table>
                  <div v-if="isInLoading" class="text-center py-4">
                     <i class="material-icons text-center rotating" style="font-size:3rem;">autorenew</i>
                  </div>
                  <div v-if="!isInLoading && list && list.length==0" class="text-center py-4">
                     Nessuna configurazione trovata 
                  </div>
                  <div v-if="list && list.length>0">
                     <Paginator :currentPage="currentPage" :totalPages="filters.TotalPages" :perPage="perPage" @pagechanged="onPageChange" class="pt-4"/>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <div class="modal fade" id="EditConfigurationModal" tabindex="-1" role="dialog" aria-labelledby="EditConfigurationModal" aria-hidden="true">
      <div class="modal-dialog modal-xl">
         <div class="modal-content p-4">
            <button type="button" class="btn-close top-right-corner py-4 px-5" data-bs-dismiss="modal" aria-label="Close">
               <span class="material-icons material-symbols-outlined light-dark">close</span>
            </button>
            <div class="row text-center">
               <h5>Crea Provvigione</h5>
            </div>
            <div class="row col-md-12 p-4">
               <div class="col-md-3">
                  <div class="input-group input-group-static">
                     <label class="text-xxs text-primary">Descrizione</label>
                     <input class="form-control text-xs" type="text" v-model="newConfiguration.Description">
                  </div>
               </div>
               <div class="col-md-1">
                  <div class="input-group input-group-static">
                     <label class="text-xxs text-primary">Cod. Provv.</label>
                     <input class="form-control text-xs" type="text" v-model="newConfiguration.Code">
                  </div>
               </div>
               <div class="col-md-1">
                  <div class="input-group input-group-static">
                     <label class="text-xxs text-primary">Cod. Prod.</label>
                     <input class="form-control text-xs" type="text" v-model="newConfiguration.ProductCode">
                  </div>
               </div>
               <div class="col-md-2">
                  <div class="input-group input-group-static">
                     <label class="text-xxs text-primary">Tipo</label>
                     <select class="form-control text-xs p-1 py-2" v-model="newConfiguration.CommissionTableType">
                        <option v-for="typ in CommissionTableTypeList" :key="typ.value" :value="typ.value">{{ typ.label }}</option>
                     </select>
                  </div>
               </div>
               <div class="col-md-2">
                  <div class="input-group input-group-static">
                     <label class="text-xxs text-primary">Azienda</label>
                     <select class="form-control text-xs p-1 py-2" v-model="newConfiguration.Company">
                        <option v-for="typ in CompanyList" :key="typ.value" :value="typ.value">{{ typ.label }}</option>
                     </select>
                  </div>
               </div>
               <div class="col-md-2">
                  <div class="input-group input-group-static">
                     <label class="text-xxs text-primary">Tipo Calcolo</label>
                     <select class="form-control text-xs p-1 py-2" v-model="newConfiguration.CommissionCalcType">
                        <option v-for="typ in CommissionCalcTypeList" :key="typ.value" :value="typ.value">{{ typ.label }}</option>
                     </select>
                  </div>
               </div>
            </div>
            <div class="row col-md-12 p-4">
               <div class="col-md-2">
                  <div class="input-group input-group-static">
                     <label class="text-xxs text-primary">Commissione (€)</label>
                     <input type="number" class="form-control text-xs" v-model="newConfiguration.Commission" step="0.01" min="0" lang="it"> 
                  </div>
               </div>
               <div class="col-md-2">
                  <div class="input-group input-group-static">
                     <label class="text-xxs text-primary">Commissione Agg. (€)</label>
                     <input type="number" class="form-control text-xs" v-model="newConfiguration.FixedCommission" step="0.01" min="0" lang="it"> 
                  </div>
               </div>
               <div class="col-md-3">
                  <div class="input-group input-group-static">
                     <label class="text-xxs text-primary">Data Contr. Da</label>
                     <input type="date" class="form-control text-xs" v-model="newConfiguration.ContractDateFrom">
                  </div>
               </div>
               <div class="col-md-3">
                  <div class="input-group input-group-static">
                     <label class="text-xxs text-primary">Data Contr. A</label>
                     <input type="date" class="form-control text-xs" v-model="newConfiguration.ContractDateTo">
                  </div>
               </div>
            </div>

            <div class="d-flex justify-content-end mt-4 px-4">
               <button @click="saveCommission(newConfiguration); LoadData()" type="button" name="button" class="btn bg-gradient-success m-0 ms-2 shadow-dark" data-bs-dismiss="modal">
                  <i class="material-icons text-sm">save</i>&nbsp;&nbsp;Salva
               </button>
               <button @click="newConfiguration={}" type="button" name="button" class="btn bg-gradient-secondary m-0 ms-2 shadow-dark" data-bs-dismiss="modal">
                  <i class="material-icons text-sm">delete</i>&nbsp;&nbsp;Annulla
               </button>
            </div>
         </div>
      </div>
  </div>
</template>

<script>
import fetchFromAPI from "../../script/api.js"
import helpers from '../../script/helpers.js'
import Paginator from "../../components/Paginator.vue"

export default {
   components: {
      Paginator
   },
   data() {
      return {
         currentPage: 1,
         perPage: 15,
         filters: {},
         list: [],
         sellersList: [],
         isInLoading: false,
         isSellersLoading: false,
         itemToEdit: 0,
         sellerToEdit: 0,
         configTab: true,
         newSellerCompiling: false,
         newSeller: {},
         newConfiguration: {},
         CommissionTableTypeList: [
            { value: 1, label: 'Dipendenti (Nuovo)'},
            { value: 2, label: 'Dipendenti (Usato)'},
            { value: 3, label: 'Agenti (Nuovo)'},
            { value: 4, label: 'Agenti (Usato)'}
         ],
         CompanyList: [
            { value: 1, label: 'Trivellato Spa'},
            { value: 2, label: 'Trivellato V. I. Srl'},
            { value: 3, label: 'CityCar Srl'},
            { value: 6, label: 'Padova Star Srl'},
            { value: 7, label: 'Atlante Srl'},
         ],
         CommissionCalcTypeList: [
            { value: 1, label: 'Margine'},
            { value: 2, label: 'Fatturato'},
            { value: 3, label: 'Fissa'},
            { value: 4, label: 'Nessuno'}
         ]

      };
   },

   methods: {
      formatDate: helpers.formatDate,

      getToken(){
         return helpers.token();
      },

      getLabelFromValue(list, value){
         for(var i=0; i<list.length; i++){
            if(list[i].value == value) return list[i].label;
         }
         return 'nessuno';
      },

      LoadData(){
         this.list = [];
         this.isInLoading = true;
         this.filters.Page = this.currentPage;
         this.filters.TotalRows = this.perPage;
         fetchFromAPI("CommissionConfiguration/GetConfigurationsList", this.filters, (res) => {
            this.filters = res.Filter;
            this.list = res.Configs;
            this.isInLoading = false;
         });
      },

      getSellersList(config){
         this.isSellersLoading = true;
         fetchFromAPI("CommissionConfiguration/LoadConfigurationSellerList", { Token : helpers.token(), Description: config.Code }, (res) => {
            config.Sellers = res;
            this.isSellersLoading = false;
         });
      },

      onPageChange(page) {
         this.filters.Page = page;
         this.currentPage = page;
         this.LoadData();
      },

      search(){
         this.filters.Page = 1;
         this.LoadData();
      },

      editCommission(id){
         this.itemToEdit = id;
      },

      editSeller(id){
         this.sellerToEdit = id;
      },

      saveCommission(item){
         fetchFromAPI("CommissionConfiguration/SaveCommissionConfiguration", item, () => {
            this.itemToEdit = 0;
            this.$toast.success("Modifiche salvate");
            this.newConfiguration = {};
            //this.LoadData();
         })
      },

      createNewSeller(item){
         var id = 0;
         if(item) id = item.Id;
         fetchFromAPI("CommissionConfiguration/LoadSellerConfiguration", { Token : helpers.token(), Id: id }, (res) => {
            this.newSeller = res;
            this.newSellerCompiling = true;
         })
      },

      saveSeller(seller, config){
         seller.ConfigurationCode = config.Code;
         fetchFromAPI("CommissionConfiguration/SaveSellerConfiguration", seller, () => {
            this.sellerToEdit = 0;
            this.newSellerCompiling = false;
            this.$toast.success("Modifiche salvate");
            this.LoadData();
         })
      },

      createDuplicate(item){
         fetchFromAPI("CommissionConfiguration/LoadCommissionConfiguration", item, (res) => {
            this.newConfiguration = res;
            this.newConfiguration.Id = 0;
         })
      },

      deleteCommission(id){
         fetchFromAPI("CommissionConfiguration/DeleteCommissionConfiguration", { Token : helpers.token(), Id: id }, () => {
            this.LoadData();
         })
      },

      deleteSeller(id){
         fetchFromAPI("CommissionConfiguration/DeleteSellerConfiguration", { Token : helpers.token(), Id: id }, () => {
            this.LoadData();
         })
      },

      deleteCommissionModal(id){
         this.$swal.fire({
         title: 'Sicuro?',
         text: 'Eliminare questa configurazione?',
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Si'
         }).then((result) => {
            if (result.isConfirmed) {
               this.deleteCommission(id);
               this.$toast.success("Elemento rimosso");
            }
         })
      },

      deleteSellerModal(id){
         this.$swal.fire({
         title: 'Sicuro?',
         text: 'Eliminare questo Venditore?',
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Si'
         }).then((result) => {
            if (result.isConfirmed) {
               this.deleteSeller(id);
               this.$toast.success("Elemento rimosso");
            }
         })
      },
   },

   created() {
      fetchFromAPI("CommissionConfiguration/BindFiltersForHub", { Token : helpers.token() }, (res) => {
         this.filters = res;
         this.LoadData();
      })
   }
}
</script>

<style scoped>



</style>