<template>
    <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-center">
            <li class="page-item" :class="{disabled: currentPage ==1}">
                <a class="page-link" role="button" @click="onClickPreviousPage" >
                    <span class="material-icons">keyboard_arrow_left</span>
                </a>
            </li>
            <li v-for="page in pages" :key="page" class="page-item" :class="{active: page==currentPage}" role="button">
                <a class="page-link" @click="onClickPage(page)">{{page}}</a>
            </li>
            <li class="page-item" :class="{disabled: currentPage == totalPages}" >
                <a class="page-link" @click="onClickNextPage" role="button">
                    <span class="material-icons">keyboard_arrow_right</span>
                </a>
            </li>
        </ul>
    </nav>
</template>

<script>
    export default {
        props: {
            maxVisibleButtons: {
                type: Number,
                required: false,
                default: 3
            },
            totalPages: {
                type: Number,
                required: true
            },
            perPage: {
                type: Number,
                required: true
            },
            currentPage: {
                type: Number,
                required: true
            }
        },
        computed: {
            startPage() {
                if (this.currentPage === 1) {
                    return 1;
                }
                if (this.totalPages>=this.maxVisibleButtons && this.currentPage == this.totalPages) {
                    return this.totalPages - this.maxVisibleButtons + 1;
                }
                return this.currentPage - 1;
            },
            pages() {
                const range = [];

                for (var i = this.startPage; i <= Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages); i++) {
                    range.push(i);
                }
                return range;
            }
        },
        methods: {
            onClickPreviousPage() {
                this.$emit('pagechanged', this.currentPage - 1);
            },
            onClickPage(page) {
                this.$emit('pagechanged', page);
            },
            onClickNextPage() {
                this.$emit('pagechanged', this.currentPage + 1);
            }
        },
    };
</script>