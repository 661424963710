<template>
  <div v-if="service" class="container-fluid py-4">
   <div class="row mt-4">
      <div class="col-lg-9 col-12 mx-auto position-relative">
         <div class="card">
            <div class="card-header p-3 pt-2">
               <div class="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                  <i class="material-icons opacity-10">tune</i>
               </div>
               <h6 class="mb-0">Modifica - {{service.ServiceName}}</h6>
            </div>
            <div class="card-body pt-2">
               <div class="row mt-4">
                     <div class="col-md-12">
                     <div class="input-group input-group-static">
                        <label>Nome Servizio</label>
                        <input type="text"  v-model="service.ServiceName" class="form-control">
                     </div>
                  </div>
               </div>
               <div class="row mt-4 mx-2">
                  <div class="col-md-6">
                     <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="service.New">
                        <label class="form-check-label" for="flexSwitchCheckDefault">Nuovo</label>
                     </div>
                  </div>
                  <div class="col-md-6">
                     <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="service.Used">
                        <label class="form-check-label" for="flexSwitchCheckDefault">Usato</label>
                     </div>
                  </div>
               </div>
               <div class="row mt-4">
                     <div class="col-md-12">
                        <div class="input-group input-group-static my-3">
                        <label>Descrizione e Note</label>
                        <input type="text" v-model="service.Note" class="form-control">
                        </div>
                     </div>
               </div>
               <div class="row mt-4 mx-1">
                  <label class="mt-4">Info aggiuntive, link, risorse ecc...</label>
                  <quill-editor v-if="service.HtmlModalInfo" v-model:content="service.HtmlModalInfo" contentType="html" />
               </div>
               <div class="row mt-4">
                  <div class="col-12">
                  <DropFiles ApiPath="FinancialService/FileUpload" :Token="getToken()" :Id="service.Id" @documentuploaded="handleUploadResponse($event)"></DropFiles>
               </div>
               </div>
               <div class="row mt-4">
                  <div class="col-md-12" v-for="att in service.Attachments" :key="att.Id">
                     <div class="input-group input-group-static my-3">
                        <div class="d-flex align-items-center">
                        <button @click="deleteAttachmentModal(att)" class="btn btn-icon-only btn-rounded btn-outline-danger mb-0 me-2 btn-sm d-flex align-items-center justify-content-center"><i class="material-icons text-sm" aria-hidden="true">clear</i></button>
                           <span>{{att.PublicFileName}}</span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="row mt-4">
                  <div class="col-md-4">
                     <div class="input-group input-group-static my-3">
                        <label>Margine Medio</label>
                        <input v-model="service.AVGMargin" type="text" class="form-control">
                     </div>
                  </div>
                  <div class="col-md-4">
                     <div class="input-group input-group-static my-3">
                        <label>Provvigione Dip.</label>
                        <input v-model="service.EmployeeCommission"  type="text" class="form-control">
                     </div>
                  </div>
                  <div class="col-md-4">
                     <div class="input-group input-group-static my-3">
                        <label>Provvigione Ag.</label>
                        <input v-model="service.AgentCommission"  type="text" class="form-control">
                     </div>
                  </div>
               </div>
               <div class="row mt-6 mx-2">
                  <div class="col-md-6">
                     <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="service.Active">
                        <label class="form-check-label" for="flexSwitchCheckDefault">Servizio Attivo</label>
                     </div>
                  </div>
                  <div class="col-md-6">
                     <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="service.IsSuggested">
                        <label class="form-check-label" for="flexSwitchCheckDefault">Servizio Consigliato</label>
                     </div>
                  </div>
               </div>
               <div class="d-flex justify-content-end mt-4">
                  <button @click="savedata(1)" type="button" name="button" class="btn bg-gradient-dark m-0 ms-2">
                     <i v-if="isInLoading" class="material-icons text-sm text-warning rotating">autorenew</i>
                     <i v-else class="material-icons text-sm">save</i>
                     &nbsp;&nbsp;Salva
                  </button>
                  <button @click="deleteItemModal(service)" type="button" name="button" class="btn bg-gradient-danger m-0 ms-2">
                     <i v-if="isInLoading" class="material-icons text-sm text-warning rotating">autorenew</i>
                     <i v-else class="material-icons text-sm">delete</i>
                     &nbsp;&nbsp;Elimina
                  </button>
               </div>
            </div>

         </div>
      </div>
   </div>
</div>

</template>
<script>
import fetchFromAPI from "../script/api.js";
import helpers from '../script/helpers.js';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css'; 
import DropFiles from "./DropFiles.vue";
import { toRaw } from 'vue';

export default {
   props:['model'],
   components: {
      QuillEditor,
      DropFiles
   },
   data() {
      return {
         service: this.model,
         isInLoading: false
      };
   },
   methods:{
      getToken(){
         return helpers.token();
      },
      handleUploadResponse(docs){
         var list = (this.service.Attachments ? toRaw(this.service.Attachments) : []);
         list=list.concat(docs);
         this.service.Attachments = list;
         this.$toast.success("Upload eseguito");
      },
      removeDoc(doc){
         var rawDoc = toRaw(doc);
         var list = toRaw(this.service.Attachments);
         var newList = helpers.removeItemFromList(list,rawDoc);
         this.service.Attachments = newList;
      },
      savedata(type){
         if(type>0){
            this.isInLoading = true;
            fetchFromAPI("FinancialService/SaveItem", this.service, (res) => {
               this.service = res;
               this.isInLoading = false;
               this.$toast.success("Modifiche Salvate");
               this.$emit('service-modified');
            });
         }
      },
      deleteAttachment(item){
         fetchFromAPI("FinancialService/DeleteAttachment", item, (res) => {
            if(res) this.LoadData();
         });
      },
      deleteAttachmentModal(item){
      this.$swal.fire({
         title: 'Sicuro?',
         text: 'Vuoi cancellare l\'allegato "' + item.PublicFileName + '"?',
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Si, vai!'
      }).then((result) => {
         if (result.isConfirmed) {
            this.$swal.fire('Ok fatto!','File cancellato con successo.')
            this.removeDoc(item);
            this.deleteAttachment(item)
         }
      })
      },
      deleteItem(item){
         this.isInLoading = true
         fetchFromAPI("FinancialService/DeleteItem", item, (res) => {
            if(res){
               this.isInLoading = false;
               this.$emit('service-modified');
            }
         });
      },
      deleteItemModal(item){
         this.$swal.fire({
            title: 'Sicuro?',
            text: 'Vuoi cancellare i dettagli del servizio ' + item.ServiceName + '?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, vai!'
         }).then((result) => {
            if (result.isConfirmed) {
               this.$swal.fire('Ok fatto!','Oggetto cancellato con successo.');
               this.deleteItem(item);
            }
         })
      },
      LoadData(){
         if(this.service.Id==0){
            fetchFromAPI("FinancialService/LoadItem", { Token: localStorage.getItem('sa_trivellato') , Id: this.service.Id}, (res) => {
               this.service = res;
            });
         }
      }
   },
   created() {
      //this.LoadData();
   }
}
</script>