<template>
    <div class="container-fluid py-4">
       <div class="card-body p-4">
          <div class="row">
             <div class="col-md-3 mb-md-0 mb-4">
                <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                   <div class="input-group input-group-dynamic">
                      <label class="form-label"></label>
                      <input class="form-control" aria-label="" placeholder="descrizione o codice"  type="text" v-model="filters.Description">
                   </div>
                </div>
             </div>
             <!--<div class="col-md-3">
                <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                   <div class="input-group input-group-dynamic">
                      <label class="form-label"></label>
                      <input type="date" class="form-control" placeholder="Da" aria-label="" v-model="filters.DateFrom" >
                   </div>
                </div>
                </div>
                <div class="col-md-3">
                <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                   <div class="input-group input-group-dynamic">
                      <label class="form-label"></label>
                      <input type="date" class="form-control" placeholder="a" aria-label="" v-model="filters.DateTo" >
                   </div>
                </div>
                </div>-->
             <div class="col-md-3">
                <div class="card card-body card-plain d-flex align-items-center flex-row">
                   <div class="input-group input-group-dynamic ">
                      <a class="float-right align-bottom btn bg-gradient-dark mb-0" @click="LoadData()">
                      <i v-if="isInLoading" class="material-icons text-sm text-warning rotating">autorenew</i>
                      <i v-else class="material-icons text-sm">search</i>
                      &nbsp;&nbsp;Cerca
                      </a>
                   </div>
                </div>
             </div>
          </div>
       </div>
          <div class="row">
             <div class="col-12 mt-4">
                <div class="card mb-4" v-for="(item, index) in list" :key="index">
                   <div class="card-header pb-0 p-3">
                      <h6>Ubicazione: {{item.Location}}</h6>
                   </div>
                   <div class="card-body px-0 pt-0 pb-2">
                      <div class="table-responsive p-0">
                         <table class="table align-items-center mb-0">
                            <thead>
                               <tr>
                                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Descrizione/Codice</th>
                                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Qta Disponibile</th>
                                  <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Qta Prenotata</th>
                               </tr>
                            </thead>
                            <tbody>
                               <tr   v-for="(subItem, index) in item.TyreList" :key="index">
                                  <td>
                                     <div class="d-flex px-3 py-1">
                                        <div>
                                           <img src="https://www.pngall.com/wp-content/uploads/8/Car-Tire-PNG-Free-Download.png" class="avatar me-3" alt="avatar image">
                                        </div>
                                        <div class="d-flex flex-column justify-content-center">
                                           <h6 class="mb-0 text-sm">{{subItem.Description}}</h6>
                                           <p class="text-sm font-weight-normal text-secondary mb-0"><span class="text-info font-weight-bold">{{subItem.Code}}</span></p>
                                        </div>
                                     </div>
                                  </td>
                                  <td>
                                     <p class="text-sm font-weight-normal mb-0">{{subItem.Quantity}}</p>
                                  </td>
                                  <td class="align-middle text-center text-sm">
                                     <p class="text-sm font-weight-normal mb-0">{{subItem.BookedQuantity}}</p>
                                  </td>
                               </tr>
                            </tbody>
                         </table>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
 </template>
 <script>
    import fetchFromAPI from "../../script/api.js"
    export default {
          data() {
             return {
             filters: {},
             list: [],
             isInLoading: false
          };
       },
       methods: {
        LoadData(){
              /*helpers.resetFilter('tiresSearchList')
              helpers.setObject('tiresSearchFilter',this.filters);*/
              this.isInLoading = true;
              fetchFromAPI("TiresSearch/LoadList", this.filters, (res) => {
                   this.list = res;
                   this.isInLoading = false;
                   /*helpers.setObject('tiresSearchList', res);*/
            });
        },
        formatDate(dateString) {
          const date = new Date(dateString);
          let dateFormatted = new Intl.DateTimeFormat('it-IT', { dateStyle: 'full', timeStyle: 'long' }).format(date);
          if(dateFormatted != "1 gennaio 1"){
              return dateFormatted;
          }
          return "";
        }
    },
        created() {
            /*let filterData = helpers.getFilters('tiresSearchFilter');
            if (filterData && typeof filterData !== "undefined") {
                this.filters = filterData;
                this.LoadData();
                return;
            }*/
            fetchFromAPI("TiresSearch/BindFiltersForHub", { Token : localStorage.getItem('sa_trivellato') }, (res) => {
            this.filters = res;
            //this.LoadData()
            })
    
        }
           
    }
 </script>