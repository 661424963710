<template>
       <div v-if="filters" class="container-fluid py-4">
        <div class="card-body p-4">
            <div class="row">
                <div class="col-md-3 mb-md-0 mb-4">
                    <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                        <div v-if="filters.GroupType==3" class="input-group input-group-dynamic">
                           <h5>{{ filters.NameAndSurname }}</h5>
                        </div>
                        <div v-else class="input-group input-group-dynamic">
                           <input class="form-control" aria-label="" placeholder="Cerca per venditore"  type="text" v-model="filters.NameAndSurname" :disabled="filters.GroupType==3">
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                        <div class="input-group input-group-dynamic">
                           <input class="form-control" aria-label="" placeholder="Cerca cliente"  type="text" v-model="filters.CustomerData">
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                        <div class="input-group input-group-dynamic">
                           <input type="date" class="form-control" placeholder="Da" aria-label="" v-model="filters.DateFrom" >
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                        <div class="input-group input-group-dynamic">
                           <input type="date" class="form-control" placeholder="a" aria-label="" v-model="filters.DateTo" >
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="card card-body card-plain d-flex align-items-center flex-row">
                        <div class="input-group input-group-dynamic ">
                            <a class="float-right align-bottom btn bg-gradient-dark mb-0" @click="searchData()">
                                <i v-if="isInLoading" class="material-icons text-sm text-warning rotating">autorenew</i>
                                <i v-else class="material-icons text-sm">search</i>
                                &nbsp;&nbsp;Cerca
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      <div class="row">
         <div class="col-12">
            <div class="card my-4">
               <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                  <div class="bg-gradient-light shadow-dark border-radius-lg pt-4 pb-3">
                     <h5 class="text-dark text-capitalize ps-3">Clienti</h5>
                  </div>
               </div>
               <div class="card-body px-0 pb-2">
                  <div v-if="list.Customers && list.Customers.length<=0" class="py-2 px-4">Nessun cliente trovato</div>
                  <div v-if="list.Customers && list.Customers.length>0" class="p-0" style="overflow-x: scroll;">
                     <table class="table table-responsive align-items-center mb-0">
                        <thead>
                           <tr>
                              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8">
                                 Nome Cognome
                                 <div type="button" @click="reorderAlphabeticalList()" class="btn m-0 p-0 px-1 mb-1">
                                    <i v-if="filters.PropertyName=='DESC' || filters.PropertyName==''" class="material-icons pltext-dark text-xs">arrow_upward</i>
                                    <i v-if="filters.PropertyName=='ASC'" class="material-icons pltext-dark text-xs">arrow_downward</i>
                                 </div>                              
                              </th>
                              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8">Codice Fiscale</th>
                              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8">P.IVA</th>
                              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8">Codice Sf</th>
                              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8">Codice Infinity</th>
                              <th v-if="filters.GroupType!=3" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8">Venditore</th>
                              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8">Pratiche pronte</th>
                              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8">
                                 Data Ultima Modifica
                                 <div type="button" @click="reorderList()" class="btn m-0 p-0 px-1 mb-1">
                                    <i v-if="filters.ActionDescription=='DESC'" class="material-icons pltext-dark text-xs">arrow_downward</i>
                                    <i v-if="filters.ActionDescription=='ASC'" class="material-icons pltext-dark text-xs">arrow_upward</i>
                                 </div>
                              </th>
                           </tr>
                        </thead>
                        <tbody v-if="!isInLoading && list.Customers && list.Customers.length>0">
                           <tr v-for="item in list.Customers" :key="item.Id">
                              <td>
                                 <div class="d-flex px-3 text-wrap">
                                    <div class="d-flex flex-column justify-content-center">
                                       <h6 class="mb-0 text-sm">
                                          <router-link :to="{path:'/CustomerDocuments', params:{itemData : item}, query: {id:item.Id}}" class="text-secondary" exact-path >
                                          <p v-if="item.BusinessName" class="font-weight-bold text-wrap">{{item.BusinessName}}</p>
                                          <p v-else class="text-xs">nome mancante</p>
                                          </router-link>
                                       </h6>
                                    </div>
                                 </div>
                              </td>
                              <td>
                                 <div class="d-flex px-3 text-wrap">
                                    <div class="d-flex flex-column justify-content-center">
                                       <p>{{ item.FiscalCode }}</p>
                                    </div>
                                 </div>
                              </td>
                              <td>
                                 <div class="d-flex px-3">
                                    <div class="d-flex flex-column justify-content-center">
                                       <p>{{ item.VatNumber }}</p>
                                    </div>
                                 </div>
                              </td>
                              <td>
                                 <div class="d-flex px-3">
                                    <div class="d-flex flex-column justify-content-center">
                                       <p>{{ item.CustomerCode }}</p>
                                    </div>
                                 </div>
                              </td>
                              <td>
                                 <div class="d-flex px-3">
                                    <div class="d-flex flex-column justify-content-center">
                                       <p>{{ item.InfinityCode }}</p>
                                    </div>
                                 </div>
                              </td>
                              <td v-if="filters.GroupType!=3">
                                 <div class="d-flex px-3">
                                    <div class="d-flex flex-column justify-content-center">
                                       <p>{{ item.SellerName}}</p>
                                    </div>
                                 </div>
                              </td>
                              <td>
                                 <div class="d-flex px-3">
                                    <div class="d-flex flex-column justify-content-center">
                                       <p> {{ ReadyDossiers(item.Dossiers) }} / <span class="font-weight-bold">{{ item.Dossiers.length }}</span></p>
                                    </div>
                                 </div>
                              </td>
                              <td>
                                 <div class="d-flex px-3">
                                    <div class="d-flex flex-column justify-content-center">
                                       <p>{{ GetLastUsefulDate(item) }}</p>
                                    </div>
                                 </div>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                     <div v-if="isInLoading" class="text-center py-4">
                        <i class="material-icons text-center rotating" style="font-size:3rem;">autorenew</i>
                     </div>
                     <div v-if="list.Customers && list.Customers.length>0">
                        <Paginator :currentPage="currentPage" :totalPages="filters.TotalPages" :perPage="perPage" @pagechanged="onPageChange" class="pt-4"/>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import fetchFromAPI from "../../script/api.js"
import helpers from '../../script/helpers.js'
import Paginator from "../../components/Paginator.vue"
export default {
   components: {
      Paginator
   },
   data() {
      return {
         currentPage: 1,
         perPage: 10,
         filters: {},
         list: [],
         isInLoading: false,
      };
   },
   methods: {
      LoadData(){
         helpers.resetFilter('customersList')
         helpers.setObject('customersFilter',this.filters);
         this.isInLoading = true;
         fetchFromAPI("CustomerDocumentsSpace/GetCustomersList", this.filters, (res) => {
            this.list = res;
            this.filters = res.Filter;
            if(this.filters.DateFrom) this.filters.DateFrom = this.filters.DateFrom.toString().slice(0,10);
            if(this.filters.DateTo) this.filters.DateTo = this.filters.DateTo.toString().slice(0,10);
            this.isInLoading = false;
            helpers.setObject('customersList', res);
         });
      },
      reorderList(){
         this.filters.PropertyName="";
         if (this.filters.ActionDescription=="DESC") this.filters.ActionDescription="ASC";
         else this.filters.ActionDescription="DESC";
         this.LoadData();
      },
      reorderAlphabeticalList(){
         if (this.filters.PropertyName=="") this.filters.PropertyName="ASC";
         else if (this.filters.PropertyName=="DESC") this.filters.PropertyName="ASC";
         else this.filters.PropertyName="DESC";
         this.LoadData();
      },
      searchData(){
         this.filters.Page = 1;
         this.LoadData();
      },
      ReadyDossiers(dossiers){
         var n=0;
         for(let i=0; i<dossiers.length; i++){
            if(dossiers[i].DossierStatus==1 || dossiers[i].DossierStatus==2) n++;
         }
         return n;
      },
      LastDossierDate(dossiers){
         if(dossiers.length>0){
            var date=dossiers[0].DossierDate;
            for(let i=0; i<dossiers.length; i++){
               if(dossiers[i].DossierDate>date) date=dossiers[i].DossierDate;
            }
            date=helpers.formatDate(String(date));
            return date;
         }
         return "nessuna pratica"
      },
      GetLastUsefulDate(customer){
         if(helpers.checkValidDate(customer.LastModifyDate)) return helpers.formatDate(String(customer.LastModifyDate));
         return helpers.formatDate(String(customer.CreatedDate));
      },
      onPageChange(page) {
         this.filters.Page = page;
         this.currentPage = page;
         this.LoadData();
      }
   },
   created() {
      let filterData = helpers.getFilters('customersFilter');
      if (filterData && typeof filterData !== "undefined") {
         this.filters = filterData;
      }
      let data = helpers.getFilters('customersList');
      if (data && typeof data !== "undefined") {
         this.list = data;
         return;
      }
   },
   mounted(){
      fetchFromAPI("CustomerDocumentsSpace/BindFiltersForHub", { Token : helpers.token() }, (res) => {
         if(!res.ReducedView){
            this.$router.push({ name: 'PageNotFound' });
         }
         this.filters = res;
         this.filters.TotalRows = this.perPage;
         this.filters.Page = this.currentPage;
         this.LoadData();
      });
   }
}
</script>