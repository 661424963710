<template>
  <div id="home">
    <div v-if="marginData && marginData && marginData.QuoteMarginFooter" class="container-fluid py-4">
      <div class="row">
        <div class="col-xl-4 col-sm-6 mb-xl-0 mb-4 mr-2">
          <div class="card" style="height: 350px;">
            <div class="card-header p-3 pt-2">
              <div class="icon icon-lg icon-shape bg-gradient-dark shadow-dark text-center border-radius-xl mt-n4 position-absolute">
                <i class="material-icons opacity-10">request_quote</i>
              </div>
              <div class="text-end pt-1">
                <p class="text-sm mb-0 text-capitalize">Margine Ferro Nuovo</p>
                <h4 class="mb-0">{{marginData.QuoteMarginFooter.QuoteMargin}} &euro;</h4>
              </div>
            </div>
            <div class="card-body">
              <div class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg">
                  <div class="d-flex flex-column">
                    <h6 class="mb-3 text-sm">Preventivo {{marginData.QuoteMarginHeader.QuoteNumber}} del {{marginData.QuoteMarginHeader.QuoteDate}}</h6>
                    <span class="mb-2 text-xs">Cliente: <span class="text-dark font-weight-bold ms-sm-2">{{marginData.QuoteMarginHeader.AccountDescription}}</span></span>
                    <span class="mb-2 text-xs">Venditore: <span class="text-dark ms-sm-2 font-weight-bold">{{marginData.QuoteMarginHeader.SellerName}}</span></span>
                    <span class="text-xs">Vettura: <span class="text-dark ms-sm-2 font-weight-bold">{{marginData.QuoteMarginHeader.VehicleDescription}}</span></span>
                  </div>
                </div>
            </div>
            <hr class="dark horizontal my-0">
            <div class="card-footer p-3">
              <p class="mb-0">margine <span class="text-secondary text-sm font-weight-bolder">{{marginData.QuoteMarginFooter.PercentageQuoteMargin}} </span></p>
            </div>
          </div>
        </div>
        
        <div class="col-xl-4 col-sm-6 mb-xl-0 mb-4 mr-2">
           <div class="card" style="height: 350px;">
            <div class="card-header p-3 pt-2">
              <div class="icon icon-lg icon-shape bg-gradient-dark shadow-dark text-center border-radius-xl mt-n4 position-absolute">
                <i class="material-icons opacity-10">account_balance</i>
              </div>
              <div class="text-end pt-1">
                <p class="text-sm mb-0 text-capitalize">Margine Finanziario</p>
                <h4 class="mb-0">{{marginData.FinancialData.FinancialMargin}} &euro;</h4>
              </div>
            </div>
            <div class="card-body">
              <div v-if="marginData.FinancialData.FinancialMarginDecimal > 0" class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg">
                  <div class="d-flex flex-column">
                    <h6 class="mb-3 text-sm">Dettagli</h6>
                    <span class="mb-2 text-xs">Finanziaria: <span class="text-dark font-weight-bold ms-sm-2">{{marginData.FinancialData.FinancialInstitute}}</span></span>
                    <span class="mb-2 text-xs">Tipo di finanziamento: <span class="text-dark ms-sm-2 font-weight-bold">{{marginData.FinancialData.FinancialType}}</span></span>
                    <span class="text-xs">Piano Finanziario: <span class="text-dark ms-sm-2 font-weight-bold">{{marginData.FinancialData.FinancialPlane}}</span></span>
                  </div>
                </div>
               <div v-else class="py-3 align-items-center">
                    <small class="text-danger font-weight-bold">Mancano i dati del finanziamento.</small>
              </div>
            </div>
            <hr class="dark horizontal my-0">
            <div class="card-footer p-3">
              <!--<p class="mb-0"><span class="text-danger text-sm font-weight-bolder">-2%</span> than yesterday</p>-->
            </div>
          </div>
          </div>
          <div class="col-xl-4 col-sm-6 mb-xl-0 mb-4">
           <div class="card" style="height: 350px;">
            <div class="card-header p-3 pt-2">
              <div class="icon icon-lg icon-shape bg-gradient-dark shadow-dark text-center border-radius-xl mt-n4 position-absolute">
                <i class="material-icons opacity-10">car_repair</i>
              </div>
              <div class="text-end pt-1">
                <p class="text-sm mb-0 text-capitalize">Margine Permuta</p>
                <h4 class="mb-0">{{marginData.QuoteMarginFooter.BarterTotalMargin}} &euro;</h4>
              </div>
            </div>
            <div v-if="marginData.QuoteMarginFooter.BarterData.length > 0" style="overflow-y : auto" >
             <div class="card-body" v-for="item in marginData.QuoteMarginFooter.BarterData" :key="item.Plate">
              <div class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg">
                  <div class="d-flex flex-column">
                    <h6 class="mb-3 text-sm">{{item.Description}}</h6>
                    <span class="mb-2 text-xs">Targa: <span class="text-dark font-weight-bold ms-sm-2">{{item.Plate}}</span></span>
                    <span class="mb-2 text-xs">Km: <span class="text-dark font-weight-bold ms-sm-2">{{item.Km}}</span></span>
                    <span class="mb-2 text-xs">Immatricolazione: <span class="text-dark ms-sm-2 font-weight-bold">{{item.RegistrationDate}}</span></span>
                  </div>
                </div>
            </div>
            </div>
            <div v-else class="card-body">
               <div class="py-3 align-items-center">
                    <small class="text-danger font-weight-bold">Permuta con dati mancanti</small>
              </div>
            </div>
            <hr v-if="marginData.QuoteMarginFooter.BarterData.length > 0" class="dark horizontal my-0">
            <div v-if="marginData.QuoteMarginFooter.BarterData.length > 0 && marginData.QuoteMarginFooter.BarterData[0].hasTaxableEstimatedSellingPrice" class="card-footer p-3">
              <p class="mb-0">margine <span class="text-secondary text-sm font-weight-bolder">{{marginData.QuoteMarginFooter.PercentageBarterTotalMargin}} </span></p>
            </div>
          </div>
        </div>
      </div>
      
      <div class="row mb-4 mt-4">
        <div class="col-lg-8 col-md-6 mb-md-0 mb-4">
          <div class="card">
            <div class="card-header pb-0">
              <div class="row">
                  <h6>Valutazione Margine Preventivo + Finanziamento</h6>
                <div class="row">
                <div class="col-lg-1">
                    <div class="icon">
                      <i v-if="marginData.QuoteMarginFooter.MarginProgressBarData.Color == 'bg-danger'" class="material-icons opacity-10 text-danger">error_outline</i>
                      <i v-else-if="marginData.QuoteMarginFooter.MarginProgressBarData.Color == 'bg-warning'" class="material-icons opacity-10 text-warning">warning_amber</i>
                      <i v-else-if="marginData.QuoteMarginFooter.MarginProgressBarData.Color == 'bg-success'" class="material-icons opacity-10 text-success">thumb_up_alt</i>
                    </div>
                </div>
                <div class="col-lg-11">
                    <div style="height:15px" class="progress mt-1"><div :class="['progress-bar', 'progress-bar-striped', 'progress-bar-animated', marginData.QuoteMarginFooter.MarginProgressBarData.Color]" role="progressbar" :aria-valuenow=marginData.QuoteMarginFooter.MarginProgressBarData.Percentage aria-valuemin="0" aria-valuemax="100" :style="{width: marginData.QuoteMarginFooter.MarginProgressBarData.Percentage + '%', height:'15px'}"></div></div>
                </div>
                </div>
              </div>
            </div>
            <hr class="dark horizontal my-0">
            <div class="card-footer p-3">
              <p v-if="marginData.QuoteMarginFooter.MarginProgressBarData.Color" class="mb-0">margine con finanziamento <span class="text-sm font-weight-bolder"> 
                  <span v-if="marginData.QuoteMarginFooter.MarginProgressBarData.Color == 'bg-danger'" class="opacity-10 text-danger">{{marginData.QuoteMarginFooter.GranTotalMarginWithoutBarter}} &euro;</span>
                  <span v-if="marginData.QuoteMarginFooter.MarginProgressBarData.Color == 'bg-warning'" class="opacity-10 text-warning">{{marginData.QuoteMarginFooter.GranTotalMarginWithoutBarter}} &euro;</span>
                  <span v-if="marginData.QuoteMarginFooter.MarginProgressBarData.Color == 'bg-success'" class="opacity-10 text-success">{{marginData.QuoteMarginFooter.GranTotalMarginWithoutBarter}} &euro;</span>
                </span>
                </p>
              <p v-if="marginData.QuoteMarginFooter.MarginProgressBarData.Color" class="mb-0"><span class="align-top icon mt-1 text-dark material-icons">trending_flat</span> margine minimo <span class="text-secondary text-sm font-weight-bolder"> {{marginData.QuoteMarginFooter.MarginProgressBarData.MinMargin}} &euro;</span></p>
              <p v-else class="mb-0"><span class="align-top icon mt-1 small text-warning material-icons">warning_amber</span> parametri mancanti per il codice veicolo <span class="text-secondary text-sm font-weight-bolder"> "{{marginData.QuoteMarginHeader.ModelCode}}"</span></p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="card">
            <div class="card-header pb-0">
              <h6>Valutazione Margine Complessivo</h6>
              <div class="row container-fluid">
                <div class="col-sm-1">
                    <div class="icon">
                      <i v-if="marginData.QuoteMarginFooter.UsedMarginProgressBarData.Color == 'bg-danger'" class="material-icons opacity-10 text-danger">error_outline</i>
                      <i v-else-if="marginData.QuoteMarginFooter.UsedMarginProgressBarData.Color == 'bg-warning'" class="material-icons opacity-10 text-warning">warning_amber</i>
                      <i v-else-if="marginData.QuoteMarginFooter.UsedMarginProgressBarData.Color == 'bg-success'" class="material-icons opacity-10 text-success">thumb_up_alt</i>
                    </div>
                </div>
                <div class="col-sm-11">
                    <div style="height:15px" class="progress mt-1"><div :class="['progress-bar', 'progress-bar-striped', 'progress-bar-animated', marginData.QuoteMarginFooter.UsedMarginProgressBarData.Color]" role="progressbar" :aria-valuenow=marginData.QuoteMarginFooter.UsedMarginProgressBarData.Percentage aria-valuemin="0" aria-valuemax="100" :style="{width: marginData.QuoteMarginFooter.UsedMarginProgressBarData.Percentage + '%', height:'15px'}"></div></div>            
                </div>
              </div>
            </div>
            <hr class="dark horizontal my-0">
            <div class="card-footer p-3">
                  <p v-if="marginData.QuoteMarginFooter.UsedMarginProgressBarData.Color" class="mb-0">margine totale <span class="text-sm font-weight-bolder"> 
                  <span v-if="marginData.QuoteMarginFooter.UsedMarginProgressBarData.Color == 'bg-danger'" class="opacity-10 text-danger">{{marginData.QuoteMarginFooter.GranTotalMargin}} &euro;</span>
                  <span v-if="marginData.QuoteMarginFooter.UsedMarginProgressBarData.Color == 'bg-warning'" class="opacity-10 text-warning">{{marginData.QuoteMarginFooter.GranTotalMargin}} &euro;</span>
                  <span v-if="marginData.QuoteMarginFooter.UsedMarginProgressBarData.Color == 'bg-success'" class="opacity-10 text-success">{{marginData.QuoteMarginFooter.GranTotalMargin}} &euro;</span>
                </span>
                </p>
              <p v-if="marginData.QuoteMarginFooter.UsedMarginProgressBarData.Color" class="mb-0"><span class="align-top icon mt-1 text-dark material-icons">trending_flat</span> margine minimo <span class="text-secondary text-sm font-weight-bolder"> {{marginData.QuoteMarginFooter.UsedMarginProgressBarData.MinMargin}} &euro;</span></p>
              <p v-else class="mb-0"><span class="align-top icon mt-1 small text-warning material-icons">warning_amber</span> parametri mancanti per il codice veicolo <span class="text-secondary text-sm font-weight-bolder"> "{{marginData.QuoteMarginHeader.ModelCode}}"</span></p>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header blur p-0 position-relative mt-n4 mx-3 z-index-2">
              <div class="bg-gradient-light shadow-dark  border-radius-lg pt-4 pb-3">
                <h6 class="text-dark text-capitalize ps-3">Riepilogo Margini</h6>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"></th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"></th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"></th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"></th>
                      <th class="text-secondary opacity-7"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div class="d-flex px-2 py-1">
                          <!--<div>
                            <img src="../assets/img/team-2.jpg" class="avatar avatar-sm me-3 border-radius-lg" alt="user1">
                          </div>-->
                          <div class="icon icon-xl icon-shape">
                            <i class="material-icons opacity-10 text-dark">request_quote</i>
                          </div>
                          <div class="d-flex flex-column justify-content-center">
                            <h6 class="mb-0 text-sm">Preventivo {{marginData.QuoteMarginHeader.QuoteNumber}} </h6>
                            <p class="text-xs text-secondary mb-0">{{marginData.QuoteMarginHeader.AccountDescription}}</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p class="text-xs font-weight-bold mb-0">Costi</p>
                        <p class="text-xs text-secondary mb-0">{{marginData.QuoteMarginFooter.QuoteCosts}} &euro;</p>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <p class="text-xs font-weight-bold mb-0">Ricavi</p>
                        <p class="text-xs text-secondary mb-0">{{marginData.QuoteMarginFooter.QuoteRevenues}} &euro;</p>
                      </td>
                      <td class="align-middle text-center">
                        <p class="text-xs font-weight-bold mb-0">Margine</p>
                        <p class="text-xs text-secondary mb-0">{{marginData.QuoteMarginFooter.QuoteMargin}} &euro;</p>
                      </td>
                      <td class="align-middle">
                          <span class="badge badge-sm bg-gradient-secondary blur">{{marginData.QuoteMarginFooter.PercentageQuoteMargin}}</span>
                      </td>
                    </tr>
                    <tr v-for="item in marginData.QuoteMarginFooter.BarterData" :key="item.Plate">
                      <td >
                        <div class="d-flex px-2 py-1">
                          <!--<div>
                            <img src="../assets/img/team-2.jpg" class="avatar avatar-sm me-3 border-radius-lg" alt="user1">
                          </div>-->
                          <div class="icon icon-xl icon-shape">
                            <i class="material-icons opacity-10 text-dark">car_repair</i>
                          </div>
                          <div class="d-flex flex-column justify-content-center">
                            <h6 class="mb-0 text-sm">Permuta {{item.Plate}} </h6>
                            <p class="text-xs text-secondary mb-0">{{item.Description}}</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p class="text-xs font-weight-bold mb-0">Ritiro</p>
                        <p class="text-xs text-secondary mb-0">{{item.TaxableReturnValue}} &euro;</p>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <p class="text-xs font-weight-bold mb-0">Ripristini e costi vari</p>
                        <p class="text-xs text-secondary mb-0">{{item.FixedAndRestoreCosts}} &euro;</p>
                      </td>
                      <td class="align-middle text-center">
                        <p class="text-xs font-weight-bold mb-0">Presunta Vendita</p>
                        <p class="text-xs text-secondary mb-0">{{item.TaxableEstimatedSellingPrice}} &euro;</p>
                      </td>
                      <td class="align-middle">
                          <span class="badge badge-sm bg-gradient-secondary">{{item.PercedentageBarterMargin}}</span>
                      </td>
                    </tr>
                    <tr v-if="marginData.QuoteMarginFooter.BarterData.length == 0" >
                      <td colspan="6">
                        <div class="d-flex px-2 py-1">
                          <!--<div>
                            <img src="../assets/img/team-2.jpg" class="avatar avatar-sm me-3 border-radius-lg" alt="user1">
                          </div>-->
                          <div class="icon icon-xl icon-shape">
                            <i class="material-icons opacity-10 text-danger">report_problem</i>
                          </div>
                          <div class="d-flex flex-column justify-content-center">
                            <h6 class="mb-0 text-sm">Verifica la trattativa </h6>
                            <p class="text-xs text-secondary mb-0"><small class="text-danger font-weight-bold"> Mancano i dati della permuta</small></p>
                          </div>
                        </div>
                      </td>
                    </tr>
                     <tr v-if="marginData.FinancialData.FinancialMarginDecimal">
                      <td>
                        <div class="d-flex px-2 py-1">
                          <!--<div>
                            <img src="../assets/img/team-2.jpg" class="avatar avatar-sm me-3 border-radius-lg" alt="user1">
                          </div>-->
                          <div class="icon icon-xl icon-shape">
                            <i class="material-icons opacity-10 text-success">account_balance</i>
                          </div>
                          <div class="d-flex flex-column justify-content-center">
                            <h6 class="mb-0 text-sm">Finanziaria {{marginData.FinancialData.FinancialInstitute}} </h6>
                            <p class="text-xs text-secondary mb-0">{{marginData.FinancialData.FinancialType}}</p>
                          </div>
                        </div>
                      </td>
                      <td colspan="3" class="align-middle text-center text-sm">
                        <p class="text-xs font-weight-bold mb-0">Piano</p>
                        <p class="text-xs text-secondary mb-0">{{marginData.FinancialData.FinancialPlane}}</p>
                      </td>
                      <td class="align-middle">
                          <span class="badge badge-sm bg-gradient-secondary blur">{{marginData.FinancialData.FinancialMargin}} &euro;</span>
                      </td>
                    </tr>
                    <tr v-else>
                      <td colspan="6">
                        <div class="d-flex px-2 py-1">
                          <!--<div>
                            <img src="../assets/img/team-2.jpg" class="avatar avatar-sm me-3 border-radius-lg" alt="user1">
                          </div>-->
                          <div class="icon icon-xl icon-shape">
                            <i class="material-icons opacity-10 text-danger">report_problem</i>
                          </div>
                          <div class="d-flex flex-column justify-content-center">
                            <h6 class="mb-0 text-sm">Verifica la trattativa </h6>
                            <p class="text-xs text-secondary mb-0"><small class="text-danger font-weight-bold"> Mancano i dati del finanziamento</small></p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import fetchFromAPI from "../../script/api.js"
import helpers from '../../script/helpers.js'
export default {
  data() {
    return {
      marginData: {},
    };
  },
  created() {
        let params = new URL(document.location).searchParams;
        let id = params.get("id");
        let company = params.get("company");
        let stock = params.get("stock");
        let token = helpers.token()
        fetchFromAPI("QuoteMargin/LoadQuoteMarginDataFromSalesforceCBForHub", { token:token, Id: id, CompanyId: company, StockMainType: stock }, (res) => {
        this.marginData = res;
    });
  }
 }
</script>