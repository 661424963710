<template>
  <div class="container-fluid py-4">
    <div class="card-body p-4">
      <div class="row">
        <div class="col-md-3 mb-md-0 mb-4">
          <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
            <div class="input-group input-group-dynamic">
              <label class="form-label"></label>
              <input class="form-control" aria-label="" placeholder="targa" type="text"
                v-model="filters.CommissionNumber">
            </div>
          </div>
        </div>
        <div class="col-md-3">
        </div>
        <div class="col-md-3">
        </div>
        <div class="col-md-3">
          <div class="card card-body card-plain d-flex align-items-center flex-row">
            <div class="input-group input-group-dynamic ">
              <a class="float-right align-bottom btn bg-gradient-dark mb-0" @click="LoadData">
                <i v-if="isInLoading" class="material-icons text-sm text-warning rotating">autorenew</i>
                <i v-else class="material-icons text-sm">search</i>
                &nbsp;&nbsp;Cerca
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-for="(item, index) in list" :key=index class="col-12 mx-auto pt-5">
      <div class="card shadow-lg">
        <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2 bg-transparent">
          <img src="../../assets/img/shapes/pattern-lines.svg"
            class="position-absolute opacity-8 w-100 top-0 d-md-block d-none" alt="avatar">
          <div class="bg-gradient-dark shadow-dark border-radius-lg p-4">
            <div class="row">
              <div class="col-8 text-start text-white font-weight-bold">
                <h6 class="font-weight-bolder mb-0 text-white">Contratto {{ item.ContractNumber }} del <span
                    class="text-white">{{ formatDate(item.ContractDate) }}</span></h6>
                <p class="text-sm mb-1 text-capitalize font-weight-bold">Cliente: {{ item.AccountDescription }}</p>
                <span class="text-sm text-end text-success font-weight-bolder mb-0"><i class="fa fa-car"
                    aria-hidden="true"></i> <span class="font-weight-normal text-white"><strong>{{
                      item.StockMainTypeDescription }}</strong> -
                    <span>{{ item.VehicleDescription }}</span></span></span>
              </div>
              <div class="col-4">
                <div class="dropdown text-end top-0">
                  <span class="text-xs">
                    <p class="small text-white">{{ item.SellerName }}</p>
                    <span v-if="item.IsExpired"
                      class="icon-xs text-danger text-sm material-icons pb-0 mb-0 bottom-0">report_problem</span><span
                      v-if="item.IsExpired" class="small text-danger mb-2"> Fine Finanziamento:
                      {{ formatDate(item.EndOFFinancialContract) }}</span>
                    <p v-else class="small text-white"> Fine Finanziamento: {{ formatDate(item.EndOFFinancialContract) }}
                    </p>

                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body p-5 pt-0" style="z-index: 999;">
          <br>
          <h3>Servizi veicolo</h3>
          <div v-if="item.Services && item.Services.length > 0">
            <h5>Finanziamento {{ item.Services[0].Description}}</h5>
          </div>
          <div v-if="item.Services && item.Services.length > 1">
            <div v-for="(subitem, index2) in item.Services" :key=index2>
              <div v-if="index2 > 0">
                <h6 class="mt-4 mb-2">{{ subitem.Description }}</h6>
                <hr class="opacity-1">
              </div>
            </div>
          </div>
          <div v-if="!item.Services || item.Services.length <= 1">
            <p>Nessun servizio finanziario trovato associato a questa vettura
              <span class="material-icons material-symbols-outlined text-start align-middle">
                search_off
              </span>
            </p>
          </div>
          <h3>Documenti veicolo</h3>
          <div v-if="item.Docs && item.Docs.length > 0">
            <div v-for="(subitem, index3) in item.Docs" :key=index3>
              <a href="javascript:" class="align-middle"
                @click="downloadFile(subitem, subitem.descrizione_doc.replaceAll(' ', '_') + '_' + item.ContractNumber + '_' + item.ContractDate.split('-')[0])">
                {{ subitem.descrizione_doc }}
                <span class="material-icons material-symbols-outlined text-start align-middle">
                  cloud_download
                </span>
              </a>
              <hr class="opacity-1">
            </div>
          </div>
          <div v-else>
            <p>Nessun documento di carattere finanziario trovato, per visualizzare la lista completa di documenti
              controllare su Infinity
              <span class="material-icons material-symbols-outlined text-start align-middle">
                search_off
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>    
<script>
import fetchFromAPI from "../../script/api.js"
import helpers from '../../script/helpers.js'
export default {
  data() {
    return {
      filters: {},
      list: [],
      isInLoading: false
    };
  },
  methods: {
    LoadData() {
      helpers.resetFilter('carServiceSearchList')
      helpers.setObject('carServiceSearchFilter', this.filters);
      this.isInLoading = true;
      fetchFromAPI("CarServiceSearch/LoadList", this.filters, (res) => {
        this.list = res;
        this.isInLoading = false;
        console.log(res)
        helpers.setObject('carServiceSearchList', res);
      });
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      let dateFormatted = new Intl.DateTimeFormat("default", { dateStyle: "long" }).format(date);
      if (dateFormatted != "1 gennaio 1") {
        return dateFormatted;
      }
      return "";
    },
    base64ToArrayBuffer(base64) {
      var binaryString = window.atob(base64);
      var binaryLen = binaryString.length;
      var bytes = new Uint8Array(binaryLen);
      for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      return bytes;
    },
    downloadFile(docObj, filename) {
      this.$toast.info('Download in corso');
      fetchFromAPI("CarServiceSearch/DownloadDoc", { req: this.filters, docObj: docObj }, (res) => {
        var blob = new Blob([this.base64ToArrayBuffer(res)], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = filename + '.pdf';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        this.$toast.success('File scaricato');
      }, (/*err*/) => {
        this.$toast.error(helpers.stdErrorMessage);
      });
      return;
    }
  },
  created() {
    fetchFromAPI("CarServiceSearch/BindFiltersForHub", { Token: localStorage.getItem('sa_trivellato') }, (res) => {
      this.filters = res;
    })
  }
}
</script>