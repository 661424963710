<template>
    <div class="container-fluid py-4">
            <div class="card-body p-4">
            <div class="row">
                <div class="col-md-3 mb-md-0 mb-4">
                <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                    <div class="input-group input-group-dynamic">
                        <label class="form-label"></label>
                        <input class="form-control" aria-label="" placeholder="venditore 1, venditore 2..."  type="text" v-model="filters.NameAndSurname">
                    </div>
                </div>
                </div>
                <div class="col-md-3">
                <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                    <div class="input-group input-group-dynamic">
                        <label class="form-label"></label>
                        <input type="date" class="form-control" placeholder="Da" aria-label="" v-model="filters.DateFrom" >
                    </div>
                </div>
                </div>
                <div class="col-md-3">
                <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                    <div class="input-group input-group-dynamic">
                        <label class="form-label"></label>
                        <input type="date" class="form-control" placeholder="a" aria-label="" v-model="filters.DateTo" >
                    </div>
                </div>
                </div>
                <div class="col-md-3">
                <div class="card card-body card-plain d-flex align-items-center flex-row">
                    <div class="input-group input-group-dynamic ">
                        <a class="float-right align-bottom btn bg-gradient-dark mb-0" @click="LoadData()">
                           <i v-if="isInLoading" class="material-icons text-sm text-warning rotating">autorenew</i>
                            <i v-else class="material-icons text-sm">search</i>
                              &nbsp;&nbsp;Cerca
                        </a>
                    </div>
                </div>
                </div>
            </div>
        </div>
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <div class="row">
            <div v-for="(item, index) in list.FEACards" :key="index" class="col-lg-3 col-md-3 mb-4">
              <div class="card">
                <div class="card-header p-3 py-2">
                  <div class="icon icon-lg icon-shape bg-gradient-info shadow text-center border-radius-xl mt-n4 position-absolute">
                    <i class="material-icons text-white opacity-10 px-2">
                        <span v-if="item.FEAStatus == 0 || item.FEAStatus == 1">not_started</span>
                        <!--<span v-if="item.FEAStatus == 1">pending</span>-->
                        <span v-if="item.FEAStatus == 2 || item.FEAStatus == 3">check_circle</span>
                    </i>
                  </div>
                  <div class="text-end pt-1">
                    <p v-if="item.FEAStatus == 0" class="text-sm mb-0 text-capitalize">Documenti da Firmare</p>
                    <!--<p v-else-if="item.FEAStatus == 1" class="text-sm mb-0 text-capitalize">Documenti con Firma Iniziata</p>-->
                    <p v-else class="text-sm mb-0 text-capitalize">Firma Conclusa</p>
                    <h4 class="mb-0">{{item.FEACount}}</h4>
                  </div>
                </div>
                <hr class="dark horizontal my-0">
                <div class="card-footer p-3">
                  <p class="mb-0"><span class="text-dark text-sm font-weight-bolder">{{item.FEAAnalytics.Count}} </span> Rispetto al periodo precedente ({{item.FEAAnalytics.Days}} giorni)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--<div class="col-lg-6 col-12 mt-4 mt-lg-0">
          <div class="card h-100">
            <div class="card-header pb-0 p-3">
              <h6 class="mb-0">Confronti</h6>
            </div>
            <div class="card-body pb-0 p-3">
              <ul class="list-group">
                <li v-for="(item, index) in list.FEACards" :key="index" class="list-group-item border-0 d-flex align-items-center px-0 mb-0">
                  <div class="w-100">
                    <div class="d-flex mb-2">
                      <span v-if="item.FEAStatus == 0" class="me-2 text-sm text-capitalize">Documenti da Firmare</span>
                      <span v-if="item.FEAStatus == 1" class="me-2 text-sm text-capitalize">Documenti con Firma Iniziata</span>
                      <span v-if="item.FEAStatus == 2 || item.FEAStatus == 3" class="me-2 text-sm text-capitalize">Firma Conclusa</span>
                      <span class="ms-auto text-sm">{{item.FEAAnalytics.Percent}}</span>
                    </div>
                    <div>
                      <div class="progress progress-md">
                        <div class="progress-bar bg-gradient-info" :style="{width: item.FEAAnalytics.ProgressBarClass + '%'}" role="progressbar" :aria-valuenow="item.FEAAnalytics.ProgressBarClass" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="card-footer pt-0 p-3 d-flex align-items-center">
              <div class="w-60">
                <p class="text-sm mb-0">
                  More than <b>1,500,000</b> developers used Creative Tim's products and over <b>700,000</b> projects were created.
                </p>
              </div>
              <div class="w-40 text-end">
                <a class="btn bg-gradient-dark mb-0" href="javascript:;">Vedi tutti</a>
              </div>
            </div>
          </div>
        </div>-->
      </div>
      <div class="row my-4">
        <div class="col-12">
        <div class="card">
        <!--<div  v-if="isInLoading" class="row justify-content-center">
        <div class="spinner-grow mx-auto" role="status">
            <span class="sr-only">Loading...</span>
        </div>
      </div>-->
          <div class="table-responsive" style="overflow-x:hidden">
            <table class="table align-items-center mb-0">
              <thead>
                <tr>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Contratto</th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Veicolo</th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Venditore</th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Dati Firma</th>
                </tr>
              </thead>
              <tbody style="border-top:0px" v-for="(item, index) in list.FeaContractDatas" :key="index">
                <tr>
                  <td>
                  <a :href="'#multistep_' + item.ContractBaseData.ContractId + '_' + item.FEADataModel.FEAType"  data-bs-toggle="collapse" role="button" aria-expanded="false" :aria-controls="'multistep_'+item.ContractBaseData.ContractId">
                    <div class="d-flex px-2 py-1">
                    <span data-bs-toggle="tooltip" data-bs-placement="top" :title="(item.FEADataModel.FEAStatus == 0 || item.FEADataModel.FEAStatus == 1 ? 'Inizia la firma' : 'Controlla i documenti')" class="">                    
                      <i class="collapse-close material-icons px-1 opacity-10" :class="FEAStatusClass(item.FEADataModel.FEAStatus)">
                        <span v-if="item.FEADataModel.FEAStatus == 0 || item.FEADataModel.FEAStatus == 1">not_started</span>
                       <!--<span v-if="item.FEADataModel.FEAStatus == 1">pending</span>--> 
                        <span v-if="item.FEADataModel.FEAStatus == 2 || item.FEADataModel.FEAStatus == 3">check_circle</span>
                      </i>
                    </span>
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-0 text-xs">{{item.ContractBaseData.AccountDescription}}</h6>
                        <p class="text-xs text-secondary mb-0"><span v-if="item.FEADataModel.FEAType === 3"><b>Proposta Irr. Ven. -- </b></span>Contratto: {{item.ContractBaseData.ContractNumber}} del {{formatDate(item.ContractBaseData.ContractDate)}}</p>
                      </div>
                    </div>
                   </a>

                  </td>
                  <td>
                    <p class="text-xs font-weight-bold mb-0">{{item.ContractBaseData.VehicleDescription}}</p>
                    <p class="text-xs text-secondary mb-0">{{item.ContractBaseData.StockMainTypeDescription}} - {{item.ContractBaseData.LicensePlate}}</p>
                  </td>
                  <td>
                    <span class="text-secondary text-xs font-weight-bold">{{item.ContractBaseData.SellerName}}</span>
                  </td>
                  <td>
                      <p class="text-xs font-weight-bold mb-0">{{item.FEADataModel.FEATypeDescription}} {{item.FEADataModel.FEAStatusDescription}}</p>
                      <p class="text-xs text-secondary mb-0">{{formatDate(item.FEADataModel.CreatedDate)}}</p>
                  </td>
                </tr>
                <tr>
                  <td class="p-0" colspan="4"> 
                    <div class="collapse rounded bg-gray-200" :id="'multistep_'+item.ContractBaseData.ContractId + '_' + item.FEADataModel.FEAType">
                      <multistep v-if="item.FEADataModel.FEAStatus == 0 || item.FEADataModel.FEAStatus == 1" :model="item"></multistep>
                      <contracts v-else-if="!this.isInLoading" :model="item"></contracts>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        </div>
      </div>
    </div>
</template>
<script>
import fetchFromAPI from "../../script/api.js"
import helpers from '../../script/helpers.js'
import multistep from '../../components/MultiStep.vue'
import contracts from '../../components/SignedContracts.vue'
export default {
      components: {
        multistep,
        contracts
      },
      data() {
         return {
         filters: {},
         list: {},
         isInLoading: false,
         sid: '',
         i:0
      };
   },
   methods: {
      LoadData(){
          helpers.resetFilter('FEAList')
          helpers.setObject('FEAListFilter',this.filters);
          this.isInLoading = true;
          fetchFromAPI("FEAWebHook/LoadList", this.filters, (res) => {
               this.i++
               this.list = res;
               this.isInLoading = false;
               helpers.setObject('FEAList', res);
               //if(this.i<=10){
                 //this.LoadData()
               //}
          });
      },
      formatDate(dateString) {
        const date = new Date(dateString);
        let dateFormatted = new Intl.DateTimeFormat("default", { dateStyle: "long" }).format(date);
        if(dateFormatted != "1 gennaio 1"){
            return dateFormatted;
        }
        return "";
      },
      FEAStatusClass(status){
        switch(status){
          case 0:
          case 1:
            return "text-info";
          case 2:
          case 3:
            return "text-success";
            
        }
      }
   },
   created() {
         let params = new URL(document.location).searchParams;
         let sid = params.get("SID");
         /*let filterData = helpers.getFilters('FEAListFilter');
         if (filterData && typeof filterData !== "undefined") {
            this.filters = filterData;
            this.filters.JobId = sid;
            this.LoadData();
            return;
          }*/
          fetchFromAPI("FEAWebHook/BindFiltersForHub", { Token : helpers.token() }, (res) => {
          res.DateFrom = new Date(res.DateFrom).toISOString().slice(0,10)
          res.DateTo = new Date(res.DateTo).toISOString().slice(0,10)
          this.filters = res;
          this.filters.JobId = sid;
          this.LoadData();
       });
   }
}
</script>
