import axios from "axios";
import helpers from "./helpers";
//import router from '../router/index.js'
const endPoint = process.env.VUE_APP_END_POINT;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const fetchFromAPI = (url, data, cb, errCb = null) => {
    return axios.post(endPoint + url, data).then((response) => {
        return response.data;
    }).then((res) => {
        cb(res);
    }).catch((err) => {
        if (((((err || {}).response || {}).data || {}).ExceptionMessage || null) == "l'utente non è autenticato") {
            window.location.href = window.location.origin + '/login?redirect=' + encodeURIComponent(window.location.pathname);
        }
        //helpers.removeToken();
        helpers.removeFiltersData();
        //router.push('Login') 
        console.log(err.response);
        if (errCb) {
            errCb(err);
        }
        throw err;
    })
}

export default fetchFromAPI;