<template>
<div class="container-fluid py-4">   
    <div class="card-body p-4">
            <div class="row">
                <div class="col-md-3 mb-md-0 mb-4">
                <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                    <div class="input-group input-group-dynamic">
                        <label class="form-label"></label>
                        <input class="form-control" aria-label="" placeholder="targa1, targa2"  type="text" v-model="filters.CommissionNumber">
                    </div>
                </div>
                </div>
                <div class="col-md-3">
                </div>
                <div class="col-md-3">
                </div>
                <div class="col-md-3">
                <div class="card card-body card-plain d-flex align-items-center flex-row">
                    <div class="input-group input-group-dynamic ">
                        <a class="float-right align-bottom btn bg-gradient-dark mb-0" @click="LoadData">
                           <i v-if="isInLoading" class="material-icons text-sm text-warning rotating">autorenew</i>
                            <i v-else class="material-icons text-sm">search</i>
                              &nbsp;&nbsp;Cerca
                        </a>
                    </div>
                </div>
                </div>
            </div>
        </div>
    <div v-for="(item, parentIndex) in list" :key="parentIndex" class="row mt-4">
        <div class="col-lg-8">
                <div class="card text-white bg-gradient-dark text-md-start text-center px-sm-5 shadow-lg py-sm-5">
                    <img src="../../assets/img/illustrations/pattern-tree.svg" class="position-absolute opacity-2 start-0 top-0 w-60 z-index-0 " alt="pattern-tree">
                    <div class="card-body">
                        <h4 class="text-white mb-4">Fidelity Card di {{item.CommissionDataHeader.AccountDescription}}</h4>

                        <p class="lead text-white pe-md-5 me-md-5">
                        Da qui puoi verificare e disabilitare gli slot della Fidelity Card legata alla {{item.CommissionDataHeader.VehicleDescription}}. attualmente sono attivi {{CheckSlots(item)}}
                        </p>
                        <div class="buttons z-index-1">
                            <button v-for="(subitem, index) in item.CardSlotStatus" :key="index" type="button" :disabled=!subitem.IsActive :class="[subitem.IsActive ? 'bg-gradient-primary' : 'btn-outline-secondary ']" @click="showAlertTrue(parentIndex, index)" class="btn mt-4 ms-2">Slot {{subitem.SlotName}}</button>
                        </div>
                    </div>
                </div>
        </div>
        <div class="col-lg-4 mt-lg-0">
            <div class="card bg-gradient-dark">
            <div class="card-header bg-transparent">
              <h6 class="text-white">Slot Log Inattivi</h6>
            </div>
            <div class="card-body pt-0">
              <div class="timeline timeline-dark timeline-one-side" data-timeline-axis-style="dotted">
                    <div v-for="(subitem, index1) in item.CardSlotStatus" :key="index1">
                    <div v-if="!subitem.IsActive"  class="timeline-block mb-1">
                    <span class="timeline-step bg-success p-3">
                        <i class="material-icons text-white text-sm opacity-10">
                            shopping_cart
                        </i>
                    </span>
                    <div class="timeline-content pt-1">
                        <h6 class="text-white text-sm font-weight-bold mb-0">Slot {{subitem.SlotName}}</h6>
                        <p class="text-secondary text-xs mt-1 mb-0"><b>Data utilizzo Slot:</b> {{formatDate(subitem.LogDate)}}</p>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     </div>
</template>
<script>
import fetchFromAPI from "../../script/api.js"
import helpers from '../../script/helpers.js'
export default {
      data() {
         return {
         filters: {},
         list: [],
         isInLoading: false
      };
   },
   methods: {
        LoadData(){
          helpers.resetFilter('fidelityCardList')
          helpers.setObject('fidelityCardFilter',this.filters);
          this.isInLoading = true;
          fetchFromAPI("FidelityCard/LoadList", this.filters, (res) => {
               this.list = res;
               this.isInLoading = false;
               helpers.setObject('fidelityCardList', res);
        });
      },
       formatDate(dateString) {
      const date = new Date(dateString);
      let dateFormatted = new Intl.DateTimeFormat('it-IT', { dateStyle: 'full', timeStyle: 'long' }).format(date);
      if(dateFormatted != "1 gennaio 1"){
          return dateFormatted;
      }
      return "";
    },
    CheckSlots(item){
        return item.CardSlotStatus.filter(function(x) {return x.IsActive}).length + ' slot con il valore di ' + item.CardSlotStatus[0].SlotValue + '€'
    },
    showAlertTrue(parentIndex,index) {
      this.$swal.fire({
          title: 'Sicuro?',
          text: "Lo slot verrà disabilitato",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, vai!'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$swal.fire(
              'Ok fatto!',
              'Hai disabilitato lo slot selezionato, in modo permanente',
              'success'
            )
            this.DisableSlot(parentIndex,index)
          }
        })
    },
    DisableSlot(parentIndex, index){
        this.list[parentIndex].CardSlotStatus[index].IsActive = false;
        this.list[parentIndex].CardSlotStatus[index].LogDate = new Date();
         fetchFromAPI("FidelityCard/DisableSlot", this.list, (res) => {
               this.list = res;
               this.isInLoading = false;
               helpers.setObject('fidelityCardList', res);
        });
    }
     },
   created() {
        let data = helpers.getFilters('fidelityCardFilter');
        if (data && typeof data !== "undefined") {
            this.filters = data
            this.LoadData()
            return;
          }

        fetchFromAPI("FidelityCard/BindFiltersForHub", { Token : localStorage.getItem('sa_trivellato') }, (res) => {
        this.filters = res;
        this.LoadData()
        })


    }
}
</script>