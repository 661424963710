import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '/src/assets/css/material-dashboard.css'
import '/src/assets/css/nucleo-icons.css'
import '/src/assets/css/rotation.css'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'bootstrap'
import DropZone from 'dropzone-vue';
import VueSelect from "vue-select";
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';



const app = createApp(App)
app.use(router);
app.use(VueSweetalert2);
app.use(DropZone);
app.use(ToastPlugin, {
    position: 'top-right',
    dismissible: true
});
app.component("v-select", VueSelect);
app.mount('#app');