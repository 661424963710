<template>
<div class="container-fluid py-4">
   <div class="row">
      <div class="col-12">
         <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
               <div class="bg-gradient-light shadow-dark border-radius-lg pt-4 pb-3">
                  <h5 class="text-dark text-capitalize ps-3">VEICOLI IN IMPORTAZIONE</h5>
               </div>
            </div>
            <div class="card-body px-0 pb-2">
               <div v-if="list && list.length<=0" class="py-2 px-4">Nessun processo attivo o da confermare</div>
               <div v-else class="p-0" style="overflow-x: scroll;">
                  <table class="table table-responsive align-items-center mb-0">
                     <thead>
                        <tr class="text-center">
                           <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8">PDF</th>
                           <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8">Id Processo</th>
                           <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8">Telaio</th>
                           <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8">Stato</th>
                           <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8">Data</th>
                           <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"></th>
                           <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"></th>
                        </tr>
                     </thead>
                     <tbody v-if="!isInLoading">
                        <tr v-for="item in list" :key="item.ExecutionId" class="text-center">
                           <td>
                              <div class="d-flex px-3">
                                 <div class="d-flex flex-column justify-content-center my-auto mx-auto">
                                    <div type="button" @click="openDocument(item.PDFPath)" title="Visualizza documento">
                                       <img src="@/assets/img/pdf.png" alt="scarica pdf" />
                                    </div>
                                 </div>
                              </div>
                           </td>
                           <td>
                              <div class="d-flex flex-column justify-content-center my-auto">
                                 {{ item.ExecutionId }}
                              </div>
                           </td>
                           <td>
                              <div class="d-flex flex-column justify-content-center my-auto">
                                 {{ item.Frame }}
                              </div>
                           </td>
                           <td>
                              <div class="d-flex justify-content-center my-auto">
                                 <span v-if="item.Status==1" class="text-xs mb-0 badge bg-warning">CARICAMENTO DATI</span>
                                 <span v-if="item.Status==3" class="text-xs mb-0 badge bg-success">TERMINATO</span>
                                 <span v-if="item.Status==2" class="text-xs mb-0 badge bg-warning">CARICAMENTO IMG + OPT</span>
                                 <span v-if="item.Status==99" class="text-xs mb-0 badge bg-danger">ERRORE</span>
                              </div>
                           </td>
                           <td>
                              <div class="d-flex justify-content-center my-auto">
                                 {{ formatDate(String(item.CreatedDate)) }}
                              </div>
                           </td>
                           <td >
                              <div v-if="item.Status==3">
                                 <button class="btn bg-gradient-info shadow-dark my-auto" @click="confirmImportation(item)">
                                    <i v-if="isConfirming" class="material-icons text-sm rotating mb-1">autorenew</i>
                                    <i v-else class="material-icons text-sm mb-1">check</i>
                                    &nbsp;&nbsp;Conferma
                                 </button>
                              </div>
                              <div v-if="item.Status==1 || item.Status==2" class="d-flex my-auto">
                                 <i class="material-icons text-sm rotating opacity-5" style="font-size: 2rem !important;">refresh</i>
                              </div>
                              <div v-if="item.Status==99">
                                 <span class="text-xs text-danger">Necessaria verifica</span>
                                 <!-- <button class="btn bg-gradient-info shadow-dark my-auto">
                                    <span class="mt-1">rilancia processo &nbsp;&nbsp;</span>
                                    <i v-if="isRelaunching" class="material-icons text-sm rotating mb-1">autorenew</i>
                                    <i v-else class="material-icons text-sm mb-1">arrow_forward</i> 
                                 </button>-->
                              </div>
                           </td>
                           <td>
                              <div v-if="item.Status==99" class="d-flex my-auto" type="button" @click="confirmDeleteRow(item.ExecutionId)">
                                 <i class="material-icons">delete</i>
                              </div>
                           </td>
                        </tr>
                     </tbody>
                  </table>
                  <div v-if="isInLoading" class="text-center py-4">
                     <i class="material-icons text-center rotating" style="font-size:3rem;">autorenew</i>
                  </div>
                  <div v-if="list && list.length>0">
                     <Paginator :currentPage="currentPage" :totalPages="filters.TotalPages" :perPage="perPage" @pagechanged="onPageChange" class="pt-4"/>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
</template>

<script>
import fetchFromAPI from "../../script/api.js"
import helpers from '../../script/helpers.js'
import Paginator from "../../components/Paginator.vue"

export default {
   components: {
      Paginator
   },
   data() {
      return {
         currentPage: 1,
         perPage: 10,
         filters: {},
         list: [],
         isInLoading: false,
         isConfirming: false,
         isRelaunching: false
      };
   },

   methods: {
      formatDate: helpers.formatDate,

      LoadData(){
         this.isInLoading = true;
         this.filters.Page = this.currentPage;
         this.filters.TotalRows = this.perPage;
         fetchFromAPI("AutomationProcessApproval/LoadProcessList", this.filters, (res) => {
            this.filters = res.Filter;
            this.list = res.Bots; //this.filters.IsManager ? res.Bots : res.Bots.filter(row => row.Status != 99);
            this.isInLoading = false;
         });
      },

      confirmImportation(item){
         this.$swal.fire({
         title: 'Sicuro?',
         text: 'Confermare i dati della vettura?',
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Si'
         }).then((result) => {
         if (result.isConfirmed) {
            this.confirmVehicleData(item);
         }
         })
      },

      confirmVehicleData(item){
         this.isConfirming = true;
         fetchFromAPI("AutomationProcessApproval/ConfirmAutomationProcess", { Token:helpers.token(), Id: item.ExecutionId, Description: item.PDFPath }, () => {
            this.isConfirming = false;
            this.LoadData();
            this.$toast.success("Vettura confermata. Immagini in caricamento.");
         });
      },

      openDocument(path){
         fetchFromAPI("AutomationProcessApproval/LoadVehicleDocument", { Token:helpers.token(), Description: path }, (res) => {
            if(res){
               var fileName = res.FileName;
               var path = res.FilePath;
               this.downloadFile(path,fileName);
            }
         });
      },

      downloadFile(url,filename) {
         const link = document.createElement("a");
         link.href = url
         link.setAttribute('download', filename);
         link.target = "_blank";
         document.body.appendChild(link);
         link.click();
         document.body.removeChild(link);
      },

      deleteRow(id){
         fetchFromAPI("AutomationProcessApproval/DeleteProcessRow", { Token:helpers.token(), Id: id }, () => {
            this.LoadData();
            this.$toast.success("Riga eliminata");
         });
      },

      confirmDeleteRow(id){
         this.$swal.fire({
         title: 'Sicuro?',
         text: 'Vuoi eliminare questa riga?',
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Si'
         }).then((result) => {
            if (result.isConfirmed) {
               this.deleteRow(id);
            }
         })
      },
   },

   created() {
      fetchFromAPI("AutomationProcessApproval/BindFiltersForHub", { Token : helpers.token() }, (res) => {
         this.filters = res;
         this.LoadData()
      })
   }
}
</script>