<template>
  <div class="form-control border border-warning" style="border-style:dashed!important;">
    <div>
      <div style="text-align:center" v-bind="getRootProps()">
        <input v-bind="getInputProps()" />
        <p v-if="isDragActive">Ora rilascia...</p>
        <p class="p-0 mb-1" type="button" v-else>Trascina e rilascia qui i tuoi files</p>
        <p class="text-xs m-0 p-0 pb-1">Oppure clicca per selezionarli (è possibile caricare più file in una volta)</p>
      </div>
      <!--<button type="button" name="button" class="btn bg-gradient-dark m-0 ms-2" @click="open">open</button>-->
    </div>
  </div>
  </template>
  
  <script>
  import { useDropzone } from "vue3-dropzone";
  import axios from "axios";
  //import store from "./store";
  //import { reactive } from 'vue';
  
  export default {
    data(){
      return{
        that: this
      }
    },
    props:{
      ApiPath: String, // il percorso dove salvare i file
      Token: String, // è possibile passare anche il token di autenticazione se necessario
      Id: Number,
      Format: String
    },
    name: "UseDropzone",
    setup(props, {emit}) {
      let host = process.env.VUE_APP_END_POINT;
      const url = host + props.ApiPath;
      const saveFiles = (files) => {
        const formData = new FormData(); // pass data as a form
        for (var x = 0; x < files.length; x++) {
          // append files as array to the form, feel free to change the array name
          formData.append("files[]", files[x]);
        }
        axios
          .post(url+buildParams(), formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            emit('documentuploaded', response.data);
          })
          .catch((err) => {
            console.error(err);
          });
      };
  
      function onDrop(acceptFiles, rejectReasons) {
        if(notSpecifiedFormat(acceptFiles)){
          emit('documentuploaded', true);
        }
        else{
          saveFiles(acceptFiles); // saveFiles as callback
          console.log(rejectReasons);
        }
      }

      function buildParams(){
        return "?id=" + props.Id + '&x=' + props.Token;
      }

      function notSpecifiedFormat(files){
        if(props.Format==undefined) return false;
        for(var i=0; i<files.length; i++){
          var ext = files[i].name.split('.').pop();
          if(ext!=props.Format){
            return true;
          }
        }
        return false;
      }

      const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop, maxSize : 100000000 });
  
      return {
        getRootProps,
        getInputProps,
        ...rest,
      };
    }
  };
  </script>