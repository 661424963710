<template>
    <link rel="stylesheet" href="https://unpkg.com/vue-select@latest/dist/vue-select.css">
    <div class="container-fluid all-small no-scroll-content">
        <div class="row no-scroll-content">
            <div class="col-sm-4 no-scroll-content">
                <div class="card mh-100">
                    <div class="card-body overflow-y-auto overflow-x-hidden">
                        <div class="row justify-content-between align-items-center mb-2">
                            <div class="col-sm-6">
                                <button class="btn bg-gradient-dark text-white px-3 py-2 m-0" @click="createMapping"
                                    :disabled="!entities">
                                    <span class="material-icons material-symbols-outlined">
                                        add
                                    </span>
                                    Nuova card
                                </button>
                            </div>
                            <div class="col-sm-6">
                                <div class="input-group input-group-outline trivellato" ref="license-search">
                                    <label class="form-label">Cerca card</label>
                                    <input type="text" class="form-control" aria-label="" :disabled="!entities"
                                        v-model="searched" />
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table mh-100 scrollbar-thin table-hover mw-100 ">
                                <thead>
                                    <tr>
                                        <th>Titolo</th>
                                        <th>Descrizione</th>
                                    </tr>
                                </thead>
                                <tbody v-if="entities">
                                    <tr v-for="entity in filteredAndSorted" :key="entity.Id"
                                        class="cursor-pointer hover-bg-trivellato" @click="selectEntity(entity)">
                                        <td>{{ entity.Title }}</td>
                                        <td><span>{{ entity.Description }}</span></td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="2" class="text-center">
                                            <i class="spinner-border text-dark align-middle" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </i>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-8 no-scroll-content">
                <div class="card mh-100" v-if="selectedEntity">
                    <div class="card-body overflow-auto">
                        <h3 class="mb-4">Gestisci card</h3>
                        <div class="form-group d-flex flex-column align-items-start">
                            <div class="input-group input-group-dynamic my-2 trivellato"
                                :class="{ 'is-filled': selectedEntity.Title != null && selectedEntity.Title != '' }">
                                <label class="form-label">Nome card</label>
                                <input type="text" class="form-control" aria-label="" v-model="selectedEntity.Title" />
                            </div>

                            <div class="input-group input-group-outline my-2 trivellato"
                                :class="{ 'is-filled': selectedEntity.Description != null && selectedEntity.Description != '' }">
                                <label class="form-label">Descrizione card</label>
                                <textarea class="form-control" placeholder="" rows="4"
                                    v-model="selectedEntity.Description"></textarea>
                            </div>
                            <br>

                            <form class="col-md-12">
                                <div class="row mb-3" v-if="selectedEntity.Id != -1 && userGroups">
                                    <div class="col">
                                        <div class="input-group input-group-dynamic my-2 trivellato"
                                            :class="{ 'is-filled': cardGroups != null && cardGroups.length > 0 }">
                                            <label class="form-label">Gruppi</label>
                                            <v-select class="form-control" :options="userGroups" label="GROUPNAME"
                                                value="ID" v-model="cardGroups" multiple>
                                            </v-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col">
                                        <div class="input-group input-group-dynamic my-2 trivellato"
                                            :class="{ 'is-filled': selectedEntity != null && selectedEntity.IconPath !== null }">
                                            <label class="form-label">Icona</label>
                                            <input type="text" class="form-control" aria-label=""
                                                v-model="selectedEntity.IconPath" />
                                            <span class="bg-icon-preview material-icons material-symbols-outlined">
                                                {{ selectedEntity.IconPath }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="input-group input-group-dynamic my-2 trivellato"
                                            :class="{ 'is-filled': selectedEntity != null && selectedEntity.IconColor !== null }">
                                            <label class="form-label">Colore sfondo icona</label>
                                            <input type="text" class="form-control" aria-label=""
                                                v-model="selectedEntity.IconColor" />
                                            <span class="bg-color-preview" :class="selectedEntity.IconColor">&nbsp;</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col">
                                        <div class="input-group input-group-dynamic my-2 trivellato"
                                            :class="{ 'is-filled': selectedEntity != null && selectedEntity.Url !== null }">
                                            <label class="form-label">Url</label>
                                            <input type="text" class="form-control" aria-label=""
                                                v-model="selectedEntity.Url" />
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="input-group input-group-dynamic my-2 trivellato"
                                            :class="{ 'is-filled': selectedEntity != null && selectedEntity.LinkLabel !== null }">
                                            <label class="form-label">Testo link</label>
                                            <input type="text" class="form-control" aria-label=""
                                                v-model="selectedEntity.LinkLabel" />
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <br>
                            <h5><a href="javascript:" class="hover-trivellato" data-bs-toggle="modal"
                                    data-bs-target="#mappingModal">Modifica intestazione
                                    pagina</a></h5>

                        </div>
                        <div class="buttons-container mt-6">
                            <button class="btn bg-gradient-danger mx-1" @click="del()">Elimina</button>
                            <button class="btn bg-gradient-dark mx-1 float-end" @click="save()">Salva</button>
                            <button class="btn bg-gradient-light mx-1 float-end" @click="refreshItem()">Annulla</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="mappingModal" tabindex="-1" aria-labelledby="mappingModalLabel" aria-hidden="true"
        v-if="selectedEntity">
        <div class="modal-dialog">
            <div class="modal-content">
                <headerMapperDetail :entity-page-name="selectedEntity.Url"></headerMapperDetail>
                <button type="button" class="btn-close top-right-corner p-2" data-bs-dismiss="modal" aria-label="Close">
                    <span class="material-icons material-symbols-outlined light-dark">
                        close
                    </span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import fetchFromAPI from "../../script/api.js"
import helpers from '../../script/helpers.js'
import headerMapperDetail from '../../components/HeaderMapperDetail.vue'

export default {
    components: {
        headerMapperDetail
    },
    data() {
        return {
            filters: null,
            entities: null,
            selectedEntity: null,
            searched: '',
            ctrlPressed: false,
            companies: [
                {
                    Id: 1,
                    Nome: "Trivellato Spa"
                },
                {
                    Id: 6,
                    Nome: "Padova Star Srl"
                },
            ],
            userGroups: null,
            cardGroups: null
        }
    },
    computed: {
        filteredAndSorted() {
            // function to compare names
            function compare(a, b) {
                if (a.PageName < b.PageName) return -1;
                if (a.PageName > b.PageName) return 1;
                return 0;
            }

            return this.entities.filter(entity => {
                return entity.Title.toLowerCase().includes(this.searched.toLowerCase()) || entity.Description.toLowerCase().includes(this.searched.toLowerCase());
            }).sort(compare)
        }
    },

    methods: {
        LoadData() {
            fetchFromAPI("HomeCards/LoadList", this.filters, (res) => {
                this.entities = res;
            }, (err) => {
                console.error("Something went wrong fetching data. Error: " + err);
            });
        },
        createMapping() {
            var x = {
                Id: -1,
                Title: null,
                Description: null,
                IconPath: null,
                IconColor: null,
                Url: null,
                LinkLabel: null,
                Token: helpers.token()
            };
            this.selectedEntity = x;
        },
        selectEntity(entity) {
            this.userGroups = null;
            this.cardGroups = null;
            fetchFromAPI("HomeCards/GetSingleEntity", entity, (res) => {
                this.selectedEntity = res;
                this.loadCardGroups();
            });
        },
        save() {
            var payload = this.selectedEntity;
            fetchFromAPI("HomeCards/SaveEntity", payload, (res) => {
                var isNewGroup = false;
                if (this.selectedEntity.Id == -1) {
                    isNewGroup = true;
                }
                this.selectedEntity.Id = res.Id;
                this.LoadData();
                if (!isNewGroup) {
                    this.$toast.success('Card salvata');
                    fetchFromAPI("HomeCards/UpdateGroups", { token: helpers.token(), groups: this.cardGroups, cardId: this.selectedEntity.Id }, (/*res*/) => {
                        this.loadCardGroups();
                        this.$toast.success('Permessi card aggiornati');
                    }, (err) => {
                        console.error("Something went wrong saving data. Error: " + err);
                        this.$toast.error('Qualcosa è andato storto, ricaricare la pagina e tentare di nuovo<br/>Se il problema persiste contattare un responsabile');
                    })
                }
                else {
                    this.loadCardGroups();
                    this.$toast.success('Card salvata');
                }
            }, (err) => {
                console.error("Something went wrong saving data. Error: " + err);
                this.$toast.error('Qualcosa è andato storto, ricaricare la pagina e tentare di nuovo<br/>Se il problema persiste contattare un responsabile');
            });
        },
        del() {
            fetchFromAPI("HomeCards/DeleteEntity", this.selectedEntity, (/*res*/) => {
                this.selectedEntity = null;
                this.LoadData();
                this.$toast.success('Card eliminata');
            }, (err) => {
                console.error("Something went wrong deleting data. Error: " + err);
                this.$toast.error('Qualcosa è andato storto, ricaricare la pagina e tentare di nuovo<br/>Se il problema persiste contattare un responsabile');
            });
        },
        refreshItem() {
            if (this.selectedEntity.Id == -1) {
                this.selectedEntity.Title = null;
                this.selectedEntity.Description = null;
                this.selectedEntity.IconPath = null;
                this.selectedEntity.IconColor = null;
                this.selectedEntity.Url = null;
                this.selectedEntity.LinkLabel = null;
                this.$toast.info('Dati originali ricaricati');
                return;
            }

            fetchFromAPI("HomeCards/GetSingleEntity", this.selectedEntity, (res) => {
                this.selectedEntity.Title = res.Title;
                this.selectedEntity.Description = res.Description;
                this.selectedEntity.IconPath = res.IconPath;
                this.selectedEntity.IconColor = res.IconColor;
                this.selectedEntity.Url = res.Url;
                this.selectedEntity.LinkLabel = res.LinkLabel;
                this.$toast.info('Dati originali ricaricati');
            }, (err) => {
                console.error("Something went wrong refreshing the selected element. Error: " + err);
                this.$toast.error('Qualcosa è andato storto, ricaricare la pagina e tentare di nuovo<br/>Se il problema persiste contattare un responsabile');
            })
        },
        navToMapper() {
            if (!this.ctrlPressed) {
                this.$swal.fire({
                    title: 'Attenzione!',
                    text: 'Proseguendo potresti perdere eventuali modifiche non salvate, vuoi procedere?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#344767',
                    cancelButtonColor: '#f0f2f5',
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$router.push('headermapper?PageName=' + this.selectedEntity.Url);
                    }
                })
            }
            else {
                window.open(window.location.origin + '/headermapper?PageName=' + this.selectedEntity.Url);
            }
        },
        loadCardGroups() {
            fetchFromAPI("HomeCards/LoadGroups", { token: helpers.token(), cardId: this.selectedEntity.Id }, (res) => {
                this.userGroups = res;
                this.cardGroups = res.filter(x => x["HUB_HOME_CARDID"] != null && x["ISVISIBLE"] == true)
            })
        }
    },
    created() {
        fetchFromAPI("HomeCards/BindFiltersForHub", { Token: helpers.token() }, (res) => {
            res.DateFrom = new Date(res.DateFrom).toISOString().slice(0, 10)
            res.DateTo = new Date(res.DateTo).toISOString().slice(0, 10)
            this.filters = res;
            this.filters.CodiceUbicazione = null;
            this.LoadData();
        });
        window.addEventListener("keydown", (event) => {
            if (event.ctrlKey) {
                this.ctrlPressed = true;
            }
        });
        window.addEventListener("keyup", (event) => {
            if (event.ctrlKey) {
                this.ctrlPressed = false;
            }
        });
    },
    mounted() {
        this.$nextTick(() => {
            helpers.setMaterialInput();
        });
    }
}
</script>

<style scoped>
#app {
    max-height: 100vh;
}

.container-fluid {
    max-height: calc(100vh - 100px);
}

::-webkit-scrollbar {
    width: 6px;
    border-radius: 10px;
}

.del-breadcrumb-item:hover {
    color: red;
}

.close-breadcrumb-item:hover {
    color: lightskyblue;
}

.bg-color-preview {
    width: 20px;
    height: 20px;
    border-radius: 50% !important;
    position: absolute;
    right: 0;
    transform: translate(0, 50%);
}

.bg-icon-preview {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0;
    transform: translate(0, 50%);
}

.modal-dialog {
    width: 100%;
    max-width: 1000px;
}
</style>