<template>
    <link rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0" />
    <div class="container py-4 all-small">
        <div class="row">
            <div class="col-md-4 mb-md-0 mb-4">
                <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                    <div class="input-group input-group-dynamic trivellato"
                        :class="{ 'is-filled': filters.CommissionNumber != null }">
                        <label class="form-label">Telaio o Id</label>
                        <input type="text" class="form-control" id="fastItemLicense" v-model="filters.CommissionNumber"
                            aria-label="" v-on:keyup.enter="searchVehicle()" />
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                    <div class="input-group input-group-dynamic trivellato"
                        :class="{ 'is-filled': filters.StockMainType != null }">
                        <label class="form-label">Stock veicolo</label>
                        <v-select class="form-control" :options="stock" label="label" :reduce="stock => stock.value"
                            v-model="filters.StockMainType">
                        </v-select>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="card card-body card-plain d-flex align-items-center flex-row">
                    <div class="input-group input-group-dynamic justify-content-end">
                        <a class="float-right align-bottom btn bg-gradient-dark mb-0" @click="searchVehicle()"
                            :class="{ 'disabled': isInLoading }">
                            <i v-if="isInLoading" class="material-icons text-sm text-warning rotating">autorenew</i>
                            <i v-else class="material-icons text-sm">search</i>
                            &nbsp;&nbsp;Cerca
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-if="isInLoading">
            <div class="card col-md-12 text-center">
                <div class="card-body">
                    Caricamento in corso
                </div>
            </div>
        </div>
        <div class="row" v-else-if="mainVehicle != null">
            <div class="col-12  col-xs-12 col-md-4">
                <div class="custom-card card sticky-top mb-4">
                    <div class="override-body card-body">
                        <div class="details">
                            <div class="detail-item">
                                <b>ID</b>: {{ mainVehicle.idVeicolo }}
                            </div>
                            <div class="detail-item">
                                <b>Marca</b>: {{ mainVehicle.descrizioneMarca }}
                            </div>
                            <div class="detail-item">
                                <b>Modello</b>: {{ mainVehicle.descrizioneModello }}
                            </div>
                            <div class="detail-item">
                                <b>Versione</b>: {{ mainVehicle.descrizioneVersione }}
                            </div>
                            <div class="detail-item">
                                <b>Targa</b>: {{ mainVehicle.targa }}
                            </div>
                            <div class="detail-item">
                                <b>Telaio</b>: {{ mainVehicle.telaio }}
                            </div>
                            <div class="detail-item">
                                <b>Colore Esterno</b>: {{ mainVehicle.descrizioneColoreEsterno }}
                            </div>
                            <div class="detail-item">
                                <b>Colore Interno</b>: {{ mainVehicle.descrizioneColoreInterno }}
                            </div>
                            <div class="detail-item">
                                <b>Azienda</b>: {{ mainVehicle.azienda }}
                            </div>
                        </div>
                        <div class="button-container">
                            <div class="border-radius-lg d-flex align-items-center flex-row  my-2">
                                <div class="form-check form-switch float-right">
                                    <input class="form-check-input my-2" type="checkbox" role="switch"
                                        id="flexSwitchCheckDefault" v-model="filters.Active" :disabled="viewSimilar">
                                    <label class="form-check-label my-0" for="flexSwitchCheckDefault">Ignora
                                        interni</label>
                                </div>
                            </div>
                            <a v-if="!viewSimilar" class="btn btn-success my-2" type="button" size="sm"
                                @click="searchSimilarVehicles(false, filters.Active)">
                                Cerca veicoli uguali
                            </a>
                            <a v-if="viewSimilar" class="btn btn-warning shadow-warning my-2" type="button" size="sm"
                                @click="viewSimilar = false">
                                Modifica ricerca
                            </a>
                            <a v-if="viewSimilar" type="button" class="btn bg-trivellato shadow-info text-white my-2"
                                :class="{ 'disabled': disableExtendedBtn }"
                                @click="searchSimilarVehicles(true, filters.Active)">Estendi
                                ricerca ad
                                altre versioni</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12  col-xs-12 col-md-8">
                <div class="optionals-container overflow-auto rounded"
                    v-if="mainVehicle && mainVehicle.optionals && mainVehicle.optionals.optionals && mainVehicle.optionals.optionals.length > 0 && !viewSimilar">
                    <table class="table bg-white rounded text-center">
                        <thead class="">
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">Optional</th>
                                <th scope="col">Descrizione</th>
                                <th scope="col" class="serie-column">Di serie</th>
                                <th scope="col">Prezzo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="optional in sortedOptionals" :key="optional.optional" class="align-middle">
                                <td>
                                    <div class="custom-control custom-checkbox">
                                        <input class="custom-control-input" type="checkbox"
                                            :id="'customCheck' + optional.id" v-model="optional.selected" />
                                        <label class="custom-control-label" :for="'customCheck' + optional.id" />
                                    </div>
                                </td>
                                <td><b>{{ optional.optional }}</b></td>
                                <td>{{ optional.descrizione }}</td>
                                <td class="serie-column" v-if="optional.serie === 'S'">
                                    <i class="ni ni-check-bold"></i>
                                </td>
                                <td v-else>
                                    <i class="ni ni-fat-remove" style="font-size: 21;"></i>
                                </td>
                                <td>{{ formatMoney(optional.prezzo) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else-if="viewSimilar">
                    <div>
                        <div v-if="isInLoadingSimilar">
                            <div class="custom-card card mb-3">
                                <div class="override-body card-body">
                                    <div class="card-body-container">
                                        <div class="row text-center">
                                            <h5
                                                class="material-icons material-symbols-outlined rotating color-trivellato fs-1">
                                                autorenew
                                            </h5>
                                            <h5>Ricerca veicoli simili in corso</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="!similarVehicles || similarVehicles.length == 0" class="col-xs-12">
                            <div class="card col-md-12 text-center">
                                <div class="card-body">
                                    <h5 class="material-icons material-symbols-outlined color-trivellato fs-1">
                                        search_off
                                    </h5>

                                    <h5>Nessun veicolo uguale e con immagini trovato</h5>
                                </div>
                            </div>
                        </div>
                        <div v-else class="col-xs-12">
                            <div class="custom-card card mb-4" v-if="filters.StockMainType == 2">
                                <div class="card card-body card-plain border-radius-lg d-flex align-items-center">
                                    <h5 class="align-self-start">Filtra per colore</h5>
                                    <div class="input-group input-group-dynamic trivellato mt-3"
                                        :class="{ 'is-filled': (similarFilterExt != null && similarFilterExt != '') }">
                                        <label class="form-label">Colore esterni</label>
                                        <input type="text" class="form-control" v-model="similarFilterExt" aria-label="" />
                                    </div>
                                    <div class="input-group input-group-dynamic trivellato mt-3"
                                        :class="{ 'is-filled': (similarFilterInt != null && similarFilterInt != '') }">
                                        <label class="form-label">Colore interni</label>
                                        <input type="text" class="form-control" v-model="similarFilterInt" aria-label="" />
                                    </div>
                                </div>
                            </div>
                            <div class="custom-card card mb-4" v-for="(vehicle, index) in similarVehiclesFiltered"
                                :key="index">
                                <div class="override-body card-body">
                                    <div class="card-body-container">

                                        <div class="row">
                                            <div class="col-xs-12 col-md-5 d-flex flex-column">
                                                <div class="card-image flex-grow-1">
                                                    <iframe v-if="vehicle.wrap360Links && vehicle.wrap360Links.Esterno" class="w-100 h-100" :src="vehicle.wrap360Links.Esterno"></iframe>
                                                    <iframe v-else-if="vehicle.wrap360Links && vehicle.wrap360Links.Interno" class="w-100 h-100" :src="vehicle.wrap360Links.Interno"></iframe>
                                                    <iframe v-else-if="vehicle.wrap360Links && vehicle.wrap360Links.Dettaglio" class="w-100 h-100" :src="vehicle.wrap360Links.Dettaglio"></iframe>
                                                    <img v-else :src="vehicle.imageUrl" class="img-fluid rounded" alt="card img" /> 
                                                </div>
                                            </div>
                                            <div class="col-xs-12 col-md-7">
                                                <div class="card-text">
                                                    <h6 v-if="vehicle.warning" class="text-warning align-bottom">
                                                        <b><span class="material-symbols-outlined align-bottom">
                                                            warning
                                                        </span>
                                                        Attenzione! Possibili optional extra visibili nelle foto</b>
                                                    </h6>
                                                    <div>
                                                        <b>Marca</b>: {{ vehicle.descrizioneMarca }}
                                                    </div>
                                                    <div>
                                                        <b>Modello</b>: {{ vehicle.descrizioneModello }}
                                                    </div>
                                                    <div>
                                                        <b>Versione</b>: {{ vehicle.descrizioneVersione }}
                                                    </div>
                                                    <div>
                                                        <b>Targa</b>: {{ vehicle.targa }}
                                                    </div>
                                                    <div>
                                                        <b>Telaio</b>: {{ vehicle.telaio }}
                                                    </div>
                                                    <div>
                                                        <b>Azienda</b>: {{ vehicle.azienda }}
                                                    </div>
                                                    <div v-if="vehicle.wrap360Links">
                                                        <p v-if="vehicle.wrap360Links.Esterno" class="mb-1"><a
                                                                target="_blank" class="color-trivellato fw-bold"
                                                                :href="vehicle.wrap360Links.Esterno">Vedi Esterni</a></p>
                                                        <p v-if="vehicle.wrap360Links.Interno" class="mb-1"><a
                                                                target="_blank" class="color-trivellato fw-bold"
                                                                :href="vehicle.wrap360Links.Interno">Vedi Interni</a></p>
                                                        <p v-if="vehicle.wrap360Links.Dettaglio" class="mb-1"><a
                                                                target="_blank" class="color-trivellato fw-bold"
                                                                :href="vehicle.wrap360Links.Dettaglio">Vedi Dettagli</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div v-else>
                    <div class="card col-md-12 text-center">
                        <div class="card-body">
                            <h5 class="material-icons material-symbols-outlined color-trivellato fs-1">
                                unpublished
                            </h5>
                            <h5>Non ci sono optional selezionabili per questa vettura</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="mainVehicle == null">
            <div class="card col-md-12 text-center">
                <div class="card-body">
                    <h5 class="material-icons material-symbols-outlined color-trivellato fs-1">
                        search
                    </h5>
                    <h5>Cerca un veicolo per vedere i risultati</h5>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
tr>th:first-child,
tr>td:first-child {
    position: sticky;
    left: 0;
    background-color: white;
}

iframe #document .carousel-indicators {
    display: none;
}
</style>

<script>
import fetchFromAPI from "../../script/api.js"
import helpers from '../../script/helpers.js'
// import _ from 'lodash'

export default {
    data() {
        return {
            filters: {},
            stock: [
                {
                    label: "Nuovo",
                    value: 1
                },
                {
                    label: "Usato",
                    value: 2
                }
            ],
            StatusVeicoli: [
                {
                    label: "In stock",
                    value: 0
                },
                {
                    label: "Virtuali",
                    value: 0
                },
                {
                    label: "In stock + virtuali",
                    value: 0
                }
            ],
            optionals: [],
            isInLoading: false,
            similarFilterExt: null,
            similarFilterInt: null,
            isInLoadingSimilar: false,
            mainVehicle: null,
            similarVehicles: null,
            viewSimilar: false,
            disableExtendedBtn: false,
            initialCommissionNumber: this.$route.params.InitialCommissionNumber || null,
            initialStock: this.$route.params.InitialStock || 1,
            autoSearch: this.$route.params.AutoSearch || false
        }
    },
    computed: {
        selectedStock() {
            return this.stock.find(x => x.value == this.filters.StockMainType);
        },
        sortedOptionals() {
            function compare(a, b) {
                if (a.selected < b.selected && a.descrizione > b.descrizione) return 2;
                if (a.selected < b.selected && a.descrizione < b.descrizione) return 1;
                if (a.selected > b.selected && a.descrizione > b.descrizione) return -1;
                if (a.selected > b.selected && a.descrizione < b.descrizione) return -2;
                return 0;
            }

            if (!this.mainVehicle || !this.mainVehicle.optionals || !this.mainVehicle.optionals.optionals) {
                return [];
            }
            return this.mainVehicle.optionals.optionals.filter(() => 1 == 1).sort(compare);
        },
        similarVehiclesFiltered() {
            if (this.mainVehicle.stock == 1) {
                return this.similarVehicles;
            }

            var filteredVehicles = this.similarVehicles;

            if (this.similarFilterExt != null && this.similarFilterExt != "") {
                filteredVehicles = filteredVehicles.filter((x) => x.descrizioneColoreEsterno.toLowerCase().includes(this.similarFilterExt.toLowerCase()))
            }

            if (this.similarFilterInt != null && this.similarFilterInt != "") {
                filteredVehicles = filteredVehicles.filter((x) => x.descrizioneColoreInterno.toLowerCase().includes(this.similarFilterInt.toLowerCase()))
            }

            return filteredVehicles;
        }
    },
    watch: {
        // mainVehicle: {
        //     handler(newOptionals) {
        //         function compare(a, b) {
        //             if (a.selected < b.selected && a.descrizione > b.descrizione) return 2;
        //             if (a.selected < b.selected && a.descrizione < b.descrizione) return 1;
        //             if (a.selected > b.selected && a.descrizione > b.descrizione) return -1;
        //             if (a.selected > b.selected && a.descrizione < b.descrizione) return -2;
        //             return 0;
        //         }
        //         newOptionals.optionals.optionals = newOptionals.optionals.optionals.sort(compare);

        //         this.mainVehicle = null;
        //         this.mainVehicle = newOptionals;
        //         return;
        //     },
        //     deep: true
        // }
    },
    created() {
    },
    mounted() {
        this.$nextTick(() => {
            helpers.setMaterialInput();
            fetchFromAPI("VehiclePhotos/BindFiltersForHub", { Token: helpers.token() }, (res) => {
                this.filters = res;
                this.filters.CommissionNumber = this.initialCommissionNumber;
                this.filters.StockMainType = this.stock.find((x) => x.value == this.initialStock).value;
                if (this.autoSearch) {
                    this.searchVehicle();
                }
            });
        });
    },
    methods: {
        searchVehicle() {
            this.disableExtendedBtn = false;
            this.viewSimilar = false;
            this.isInLoading = true;
            fetchFromAPI("VehiclePhotos/getMainVehicle", this.filters, (res) => {
                this.mainVehicle = res.vehicle;
                this.isInLoading = false;
            }, (/*err*/) => {
                this.mainVehicle = null;
                this.isInLoading = false;
                this.$toast.error(helpers.stdErrorMessage);
            });
        },
        searchSimilarVehicles(ignoreVersion, ignoreInterns) {
            this.disableExtendedBtn = ignoreVersion;
            this.viewSimilar = true;
            this.isInLoadingSimilar = true;
            var opts = this.mainVehicle.optionals?.optionals.filter(o => o.selected).map(x => x.optional);
            fetchFromAPI("VehiclePhotos/getSimilarVehicles", { vehicle: this.mainVehicle, optionals: opts, ignoreVersion: ignoreVersion, ignoreInterns: ignoreInterns }, (res) => {
                this.isInLoadingSimilar = false;
                this.similarVehicles = res.vehicles;
            }, (/*err*/) => {
                this.isInLoadingSimilar = false;
                this.$toast.error(helpers.stdErrorMessage);
            });
        },
        formatMoney(val) {
            var formatter = new Intl.NumberFormat('it-IT', {
                style: 'currency',
                currency: 'EUR'
            });
            return formatter.format(val);
        }
    }
}
</script>

<style>
.carousel-item img {
    width: 100%; /* Adatta l'immagine alla larghezza del contenitore */
    height: auto; /* Mantiene le proporzioni */
    object-fit: cover; /* Evita distorsioni e riempie il contenitore */
}

</style>