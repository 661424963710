<template>
    <div class="container-fluid">
          <div class="card-body p-4">
          <div class="row">
              <div class="col-md-2 mb-md-0 mb-4">
                <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                    <div class="input-group input-group-dynamic">
                        <select class="form-control" v-model="company">
                          <option v-for="typ in filters.CompanyList" :key="typ.Key" :value="typ.Key">{{ typ.Value }}</option>
                        </select>
                    </div>
                </div>
              </div>
              <div class="col-md-2 mb-md-0 mb-4">
                <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                    <div class="input-group input-group-dynamic">
                      <select class="form-control" v-model="stock">
                        <option value="N">Nuovo</option>
                        <option value="U">Usato</option>
                      </select>
                    </div>
                </div>
              </div>
              <div class="col-md-2">
              <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                  <div class="input-group input-group-dynamic">
                      <label class="form-label"></label>
                      <input type="date" class="form-control" placeholder="Da" aria-label="" v-model="filters.DateFrom" >
                  </div>
              </div>
              </div>
              <div class="col-md-2">
              <div class="card card-body card-plain border-radius-lg d-flex align-items-center flex-row">
                  <div class="input-group input-group-dynamic">
                      <label class="form-label"></label>
                      <input type="date" class="form-control" placeholder="a" aria-label="" v-model="filters.DateTo" >
                  </div>
              </div>
              </div>
              <div class="col-md-2">
              <div class="card card-body card-plain d-flex align-items-center flex-row">
                  <div class="input-group input-group-dynamic ">
                      <a class="float-right align-bottom btn bg-gradient-dark mb-0" @click="LoadData()">
                        <i v-if="loading" class="material-icons text-sm text-warning rotating">autorenew</i>
                        <i v-else class="material-icons text-sm">search</i>&nbsp;&nbsp;Cerca
                      </a>
                  </div>
              </div>
              </div>
          </div>
      </div>
      <div v-if="loading" class="col-xl-11 col-lg-11 text-center py-5">
        <i class="spinner-border text-dark align-middle" role="status">
          <span class="visually-hidden">Loading...</span>
        </i>
        <h5 class="mt-3">Caricamento...</h5>
        <p>(se l'intervallo di tempo è molto ampio, l'operazione potrebbe richiedere anche alcuni minuti)</p>
      </div>
      <div v-if="!loading" class="row">
        <div class="col-lg-12 col-md-12 px-12 py-3">
          <div class="row">
            <div class="col-md-4 mb-4">
              <div class="card">
                <div class="p-3 py-2">
                  <div class="icon icon-lg icon-shape bg-gradient-info shadow text-center border-radius-xl mt-n4 position-absolute">
                    <i class="material-icons text-white opacity-10 px-2"><span>group</span></i>
                  </div>
                  <div class="text-end pt-1">
                    <p class="text-sm mb-0 text-capitalize">VENDITORI</p>
                    <h4 class="mb-0"> {{initData.sellers.length}} </h4>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-4">
              <div class="card">
                <div class="p-3 py-2">
                  <div class="icon icon-lg icon-shape bg-gradient-success shadow text-center border-radius-xl mt-n4 position-absolute">
                    <i class="material-icons text-white opacity-10 px-2"><span>check_circle</span></i>
                  </div>
                  <div class="text-end pt-1">
                    <p class="text-sm mb-0 text-capitalize">VALUTAZIONI DEKRA</p>
                    <h4 class="mb-0"> {{initData.EvaluationsCount}} </h4>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-4">
              <div class="card">
                <div class="p-3 py-2">
                  <div class="icon icon-lg icon-shape bg-gradient-danger shadow text-center border-radius-xl mt-n4 position-absolute">
                    <i class="material-icons text-white opacity-10 px-2"><span>percent</span></i>
                  </div>
                  <div class="text-end pt-1">
                    <p class="text-sm mb-0 text-capitalize">MEDIA DELTA PERCENTUALE</p>
                    <h4 class="mb-0"> {{initData.MargineTotale}} % </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!loading" class="row">
         <div :class= "{ 'col-5': rowSelected, 'col-12': !rowSelected }">
            <div class="card my-4">
               <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                  <div class="bg-gradient-light shadow-dark border-radius-lg pt-4 pb-3">
                     <h5 class="text-dark text-capitalize ps-3">Venditori</h5>
                  </div>
               </div>
               <div class="card-body px-0 pb-2">
                  <div v-if="initData.sellers && initData.sellers.length<=0" class="py-2 px-4">Nessun venditore trovato</div>
                  <div v-if="initData.sellers && initData.sellers.length>0" class="table-responsive p-0">
                     <table class="table table-hover align-items-center mb-0">
                        <thead>
                           <tr>
                              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8">Nome</th>
                              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8">Delta tot.</th>
                              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8">Percentuale delta</th>
                              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8">N° Perizie</th>
                              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"></th>
                           </tr>
                        </thead>
                        <tbody v-if="initData.sellers && initData.sellers.length>0">
                           <tr v-for="item in initData.sellers" :key="item.Id" :class="{ 'row-selected': selectedRow==item }">
                              <td>
                                 <div class="d-flex px-3 text-wrap">
                                    <div class="d-flex flex-column justify-content-center">
                                       <h6 class="mb-0 text-sm">
                                          <p class="font-weight-bold my-auto">{{item.Descrizione}}</p>
                                       </h6>
                                    </div>
                                 </div>
                              </td>
                              <td>
                                 <div class="d-flex px-3 text-wrap">
                                    <div class="d-flex flex-column justify-content-center">
                                       <p class="font-weight-bold my-auto">{{ item.Average.AverageMargin }}€</p>
                                    </div>
                                 </div>
                              </td>
                              <td>
                                 <div class="d-flex px-3">
                                    <div class="d-flex flex-column justify-content-center col-12 align-middle">
                                      <span class="col-2">{{ item.Average.AveragePercentage }}%</span>
                                      <span class="progress col-10">
                                        <span class="progress-bar" 
                                          :class="getStatusType(item.Average.AveragePercentage)" 
                                          role="progressbar" 
                                          :style="{ width: item.Average.AveragePercentage + '%' }" 
                                          :aria-valuenow=item.Average.AveragePercentage>
                                        </span>
                                      </span>
                                    </div>
                                 </div>
                              </td>
                              <td>
                                <div class="d-flex px-3">
                                  <div class="d-flex flex-column justify-content-center align-middle">
                                    <p class="my-auto">{{ item.EvaluationCount }}</p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="d-flex px-3">
                                  <div class="d-flex flex-column justify-content-center">
                                    <a type="button" @click="selectRow(item)">
                                      <i class="material-icons px-2 my-auto" style="font-size:30px;">chevron_right</i>
                                    </a>
                                  </div>
                                </div>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </div>
        <!-- <div v-if="!rowSelected" class="col-4">
          <div class="card">
            <h5 class="text-center py-3">Delta percentuale</h5>
            <BarChart class="p-2" :loader="!loading" :chartOptions="chartOptions" :chartData="chartData"/>
          </div>
        </div> -->
        <div v-if="rowSelected" class="col-7">
          <div class="card">
            <div class="p-3">
              <span class="text-uppercase text-muted text-bold m-0 text-xs">Valutazioni di</span>
              <span class="float-end text-uppercase text-muted p-1 text-xs my-auto mx-5">N. Perizie  &nbsp;&nbsp;<b style="font-size:25px">{{evaluations.length}}</b></span>
              <h5>{{selectedRow.Descrizione}}</h5>
            </div>
          </div>
          <div v-if="loadingPerizie" class="col-xl-11 col-lg-11 text-center py-5">
            <i class="spinner-border text-dark align-middle" role="status">
              <span class="visually-hidden">Loading...</span>
            </i>
          </div>
          <div v-else>
            <div class="card my-3" v-for="item in evaluations" :key="item.Id">
              <div class="card-body pt-0 pt-md-4">
                <div class="row">
                  <div class="col-md-12">
                    <div class="row" style="margin: auto">
                      <div class="col-md-6" style="margin: auto">
                        <h4 class="text-black">{{ item.EvaluationInfo.DescrizioneVeicolo }}</h4>
                      </div>
                      <div class="col-md-6" style="margin: auto; margin-top: -2rem; margin-bottom: 15px">
                        <div class="card-profile-stats d-flex justify-content-center mt-md-5">
                          <div class="text-center mx-2">
                            <div class="text-xs text-uppercase text-muted">N. Contratto</div>
                            <div class="font-weight-bold">{{item.ContractInfo.NumeroContratto}}</div>
                          </div>
                          <div class="text-center mx-2">
                            <div class="text-xs text-uppercase text-muted">Data Contratto</div>
                            <div class="font-weight-bold">{{item.ContractInfo.DataContratto}}</div>
                          </div>
                          <div class="text-center mx-2">
                            <div class="text-xs text-uppercase text-muted">Targa Perizia</div>
                            <div class="font-weight-bold">{{item.ContractInfo.TargaPerizia}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row col-md-12">
                      <div class="col-md-6" style="margin-top: -2.7rem">
                        <div class="card-profile-stats d-flex justify-content-center mt-md-5 text-white">
                          <div class="card text-center mx-2 bg-gradient-info">
                            <div class="p-3">
                              <strong>Tot. Costi Venditore</strong>
                              <div style="font-size: 20px">{{ item.EvaluationInfo.TotaleCostiPreventivi }} €</div>
                            </div>
                          </div>
                          <div class="card text-center mx-2 bg-gradient-info">
                            <div class="p-3">
                              <strong>Tot. Costi Dekra</strong>
                              <div style="font-size: 20px">{{ item.EvaluationInfo.TotaleCostiConsultivi }} €</div>
                            </div>
                          </div>
                          <div v-if="showRealCost" class="card text-center mx-2 bg-gradient-info">
                            <div class="p-3">
                              <strong>Costi Effettivi</strong>
                              <div style="font-size: 20px">{{ item.EvaluationInfo.PrezzoRealeRipristini }} €</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 my-auto">
                        <div>
                          <span class="badge badge-info my-1">Percentuale delta</span>
                          <span class="float-end my-1">{{ item.EvaluationInfo.Percentage }}%</span>
                        </div>
                        <div class="progress col-md-12">
                          <span class="progress-bar" 
                            :class="getStatusType(item.EvaluationInfo.Percentage)" 
                            role="progressbar" 
                            :style="{ width: item.EvaluationInfo.Percentage + '%' }" 
                            :height="8"
                            :aria-valuenow=item.EvaluationInfo.Percentage>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import fetchFromAPI from "../../script/api.js"
import helpers from '../../script/helpers.js';
//import BarChart from "../../components/BarChart.vue"

export default {
      components: {
        //BarChart
      },
      data() {
        return {
          filters: {},
          initData: {},
          loading: true,
          loadingPerizie: true,
          rowSelected: false,
          selectedRow: {},
          evaluations: [],
          company: 1,
          stock: 'N',
          // chartData: {
          //   labels: [],
          //   datasets: [
          //     {
          //       label: "Delta %",
          //       data: [],
          //     },
          //   ],
          // },
          // chartOptions: {
          //   responsive: true
          // }
        };
   },
   methods: {
    LoadData() {
      this.loading = true;
      this.rowSelected = false
      fetchFromAPI("RankingExpertise/loadInitData",
        {
          Company: this.company,
          Stock: this.stock,
          DateStart: this.filters.DateFrom,
          DateEnd: this.filters.DateTo,
          Token: helpers.token(),
        },
        (res) => {
          console.log(res);
          console.log(this.chartData);
          this.initData = res;
          this.chartData = {
            labels: res.GraphData.Labels,
            datasets: [
              {
                label: "Delta %",
                backgroundColor: 'rgba(255, 165, 0, 0.4)',
                data: res.GraphData.Data,
              },
            ],
          };
          console.log(this.chartData);
          this.loading = false;
        }
      );
    },
    getStatusType(value) {
      if (value <= 5) {
        return "bg-danger";
      } else if (value <= 18) {
        return "bg-warning";
      } else if (value <= 30) {
        return "bg-info";
      } else if (value > 30) {
        return "bg-success";
      }
    },
    selectRow(item){
      this.loadingPerizie = true;
      this.rowSelected=true;
      this.selectedRow=item;

      fetchFromAPI("RankingExpertise/loadEvaluations",
          {
            Company: this.company,
            Stock: this.stock,
            DateStart: this.filters.DateFrom,
            DateEnd: this.filters.DateTo,
            Token: helpers.token(),
            UserName: item.Descrizione
          },
          (res) => {
            this.evaluations = res;
            this.loadingPerizie = false;
          }
        );
    }
   },
   created() {
    fetchFromAPI("RankingExpertise/BindFiltersForHub", { Token : helpers.token() }, (res) => {
          res.DateFrom = new Date(res.DateFrom).toISOString().slice(0,10)
          res.DateTo = new Date(res.DateTo).toISOString().slice(0,10)
          this.filters = res;
          this.LoadData();
       });
   }
}
</script>

<style>
  .row-selected {
    background-color: #7b809a4f;
    color:white;
  }
</style>
