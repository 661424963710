<template>
    <div v-if="list && list.length" class="container-fluid py-4">
        <div class="row justify-content-left">
        <div class="col-lg-10">
            <div class="card">
            <div class="table-responsive">
                <table class="table align-items-center mb-0">
                <thead>
                    <tr>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Firmatario</th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Documento</th>
                    <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Tipo FEA</th>
                    <th class="text-secondary opacity-7"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in list" :key=index>
                    <td>
                        <div class="d-flex px-2 py-1">
                        <div>
                            <img v-if="item.FEAType == 1" src="../assets/img/fea.jpg" class="avatar avatar-sm me-3">
                            <img v-else src="../assets/img/handshake.jpg" class="avatar avatar-sm me-3">
                        </div>
                        <div class="d-flex flex-column justify-content-center">
                            <h6 class="mb-0 text-xs">{{item.SignNameAndSurname}}</h6>
                            <p class="text-xs text-secondary mb-0">{{item.CustomerFiscalCode}}</p>
                        </div>
                        </div>
                    </td>
                    <td>
                        <p class="text-xs font-weight-bold mb-0">{{item.AttachmentModel.PublicFileName}}</p>
                        <p class="text-xs text-secondary mb-0">{{formatDate(item.CreatedDate)}}</p>
                    </td>
                    <td class="align-middle text-center text-sm">
                        <span v-if="item.FEAType == 1" class="badge badge-sm badge-info">Sottoscrizione</span>
                        <span v-else-if="item.FEAType == 2" class="badge badge-sm badge-success">Contratto</span>
                        <span v-else-if="item.FEAType == 3" class="badge badge-sm badge-secondary">Proposta Irrevocabile di Vendita</span>
                    </td>
                    <td v-if="item.FEAStatus == 3" class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold">
                            <a target="_blank" v-if="item.AttachmentModel"  :href="'https://sa.trivellato.it/' + item.AttachmentModel.VirtualFilePath" :download="item.AttachmentModel.PublicFileName">Scarica</a>
                        </span>
                    </td>
                    </tr>
                </tbody>
                </table>
            </div>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
import helpers from '../script/helpers';
import fetchFromAPI from "../script/api.js"
export default {
      props: ['model'],
      data() {
          return{
            list:[],
          }
      },
      created(){
          fetchFromAPI("FEAWebHook/GetSignedDocuments", { Token : helpers.token(), MasterCode: this.model.FEADataModel.MasterCode, FEAType: this.model.FEADataModel.FEAType }, (res) => {
            this.list = res;
          })
      },
       methods: {
        formatDate(dateString) {
            const date = new Date(dateString);
            let dateFormatted = new Intl.DateTimeFormat('it-IT', { dateStyle: 'full', timeStyle: 'long' }).format(date);
            if(dateFormatted != "1 gennaio 1"){
                return dateFormatted;
            }
            return "";
        }
    }
}

</script>
