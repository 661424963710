<template>
<div class="page-header align-items-start min-vh-100" style="background-image: url('https://images.unsplash.com/photo-1497294815431-9365093b7331?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1950&amp;q=80');" loading="lazy">
    <span class="mask bg-gradient-dark opacity-6"></span>
    <div class="container my-auto">
      <div class="row">
        <div class="col-lg-4 col-md-8 col-12 mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div class="bg-gradient-dark shadow-dark border-radius-lg py-3 pe-1">
                <h5 class="text-white font-weight-bolder text-center mt-2 mb-0">Trivellato LogIn</h5>
                <!--<div class="row mt-3">
                  <div class="col-2 text-center ms-auto">
                    <a class="btn btn-link px-3" href="javascript:;">
                      <i class="fa fa-facebook text-white text-lg" aria-hidden="true"></i>
                    </a>
                  </div>
                  <div class="col-2 text-center px-1">
                    <a class="btn btn-link px-3" href="javascript:;">
                      <i class="fa fa-github text-white text-lg" aria-hidden="true"></i>
                    </a>
                  </div>
                  <div class="col-2 text-center me-auto">
                    <a class="btn btn-link px-3" href="javascript:;">
                      <i class="fa fa-google text-white text-lg" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>-->
              </div>
            </div>
            <div class="card-body">
              <form role="form" class="text-start">
                <div class="input-group input-group-outline my-3">
                  <input type="email" placeholder="Email" @keyup.enter="$refs.pw.focus()" v-model="email" class="form-control">
                </div>
                <div class="input-group input-group-outline mb-3">
                  <input type="password" placeholder="Password" ref="pw" @keyup.enter="LogIn" v-model="password" class="form-control">
                </div>
                <div class="form-check form-switch d-flex align-items-center mb-3">
                  <input class="form-check-input" type="checkbox" id="rememberMe">
                  <label class="form-check-label mb-0 ms-2" for="rememberMe">Ricordami</label>
                </div>
                <div class="text-center">
                  <button type="button" @click="LogIn" class="btn bg-gradient-dark w-100 my-4 mb-2">Accedi</button>
                </div>
                <p v-if="error" class="mt-4 text-sm text-center text-danger">
                    Credenziali Errate
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import User from "../script/uc.js";
//import helpers from '../script/helpers.js'

export default {
  components: {
  },
  data() {
    return {
      email: null,
      password: null,
      error: false,
    };
  },
  methods: {
    LogIn() {
      //helpers.removeToken();
      User.login(this.email, this.password, () => (this.error = true));
    }
  },
};
</script>